import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link, Router } from 'react-router-dom';
import { ArrowLeftOutlined, ShareAltOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Flex, Space, Tag, Modal, Row, Col, Input } from 'antd';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import IconRightArrowViewDashboard from "../../../../assets/icon/icon_right_arrow_view_dashboard.js";
import NoImg from "../../../../assets/img/no_img.png";
import CustomFooter from "../../../../components/Footer/Footer.js";
import "./DetailDashBoard.css";
import { DashboardService } from "../../../../services/DashboardService.js";
import { InsightReportService } from "../../../../services/InsightReportService.js";
import { useLocation } from 'react-router-dom';
import { FacebookShareButton, LineShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import icon_fb from "../../../../assets/icon/svg/icon_fb.svg";
import icon_linkedin from "../../../../assets/icon/svg/icon_linkedin.svg";
import icon_x from "../../../../assets/icon/svg/icon_x.svg";
import icon_line from "../../../../assets/icon/svg/icon_line.svg";


const DetailDashboard = ({ id }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id: urlId } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dashboardData, setDashboardData] = useState({})
    const [dashboardDataList, setDashboardDataList] = useState([]);
    const [insightDataList, setInsightDataList] = useState([]);
    const [isCopied, setIsCopied] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0); // index ปัจจุบั
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const itemsPerPage = 4;
    const itemsPerInsightPage = 4;
    const [zoomScale, setZoomScale] = useState(1);

    function isValidURL(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }
    function isLocalhost(url) {
        return url.includes("localhost");
    }

    useEffect(() => {
        
        const handleWheel = (event) => {
            if (event.ctrlKey) {
                const newScale = Math.max(0.5, Math.min(2, 1 / window.devicePixelRatio));
                setZoomScale(newScale);
            }
        };
        window.addEventListener("wheel", handleWheel);

        return () => {
            window.removeEventListener("wheel", handleWheel);
        };
    }, []);




    const handleNext = () => {
        if (currentIndex + itemsPerPage < dashboardDataList.length) {
            setCurrentIndex(currentIndex + itemsPerPage);
        }
    };

    const handlePrev = () => {
        if (currentIndex - itemsPerPage >= 0) {
            setCurrentIndex(currentIndex - itemsPerPage);
        }
    };

    const handleNext2 = () => {
        if (currentIndex2 + itemsPerInsightPage < insightDataList.length) {
            setCurrentIndex2(currentIndex2 + itemsPerInsightPage);
        }
    };

    const handlePrev2 = () => {
        if (currentIndex2 - itemsPerInsightPage >= 0) {
            setCurrentIndex2(currentIndex2 - itemsPerInsightPage);
        }
    };

    const displayedData = dashboardDataList.slice(currentIndex, currentIndex + itemsPerPage);
    const displayedData2 = insightDataList.slice(currentIndex2, currentIndex2 + itemsPerInsightPage);


    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };


    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const title = decodeURI(window.location.pathname.split("/").pop());
                if (title) {
                    const response = await DashboardService.get(title); // ยิง API
                    const ogImage = document.querySelector(`meta[property="og:image"]`);
                    if (ogImage) {
                        ogImage.setAttribute("content", response.thumbnailSrc || NoImg);
                    }
                    const metaImage = document.querySelector(`meta[name="twitter:image"]`);
                    if (metaImage) {
                        metaImage.setAttribute("content", response.thumbnailSrc || NoImg);
                    }
                    setDashboardData(response);
                    const tagIdObjects = response.dashboardTags.map((tag) => ({ tagId: tag.tagId }));
                    //console.log(tagIdObjects);
                    const tagIds = tagIdObjects.map((obj) => obj.tagId);
                    const responseList = await DashboardService.getdatalistTag(tagIds);
                    if (Array.isArray(responseList.items)) {
                        setDashboardDataList(responseList.items);
                    } else {
                        console.error("responseList.items is not an array");
                        setDashboardDataList([]);
                    }
                    const idDashboard = response.id;
                    const responseList2 = await InsightReportService.getdatalist(idDashboard);
                    if (Array.isArray(responseList2.items)) {
                        setInsightDataList(responseList2.items);
                    } else {
                        console.error("responseList.items is not an array");
                        setInsightDataList([]);
                    }
                } else {
                    console.error("No ID found in sessionStorage or URL");
                }
            } catch (err) {
                console.error("Error fetching dashboard data:", err);
                if(err.response?.status === 404) {
                    navigate("/not-found");
                }
                
            } finally {
                setIsLoading(false); // ปิดสถานะโหลด
            }
        };
        fetchDashboardData();
    }, [location.pathname, urlId]);

    const handleBackClick = () => {
        navigate('/dashboard');  // Navigate back to the /dashboard page
    }

    const handleClickInsight = (id, name) => {
        const Name = encodeURIComponent(name);
        navigate(`/insight-report/${Name}`);
        window.scrollTo(0, 0);
    };

    const handleClickDashbord = (id, name) => {
        const Name = encodeURIComponent(name);
        navigate(`/dashboard/${Name}`);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div className="dashboard-detail">
                <div className="dashboard-detail-title">
                    <span className="content-card-title">
                        <ArrowLeftOutlined  onClick={handleBackClick}/> {dashboardData?.name || ""}
                    </span>
                </div>

                <div className="dashboard-detail-body">
                    <div className="card" style={{transform: `scale(${1 / zoomScale})`}}>
                        <div className="content-dashboard-detail">
                            <div className="flex-container">
                                <div>
                                    <div className="text-left">
                                        <span className="content-card-title-detail"> {dashboardData?.name || ""}</span>
                                        <div className="tag-container">
                                            {dashboardData?.dashboardTags?.map((tagItem, tagIndex) => (
                                                <Tag 
                                                    key={tagItem.id}
                                                    bordered={false}
                                                    color={tagItem.tagColor}>
                                                    {tagItem.tagName}
                                                </Tag>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    className="share-button"
                                    onClick={showModal}
                                >
                                    <ShareAltOutlined /> แชร์
                                </Button>

                                <Modal
                                    title="แชร์ข้อมูลของคุณ"
                                    className="share-modal"
                                    open={isModalVisible}
                                    onCancel={handleCancel}
                                    footer={null} // ถ้าไม่ต้องการปุ่มใน footer ของ Modal
                                    width={500} // ความกว้างของ Modal
                                >
                                    <FacebookShareButton url={window.location.href}>
                                        <img src={icon_fb} alt="FB" width={30} height={30} title="แชร์ไปยัง Facebook"/>
                                    </FacebookShareButton>
                                    <TwitterShareButton url={window.location.href}>
                                        <img src={icon_x} alt="X" width={30} height={30} title="แชร์ไปยัง X"/>
                                    </TwitterShareButton>
                                    <LinkedinShareButton url={window.location.href}>
                                        <img src={icon_linkedin} alt="Linkedin" width={30} height={30} title="แชร์ไปยัง Linkedin"/>
                                    </LinkedinShareButton>
                                    <LineShareButton url={window.location.href}>
                                        <img src={icon_line} alt="Line" width={30} height={30} title="แชร์ไปยัง Line"/>
                                    </LineShareButton>
                                    <h4 style={{margin:"0px"}}>คัดลอก URL <small className="copied-text">{isCopied}</small></h4>
                                    <Space.Compact className="copy-url-container">
                                        <Input defaultValue={decodeURI(window.location.href)} readOnly={true}/>
                                        <CopyToClipboard text={decodeURI(window.location.href)}
                                            onCopy={() => {
                                                setIsCopied("คัดลอกแล้ว");
                                                setTimeout(() => {
                                                    setIsCopied("");
                                                }, 3000);
                                            }}>
                                            <Button type="default" className="copy-url-button">คัดลอก</Button>
                                        </CopyToClipboard>
                                    </Space.Compact>
                                    
                                </Modal>
                            </div>
                            <p className="dashboard-description" dangerouslySetInnerHTML={{ __html: dashboardData?.description || "" }} />
                            <br />
                            {dashboardData?.embedSrc && isValidURL(dashboardData.embedSrc) && !isLocalhost(dashboardData.embedSrc) ? (
                                <div className="pbi">
                                    <iframe
                                        loading="lazy"
                                        src={dashboardData.embedSrc}
                                        height="100%"
                                        width="100%"
                                        allowFullScreen={true}
                                    ></iframe>
                                </div>
                            ) : null}



                            <div className="flex-space-between">
                                {/* Left Section */}
                                <div style={{ flex: 1 }}>
                                    <h3>ประเภท</h3>
                                    <p className="link-style">{dashboardData?.dashboardCategoryName || ""}</p>
                                </div>

                                {/* Vertical Divider */}
                                <div className="vertical-line"></div>


                                <div className="flex-text-left">
                                    <h3>แหล่งข้อมูล</h3>
                                    <Link to="/data-catalog">
                                        <Button
                                            type="primary"
                                            className="responsive-button"
                                        >
                                            <CopyOutlined />
                                            <span className="button-text">แสดงแหล่งข้อมูลของแดชบอร์ด</span>
                                        </Button>
                                    </Link>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>

                {displayedData2?.length > 0 ? (
                    <div style={{ padding: '20px' }}>
                        <div style={{
                            transform: `scale(${1 / zoomScale})`
                        }}>

                            <div className="container-dashboard-detail">
                                <div className="content-dashboard-detail">
                                    <Flex justify="space-between" align="center">
                                        <span className="content-card-title-detail">รายงานที่วิเคราะห์ข้อมูลเชิงลึกที่เกี่ยวข้อง (Insight Report)</span>
                                        <Space>
                                            <Button className="arrow-left-home" onClick={handlePrev2}>
                                                <HiOutlineArrowLeft />
                                            </Button>
                                            <Button className="arrow-right-home" onClick={handleNext2}>
                                                <HiOutlineArrowRight />
                                            </Button>
                                        </Space>
                                    </Flex>
                                </div>
                                <div className="container-card-insight-report-detail">
                                    <Space size="middle">
                                        <Row style={{ width: "calc(100vw - 80px)" }}>
                                            {displayedData2?.map((item) => (
                                                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
                                                    <div className="card-container">
                                                        <div key={item.id} className="content-card-item">
                                                            <div className="content-image-container">
                                                                <img
                                                                    className="content-image-insight-report-detail-2"
                                                                    alt="error"
                                                                    src={item.thumbnailSrc ? item.thumbnailSrc : NoImg}
                                                                    onError={(e) => {
                                                                        if (e.target.alt === 'error') {
                                                                            //console.log("No img")
                                                                            e.target.src = NoImg;
                                                                        } else {
                                                                            e.target.src = NoImg;
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="card-detail-container">
                                                                <Space>
                                                                    {item.insightReportTags?.map((tag) => (
                                                                        <Tag
                                                                            key={tag.id}
                                                                            bordered={false}
                                                                            color={tag.tagColor}
                                                                        >
                                                                            {tag.tagName}
                                                                        </Tag>
                                                                    ))}
                                                                </Space>
                                                                <p className="content-card-title-insight-report-detail">{item.name}</p>
                                                                <p className="content-card-detail-insight-report-detail">{item.shortDescription}</p>
                                                                <p className="content-card-detail-dashboard-detail">{item.createdDateFrom}</p>
                                                                <Flex
                                                                    className="detail-button-footer"
                                                                    justify="center"
                                                                    align="center"
                                                                    onClick={() => handleClickInsight(item.id, item.name)}
                                                                >
                                                                    <p>
                                                                        <span>อ่านต่อ</span>
                                                                        <IconRightArrowViewDashboard />
                                                                    </p>
                                                                </Flex>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Space>
                                </div>
                            </div>
                        </div>

                    </div>
                ) : null}

                {displayedData?.length > 0 ? (
                    <div style={{ padding: '20px' }}>
                        <div style={{
                            transform: `scale(${1 / zoomScale})`
                        }}>
                            <div className="container-dashboard-detail">
                                <div className="content-dashboard-detail">
                                    <Flex justify="space-between" align="center">
                                        <span className="content-card-title-detail">แดชบอร์ดที่เกี่ยวข้อง</span>
                                        <Space>
                                            <Button className="arrow-left-home" onClick={handlePrev}>
                                                <HiOutlineArrowLeft />
                                            </Button>
                                            <Button className="arrow-right-home" onClick={handleNext}>
                                                <HiOutlineArrowRight />
                                            </Button>
                                        </Space>
                                    </Flex>
                                </div>

                                <div className="container-card-insight-report-detail">
                                    <Space size="middle">
                                        <Row style={{ width: "calc(100vw - 80px)" }}>
                                            {displayedData.map((item, index) => (
                                                <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
                                                    <div className="card-container">
                                                        <div key={index} className="content-card-item">
                                                            <div>
                                                                <img
                                                                    className="content-image-insight-report-detail-2"
                                                                    alt="error"
                                                                    src={item.thumbnailSrc ? item.thumbnailSrc : NoImg}
                                                                    onError={(e) => {
                                                                        if (e.target.alt === 'error') {
                                                                            //console.log("No img");
                                                                            e.target.src = NoImg;
                                                                        } else {
                                                                            e.target.src = NoImg;
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="card-detail-container">
                                                                <Space>
                                                                    {item.dashboardTags &&
                                                                        item.dashboardTags.map((tagItem, tagIndex) => (
                                                                            <Tag
                                                                                key={tagIndex}
                                                                                bordered={false}
                                                                                color={tagItem.tagColor}
                                                                            >
                                                                                {tagItem.tagName}
                                                                            </Tag>
                                                                        ))}
                                                                </Space>
                                                                <p className="content-card-title-insight-report-detail">{item.name}</p>
                                                                <p className="content-card-detail-insight-report-detail">
                                                                    {item.shortDescription}
                                                                </p>
                                                                <p className="dashboard-date">
                                                                </p>

                                                                <Flex
                                                                    className="detail-button-footer"
                                                                    justify="center"
                                                                    align="center"
                                                                    onClick={() => handleClickDashbord(item.id, item.name)}
                                                                >
                                                                    <p>
                                                                        <span>ดูรายละเอียด</span>
                                                                        <IconRightArrowViewDashboard />
                                                                    </p>
                                                                </Flex>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Space>
                                </div>

                            </div>
                        </div>
                    </div >
                ) : null}


                <CustomFooter />
            </div >
            {isLoading && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="spinner"></div>
                        <p>กรุณารอซักครู่...</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default DetailDashboard;



