import "./DataCatalog.css";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Tabs,
  Space,
  DatePicker,
  Flex,
  Button,
  Col,
  Row,
  Tag,
  Grid,
  Layout,
  Menu,
  Input,
} from "antd";
import { LiaReadme } from "react-icons/lia";
import { TagService } from "../../../services/TagService";
import TabPane from "antd/es/tabs/TabPane";
import NoData from "../../../assets/img/no_data.js";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { DataSourceService } from "../../../services/DataSourceService.js";
import { DataSourceCategoryService } from "../../../services/DataSourceCategoryService.js";
import { DataSourceTagService } from "../../../services/DataSourceTagService";
import { useCookies } from "react-cookie";
import dayjs from 'dayjs';

const { useBreakpoint } = Grid;

const DataCatalog = () => {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [dataTag, setDataTag] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceCategory, setDataSourceCategory] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [keywords, setKeywords] = useState([]);
    const [cookies, setCookie] = useCookies(['data-catalog-condition']);
    const config = cookies['data-catalog-condition'];

    const menuDownload = (
        <Menu
            items={[
                {
                    key: '1',
                    label: 'ดาวน์โหลด Excel',
                },
                {
                    key: '2',
                    label: 'ดาวน์โหลด CSV',
                },
            ]}
        />
    );


    const fetchDataTags = async () => {
        try {
          const response = await DataSourceTagService.get();
          if(config && config.TagIds){
            setSelectedTags(config.TagIds);
          }

          if(config && config.LastCreatedDate && config.LastCreatedDate.length > 0){
            setSelectedDate(config.LastCreatedDate);
          }
          console.log("response == ",response)
          setDataTag(response);
        } catch (error) {
          console.error("Error fetching tags:", error);
        } finally {
        }
    };

    const fetchDataSource = async () => {
        try {
    
          const params = {
            TagIds:selectedTags,
            LastCreatedDate: selectedDate,
            Keywords: keywords,
            IsActivated: true,
          };
          if(localStorage.getItem('cookie') === 'true'){
            setCookie('data-catalog-condition', JSON.stringify(params), { path: '/' });
          }
          setTimeout(async () => {
            const response = await DataSourceService.searchPublic(params);
            setDataSource(response.items);
          }, 150); 
            
    
          //setLoading(false);
        } catch (error) {
          //setLoading(false);
          console.error(error);
        }
      };

    const fetchDataSourceCategory = async () => {
        try {
    
          const params = {
            //TagIds:selectedTags,
            //CreatedDateFrom: selectedDateRange[0],
            //CreatedDateTo: selectedDateRange[1],
            //Keywords: value,
            IsActivated: true,
          };
          
          setTimeout(async () => {
            const response = await DataSourceCategoryService.search(params);
            setDataSourceCategory(response.items);
          }, 150); 
            
    
          //setLoading(false);
        } catch (error) {
          //setLoading(false);
          console.error(error);
        }
      };

      const tagRender = (props) => {
        const { label, value, color, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            color={color}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
              marginInlineEnd: 4,
            }}
          >
            {label}
          </Tag>
        );
      };

      useEffect(() => {
        fetchDataTags();
        fetchDataSourceCategory();
      }, []);

      useEffect(() => {
        fetchDataSource();
      }, [selectedTags,selectedDate,keywords]);

      const headTaps =screens.lg ? (<div className="tabs-header">ประเภทระบบฐานข้อมูล</div>):("") ;


    const isSelectedTag = (key) => selectedTags.includes(key);
    const toggleButtonTag = (key) => {
        if (selectedTags.includes(key)) {
        setSelectedTags(selectedTags.filter((item) => item !== key));
        } else {
        setSelectedTags([...selectedTags, key]);
        }
    };

    const handleChangeDate = (dates,datesStr) => {
        let dateStr = ""
        if(dates != null){
            dateStr = dates.format("YYYY-MM-DD")
        }
        setSelectedDate(dateStr)
    };

    let handleInputSearchTimeout;
    const handleInputSearch = (value) => {
        if (handleInputSearchTimeout) clearTimeout(handleInputSearchTimeout);
        handleInputSearchTimeout = setTimeout(() => {
            setKeywords(value)
        }, 600); 
    };


    return (
    <>
       <div className="datacatalog-body" >
            <div className="datacatalog-content">
            <Tabs
                defaultActiveKey="1"
                tabPosition={screens.lg ? ("left"):("top")}
                style={{
                height: "max",
                }}
                tabBarExtraContent={{left: headTaps}}>
                    
                
                

            {dataSourceCategory
            ?.filter(
                (item) => item.count != 0
              )
            .map((itemCategory, index) => (
                <TabPane tab={itemCategory.name} key={itemCategory.id}>
                <>
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{
                        display: "flex",
                        
                      }}
                    >
                    
                          <div
                            style={{
                              padding: "20px",
                              paddingLeft:"30px",
                              background: "#FFF",
                              borderRadius: "8px",
                              
                            }}
                          >
                                <Flex justify="space-between" align="center" style={{paddingBottom:20}}>
                                <span style={{ fontSize: "18px", fontWeight: "600" }}> ดาต้าแค็ตตาล๊อค </span>
                                <Button type={"primary"}><LiaReadme />ขอใช้ API</Button>
                                </Flex>
                                <hr />
                                <div className="datacatalog-tag">
                                    <Row>
                                    <Col xs={4} sm={3} md={4} lg={2} xl={2}>
                                        <h3>Tag : </h3>
                                    </Col>
                                    <Col
                                        sm={20}
                                        md={20}
                                        lg={22}
                                        xl={20}
                                        // flex={"20"}
                                        className="group-btn-tag"
                                        style={{alignContent:"center"}}
                                    >
                                        <Flex wrap>
                                        {dataTag?.map((tag) => (
                                            <Button shape="round" key={tag.tagId} className={isSelectedTag(tag.tagId) ? "btn-tag-selected" : "btn-tag"}
                                                onClick={() => toggleButtonTag(tag.tagId)}
                                            >{tag.tagName}</Button>
                                        ))}
                                        </Flex>
                                    </Col>
                                    </Row>
                                </div>
                                <hr />

                                <div className="datacatalog-search" >
                                    <Row gutter={[16,8]}>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
                                        <h3>กรองโดย : </h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={9} lg={7} xl={5} xxl={5}
                                        className="group-type"
                                        style={{alignContent:"center" }}
                                        >
                                        <DatePicker
                                            onChange={handleChangeDate}
                                            placeholder="DD/MM/YYYY"
                                            inputReadOnly={true}
                                            size="large"
                                            format={'DD/MM/YYYY'}
                                            style={{
                                            width: "100%",
                                            borderRadius: "8px",
                                            }}
                                            value={selectedDate ? dayjs(selectedDate) : null}
                                        />
                                        </Col>
                                        {/* <Col xs={24} sm={24} md={10} lg={5} xl={5} xxl={5}
                                        className="group-type"
                                        style={{alignContent:"center"}}
                                        >
                                            <Select
                                                size="large"
                                                mode="multiple"
                                                tagRender={tagRender}
                                                defaultValue={["all"]}
                                                //onChange={handleChangeType}
                                                //value={selectedInsightType}
                                                style={{
                                                width: "100%",
                                                }}
                                                options={dataSourceCategory}
                                            />
                                        </Col> */}
                                        <Col
                                        xs={24}
                                        sm={24}
                                        md={11}
                                        lg={10}
                                        xl={11}
                                        xxl={11}
                                        className="group-type"
                                        style={{alignContent:"center"}}
                                        >
                                            <Input

                                                size="large"
                                                //value={queryStr}
                                                placeholder="ค้นหาข้อมูล..."
                                                //onPressEnter={(e) => handleInputSearch(e.target.value)}
                                                onChange={(e) => handleInputSearch(e.target.value)}
                                                suffix={
                                                <SearchOutlined
                                                    // onClick={() => handleSearch()}
                                                    style={{
                                                    color: "rgba(0,0,0,.45)",
                                                    }}
                                                />
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <hr />
                                <div className="datacatalog-item" >
                                    <Row gutter={[16,8]}>
                                        {dataSource
                                        ?.filter(
                                        (item) => item.dataSourceCategoryId === itemCategory.id
                                        )
                                        //.sort((a, b) => a.sequence - b.sequence)
                                        .map((item) => (
                                            
                                            <Col xs={24}  key={item.id}>
                                                    <div className="datacatalog-item-data">
                                                        <Row justify={"space-between"} >
                                                            <Col xs={24} sm={12} md={14} style={{alignContent:"center"}}>
                                                                <Row style={{paddingBottom:10}}>
                                                                    <span className="link" onClick={()=>navigate(`/data-catalog/${item?.name}`) }>{item?.name}</span>
                                                                    
                                                                </Row>
                                                                
                                                                <Row style={{paddingBottom:5}}>
                                                                <Flex wrap gap="small">

                                                                    {item?.dataSourceTags
                                                                    .slice(0, 3)
                                                                    .map((tag) => (
                                                                        <Tag
                                                                            key={tag.id}
                                                                            bordered={false}
                                                                            color={tag.tagColor}
                                                                        >
                                                                            {tag.tagName}
                                                                        </Tag>
                                                                    ))}
                                                                
                                                                </Flex>
                                                                </Row>
                                                                <Row>
                                                                <Flex wrap gap="small">
                                                                    <span style={{color:"#667085" ,marginBottom:5}}>อัพเดทล่าสุด : {item?.formattedUpdatedDate}</span>
                                                                </Flex>
                                                                </Row>
                                                                
                                                            
                                                            </Col>
                                                            <Col xs={24} sm={8} md={8
                                                                
                                                            } lg={6} xl={5} xxl={5} style={{alignContent:"start", textAlign:"end"}}>
                                                            {/* <Dropdown overlay={menuDownload} trigger={['click']}> */}
                                                                    <Button
                                                                        type="default"
                                                                        className=""
                                                                        style={{
                                                                            border: "1px solid #1677FF",
                                                                            color:"#1677FF",
                                                                            borderRadius:"10px",
                                                                            width:"100%"
                                                                        }}
                                                                    >
                                                                        <UploadOutlined/>
                                                                        <span className="">ดาวน์โหลดข้อมูล</span>
                                                                    </Button>

                                                                {/* </Dropdown> */}
                                                            </Col>
                                                        </Row>
                                                        <Row justify={"space-between"} >
                                                            
                                                            <Col xs={24}  style={{alignContent:"start", textAlign:"left"}}>
                                                                    {/* <p>{item.description}</p> */}
                                                                    <div dangerouslySetInnerHTML={{ __html: item.description }} />;
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                    
                                                <hr/>
                                            </Col>
                                            
                                        
                                        ))}

                                        {dataSource?.filter((item) => item.dataSourceCategoryId === itemCategory.id)
                                        .length === 0 && (
                                            <Col xs={24} >
                                                    <Flex
                                                    vertical
                                                    justify="center"
                                                    align="center"
                                                    style={{ height: "50vh" }}
                                                    >
                                                    <NoData />
                                                    <p
                                                        style={{
                                                        fontSize: "14px",
                                                        fontWeight: "600",
                                                        color: "#667085",
                                                        }}
                                                    >
                                                        ไม่มีข้อมูล
                                                    </p>
                                                </Flex>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                          </div>
                    </Space>
                
                </>
                </TabPane>
            ))}
               
            </Tabs>
            </div>
        {/* <Radio.Group
            onChange={handleModeChange}
            value={mode}
            style={{
            marginBottom: 8,
            }}
        >
            <Radio.Button value="top">Horizontal</Radio.Button>
            <Radio.Button value="left">Vertical</Radio.Button>
        </Radio.Group> */}
       

        </div>
    </>
    )
   
    
}

export default DataCatalog;