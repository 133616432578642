import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Form,
  Input,
  InputNumber,
  Divider,
  Switch,
  Spin,
  Col,
  Row,
} from "antd";
import "./GlobalConfigure.css";
import FormItem from "../../../components/FormItem/FormItem";
// import CustomButton from "../../../components/Button/CustomButton";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../components/Dialog/FailedDialog";
import NavOcelot from "../../../components/Nav/NavOcelot";
import FooterOcelot from "../../../components/Footer/FooterOcelot";
import { ReRouteService } from "../../../services/ReRouteService";

const { TextArea } = Input;

const GlobalConfigure = () => {
  const [initialData, setInitialData] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [baseUrl, setBaseUrl] = useState("");
  const [disableRateLimitHeaders, setDisableRateLimitHeaders] = useState();
  const [clientIdHeader, setClientIdHeader] = useState("");
  const [quotaExceededMessage, setQuotaExceededMessage] = useState("");
  const [httpStatusCode, sethttpStatusCode] = useState();

  useEffect(() => {
    if (!initialData) {
      fetchData();
    }
  }, [initialData]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await ReRouteService.getGlobalConfig();
      setInitialData(response);
      setBaseUrl(response?.globalConfiguration?.baseUrl);
      setDisableRateLimitHeaders(
        response?.globalConfiguration?.rateLimitOptions?.disableRateLimitHeaders
      );
      setClientIdHeader(
        response?.globalConfiguration?.rateLimitOptions?.clientIdHeader
      );
      setQuotaExceededMessage(
        response?.globalConfiguration?.rateLimitOptions?.quotaExceededMessage
      );
      sethttpStatusCode(
        response?.globalConfiguration?.rateLimitOptions?.httpStatusCode
      );

      setLoading(false);
    } catch (error) {
      // console.error(error);
    }
  };

  const handleSubmit = async () => {
    let updateData = {
      globalConfiguration: {
        baseUrl: baseUrl,
        rateLimitOptions: {
          clientIdHeader: "ClientId",
          disableRateLimitHeaders: disableRateLimitHeaders,
          quotaExceededMessage: quotaExceededMessage,
          httpStatusCode: httpStatusCode,
        },
      },
    };

    try {
      const response = await ReRouteService.setGlobalConfig(updateData);

      setIsModalSuccessOpen(true);
      setIsChanged(false);
      localStorage.removeItem("isChanged")
      setTimeout(() => {
        fetchData();
        setIsModalSuccessOpen(false);
      }, 2000);
    } catch (erro) {
      // console.error();
      setIsModalFailedOpen(true);
    }
  };

  return (
    <>
      <div className="global-config-body">
        <Col>
          <Row>
            <NavOcelot width={1144} />
          </Row>
          <Row>
            <div className="global-config-container">
              <p className="text-title">Global Configuration</p>
              <p className="text-suptitle">
                Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
                phasellus mollis sit <br /> aliquam sit nullam neque ultrices.
              </p>
              <Spin spinning={loading}>
                <Form
                  hideRequiredMark
                  layout="vertical"
                  style={{ marginTop: "30px" }}
                >
                  <FormItem
                    label="Base URL"
                    required={true}
                    requiredMessage="This filed is required."
                  >
                    <Input
                      className="custom-input"
                      type="text"
                      placeholder="http://xxx:0000"
                      value={baseUrl}
                      onChange={(e) => {
                        setBaseUrl(e.target.value);
                        setIsChanged(true);
                        sessionStorage.setItem("isChanged", true);
                      }}
                      style={{
                        width: "514.5px"
                      }}
                    />
                  </FormItem>

                  <Divider className="custom-divder" />

                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "#0063A7",
                    }}
                  >
                    Global rate limit options
                  </p>

                  <FormItem label="Disable rate limit headers">
                    {initialData && (
                      <Switch
                        checkedChildren="True"
                        unCheckedChildren="False"
                        defaultChecked={disableRateLimitHeaders}
                        onChange={(e) => {
                          setDisableRateLimitHeaders(e);
                          setIsChanged(true);
                          sessionStorage.setItem("isChanged", true);
                        }}
                      />
                    )}
                  </FormItem>

                  <FormItem label="Quota exceeded message">
                    <TextArea
                      className="custom-textarea"
                      rows={4}
                      placeholder="message"
                      value={quotaExceededMessage}
                      onChange={(e) => {
                        setQuotaExceededMessage(e.target.value);
                        setIsChanged(true);
                        sessionStorage.setItem("isChanged", true);
                      }}
                    />
                  </FormItem>

                  <FormItem label="Http status code" required={true}>
                    <InputNumber
                      className="custom-input"
                      value={httpStatusCode}
                      disabled={true}
                      style={{
                        width: "514.5px"
                      }}
                    />
                  </FormItem>

                  {/* <CustomButton
                    text="Save"
                    type="primary"
                    // htmlType="submit"
                    width={61}
                    height={32}
                    disabled={!isChanged}
                    onClick={() => setIsModalOpen(true)}
                  /> */}
                </Form>
              </Spin>

              <ConfirmDialog
                isModalOpen={isModalOpen}
                closable={false}
                title="Confirm Save"
                cancelText="Cancel"
                confirmText="Confirm"
                onCancel={() => {
                  setIsModalOpen(false);
                }}
                onConfirm={() => {
                  handleSubmit();
                }}
                description="Do you want to Save data ?"
              />

              <SuccessDialog
                isModalOpen={isModalSuccessOpen}
                closable={false}
                title="Success"
                detail="Success save data."
                onCancel={() => {
                  setIsModalSuccessOpen(false);
                }}
                onAfterClose={() => {
                  // alert();
                }}
              />

              <FailedDialog
                isModalOpen={isModalFailedOpen}
                closable={false}
                title="Failed"
                detail="Something went wrong. Please try again."
                onCancel={() => {
                  setIsModalFailedOpen(false);
                }}
                onAfterClose={() => {
                  // alert("Dialog dissmiss");
                }}
                onConfirm={() => {
                  // handleSubmit();
                  setIsModalFailedOpen(false);
                }}
              />
            </div>
          </Row>
        </Col>
      </div>
      <FooterOcelot
        // disabled={!isChanged}
        onSave={() => {
          setIsModalOpen(true);
        }}
        onCancel={() => {
          // handleCancel();
        }}
      />
    </>
  );
};

export default GlobalConfigure;
