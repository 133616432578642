import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Layout,
    Button,
    Divider,
    Input,
    Row,
    Col,
    Card,
    Checkbox
} from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { Header, Content } = Layout;

const MangeConsentDetail = () => {
    const navigate = useNavigate();
    const quillRef = useRef(null);
    const [data, setData] = useState({
        description: "",
        name: "",
        noExpiry: false,
        year: "",
        month: "",
        day: ""
    });
    const [detailError, setDetailError] = useState(null);
    const location = useLocation();
    const { catagoryId, selectedData } = location.state || {};

    useEffect(() => {
        if (selectedData) {
            setData({
                name: selectedData.name || "",
                description: selectedData.description || "",
                noExpiry: selectedData.noExpiry || false,
                year: selectedData.year || "",
                month: selectedData.month || "",
                day: selectedData.day || ""
            });
        }
    }, [selectedData]);

    const handleOnEditTextChange = (value) => {
        // Trim whitespace and check for empty content or default placeholder
        const cleanedValue = value.trim() === "<p><br></p>" ? "" : value;
    
        if (cleanedValue.length <= 15000) {
            setData((prevData) => ({ ...prevData, description: cleanedValue }));
            setDetailError(null);
        } else {
            setDetailError("ข้อความยาวเกินไป (สูงสุด 15000 ตัวอักษร)");
        }
    };
    

    const handleNameChange = (e) => {
        const { value } = e.target;
        setData((prevData) => ({ ...prevData, name: value }));
    };

    const handleCheckboxChange = (e) => {
        setData((prevData) => ({ ...prevData, noExpiry: e.target.checked }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSaveDraft = () => {
        console.log("Draft saved:", data);
        // TODO: Add API call here for saving draft
    };

    const handleSaveAndPublish = () => {
        console.log("Saved and published:", data);
        // TODO: Add API call here for saving and publishing
    };

    const getDetailLength = () => data.description.length;

    const modules = {
        toolbar: [
          { header: [1, 2, 3, 4, 5, 6, false] },
          "bold",
          "italic",
          "underline",
          "link",
          // { list: "bullet" },
          // { list: "ordered" },
        ],
        htmlEditButton: {
          debug: true,
          msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
          okText: "Ok", // Text to display in the OK button, default: Ok,
          cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
          buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
          buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
          syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
          prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
          editorModules: {}, // The default mod
        },
      };
      
    return (
        <div className="dashboard-management-body">
            <Layout>
                <Header
                    style={{
                        height: "70px",
                        background: "#FFF",
                        borderBottom: "1px solid #D0D5DD",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <AiOutlineArrowLeft
                            style={{ fontSize: "24px", cursor: "pointer" }}
                            onClick={() => navigate("/management/manage-consent")}
                        />
                        <p style={{ margin: "0", fontSize: "18px", fontWeight: "600" }}>
                            สร้างฟอร์ม
                        </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <span style={{ color: "blue", textDecoration: "underline" }} onClick={handleSaveDraft}>
                            บันทึกฉบับร่าง
                        </span>
                        |
                        <Button onClick={() => console.log("Cancelled")}>ยกเลิก</Button>
                        <Button type="primary" onClick={handleSaveAndPublish}>บันทึกและเผยแพร่</Button>
                    </div>
                </Header>
                <Layout style={{ height: "100vh" }}>
                    <Content style={{ padding: "20px" }}>
                        <Row justify="center" gutter={[16, 16]}>
                            <Col span={17}>
                                <div
                                    style={{
                                        width: "80%",
                                        margin: "0 auto",
                                        padding: "14px",
                                        background: "#FFF",
                                        borderRadius: "8px",
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                            อัปเดตล่าสุด : <span style={{ fontSize: "14px", fontWeight: "300" }}>
                                                {selectedData?.createBy}, {selectedData?.date}
                                            </span>
                                        </span>
                                        Version :
                                    </div>
                                    <Divider />
                                    <div style={{ marginBottom: "16px" }}>
                                        <label style={{ fontSize: "14px", fontWeight: "600" }}>ชื่อ:</label>
                                        <Input
                                            placeholder="กรอกชื่อ"
                                            style={{ marginTop: "8px" }}
                                            value={data.name}
                                            onChange={handleNameChange}
                                        />
                                    </div>
                                    <div style={{ marginBottom: "16px" }}>
                                        <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                            ระยะเวลาการยินยอม <span style={{ color: "red" }}>*</span><br />
                                            <span style={{ fontSize: "12px", color: "#F0536F" }}>
                                                จำเป็นต้องระบุค่า หากไม่มีค่าให้ระบุเป็น 0
                                            </span>
                                            <br />
                                            <Checkbox checked={data.noExpiry} onChange={handleCheckboxChange}>
                                                ไม่มีวันหมดอายุ ตราบเท่าที่ยังไม่มีการถอนการยินยอม
                                            </Checkbox>
                                        </span>
                                        <div style={{ display: "flex", gap: "10px", marginTop: "8px", alignItems: "center" }}>
                                            <Input placeholder="ปี" name="year" value={data.year} onChange={handleInputChange} style={{ width: "60px" }} /> ปี
                                            <Input placeholder="เดือน" name="month" value={data.month} onChange={handleInputChange} style={{ width: "60px" }} /> เดือน
                                            <Input placeholder="วัน" name="day" value={data.day} onChange={handleInputChange} style={{ width: "60px" }} /> วัน
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={17}>
                                <Card
                                    bordered={true}
                                    style={{
                                        width: "80%",
                                        margin: "0 auto",
                                        backgroundColor: "#FFFFFF",
                                        textAlign: "left",
                                        padding: "16px",
                                        borderTop: "8px solid #000000",
                                    }}
                                >
                                    <p style={{ margin: "0", fontSize: "18px", fontWeight: "600" }}>
                                        หัวข้อ / คำอธิบาย <span style={{ color: "red" }}>*</span>
                                    </p>
                                    <br />
                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "flex-end" }}>
                                        <ReactQuill
                                            ref={quillRef}
                                            theme="snow"
                                            value={data.description}
                                            modules={modules}
                                            style={{ height: 300, width: "100%" }}
                                            onChange={handleOnEditTextChange}
                                        />
                                        {detailError && <span style={{ color: "red" }}>{detailError}</span>}
                                        <span
                                            style={{
                                                color: "#9ca0a6",
                                                marginTop: detailError ? 0 : 40,
                                            }}
                                        >
                                            {getDetailLength()}/15000
                                        </span>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
};

export default MangeConsentDetail;
