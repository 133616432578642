import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white"/>
<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#BCC2CB"/>
<path d="M12.4375 8.3125H12.25C12.3531 8.3125 12.4375 8.22812 12.4375 8.125V8.3125H19.5625V8.125C19.5625 8.22812 19.6469 8.3125 19.75 8.3125H19.5625V10H21.25V8.125C21.25 7.29766 20.5773 6.625 19.75 6.625H12.25C11.4227 6.625 10.75 7.29766 10.75 8.125V10H12.4375V8.3125ZM24.25 10H7.75C7.33516 10 7 10.3352 7 10.75V11.5C7 11.6031 7.08437 11.6875 7.1875 11.6875H8.60312L9.18203 23.9453C9.21953 24.7445 9.88047 25.375 10.6797 25.375H21.3203C22.1219 25.375 22.7805 24.7469 22.818 23.9453L23.3969 11.6875H24.8125C24.9156 11.6875 25 11.6031 25 11.5V10.75C25 10.3352 24.6648 10 24.25 10ZM21.1398 23.6875H10.8602L10.293 11.6875H21.707L21.1398 23.6875Z" fill="#2E323A"/>
<path d="M12.4375 8.3125H12.25C12.3531 8.3125 12.4375 8.22812 12.4375 8.125V8.3125H19.5625V8.125C19.5625 8.22812 19.6469 8.3125 19.75 8.3125H19.5625V10H21.25V8.125C21.25 7.29766 20.5773 6.625 19.75 6.625H12.25C11.4227 6.625 10.75 7.29766 10.75 8.125V10H12.4375V8.3125ZM24.25 10H7.75C7.33516 10 7 10.3352 7 10.75V11.5C7 11.6031 7.08437 11.6875 7.1875 11.6875H8.60312L9.18203 23.9453C9.21953 24.7445 9.88047 25.375 10.6797 25.375H21.3203C22.1219 25.375 22.7805 24.7469 22.818 23.9453L23.3969 11.6875H24.8125C24.9156 11.6875 25 11.6031 25 11.5V10.75C25 10.3352 24.6648 10 24.25 10ZM21.1398 23.6875H10.8602L10.293 11.6875H21.707L21.1398 23.6875Z" fill="black" fillOpacity="0.2"/>
<path d="M12.4375 8.3125H12.25C12.3531 8.3125 12.4375 8.22812 12.4375 8.125V8.3125H19.5625V8.125C19.5625 8.22812 19.6469 8.3125 19.75 8.3125H19.5625V10H21.25V8.125C21.25 7.29766 20.5773 6.625 19.75 6.625H12.25C11.4227 6.625 10.75 7.29766 10.75 8.125V10H12.4375V8.3125ZM24.25 10H7.75C7.33516 10 7 10.3352 7 10.75V11.5C7 11.6031 7.08437 11.6875 7.1875 11.6875H8.60312L9.18203 23.9453C9.21953 24.7445 9.88047 25.375 10.6797 25.375H21.3203C22.1219 25.375 22.7805 24.7469 22.818 23.9453L23.3969 11.6875H24.8125C24.9156 11.6875 25 11.6031 25 11.5V10.75C25 10.3352 24.6648 10 24.25 10ZM21.1398 23.6875H10.8602L10.293 11.6875H21.707L21.1398 23.6875Z" fill="black" fillOpacity="0.2"/>
</svg>









);

const Icon_Bin_Admin = (props) => <Icon component={icon} {...props} />;

export default Icon_Bin_Admin;

