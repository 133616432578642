import React, { useState, useEffect } from "react";
import "./Nav.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, Space, Flex, Row, Col, Input, Grid } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const NavDashboardInsight = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const screens = useBreakpoint();
  const [queryStr, setQueryStr] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const pathToTabKey = {
    "/dashboard": "1",
    "/insight-report": "2",
  };

  useEffect(() => {
    const key = pathToTabKey[currentPath] || "1";
    setActiveKey(key);
  }, [currentPath]);

  const handleTabChange = (key) => {
    if (key === "1") {
      navigate("/dashboard");
    } else if (key === "2") {
      navigate("/insight-report");
    }
  };

  // const handleSearch = () => {
  //   switch (currentPath) {
  //     case "/dashboard":
  //       navigate(`/dashboard?search=${queryStr}`);
  //       break;
  //     case "/insight-report":
  //       navigate(`/insight-report?search=${queryStr}`);
  //       break;
  //     default:
  //       navigate(`/dashboard?search=${queryStr}`);
  //       break;
  //   }
  // };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      // if (queryStr) {
      setDebouncedQuery(queryStr);
      // }
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [queryStr]);

  useEffect(() => {
    // if (debouncedQuery) {
    switch (currentPath) {
      case "/dashboard":
        navigate(`/dashboard?search=${debouncedQuery}`);
        break;
      case "/insight-report":
        navigate(`/insight-report?search=${debouncedQuery}`);
        break;
      default:
        navigate(`/dashboard?search=${debouncedQuery}`);
        break;
    }
    // }
  }, [debouncedQuery]);

  return (
    <>
      <div className="header-dashboard-insight-container" style={{
        top: screens.md ? "0px" : "0px",
        height: screens.md ? "86px" : "auto",
      }}>
        <div
          className="header-dashboard-insight-content"
          style={{ padding: !screens.md ? "0px 10px 0px 10px" : "" }}
        >
          <Row
            gutter={[16, 16]}
            style={{ alignItems: "flex-end", height: "100%" }}
          >
            <Col xs={24} sm={24} md={18}>
              {/* <Col> */}
              <div className="tab-container">
                <Tabs
                  // defaultActiveKey="1"
                  activeKey={activeKey}
                  onChange={handleTabChange}
                  tabBarStyle={
                    {
                      //display: "flex",
                      //justifyContent: "space-around",
                    }
                  }
                >
                  <TabPane
                    tab={
                      <p
                        style={{
                          margin: 0,
                          marginBottom: "6px",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        แดชบอร์ด
                      </p>
                    }
                    key="1"
                  />
                  <TabPane
                    tab={
                      <p
                        style={{
                          margin: 0,
                          marginBottom: "6px",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        รายงานวิเคราะห์ข้อมูลเชิงลึก
                      </p>
                    }
                    key="2"
                  />
                </Tabs>
              </div>
            </Col>
            {screens.md && (
              <Col
                xs={24}
                sm={24}
                md={6}
                className="col-dashboard-insight-search"
                align="right"
              >
                <div className="dashboard-insight-search">
                  <Input
                    onChange={(e) => setQueryStr(e.target.value)}
                    size="large"
                    value={queryStr}
                    placeholder="ค้นหาข้อมูล..."
                    // onPressEnter={() => handleSearch()}
                    suffix={
                      <SearchOutlined
                        // onClick={() => handleSearch()}
                        style={{
                          color: "rgba(0,0,0,.45)",
                        }}
                      />
                    }
                  />
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
      {!screens.md && (
        <div
          className="header-dashboard-insight-condition-container"
          style={{
            // position: "sticky",
            // top: "195.2px",
            zIndex: 1099,
          }}
        >
          <div
            className="header-dashboard-insight-content"
            style={{
              padding: !screens.md ? "0px 20px 0px 20px" : "",
            }}
          >
            <div style={{ position: "relative", top: "25%" }}>
              <Input
                onChange={(e) => setQueryStr(e.target.value)}
                size="large"
                value={queryStr}
                placeholder="ค้นหาข้อมูล..."
                // onPressEnter={() => handleSearch()}
                suffix={
                  <SearchOutlined
                    // onClick={() => handleSearch()}
                    style={{
                      color: "rgba(0,0,0,.45)",
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NavDashboardInsight;
