import React from "react";
import { Card, Space, Flex } from "antd";
import "./CustomCard.css";
import CustomButton from "../../components/Button/CustomButton";
import Image from "../../assets/img/Image.png";

// const { Meta } = Card;

const CustomCard = ({ title, description, onClickButton, src }) => {
  return (
    <>
      <Card
        className="custom-card"
        // hoverable
      >
        <div className="img-container">
          <Flex justify="center" align="center">
            {src ? (
              <img
                src={src}
                width="100%"
                height="100%"
                // alt="example"
              />
            ) : (
              <img src={Image} />
            )}
          </Flex>
        </div>
        <Space direction="vertical">
          <p className="title">{title}</p>
          <p className="description">{description}</p>
        </Space>
        {/* <Meta title={title} description={description} /> */}
        <div className="card-button">
          <CustomButton
            text="Detail"
            type="primary"
            onClick={onClickButton}
            width={169}
            height={50}
            borderRadius="47.27px"
            color="#FFFF"
            background="#5D5A88"
            fontSize={20}
          />
        </div>
      </Card>
    </>
  );
};

export default CustomCard;
