import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="44" height="44" rx="12.3571" fill="#F8F8F9"/>
<rect x="0.5" y="0.5" width="44" height="44" rx="12.3571" stroke="#D0D5DD"/>
<path d="M30.1431 25.7408C30.1431 25.4131 30.028 25.1366 29.7979 24.9113L23.595 18.7771C23.3125 18.4904 23.0092 18.3573 22.6431 18.3573C22.277 18.3573 21.9632 18.4904 21.6912 18.7669L15.4883 24.9113C15.2581 25.1366 15.1431 25.4131 15.1431 25.7408C15.1431 26.3962 15.6765 26.9287 16.346 26.9287C16.6807 26.9287 16.9841 26.7956 17.2351 26.56L22.6535 21.1735L28.0615 26.56C28.3021 26.7956 28.6054 26.9287 28.9401 26.9287C29.6096 26.9287 30.1431 26.3962 30.1431 25.7408Z" fill="#D0D5DD"/>
</svg>









);

const Icon_Up_Admin_Disable = (props) => <Icon component={icon} {...props} />;

export default Icon_Up_Admin_Disable;

