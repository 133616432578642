import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const FileService = {
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/files/${id}`,
      method: "GET",
      responseType: "blob",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  uploadFile: async function (file, fileType, cancel = false) {
    const formData = new FormData();
    formData.append("File", file);
    formData.append("FileType", fileType);

    const response = await api.request({
      url: `/files`,
      method: `POST`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      //   responseType: "blob",
      signal: cancel
        ? cancelApiObject[this.uploadFile.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(FileService);
