import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  Layout,
  Space,
  Dropdown,
  Badge,
  Divider,
  Menu,
  Carousel,
  Button,
  Row,
  Col,
  Grid,
  Drawer,
} from "antd";
import {
  DownOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import Icon_user_filled from "../../assets/icon/icon_user_filled";
import Icon_user_outlined from "../../assets/icon/icon_user_outlined";
import Icon_Lock_outlined from "../../assets/icon/icon_Lock_outlined";
import Icon_logout_outlined from "../../assets/icon/icon_logout_outlined";
import TCEB_LogoSvg from "../../assets/img/TCEB_Logo.js";
import CustomButton from "../Button/CustomButton";
import { useTranslation } from "react-i18next";
import { UserService } from "../../services/UserService";
import { handleLogin, handleLogout } from "../../keycloak/KeycloakAuth";
import Banner01 from "../../assets/img/Banner01.jpg";
import Banner02 from "../../assets/img/Banner02.jpg";
import Banner03 from "../../assets/img/Banner03.jpg";
import Color_blocks from "../../assets/img/Color_blocks.js";
import NavDashboardInsight from "../Nav/NavDashboardInsight.js";


const { Header, Content } = Layout;
const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const Nav = ({}) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [userMe, setUserMe] = useState();
  const [opacity, setOpacity] = useState(0);
  const [backgroundStr, setBackgroundStr] = useState("");
  const [navPosition, setNavPosition] = useState("");
  const [permission, setPermission] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const screens = useBreakpoint();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    i18n.changeLanguage("th")
    if (!userMe && !!sessionStorage.getItem("accessToken")) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {

      let response = sessionStorage.getItem("userMe") === null ? await UserService.getMe() :  JSON.parse(sessionStorage.getItem("userMe"));
      setUserMe(response);
      sessionStorage.setItem("userMe", JSON.stringify(response));
      if(window.permissions === undefined){
        let permissions = await UserService.getPermissions();
        window.permissions = permissions.filter((p) => p.isViewable);
      }
      setPermission(window.permissions);
    } catch (error) {
      console.error("error", error);
    }
  };


  const handleScroll = () => {
    const offset = window.scrollY;
    const newOpacity = Math.min(offset / 300, 1); // Adjust the divisor for speed of opacity change
    const bgStr = `linear-gradient(to right, rgb(69, 46, 100,${newOpacity}), rgb(9, 20, 33,${newOpacity}), rgb(4, 18, 27,${newOpacity}), rgb(12, 91, 92,${newOpacity}))`;
    setOpacity(newOpacity);
    setBackgroundStr(bgStr);
  };

  useEffect(() => {
    if (CheckPath(currentPath)) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [currentPath]);

  useEffect(() => {
    if (CheckPath(currentPath)) {
      setNavPosition("fixed");
      const bgStr = `linear-gradient(to right, rgb(69, 46, 100, 0), rgb(9, 20, 33, 0), rgb(4, 18, 27, 0), rgb(12, 91, 92, 0))`;
      setBackgroundStr(bgStr);
    } else {
      const bgStr = `linear-gradient(to right, rgb(69, 46, 100, 1), rgb(9, 20, 33, 1), rgb(4, 18, 27, 1), rgb(12, 91, 92, 1))`;
      setBackgroundStr(bgStr);
      setNavPosition("inherit");
    }
  }, [currentPath]);

  // Toggle Drawer visibility (sidebar for mobile)
  const showDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);

  // Menu items for the sidebar and navbar
  const menuItems = [
    { label: "หน้าแรก", key: "/", to: "/" },
    { label: "แดชบอร์ด", key: "/dashboard", to: "/dashboard" },
    { label: "รายงานวิเคราะห์", key: "/insight-report", to: "/insight-report" },
    { label: "ดาต้าแค็ตตาล๊อค", key: "/data-catalog", to: "/data-catalog" },
  ];

  const CheckPath = (currentPath) => {
    const pathsToHide = ["/"];
    return pathsToHide.includes(currentPath);
  };

  const CheckPathDashboardInsight = (currentPath) => {
    const pathsToHide = ["/dashboard", "/insight-report"];
    return pathsToHide.includes(currentPath);
  };

  const getPathforActive = (pathname) => {
    if (pathname == "/dashboard/detail") return "/dashboard";
    else return pathname;
  };
  
  return (
    <>
      <Header
        className="main-header"
        style={
          
          {
          background: backgroundStr,
          transition: "background-color 0.3s ease",
          position: CheckPathDashboardInsight(currentPath) && !screens.xl ? "inherit" : navPosition,
          
          height: screens.xs ? "70px" : "100px",
          paddingTop: screens.xs ? "0px" : "20px",
        }}
      >
        {" "}
        <Row>
          <Col span={24}>
            <Row>
              {
                //Main Header Left
              }
              <Col span={5} lg={5} md={8} sm={8} xs={8}>
                {screens.xl ? (
                  <Link to={"/"} key={"1"}>
                    <div className="main-header-logo">
                      <TCEB_LogoSvg className="TCEB_LogoSvg" style={{height:"70px", marginTop: "-15px"}}/>
                    </div>
                  </Link>
                ) : (
                  <Row
                  >
                    <Col span={4} align="end">
                      {!screens.xl && (
                        <div
                          className="main-header-logo ant-menu-horizontal"
                          onClick={showDrawer}
                          style={
                            screens.xl
                              ? { float: "left" }
                              : { marginLeft: "0px" }
                          }
                        >
                          <MenuOutlined className="MenuOutlined" />
                        </div>
                      )}
                    </Col>
                    <Col
                      span={18}
                      
                      style={
                        !screens.xl
                          ? { marginLeft: "0px" }
                          : { marginLeft: "100px" }
                      }
                    >
                      <Link to={"/"} key={"1"}>
                        <div className="main-header-logo" style={{paddingTop: !screens.md ? "5px" : "0px"}}>
                          <TCEB_LogoSvg className="TCEB_LogoSvg"  style={{height:"60px", width: "60px", top: "0px"}}/>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                )}
              </Col>
              {
                //Main Header Center
              }
              <Col span={14} lg={14} md={8} xs={0}>
                <div style={{ marginTop: "15px" }}>
                  {screens.xl && (
                    <Menu
                      mode="horizontal"
                      selectedKeys={getPathforActive(currentPath)}
                      style={{
                        backgroundColor: "transparent",
                        justifyContent: "center",
                        alignItems: "center",
                        borderBottom: "0px"
                        //display:"inline"
                      }}
                      items={menuItems.map((item) => ({
                        label: (
                          <div>
                            <Link key={item.label} to={item.to}>{item.label}</Link>
                          </div>
                        ),
                        key: item.key,
                      }))}
                    />
                  )}
                </div>
              </Col>
              {
                //Main Header Right
              }
              <Col span={5} lg={5} md={8} sm={16} xs={16}>
                <Space
                  size="middle"
                  style={{float: "right", marginTop:"0px" }}
                >
                  {!sessionStorage.getItem("accessToken") && (
                    <CustomButton
                      text={"เข้าสู่ระบบ/ลงทะเบียน"}
                      type="primary"
                      onClick={handleLogin}
                      width={170}
                      height={32}
                      margin={0}

                    />
                  )}

                  {!!sessionStorage.getItem("accessToken") && (
                    <div className="custom-dropdown" style={{
                      marginTop: "10px"
                    }}>
                      <Dropdown
                        placement="bottomRight"
                        trigger={["click"]}
                        overlayStyle={{ zIndex: 9999 }}
                        dropdownRender={(menu) => (
                          <Menu
                            className="dropdown-menu"
                            theme="light"
                            mode="vertical"
                            style={{
                              width: 262,
                              minHeight: 100,
                              padding: "23px 12px 23px 12px",
                              marginTop: "10px",
                              marginRight: "-10px",
                            }}
                            triggerSubMenuAction="click"
                          >
                            <p
                              style={{
                                color: "#000",
                                fontSize: "16px",
                                margin: 0,
                              }}
                            >
                              <h4 style={{margin:0}}>{userMe?.fullName}</h4>
                              <small>{userMe?.email}</small>
                              
                            </p>
                            <Divider
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                            {permission.length > 0 && (
                              <SubMenu
                                key="sub1"
                                title={
                                  <Space>
                                    <Icon_user_filled />
                                    {t("admin-system")}
                                    <Badge count={permission.length} color="#667085" />
                                  </Space>
                                }
                              >
                                {permission.map((p) => (
                                  <Menu.Item key={p.pageName}>
                                    <a onClick={() => navigate(p.routePath)}>
                                      {p.pageName}
                                    </a>
                                  </Menu.Item>
                                ))}
                                
                                
                              </SubMenu>
                            )}
                            <Menu.Item key="2">
                              <a
                                onClick={() =>
                                  navigate("/management/account-management")
                                }
                                style={{ fontSize: "14px" }}
                              >
                                <Space>
                                  <Icon_user_outlined />
                                  {t("my-profile")}
                                </Space>
                              </a>
                            </Menu.Item>
                            <Menu.Item key="3">
                              <a
                                onClick={() =>
                                  navigate("/management/change-password")
                                }
                                style={{ fontSize: "14px" }}
                              >
                                <Space>
                                  <Icon_Lock_outlined />
                                  {t("change-password")}
                                </Space>
                              </a>
                            </Menu.Item>
                            <Divider
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                            <Menu.Item key="4">
                              <a
                                onClick={() => handleLogout()}
                                style={{ fontSize: "14px" }}
                              >
                                <Space>
                                  <Icon_logout_outlined />
                                  {t("logout")}
                                </Space>
                              </a>
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Space>
                          <Icon_user_filled />
                          <p>{userMe?.firstName}</p>
                          <DownOutlined />
                        </Space>
                      </Dropdown>
                    </div>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>

      {/* Drawer for mobile navigation */}
      {screens.sm && (
        <Drawer
          //title="เมนู"
          placement="top"
          closable={false}
          onClose={closeDrawer}
          open={drawerVisible}
          //width={250}
          height={280}
          className="drawer-top"
          style={{
            // backgroundColor:"transparent",
            background:
              "linear-gradient(to right, rgb(69, 46, 100,1), rgb(9, 20, 33,1), rgb(4, 18, 27,1), rgb(12, 91, 92,1))",
            borderRadius: "0 0 15px 15px",
            height: "100px",
            paddingTop: "10px",
          }}
          zIndex={2000}
        >
          <Menu
            onClick={closeDrawer}
            style={{
              backgroundColor: "transparent",
              lineHeight: 3,
              borderInlineEnd: 0,
              justifyItems: "center",
              placeContent: "center",
              borderBottom: "0px"
            }}
            className="list-menu-top"
            mode="horizontal"
            selectedKeys={currentPath}
            items={menuItems.map((item) => ({
              label: <Link key={item.label} to={item.to}>{item.label}</Link>,
              key: item.key,
              className: "item-menu-top",
            }))}
          />
        </Drawer>
      )}

      {screens.xs && (
        <Drawer
          //title="เมนู"
          placement="top"
          closable={false}
          onClose={closeDrawer}
          open={drawerVisible}
          //width={250}
          height={280}
          className="drawer-top"
          style={{
            // backgroundColor:"transparent",
            background:
              "linear-gradient(to right, rgb(69, 46, 100,1), rgb(9, 20, 33,1), rgb(4, 18, 27,1), rgb(12, 91, 92,1))",
            borderRadius: "0 0 15px 15px",
          }}
          zIndex={2000}
        >
          <div className="main-menu-header-mobile">
            <Menu
              onClick={closeDrawer}
              style={{
                backgroundColor: "transparent",
                lineHeight: 3,
                borderInlineEnd: 0,
                justifyItems: "center",
              }}
              className="list-menu-top"
              mode="inline"
              selectedKeys={currentPath}
              items={menuItems.map((item) => ({
                label: <Link key={item.label} to={item.to}>{item.label}</Link>,
                key: item.key,
                className: "item-menu-top",
              }))}
            />
          </div>
        </Drawer>
      )}

      {CheckPath(currentPath) && (
        <Content>
          <Carousel autoplay autoplaySpeed={7000}>
            <div
              style={{ backgroundColor: "#5d5a88" }}
              className="carousel-slide"
            >
              <div className="image-crop">
                <img src={Banner01} className="carousel-image" />
              </div>
              <div className="carousel-title">
                <Color_blocks />
                <p className="p-title">ข้อมูลแดชบอร์ดที่สำคัญที่สุดของคุณ</p>
                <p>
                  ด้วยข้อมูลที่นำเสนออย่างชัดเจนและเข้าใจง่ายผ่านแดชบอร์ดที่สร้างขึ้นเพื่อคุณ
                </p>
                <Link to="/dashboard">
                  <Button type="primary" size="large">
                    เริ่มต้นใช้งาน
                  </Button>
                </Link>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#5d5a88" }}
              className="carousel-slide"
            >
              <div className="image-crop">
                <img src={Banner02} className="carousel-image" />
              </div>
              <div className="carousel-title">
                <Color_blocks />
                <p className="p-title">รายงานที่วิเคราะห์ข้อมูลเชิงลึก</p>
                <p>
                  ด้วยข้อมูลที่นำเสนออย่างชัดเจนและเข้าใจง่าย
                  และผ่านการวิเคราะห์ข้อมูลเชิงลึก จากผู้เชี่ยวชาญ
                </p>
                <Link to="/insight-report">
                  <Button type="primary" size="large">
                    เริ่มต้นใช้งาน
                  </Button>
                </Link>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#5d5a88" }}
              className="carousel-slide"
            >
              <div className="image-crop">
                <img src={Banner03} className="carousel-image" />
              </div>
              <div className="carousel-title">
                <Color_blocks />
                <p className="p-title">
                  ดาต้าแค็ตตาล๊อคที่ครอบคลุมทุกความต้องการ
                </p>
                <p>
                  เรารวบรวมข้อมูลจากแหล่งข้อมูลที่เชื่อถือได้
                  และเข้าถึงได้ง่ายผ่าน Open API
                </p>
                <Link to="/data-catalog">
                  <Button type="primary" size="large">
                    เริ่มต้นใช้งาน
                  </Button>
                </Link>
              </div>
            </div>
          </Carousel>
        </Content>
      )}

      {CheckPathDashboardInsight(currentPath) && <NavDashboardInsight />}
    </>
  );
};

export default Nav;
