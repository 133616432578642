import React from "react";
import "./CustomButton.css";
import { Button, Tooltip } from "antd";
import { HiOutlineClock } from "react-icons/hi2";

const HistoryButton = ({ onClick }) => {
  return (
    <Tooltip title="">
      <Button
        className="custom-button-action"
        icon={
          <HiOutlineClock
            style={{
              fontSize: "20px",
            }}
          />
        }
        onClick={onClick}
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "8px",
        }}
      />
    </Tooltip>
  );
};

export default HistoryButton;
