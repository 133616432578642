import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  Layout,
  Menu,
  Flex,
  Col,
  Row,
  Avatar,
  Switch,
  Form,
  Button,
  Spin,
} from "antd";
import "./AdminDashboardManagement.css";
import {
  LinkOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  TruckOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";

import { UserService } from "../../../../../services/UserService";
import { TagService } from "../../../../../services/TagService";
import { RoleService } from "../../../../../services/RoleService";
import { DashboardService } from "../../../../../services/DashboardService";
import IconUpAdmin from "../../../../../assets/icon/icon_up_admin";
import IconUpAdminDisable from "../../../../../assets/icon/icon_up_admin_disable";
import IconDownAdmin from "../../../../../assets/icon/icon_down_admin";
import IconDownAdminDisable from "../../../../../assets/icon/icon_down_admin_disable";
import IconBinAdmin from "../../../../../assets/icon/icon_bin_admin";
import ConfirmDialog from "../../../../../components/Dialog/ConfirmDialog";
import SuccessDialog from "../../../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../../../components/Dialog/FailedDialog";
import DeleteDialog from "../../../../../components/Dialog/DeleteDialog";
import { PinButton } from "../../../../../components";
import ImageMockDataImage from "../../../../../assets/img/mock_data_image.png";
import {
  Input,
  Select,
  Tag,
  ConfigProvider,
  message,
  Upload,
  Image,
} from "antd";
import { FaS } from "react-icons/fa6";
import { AppContext } from "./DashboardManagementDetail";
import { arrayMoveImmutable } from "array-move";
import axios from "axios";

import htmlEditButton from "quill-html-edit-button";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

Quill.register({
  "modules/htmlEditButton": htmlEditButton,
});

const modules = {
  toolbar: [
    { header: [1, 2, 3, 4, 5, 6, false] },
    "bold",
    "italic",
    "underline",
    "link",
    // { list: "bullet" },
    // { list: "ordered" },
  ],
  htmlEditButton: {
    debug: true,
    msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
    okText: "Ok", // Text to display in the OK button, default: Ok,
    cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
    buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
    buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
    prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
    editorModules: {}, // The default mod
  },
};

const { Content, Sider } = Layout;

const AdminDashboardManagement = ({ data, submitIndex, categoryId }) => {
  let context = AppContext;
  const { detailList, setDetailList, isChanged, setIsChanged, setSubmitIndex } =
    useContext(context);
  const navigate = useNavigate();
  const { TextArea } = Input;
  // const [data, setData] = useState();
  const [form] = Form.useForm();
  const [linkEmbedHaseError, setLinkEmbedHaseError] = useState(false);
  const [roleHasError, setRoleHasError] = useState(false);
  const [tagHasError, setTagHasError] = useState(false);
  const [dashboardNameHasError, setDashboardNameHasError] = useState(false);
  const [listDetailHasError, setListDetailHasError] = useState(false);
  const [mbLine2, setMbLine2] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [fileList, setFileList] = useStateCallback([]);
  const [tagData, setTagData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [enable, setEnable] = useState(data.isActivated);
  const [selectedTag, setSelectedTag] = useState([]);
  const [linkEmbed, setLinkEmbed] = useState([]);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useStateCallback(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [currentMethod, setCurrentMethod] = useStateCallback();
  const [dialogMessage, setDialogMessage] = useState();
  const [isPinned, setIsPinned] = useState(data.isPinned);
  const [isFirstLoadTag, setIsFirstLoadTag] = useStateCallback(true);
  const [isFirstLoadRole, setIsFirstLoadRole] = useStateCallback(true);
  const [dataLinkHasError, setDataLinkHasError] = useState(false);
  const [detailError, setDetailError] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [prevEditorValue, setPrevEditorValue] = useState();
  const [detailLength, setDetailLength] = useState(0);
  const quillRef = useRef();
  const [progress, setProgress] = useState();
  const [uploadHasError, setUploadHasError] = useState(false);
  let mockImage =
    "https://archive.org/download/placeholder-image/placeholder-image.jpg";
  // const testFileList = [
  //   {
  //     uid: '0',
  //     name: 'xxx.png',
  //     status: 'uploading',
  //     percent: 33,
  //   },
  //   {
  //     uid: '-1',
  //     name: 'yyy.png',
  //     status: 'done',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //     thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   },
  //   {
  //     uid: '-2',
  //     name: 'zzz.png',
  //     status: 'error',
  //   },
  // ];
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const createTagOptions = [
    {
      value: "New Tag",
    },
  ];

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      // alert("Test")
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <ConfigProvider
        theme={{
          components: {
            Tag: {
              // defaultColor:'#000000D9',
              // colorFillSecondary: '#000000D9',
            },
          },
        }}
      >
        <Tag
          // color={value}
          closeIcon={<CloseOutlined style={{ color: "#00000073" }} />}
          color={"#F8F8F9"}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{
            marginInlineEnd: 4,
            color: "#000000D9",
            fontSize: 12,
            fontWeight: 600,
            borderColor: "#BCC2CB",
          }}
        >
          {label}
        </Tag>
      </ConfigProvider>
    );
  };

  const createTagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      // alert("Test")
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <ConfigProvider
        theme={{
          components: {
            Tag: {
              // defaultColor:'#000000D9',
              // colorFillSecondary: '#000000D9',
            },
          },
        }}
      >
        <Tag
          // color={value}
          icon={<PlusOutlined style={{ color: "#00000073" }} />}
          color={"#F8F8F9"}
          onMouseDown={onPreventMouseDown}
          closable={false}
          onClick={() => {
            // alert("Go to create tag page");
            navigate("/management/edit-tag");
          }}
          // onClose={onClose}
          style={{
            marginInlineEnd: 4,
            color: "#000000D9",
            fontSize: 12,
            fontWeight: 600,
            borderColor: "#BCC2CB",
            borderStyle: "dashed",
          }}
        >
          {label}
        </Tag>
      </ConfigProvider>
    );
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      setFileList((state) => [...state]);
      return false;
    }

    // const reader = new FileReader();
    // let picWidth = 0;
    // let picHeight = 0;
    // reader.readAsDataURL(file);
    // reader.addEventListener('load', event => {
    //   const _loadedImageUrl = event.target.result;
    //   const image = document.createElement('img');
    //   image.src = _loadedImageUrl;
    //   image.addEventListener('load', () => {
    //     const { width, height } = image;
    //     // set image width and height to your state here
    //     //console.log(width, height);
    //     picWidth = width;
    //     picHeight = height;
    //     if(picWidth > 350 || picHeight > 175){
    //       message.error('Image size choose be 350x175');
    //       setFileList((state) => [...state]);
    //       return false;
    //     }
    //   });
    // });
    if (fileList.length >= 1) {
      message.error("Upload only one file");
      setFileList((state) => [...state]);
      return false;
    }
    let isSizeNormal = await checkImageDimension(file);
    if (!isSizeNormal) {
      message.error("Image size should be 350x175");
      setFileList((state) => [...state]);
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
      setFileList((state) => [...state]);
      return false;
    }
    setFileList((state) => [...state, file]);
    return isJpgOrPng && isLt2M && isSizeNormal;
  };
  const checkImageDimension = (file) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl;

        image.addEventListener("load", () => {
          const { width, height } = image;
          if (file.size > MAX_FILE_SIZE) {
            return resolve(false);
          }

          /*if (width > 350 || height > 175) {
            return resolve(false);
          }*/

          return resolve(true);
        });
      });
    });
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const removeUpload = (file) => {
    if (fileList.some((item) => item.uid === file.uid)) {
      setFileList((fileList) =>
        fileList.filter((item) => item.uid !== file.uid)
      );

      removeFileData("thumbnail", "thumb");
      // setFileList((state) => [...state]);
      // let updatedList = detailList.map((item) => {
      //   if(item.id === data.id){
      //     return {
      //       ...item,
      //       dashboardFiles: [

      //       ],
      //     };
      //   }
      //   return item;
      // });
      // setDetailList(updatedList);
      return true;
    }
    return false;
  };

  const removeFileData = (fileType, mode) => {
    let updatedList = detailList.map((item) => {
      if (item.id === data.id) {
        return {
          ...item,
          dashboardFiles: fileList,
        };
      }
      return item;
    });
    setDetailList(updatedList, function (data) {
      triggerValidate("upload");
    });
  };

  const handleUploadChange = (info) => {
    setIsChanged(true);
    if (info.file.status === "uploading") {
      // getBase64(info.file.originFileObj, (url) => {
      //   setImageUrl(url);
      //   // info.file.thumbUrl = url;
      //   // info.file.status = "uploading";
      //   // info.file.url = url;
      //   // info.file.preview = url;
      //   // info.file.percent = progress;
      //   // let updatedList = fileList.map((item) => {
      //   //   if (item.uid === info.file.uid) {
      //   //     return info.file; //gets everything that was already in item, and updates "done"
      //   //     //'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
      //   //   }
      //   //   return item; // else return unmodified item
      //   // });
      //   // if (updatedList.length > 0) {
      //   //   setFileList(updatedList);
      //   // }
      // });
      return;
    }
    if (info.file.status === "done") {
      let updatedList = fileList.map((item) => {
        if (item.uid === info.file.uid) {
          return info.file; //gets everything that was already in item, and updates "done"
          //'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
        }
        return item; // else return unmodified item
      });
      if (updatedList.length > 0) {
        setFileList(updatedList);
      }
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
      });
    }
    if (info.file.status === "error") {
      let updatedList = fileList.map((item) => {
        if (item.uid === info.file.uid) {
          // info.file.error = {"message":"Test"}
          return info.file; //gets everything that was already in item, and updates "done"
          //'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
        }
        return item; // else return unmodified item
      });
      if (updatedList.length > 0) {
        setFileList(updatedList);
      }
    }
  };

  // useEffect(() => {
  //   if (!data) {
  //     fetchData();
  //   }
  // }, [data]);

  // const fetchData = async () => {
  //   try {
  //     const response = await UserService.getMe();
  //     setData(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const onChange = (checked) => {
    setIsChanged(true);
    //console.log(`switch to ${checked}`);
    updateDetailData("isActivated", checked);
    setEnable(checked);
  };

  const handleChangeText = (value, field, mode) => {
    // var test = form.getFieldsError()
    setIsChanged(true);
    if (value.trim() === "") {
      if (field === "linkEmbed") {
        setLinkEmbedHaseError(true);
        setLinkEmbed(value);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("embedSrc", value);
      } else if (field === "dashboardName") {
        setDashboardNameHasError(true);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("name", value);
      } else if (field === "listDetail") {
        setListDetailHasError(true);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("shortDescription", value);
      }
    } else {
      if (field === "linkEmbed") {
        if (!isValidUrl(value)) {
          setLinkEmbedHaseError(true);
          setLinkEmbed(value);
        } else {
          setLinkEmbedHaseError(false);
          setLinkEmbed(value);
          if (mode !== undefined) {
            return;
          }
          updateDetailData("embedSrc", value);
        }
        // setLinkEmbedHaseError(false);
        // setLinkEmbed(value);
        // updateDetailData("embedSrc", value);
      } else if (field === "dashboardName") {
        setDashboardNameHasError(false);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("name", value);
      } else if (field === "listDetail") {
        setListDetailHasError(false);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("shortDescription", value);
      }
    }
    if (field === "detail") {
      if (mode !== undefined) {
        return;
      }
      updateDetailData("description", value);
    }
    //console.log("handle change called");
  };

  const updateDetailData = (field, value) => {
    let updatedList = detailList.map((item) => {
      if (item.id == data.id) {
        let dataTag;
        let dataRole;
        if (field === "dashboardTags") {
          setIsFirstLoadTag(false);
          dataTag = value.map((itemString) => {
            let findData = tagData.find(
              (element) => element.name === itemString
            );

            return {
              id: findData.key,
              dashboardId: data.id,
              tagId: findData.key,
              tagName: findData.name,
              tagColor: findData.color,
            };
          });
          return { ...item, [field]: dataTag };
        } else if (field === "dashboardPermissions") {
          setIsFirstLoadRole(false);
          dataRole = value.map((id) => {
            let findData = roleData.find((element) => element.id === id);

            const findId = item.dashboardPermissions?.find((i) => i.roleId === id)
            
            return {
              id: findId ? findId?.id : null,
              dashboardId: data.id,
              roleId: findData.id,
              roleName: findData.nameTh,
            };
          });
          return { ...item, [field]: dataRole };
        } else {
          return { ...item, [field]: value }; //gets everything that was already in item, and updates "done"
        }
      }
      return item; // else return unmodified item
    });
    setDetailList(updatedList);
  };

  const handleChangeSelect = (value, field) => {
    // var test = form.getFieldsError()

    setIsChanged(true);
    let role = roleHasError;
    let tag = tagHasError;
    if (value.length === 0) {
      if (field === "role") {
        role = true;
        setRoleHasError(true);
        updateDetailData("dashboardPermissions", value);
      } else if (field === "tag") {
        tag = true;
        setTagHasError(true);
        updateDetailData("dashboardTags", value);
      } else if (field === "dataLink") {
        setDataLinkHasError(true);
      }
      checkMbLine2(role, tag);
    } else {
      if (field === "role") {
        role = false;
        setRoleHasError(false);
        updateDetailData("dashboardPermissions", value);
      } else if (field === "tag") {
        tag = false;
        setTagHasError(false);
        updateDetailData("dashboardTags", value);
      } else if (field === "dataLink") {
        setDataLinkHasError(false);
      }
      checkMbLine2(role, tag);
    }
    //console.log("handle change called");
  };

  const checkMbLine2 = (roleHasError, tagHasError) => {
    if (roleHasError && tagHasError) {
      setMbLine2(50);
    } else if (roleHasError && tagHasError) {
      setMbLine2(50);
    } else {
      setMbLine2(0);
    }
  };

  function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; // store current, passed callback in ref
      setState(state);
    }, []); // keep object reference stable, exactly like `useState`

    useEffect(() => {
      // cb.current is `null` on initial render,
      // so we only invoke callback on state *updates*
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null; // reset callback after execution
      }
    }, [state]);

    return [state, setStateCallback];
  }

  const handleDeleteDetail = (id) => {
    // onSubmit(form)
    // setIsModalDeleteOpen(true)
    setIsChanged(true);
    setDetailList((l) => l.filter((item) => item.id !== id));
    // sessionStorage.setItem("isDiscardChanged", true);
    // let currentData = findCurrentData(id);
    // if(currentData.dataStatus !== undefined){
    //   setDetailList((l) => l.filter((item) => item.id !== id));
    // }else{
    //   setCurrentMethod("delete", function(data){
    //     setMessage(data);
    //   });
    //   deleteData();
    // }
  };

  const findCurrentData = (id) => {
    let currentElement = detailList.find((element) => element.id === id);
    return currentElement;
  };

  function findOrderIndex(data) {
    let findIndex = -1;
    detailList.map((item, index) => {
      if (item.id == data.id) {
        findIndex = index; //gets everything that was already in item, and updates "done"
      } // else return unmodified item
    });
    return findIndex;
  }

  function isCanTop(index) {
    let result = false;
    if (index > 0) {
      result = true;
    }
    return result;
  }

  function isCanBottom(index) {
    let result = false;
    if (index < detailList.length - 1) {
      result = true;
    }
    return result;
  }

  function getTopIndex(data) {
    let index = findOrderIndex(data);
    if (isCanTop(index)) {
      index--;
    }
    return index;
  }

  function getBottomIndex(data) {
    let index = findOrderIndex(data);
    if (isCanBottom(index)) {
      index++;
    }
    return index;
  }

  function moveOrder(direction, data) {
    setIsChanged(true);
    setIsFirstLoadTag(true);
    setIsFirstLoadRole(true);
    triggerValidate("upload");
    let oldIndex = findOrderIndex(data);
    let updatedList = [...detailList];
    let orderIndex = -1;
    if ("top" === direction) {
      orderIndex = getTopIndex(data);
    } else {
      orderIndex = getBottomIndex(data);
    }
    let newArray = arrayMoveImmutable(updatedList, oldIndex, orderIndex);

    setDetailList(newArray, function (updateData) {
      //console.log("Sort data ", updateData);
    });
  }

  const fetchTags = async () => {
    try {
      const response = await TagService.get();
      //console.log("API Response:", response);

      // ตรวจสอบว่า response ถูกต้องหรือไม่
      if (!response || !Array.isArray(response.items)) {
        throw new Error("Response format is invalid or items are missing.");
      }

      // จัดรูปแบบข้อมูล
      const formattedData = response.items.map((item, index) => ({
        key: item.id || index.toString(),
        index: item.sequence ?? index + 1, // ใช้ sequence หรือ index + 1
        name: item.name || "Default",
        color: item.color || "default",
        isActivated: item.isActivated ?? false,
        tagExample: item.name || "Default",
      }));

      // เรียงข้อมูลตาม index (ลำดับ #)
      const sortedData = formattedData.sort((a, b) => a.index - b.index);

      // ตั้งค่าข้อมูลที่เรียงแล้ว
      let updatedList = sortedData.map((item) => {
        return { ...item, value: item.name };
      });
      setTagData(updatedList);
      updateSelectedTag();
      setIsFirstLoadTag(false);
      // setSelectedTag(null)
    } catch (error) {
      console.error("Error fetching tags:", error);
      message.error("เกิดข้อผิดพลาดในการโหลดข้อมูล");
    } finally {
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await RoleService.search();

      // ตั้งค่าข้อมูลที่เรียงแล้ว
      setRoleData(response?.items);
      updateSelectedRole();
      setIsFirstLoadRole(false);
      // setSelectedTag(null)
    } catch (error) {
      message.error("เกิดข้อผิดพลาดในการโหลดข้อมูล");
    } finally {
    }
  };

  const updateSelectedTag = () => {
    if (data.dashboardTags != undefined && data.dashboardTags.length > 0) {
      // let testList = [{tagName:"test"}, {tagName:"ss"}];
      // let tagList = testList.map((item) => {
      let tagList = data.dashboardTags.map((item) => {
        return item.tagName;
      });

      setSelectedTag(tagList);
      // let testData = ['การเงิน','การท่องเที่ยว']
      form.setFieldsValue({
        tag: tagList,
        // "tag": testData
      });
    } else {
      if (isFirstLoadTag) {
        form.setFieldsValue({
          tag: null,
        });
        setIsFirstLoadTag(false);
      }
    }
  };

  const updateSelectedRole = () => {
    if (
      data.dashboardPermissions != undefined &&
      data.dashboardPermissions.length > 0
    ) {
      // let testList = [{tagName:"test"}, {tagName:"ss"}];
      // let tagList = testList.map((item) => {
      let RoleList = data.dashboardPermissions.map((item) => {
        return item.roleId;
      });

      form.setFieldsValue({
        role: RoleList,
      });
    } else {
      if (isFirstLoadRole) {
        form.setFieldsValue({
          role: null,
        });
        setIsFirstLoadRole(false);
      }
    }
  };

  const prepareThumbnail = () => {
    if (data.dashboardFiles === undefined) {
      setFileList([]);
      return;
    }
    let fileData = data.dashboardFiles.find(
      (element) => element.fileType === "thumbnail"
    );
    if (fileData != undefined) {
      if (fileList.length > 0 && fileData.fileSrc === fileList[0].url) {
        return;
      }
      setFileList(
        [{ id: uuidv4(), url: fileData.fileSrc, thumbUrl: fileData.fileSrc }],
        function (updateData) {}
      );
    }
    // if (data.thumbnailSrc != null) {
    //   if(fileList.length > 0 && data.thumbnailSrc === fileList[0].url){
    //     return;
    //   }
    //   setFileList(
    //     [{ id: uuidv4(), url: data.thumbnailSrc, thumbUrl: data.thumbnailSrc }],
    //     // [{ id: uuidv4(), url: mockImage, thumbUrl: mockImage }],
    //     function (updateData) {}
    //   );
    // }
  };

  const onFinish = (values) => {
    //console.log(values);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let test = detailList;
        setUploadHasError(false);
        if (detailError === undefined) {
          saveData();
        } else {
          messageApi.open({
            type: "error",
            content: "กรุณากรอกข้อมูลให้สมบูรณ์",
          });
        }

        //console.log("");
      })
      .catch((errorInfo) => {
        messageApi.open({
          type: "error",
          content: "กรุณากรอกข้อมูลให้สมบูรณ์",
          // style: {
          //   marginTop: '20vh',
          // },
        });
        //console.log("errorInfo ...", errorInfo);
        let values = errorInfo.values;
        handleValidate(errorInfo);
        // handleOnFinishEdit(values)
      });
  };

  const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  };

  const saveData = async () => {
    try {
      setLoading(true);
      //Test only
      // let updatedList = detailList.map((item) => {
      //   return {
      //     ...item,
      //     dashboardFiles: [
      //       {
      //         fileId: "1234567",
      //         sequence: 0,
      //       },
      //     ],
      //   };
      // });
      // setDetailList(updatedList);
      let request = {
        dashboardCategoryId: data.dashboardCategoryId,
        items: detailList,
      };
      const response = await DashboardService.savebulk(request);
      if (response.status === 200) {
        setIsModalSuccessOpen(true);
        setTimeout(function () {
          // navigate("/management/dashboard-management");
          // setIsModalSuccessOpen(false);
          handleNavigate();
        }, 2000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsModalFailedOpen(true);
      console.error(error);
    }
  };

  const deleteData = async () => {
    try {
      setLoading(true);
      const response = await DashboardService.delete(data.id);
      setIsModalSuccessOpen(true);
      setDetailList((l) => l.filter((item) => item.id !== data.id));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsModalFailedOpen(true);
      console.error(error);
    }
  };

  const setMessage = async (currentMethod) => {
    if (currentMethod === "save") {
      setDialogMessage("Successfully saved data.");
    } else {
      setDialogMessage("Successfully delete data.");
    }
  };

  const handleUpdatePinend = (checked, item) => {
    updateDetailData("isPinned", checked);
    setIsPinned(setIsPinned);
  };

  const handleOnEditTextChange = (content, delta, source, editor) => {
    const text = editor.getText(content);
    setDetailLength(text.trim().length);
    if (text.trim().length === 0) {
      setDetailError(
        <div style={{ marginTop: 40 }} className="ant-form-item-explain-error">
          This information is required.
        </div>
      );
    } else if (text.trim().length > 1000) {
      setDetailError(
        <div style={{ marginTop: 40 }} className="ant-form-item-explain-error">
          ข้อมูลนี้ต้องไม่เกิน 1000
        </div>
      );
    } else {
      setDetailError();
    }
    if (prevEditorValue !== content) {
      setPrevEditorValue(content);
      updateDetailData("description", content);
    }
    // alert(text);
    // setValue(value);
  };

  const getDetailLength = () => {
    if (quillRef.current !== undefined) {
      const editor = quillRef.current.getEditor();
      // alert(editor.getText(data.description));
      return editor.getText(data.description).trim().length;
    }
    return 0;
  };

  // function validURL(str) {
  //   var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  //     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  //     '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  //     '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  //   return !!pattern.test(str);
  // }
  function isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  const avatarUpload = (file) => {
    // const userId = this.props.userdetail.data.data.id;
    const data = new FormData();
    data.append("File", file);
    data.append("FileType", "thumbnail");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      // .post(`${API_VERSION}/users/${userId}/avatar`, data, config)
      .post(`https://tceb.ccs-service.online/core-api/files`, data, config)
      .then((res) => {
        ////console.log(res)
      })
      .catch((err) => console.err(err));
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        let updatedList = fileList.map((item) => {
          // item.file.thumbUrl = url;
          item.file.status = "uploading";
          // item.file.url = url;
          // item.file.preview = url;
          item.file.percent = percent;
          return item.file; // else return unmodified item
        });
        if (updatedList.length > 0) {
          setFileList(updatedList);
        }
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("File", file);
    fmData.append("FileType", "thumbnail");
    try {
      const res = await axios.post(
        // "https://tceb.ccs-service.online/core-api/files",
        process.env.REACT_APP_SERVICE_BASE_URI + "/files",
        // "https://660d2bd96ddfa2943b3373  1c.mockapi.io/api/upload",
        fmData,
        config
      );
      // triggerValidate();
      let updatedList = detailList.map((item) => {
        if (item.id === data.id) {
          return {
            ...item,
            dashboardFiles: [
              {
                id: res.data.id,
                dashboardId: data.id,
                fileId: res.data.id,
                sequence: 0,
                fileType: res.data.fileType,
                fileSrc: res.data.srcUrl,
              },
            ],
          };
        }
        return item;
      });
      setDetailList(updatedList, function (data) {
        triggerValidate("upload");
      });
      onSuccess("Ok");
      // setTimeout(() => {
      //   triggerValidate();
      // }, 800);
      //console.log("server res: ", res);
    } catch (err) {
      //console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const triggerValidate = (mode) => {
    form
      .validateFields()
      .then((values) => {
        //console.log("");
        setUploadHasError(false);
      })
      .catch((errorInfo) => {
        //console.log("errorInfo ...", errorInfo);
        let values = errorInfo.values;
        handleValidate(errorInfo, mode);
        // handleOnFinishEdit(values)
      });
  };

  const handleValidate = (errorInfo, mode) => {
    let uploadError = errorInfo.errorFields.find(
      (item) => item.name[0] === "upload"
    );
    if (uploadError === undefined) {
      setUploadHasError(false);
    }
    errorInfo.errorFields.map((item) => {
      let fieldName = item.name[0];
      // setUploadHasError(false);
      const formValues = form.getFieldsValue();
      if (fieldName == "linkEmbed") {
        let linkEmbed =
          formValues.linkEmbed == undefined ? "" : formValues.linkEmbed;
        handleChangeText(linkEmbed, fieldName, mode);
      } else if (fieldName == "tag") {
        let tag = formValues.tag == undefined ? [] : formValues.tag;
        handleChangeSelect(tag, fieldName, mode);
      } else if (fieldName == "dashboardName") {
        let value =
          formValues.dashboardName == undefined ? "" : formValues.dashboardName;
        handleChangeText(value, fieldName, mode);
      } else if (fieldName == "listDetail") {
        let value =
          formValues.listDetail == undefined ? "" : formValues.listDetail;
        handleChangeText(value, fieldName, mode);
      } else if (fieldName == "detail") {
        let value = formValues.detail == undefined ? "" : formValues.detail;
        handleChangeText(value, fieldName, mode);
      } else if (fieldName == "upload") {
        setUploadHasError(true);
        // setFileList([], function (data) {
        //   setFileList(
        //     [{ id: uuidv4(), status: "error" }],
        //     function (updateData) {}
        //   );
        // });
      }

      // handleChangeText()
    });
  };

  const handleonImageError = () => {
    // alert("Ok")
    setFileList(
      // [{ id: uuidv4(), url: mo, thumbUrl: data.thumbnailSrc }],
      [{ id: uuidv4(), url: mockImage, thumbUrl: mockImage }],
      function (updateData) {}
    );
  };

  const handleOnImageSuccess = () => {
    prepareThumbnail();
  };

  const handleNavigate = () => {
    navigate(
      {
        pathname: `/management/dashboard-management`,

        // pagedata: pageValue,
      },
      { state: { catagoryId: categoryId } }
    );
    setIsModalSuccessOpen(false);
  };

  useEffect(() => {
    fetchTags();
    fetchRoles();
  }, []);

  useEffect(() => {
    setEnable(data.isActivated);
    setIsPinned(data.isPinned);
    if (data.embedSrc !== undefined) {
      setLinkEmbed(data.embedSrc);
    }
    updateSelectedTag();
    prepareThumbnail();
  }, [detailList]);

  useEffect(() => {
    if (submitIndex != 0) {
      // form.submit();
      setSubmitIndex(0, function (data) {
        setIsModalOpen(true);
      });
    }
  }, [submitIndex]);

  let index = findOrderIndex(data);
  let boolIsCanTop = isCanTop(index);
  let boolIsCanBottom = isCanBottom(index);

  return (
    <>
      {contextHolder}
      <Flex gap="small">
        <SuccessDialog
          isModalOpen={isModalSuccessOpen}
          closable={false}
          title="Success"
          detail={dialogMessage}
          // detail={"test"}
          onCancel={() => {
            // navigate("/management/dashboard-management");
            // setIsModalSuccessOpen(false);
            handleNavigate();
          }}
          onAfterClose={() => {
            // if(currentMethod === "save"){
            //   navigate("/management/dashboard-management");
            //   setIsModalSuccessOpen(false);
            // }
          }}
        />

        <ConfirmDialog
          isModalOpen={isModalOpen}
          closable={false}
          title="Confirm Save"
          cancelText="Cancel"
          confirmText="Confirm"
          onCancel={() => {
            setIsModalOpen(false);
          }}
          onConfirm={() => {
            setCurrentMethod("save", function (data) {
              setMessage(data);
            });
            handleSubmit();
            setIsModalOpen(false);
          }}
          description="Do you want to Save data ?"
        />

        <DeleteDialog
          isModalOpen={isModalDeleteOpen}
          closable={false}
          title="Confirm Delete"
          cancelText="Cancel"
          confirmText="Delete"
          onCancel={() => {
            setIsModalDeleteOpen(false);
          }}
          onConfirm={() => {
            setIsChanged(true);
            let currentData = findCurrentData(data.id);
            if (currentData.dataStatus !== undefined) {
              setDetailList((l) => l.filter((item) => item.id !== data.id));
            } else {
              setCurrentMethod("delete", function (data) {
                setMessage(data);
              });
              deleteData();
            }

            // if (currentRecord) {
            //   handleDelete(currentRecord, formDataRolelimit, formDataRole);
            // }
            setIsModalDeleteOpen(false); // Close the modal after confirming
          }}
          description="Are you sure to delete?"
        />

        <FailedDialog
          isModalOpen={isModalFailedOpen}
          closable={false}
          title="Failed"
          detail="Something went wrong. Please try again."
          onCancel={() => {
            setIsModalFailedOpen(false);
          }}
          onAfterClose={() => {
            // if (currentMethod === "save") {
            //   handleSubmit();
            // } else {
            //   deleteData();
            // }
            // alert("Dialog dissmiss");
          }}
          onConfirm={() => {
            if (currentMethod === "save") {
              handleSubmit();
            }
            setIsModalFailedOpen(false);
          }}
        />
      </Flex>

      <Spin tip="Loading" spinning={loading} fullscreen></Spin>
      <div className="content-card-main">
        <Row
          style={{
            width: "100%",
            marginTop: "0px",
            marginRight: "0px",
            // height: "150%",
          }}
        >
          <div style={{ width: "60px" }} span={2}>
            <div style={{ width: "100%", height: "100%" }}>
              <Row>
                <Col>
                  <Row style={{ marginBottom: 15 }}>
                    {!boolIsCanTop ? (
                      <IconUpAdminDisable />
                    ) : (
                      <IconUpAdmin
                        onClick={() => {
                          moveOrder("top", data);
                        }}
                      />
                    )}
                  </Row>
                  <Row>
                    {!boolIsCanBottom ? (
                      <IconDownAdminDisable />
                    ) : (
                      <IconDownAdmin
                        onClick={() => {
                          moveOrder("bottom", data);
                        }}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          <div style={{ width: "90%" }} span={22}>
            <div style={{ width: "100%", height: "100%", marginLeft: 0 }}>
              <Row>
                <Col span={24}>
                  <Row style={{}}>
                    <Col span={1}>
                      <div className="border-left"></div>
                    </Col>
                    <Col span={23}>
                      <div style={{}}>
                        <Flex justify="end">
                          <PinButton
                            // loading={pinLoading === item.id}
                            onClick={() => handleUpdatePinend(!isPinned)}
                            isPinned={isPinned}
                          />
                          <IconBinAdmin
                            onClick={() => {
                              handleDeleteDetail(data.id);
                            }}
                            style={{
                              marginRight: 10,
                            }}
                          />
                        </Flex>
                      </div>
                      <Row>
                        <div className="border-bottom"></div>

                        {/* <div
                          style={{
                            backgroundColor: "red",
                            width: "200px",
                            height: "300px",
                          }}
                        ></div> */}
                      </Row>
                      <Row style={{ marginLeft: 20 }}>
                        <Col span={12}>
                          <div
                            style={
                              {
                                // backgroundColor: "red",
                                // width: "100%",
                                // height: "300px",
                              }
                            }
                          >
                            <p className="label-admin">
                              ประเภทแดซบอร์ด
                              {/* taeasdsadasdasdasdasdasdadasddaladsjlkjckdlasjdajdakldjkdjskaljdakdja;ksdja;ksdj;aksdja;dja;ksdjaksdja;skja */}
                            </p>

                            <p className="text-admin">
                              {data.dashboardCategoryName}
                            </p>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div
                            style={{
                              // backgroundColor: "blue",
                              width: "100%",
                              // height: "300px",
                            }}
                          >
                            <p className="label-admin">ใช้งาน/ไม่ใช้งาน</p>
                            <Switch
                              checked={enable}
                              style={{
                                marginTop: 10,
                                // backgroundColor: "#1890FF",
                              }}
                              defaultChecked
                              onChange={onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Form
                        form={form}
                        // className="search_inline w-full py-6"
                        onFinish={onFinish}
                        fields={[
                          {
                            name: ["linkEmbed"],
                            value: findCurrentData(data.id).embedSrc,
                          },
                          {
                            name: ["dashboardName"],
                            value: findCurrentData(data.id).name,
                          },
                          {
                            name: ["listDetail"],
                            value: findCurrentData(data.id).shortDescription,
                          },
                          {
                            name: ["detail"],
                            value: findCurrentData(data.id).description,
                          },
                        ]}
                      >
                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 15,
                            marginBottom: linkEmbedHaseError ? 50 : 0,
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "99%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            {/* <p>{findCurrentData(data.id).id}</p> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  ลิงค์ Dashboard embed{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="linkEmbed"
                              colon={false}
                              required={false}
                              // initialValue={
                              //   findCurrentData(data.id)
                              // }
                              rules={[
                                {
                                  type: "url",
                                  message: "ข้อมูลนี้ต้องเป็น url ",
                                },
                                {
                                  required: true,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              <Input
                                onChange={(e) =>
                                  handleChangeText(e.target.value, "linkEmbed")
                                }
                                placeholder="https://app.powerbi.com/view?r=eyJrIjoiYzIyYmZlYjgtZTRmZi0..."
                                prefix={<LinkOutlined />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 20,
                            marginBottom: mbLine2,
                          }}
                        >
                          <Col span={12}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "98%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  เลือก Role ที่ต้องการแสดง{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="role"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              <Select
                                onChange={(e) => handleChangeSelect(e, "role")}
                                mode="multiple"
                                tagRender={tagRender}
                                style={{
                                  width: "100%",
                                }}
                                options={roleData?.map((role) => ({
                                  label: role.nameTh,
                                  value: role.id,
                                }))}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "98%",
                                // height: "300px",
                              }}
                            >
                             
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  Tag <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="tag"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              {/* <Select
                                  onChange={(e) => handleChangeSelect(e, "tag")}
                                  mode="multiple"
                                  tagRender={tagRender}
                                  style={{
                                    width: "100%",
                                  }}
                                  options={tagData}
                                /> */}
                              {tagData.length == 0 ? (
                                <Select
                                  onChange={(e) => handleChangeSelect(e, "tag")}
                                  mode="multiple"
                                  tagRender={createTagRender}
                                  defaultValue={["New Tag"]}
                                  style={{
                                    width: "100%",
                                  }}
                                  options={createTagOptions}
                                />
                              ) : (
                                <Select
                                  onChange={(e) => handleChangeSelect(e, "tag")}
                                  mode="multiple"
                                  tagRender={tagRender}
                                  style={{
                                    width: "100%",
                                  }}
                                  options={tagData}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 20,
                            marginBottom: dataLinkHasError ? 50 : 0,
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "98%",
                                // height: "300px",
                              }}
                            >
                             
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  เลือกดาต้าแคดตาล็อคที่ต้องการเชื่อมกับแดซบอร์ด
                                  {/* <span style={{ color: "red" }}>*</span> */}
                                </p>
                              }
                              name="dataLink"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: false,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              {/* <Select
                                  onChange={(e) => handleChangeSelect(e, "tag")}
                                  mode="multiple"
                                  tagRender={tagRender}
                                  style={{
                                    width: "100%",
                                  }}
                                  options={tagData}
                                /> */}
                              <Select
                                disabled
                                onChange={(e) =>
                                  handleChangeSelect(e, "dataLink")
                                }
                                mode="multiple"
                                tagRender={tagRender}
                                style={{
                                  width: "100%",
                                }}
                                options={tagData}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: 20, marginTop: 15 }}>
                          <Col span={24}>
                            <Form.Item
                              layout="vertical"
                              label={
                                <>
                                  <p className="label-admin">
                                    อัปโหลดภาพ{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </p>
                                </>
                              }
                              name="upload"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  validator: async () => {
                                    if (fileList <= 0)
                                      return Promise.reject(
                                        new Error("Please upload an image")
                                      );
                                  },
                                },

                                // ({ getFieldValue }) => ({
                                //   validator(_, value) {
                                //     alert(value);
                                //     if (value <= 0) return Promise.reject(new Error('Please upload an image'));
                                //   },
                                // })

                                // {
                                //   required: false,
                                //   message: "This information is required.",
                                // },
                              ]}
                              style={{
                                marginTop: 10,
                                marginRight: 10,
                                padding: 0,
                              }}
                            >
                              <span className="text-upload">
                                *สามารถอัปโหลดไฟล์ได้ 1 ไฟล์/ครั้ง
                                โดยแต่ละไฟล์ต้องมีขนาดไม่เกิน 5 MB (350x175 px)
                                (เฉพาะไฟล์ .png .jpg และ .jpeg เท่านั้น)
                              </span>
                              <p></p>
                              <img
                                id="imageCodeDisplayId"
                                width="180"
                                height="50"
                                src={data.thumbnailSrc}
                                onError={handleonImageError}
                                onSuccess={handleOnImageSuccess}
                                style={{ display: "none" }}
                              ></img>
                              <Upload
                                // action={avatarUpload}
                                // action="https://tceb.ccs-service.online/core-api/files"
                                // multiple
                                headers={() => {
                                  let accessToken =
                                    sessionStorage.getItem("accessToken");
                                  return {
                                    Authorization: `Bearer ${accessToken}`,
                                  };
                                }}
                                maxCount={1}
                                fileList={fileList}
                                // fileList={testFileList}
                                listType="picture-card"
                                // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                beforeUpload={beforeUpload}
                                onRemove={removeUpload}
                                onChange={handleUploadChange}
                                onPreview={handlePreview}
                                customRequest={uploadImage}
                              >
                                {fileList.length <= 0 ? (
                                  <button
                                    className="upload-button"
                                    style={{
                                      border: 0,
                                      background: "none",
                                    }}
                                    type="button"
                                  >
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </button>
                                ) : (
                                  ""
                                )}
                              </Upload>
                              <Image
                                // width={200}
                                // height={200}
                                wrapperStyle={{
                                  display: "none",
                                }}
                                preview={{
                                  visible: previewOpen,
                                  onVisibleChange: (visible) =>
                                    setPreviewOpen(visible),
                                  afterOpenChange: (visible) =>
                                    !visible && setPreviewImage(""),
                                }}
                                src={previewImage}
                                // src={imageUrl}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: uploadHasError ? 180 : 135,
                            marginBottom: dashboardNameHasError ? 50 : 0,
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "99%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  ชื่อแดซบอร์ด{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="dashboardName"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              <Input
                                showCount
                                maxLength={100}
                                onChange={(e) =>
                                  handleChangeText(
                                    e.target.value,
                                    "dashboardName"
                                  )
                                }
                                placeholder="ชื่อแดซบอร์ด"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 15,
                            marginBottom: listDetailHasError ? 100 : 0,
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "99%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  รายละเอียด หน้า List{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="listDetail"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              <TextArea
                                showCount
                                maxLength={300}
                                onChange={(e) =>
                                  handleChangeText(e.target.value, "listDetail")
                                }
                                placeholder="รายละเอียด หน้า List"
                                style={{ height: 85, resize: "none" }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 65,
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "99%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  รายละเอียด{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="detail"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: false,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              {/* <TextArea
                                showCount
                                maxLength={1000}
                                onChange={(e) =>
                                  handleChangeText(e.target.value, "detail")
                                }
                                placeholder="รายละเอียด"
                                style={{
                                  height: 145,
                                  resize: "none",
                                  marginBottom: 70,
                                }}
                              /> */}

                              <Flex
                                vertical={true}
                                gap="small"
                                justify="flex-end"
                                align="flex-end"
                              >
                                <ReactQuill
                                  className="react-quill"
                                  ref={quillRef}
                                  theme="snow"
                                  value={data.description}
                                  modules={modules}
                                  style={{ height: 300, width: "100%" }}
                                  onChange={handleOnEditTextChange}
                                />
                                {detailError ? detailError : ""}
                                <span
                                  style={{
                                    color: "#9ca0a6",
                                    marginTop: detailError ? 0 : 40,
                                  }}
                                >
                                  {getDetailLength()}/1000
                                </span>
                              </Flex>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default AdminDashboardManagement;
