import Icon from "@ant-design/icons";

const icon = () => (
  
<svg width="80" height="16" viewBox="0 0 80 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" fill="#FFFF67"/>
<rect x="16" width="16" height="16" fill="#3FFFFF"/>
<rect x="32" width="16" height="16" fill="#52DC4B"/>
<rect x="48" width="16" height="16" fill="#FF6E3D"/>
<rect x="64" width="16" height="16" fill="#3D45FF"/>
</svg>


);

const Color_blocks = (props) => <Icon component={icon} {...props} />;

export default Color_blocks;

