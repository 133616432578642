import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
  >
    <rect width="78" height="78" rx="20" fill="#F8F8F9" />
    <g clipPath="url(#clip0_7977_56284)">
      <path
        d="M63.375 18.375H14.625C13.5879 18.375 12.75 19.2129 12.75 20.25V57.75C12.75 58.7871 13.5879 59.625 14.625 59.625H63.375C64.4121 59.625 65.25 58.7871 65.25 57.75V20.25C65.25 19.2129 64.4121 18.375 63.375 18.375ZM61.0312 24.8672V55.4062H16.9688V24.8672L15.3516 23.6074L17.6543 20.6484L20.1621 22.5996H57.8438L60.3516 20.6484L62.6543 23.6074L61.0312 24.8672ZM57.8438 22.5938L39 37.2422L20.1562 22.5938L17.6484 20.6426L15.3457 23.6016L16.9629 24.8613L36.9785 40.4238C37.5541 40.871 38.2623 41.1138 38.9912 41.1138C39.7201 41.1138 40.4283 40.871 41.0039 40.4238L61.0312 24.8672L62.6484 23.6074L60.3457 20.6484L57.8438 22.5938Z"
        fill="#2E323A"
      />
      <path
        d="M63.375 18.375H14.625C13.5879 18.375 12.75 19.2129 12.75 20.25V57.75C12.75 58.7871 13.5879 59.625 14.625 59.625H63.375C64.4121 59.625 65.25 58.7871 65.25 57.75V20.25C65.25 19.2129 64.4121 18.375 63.375 18.375ZM61.0312 24.8672V55.4062H16.9688V24.8672L15.3516 23.6074L17.6543 20.6484L20.1621 22.5996H57.8438L60.3516 20.6484L62.6543 23.6074L61.0312 24.8672ZM57.8438 22.5938L39 37.2422L20.1562 22.5938L17.6484 20.6426L15.3457 23.6016L16.9629 24.8613L36.9785 40.4238C37.5541 40.871 38.2623 41.1138 38.9912 41.1138C39.7201 41.1138 40.4283 40.871 41.0039 40.4238L61.0312 24.8672L62.6484 23.6074L60.3457 20.6484L57.8438 22.5938Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M63.375 18.375H14.625C13.5879 18.375 12.75 19.2129 12.75 20.25V57.75C12.75 58.7871 13.5879 59.625 14.625 59.625H63.375C64.4121 59.625 65.25 58.7871 65.25 57.75V20.25C65.25 19.2129 64.4121 18.375 63.375 18.375ZM61.0312 24.8672V55.4062H16.9688V24.8672L15.3516 23.6074L17.6543 20.6484L20.1621 22.5996H57.8438L60.3516 20.6484L62.6543 23.6074L61.0312 24.8672ZM57.8438 22.5938L39 37.2422L20.1562 22.5938L17.6484 20.6426L15.3457 23.6016L16.9629 24.8613L36.9785 40.4238C37.5541 40.871 38.2623 41.1138 38.9912 41.1138C39.7201 41.1138 40.4283 40.871 41.0039 40.4238L61.0312 24.8672L62.6484 23.6074L60.3457 20.6484L57.8438 22.5938Z"
        fill="black"
        fillOpacity="0.2"
      />
    </g>
    <defs>
      <clipPath id="clip0_7977_56284">
        <rect width="60" height="60" fill="white" transform="translate(9 9)" />
      </clipPath>
    </defs>
  </svg>
);

const Icon_confirm_mail = (props) => <Icon component={icon} {...props} />;

export default Icon_confirm_mail;
