import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.75 12H20.25" stroke="#0B63E5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#0B63E5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>









);

const Icon_Right_Arrow_View_Dashboard = (props) => <Icon component={icon} {...props} />;

export default Icon_Right_Arrow_View_Dashboard;

