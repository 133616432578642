import { handleLogin } from "../keycloak/KeycloakAuth";

const PrivateRoute = ({ children, allowedRoles = [] }) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const userMe = JSON.parse(sessionStorage.getItem("userMe"));

  if (!accessToken) {
    return handleLogin();
  }

  const hasAccess =
    allowedRoles.length === 0 ||
    allowedRoles.some((role) => userMe?.userRoleName.includes(role));

  if (!hasAccess) {
    return (window.location.href = "/");
  }

  return children;
};

export default PrivateRoute;
