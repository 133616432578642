import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import "./CustomSearch.css";

const CustomSearch = ({ placeholder, onSearch, width, height}) => {
  const [keyword, setKeyword] = useState("");

  const handleSearch = () => {
    onSearch(keyword);
  };

  return (
    <>
      {/* <div className="search-container"> */}
        <Input
          className="search-input"
          placeholder={placeholder}
          suffix={
            <SearchOutlined className="search-icon" onClick={handleSearch} />
          }
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onPressEnter={handleSearch}
          style={{ width, height }}
        />
      {/* </div> */}
    </>
  );
};

export default CustomSearch;
