import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal } from 'antd';
import logo from '../../Login/logo 1.png';
import IconConWarningGray from '../../../../assets/icon/icon_warning_gray_dialog';
import IconSuccess from '../../../../assets/icon/icon_success_default';
import '../../Login/login.css';
import '../register.css';
import './verify_timeout.css';
import { KeycloakService } from '../../../../services/KeycloakService';
import { CloseCircleOutlined } from '@ant-design/icons';


const VerifyEmailTimeoutPage = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isVerifyingEmailSuccess, setVerifyingEmailSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // โหลดสคริปต์ reCAPTCHA v3
    useEffect(() => {
        const tokenFromUrl = new URLSearchParams(window.location.search);
        const token = tokenFromUrl.get('token');
        if (token) {
            setToken(token);
        }
        
    }, []);
    const resendEmailVerify = async () => {
        try {
            setIsLoading(true);
            var verifyEmailResponse = await KeycloakService.resendVerification(token);
            if (verifyEmailResponse && verifyEmailResponse.status === 204) {
                setVerifyingEmailSuccess(true);
            }
        }catch (error) {
            setIsModalVisible(true);
            setErrorMessage(`(${error.response.data.errorCode}) ${error.response.data.messageTh}`);
            console.error('Resend Email Verify failed:', error);
        }finally {
            setIsLoading(false);
        }
    };
 
    return (
        <div className="login-containe1">
            <Row>
                <Col span={18} md={18} xs={24}>
                    <Row>
                        <Col span={24} md={24} xs={0}>
                        <div className="login-bg"></div>
                        <div className='login-left'>
                            <div className="logo">
                            <img
                                src={logo}
                                alt="TCEB Logo"
                                className="logo-image"
                                onClick={() => navigate('/')}
                                style={{ cursor: 'pointer' }}
                            />
                            </div>
                        </div>
                        <div className="copyright">
                            © 2567 สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)
                        </div>
                        </Col>
                        <Col span={24} xs={24} md={0}>
                        <div className="login-bg" style={{position:'fixed'}}></div>
                        <div className="logo" style={{textAlign:"center", marginTop:"15px"}}>
                            <img
                            src={logo}
                            alt="TCEB Logo"
                            className="logo-image"
                            onClick={() => navigate('/')}
                            style={{ cursor: 'pointer' }}
                            />
                        </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={6} md={6} xs={24}>
                    <div className="center-v">
                        <div style={{textAlign:"center"}}>
                        {isVerifyingEmailSuccess?(
                                <>
                                <div
                                    className="login-card">
                                    <IconSuccess
                                        style={{
                                        marginTop: 12,
                                        }}
                                    />
    
                                    <span style={{ display: 'block', margin: '10px 0' }}> {/* ใช้ margin เพื่อเพิ่มระยะห่าง */}
                                        <h2>ระบบได้ส่งอีเมลยืนยันตัวตนใหม่แล้ว</h2>กรุณากดยืนยันตัวตนใหม่อีกครั้ง ผ่านทางอีเมลของท่าน
                                    </span>
                                    <Button type="primary" htmlType="submit" className="register-form-button" onClick={()=>{
                                        navigate('/login');
                                    }} >
                                        กลับไปยังหน้าลงชื่อเข้าใช้
                                    </Button>
                                </div>
                                <Row>
                                    <Col span={0} md={0} xs={24}><div style={{padding:"10vh"}}></div></Col>
                                </Row>
                                </>
                        ):(
                            <>
                            <div
                                className="login-card">
                                <IconConWarningGray
                                    style={{
                                    marginTop: 12,
                                    }}
                                />
                                <p
                                    style={{
                                        margin: '20px 0 0 0', // กำหนดระยะห่างด้านบนให้กับพารากราฟ
                                        textAlign: 'center',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    ลิงค์หมดอายุ
                                </p>
                                <span style={{ display: 'block', margin: '10px 0' }}> {/* ใช้ margin เพื่อเพิ่มระยะห่าง */}
                                    ลิงค์ยืนยันอีเมลหมดอายุ แล้วกรุณากดส่งยืนยันตัวตนใหม่
                                </span>
                                <Button type="primary" htmlType="submit" className="register-form-button" onClick={resendEmailVerify} >
                                    ส่งอีเมลยืนยันตัวตนอีกครั้ง
                                </Button>
                            </div>
                            <Row>
                                <Col span={0} md={0} xs={24}><div style={{padding:"10vh"}}></div></Col>
                            </Row>
                            </>
                        )}
                        </div>
                    </div>
                        
                </Col>
            </Row>
            <Modal
                open={isModalVisible}
                onOk={()=>{setIsModalVisible(false);}}
                footer={null} // ไม่มีปุ่ม Footer
                closable={false} // ไม่สามารถปิด Modal ด้วยการกดปุ่มปิด
                centered // จัด Modal ให้อยู่กลางหน้าจอ
                width={300} // กำหนดความกว้างของ Modal
            >
                <div style={{ textAlign: 'center' }}>
                <CloseCircleOutlined style={{ fontSize: '40px', color: '#E03A3A' }} /> {/* ลดขนาดไอคอน */}
                <h2 style={{ marginTop: '16px' }}>ไม่สำเร็จ</h2>
                <p style={{ fontSize: '14px' }}>เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง</p> {/* ลดขนาดข้อความ */}
                {errorMessage && <p style={{ color: '#E03A3A', fontSize: '14px' }}>{errorMessage}</p>}
                <Button type="primary" onClick={()=>{setIsModalVisible(false);}} style={{ backgroundColor: '#E03A3A', borderColor: '#E03A3A', }}>
                    ตกลง
                </Button>
                </div>
            </Modal>
            {isLoading && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="spinner"></div>
                        <p>กรุณารอซักครู่...</p>
                    </div>
                </div>
            )}
        </div>
        
    );
};

export default VerifyEmailTimeoutPage;

