import React, { useState } from "react";
import { Layout, Col, Row, Table, Input, Select, Button, Pagination } from "antd";
import "./feedback.css";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';

const { Content } = Layout;
const { Option } = Select;

// Mock Data
const mockData = [
    {
        key: '1',
        name: 'รังสรรค์ สมนกุล',
        email: 'Thawatchai@gmail.com',
        col1: 5,
        col2: 5,
        col3: 5,
        col4: 5,
        col5: 5,
        col6: 5,
        date: '12/03/2024 12:20',
    },
    {
        key: '2',
        name: 'เมธิดา พงศ์ไพกมล',
        email: 'menila.p@example.com',
        col1: 4,
        col2: 4,
        col3: 4,
        col4: 4,
        col5: 4,
        col6: 4,
        date: '12/03/2024 12:20',
    },
    {
        key: '3',
        name: 'ก้องเกียรติ ธารางกูร',
        email: 'kongyos.t@example.com',
        col1: 4,
        col2: 4,
        col3: 4,
        col4: 4,
        col5: 4,
        col6: 4,
        date: '12/03/2024 12:20',
    },
    {
        key: '4',
        name: 'พาฝัน เจริญรุ่งวนา',
        email: 'parfun.c@example.com',
        col1: 3,
        col2: 3,
        col3: 3,
        col4: 3,
        col5: 3,
        col6: 3,
        date: '12/03/2024 12:20',
    },
    {
        key: '5',
        name: 'โชคชัย พัฒน',
        email: 'chokchai.s@example.com',
        col1: 4,
        col2: 4,
        col3: 4,
        col4: 4,
        col5: 4,
        col6: 4,
        date: '12/03/2024 12:20',
    },
    {
        key: '6',
        name: 'เกียรติศักดิ์ วรสุวัฒน์',
        email: 'kittinak.j@example.com',
        col1: 4,
        col2: 4,
        col3: 4,
        col4: 4,
        col5: 4,
        col6: 4,
        date: '12/03/2024 12:20',
    },
    {
        key: '7',
        name: 'พานิตา ตระกูลดี',
        email: 'panita.p@example.com',
        col1: 4,
        col2: 4,
        col3: 4,
        col4: 4,
        col5: 4,
        col6: 4,
        date: '12/03/2024 12:20',
    },
    {
        key: '8',
        name: 'ปิยรมณ์ กัลพัฒน์',
        email: 'piyarom.t@example.com',
        col1: 4,
        col2: 4,
        col3: 4,
        col4: 4,
        col5: 4,
        col6: 4,
        date: '12/03/2024 12:20',
    },
    {
        key: '9',
        name: 'ฐานันดร์ ตั้งศิริ',
        email: 'thapanat.r@example.com',
        col1: 4,
        col2: 4,
        col3: 4,
        col4: 4,
        col5: 4,
        col6: 4,
        date: '12/03/2024 12:20',
    },
    {
        key: '10',
        name: 'จันทลี ทรงพิสิษฐ์',
        email: 'chaninsee.s@example.com',
        col1: 5,
        col2: 5,
        col3: 5,
        col4: 5,
        col5: 5,
        col6: 5,
        date: '12/03/2024 12:20',
    },
];

const FeedbackPage = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    const handleSearchChange = (e) => {
        setSearchKeyword(e.target.value);
    };

    const handlePageSizeChange = (value) => {
        setPageSize(value);
        setCurrentPage(1);
    };

    const handleExport = () => {
        console.log("Exporting data...");
        // Implement export logic here
    };

    const filteredData = mockData.filter((item) =>
        item.name.includes(searchKeyword) || item.email.includes(searchKeyword)
    );

    const columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "ชื่อ - นามสกุล",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "อีเมล",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "ข้อที่ 1",
            dataIndex: "col1",
            key: "col1",
        },
        {
            title: "ข้อที่ 2",
            dataIndex: "col2",
            key: "col2",
        },
        {
            title: "ข้อที่ 3",
            dataIndex: "col3",
            key: "col3",
        },
        {
            title: "ข้อที่ 4",
            dataIndex: "col4",
            key: "col4",
        },
        {
            title: "ข้อที่ 5",
            dataIndex: "col5",
            key: "col5",
        },
        {
            title: "ข้อที่ 6",
            dataIndex: "col6",
            key: "col6",
        },
        {
            title: "วันที่/เวลา",
            dataIndex: "date",
            key: "date",
        },
    ];

    return (
        <div className="tag-body">
            <Row style={{ width: "100%", marginTop: "20px", justifyContent: "center" }}>
                <Col lg={24}>
                    <div className="tag-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                            <h2>จัดการประเภท Tag</h2>
                            <Button type="primary" onClick={handleExport}>  <VerticalAlignBottomOutlined /> Export</Button>
                        </div>
                        <hr
                            style={{
                                border: '1px solid #ccc',
                                margin: '10px 0',
                                display: 'block',
                            }}
                        />


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                            <Input
                                placeholder="ค้นหา"
                                value={searchKeyword}
                                onChange={handleSearchChange}
                                style={{
                                    width: '300px',
                                    height: '40px',
                                    borderRadius: '10px',
                                }}
                            />
                            <Button
                                style={{
                                    height: '40px',
                                    marginLeft: '10px',
                                    borderRadius: '10px',
                                }}
                                onClick={""}
                            >
                                <AdjustmentsHorizontalIcon style={{ width: '20px', height: '20px' }} />
                                ตัวกรอง
                            </Button>
                        </div>


                        <div style={{ overflowX: 'auto' }}>
                            <Table
                                dataSource={filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                                columns={columns}
                                pagination={false}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                                <span>จำนวนทั้งหมด: {filteredData.length} รายการ</span>
                                <div style={{ textAlign: 'center' }}>
                                    <Pagination
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={filteredData.length}
                                        onChange={(page) => setCurrentPage(page)}
                                    />
                                </div>
                                <Select
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                    style={{ width: 120 }}
                                >
                                    <Option value={5}>5 รายการ</Option>
                                    <Option value={10}>10 รายการ</Option>
                                    <Option value={20}>20 รายการ</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default FeedbackPage;
