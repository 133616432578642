import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import "../../../i18n/i18n.ts";
import {
  Flex,
  Space,
  Button,
  Modal,
  Row,
  Col,
  Tag,
  Carousel,
  Grid,
} from "antd";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
// import thTh from "antd/locale/th_TH";
// import dayjs from "dayjs";
import "../../../components/Card/CustomCard.css";
import IconFeedbackButtton from "../../../assets/icon/icon_feedback_button.js";
import IconCookieButtton from "../../../assets/icon/icon_cookie_button.js";
import IconCookieMini from "../../../assets/icon/icon_cookie_mini.js";
import ImageContent1 from "../../../assets/img/content1.png";
import ImageContent2 from "../../../assets/img/content2.png";
import ImageContent3 from "../../../assets/img/content3.png";
import ImageContent4 from "../../../assets/img/content4.png";
import IconRightArrowAll from "../../../assets/icon/icon_right_arrow_all.js";
import IconRightArrowViewDashboard from "../../../assets/icon/icon_right_arrow_view_dashboard.js";
import NoImg from "../../../assets/img/no_img.png";
import { useCookies } from "react-cookie";

import "dayjs/locale/th";

import { DashboardService } from "../../../services/DashboardService";
import { InsightReportService } from "../../../services/InsightReportService";

const Home = () => {
  const { useBreakpoint } = Grid;
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const carouselRefDashboard = useRef(null);
  const carouselRefInsight = useRef(null);
  const [dashboardData, setDashboardData] = useState();
  const [insightReportData, setInsightReportData] = useState();
  const [slideShowInsight, setSlideShowInsight] = useState();
  const [heightShowInsight, setHeightShowInsight] = useState();
  const [slideShowDashboard, setSlideShowDashboard] = useState();
  const [heightShowDashboard, setHeightShowDashboard] = useState();
  const [heightShowImgDashboard, setHeighImgtShowDashboard] = useState();
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isModalCookieOpen, setIsModalCookieOpen] = useState(false);
  const [removeCookie] = useCookies(["dashboard-condition", "insight-report-condition", "data-catalog-condition"]);
  useEffect(() => {
    if (!dashboardData) {
      fetchDashboardData();
    }
  }, [dashboardData]);

  useEffect(() => {
    if (!insightReportData) {
      fetchInsightReportData();
    }
  }, [insightReportData]);

  useEffect(() => {
    const initialWidth = window.innerWidth;
    if (initialWidth < 575) {
      setSlideShowInsight(1);
      setSlideShowDashboard(1);
      setHeighImgtShowDashboard(200);
    } else if (initialWidth < 768) {
      setSlideShowInsight(2);
      setHeightShowInsight(500);
      setSlideShowDashboard(1);
      setHeighImgtShowDashboard(220);
    } else if (initialWidth < 992) {
      setSlideShowInsight(2);
      setHeightShowInsight(570);
      setSlideShowDashboard(2);
      setHeighImgtShowDashboard(220);
    } else if (initialWidth < 1200) {
      setSlideShowInsight(3);
      setHeightShowInsight(520);
      setSlideShowDashboard(2);
      setHeightShowDashboard(590);
      setHeighImgtShowDashboard(300);
    } else {
      setSlideShowInsight(4);
      setHeightShowInsight(520);
      setSlideShowDashboard(2);
      setHeightShowDashboard(590);
      setHeighImgtShowDashboard(300);
    }
  }, []);

  useEffect(() => {
    if (screens.xs) {
      setSlideShowInsight(1);
      setSlideShowDashboard(1);
      setHeighImgtShowDashboard(200);
    }
    if (screens.sm) {
      setSlideShowInsight(2);
      setHeightShowInsight(570);
      setSlideShowDashboard(1);
      setHeighImgtShowDashboard(220);
    }
    if (screens.md) {
      setSlideShowInsight(2);
      setHeightShowInsight(500);
      setSlideShowDashboard(2);
      setHeighImgtShowDashboard(220);
    }
    if (screens.lg) {
      setSlideShowInsight(3);
      setHeightShowInsight(520);
      setSlideShowDashboard(2);
      setHeightShowDashboard(590);
      setHeighImgtShowDashboard(300);
    }
    if (screens.xl) {
      setSlideShowInsight(4);
      setHeightShowInsight(520);
      setSlideShowDashboard(2);
      setHeightShowDashboard(590);
      setHeighImgtShowDashboard(300);
    }
    if(localStorage.getItem("cookie") === null){
      setIsModalCookieOpen(true);
    }
    // if (screens.xxl) setSlideShowInsight(4);
  }, [screens]);

  const fetchDashboardData = async () => {
    try {
      const params = {
        IsPinned: true,
        IsActivated: true,
      };

      const response = await DashboardService.getPublic(params);
      setDashboardData(response.items);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInsightReportData = async () => {
    try {
      const params = {
        IsPinned: true,
        IsActivated: true,
      };

      const response = await InsightReportService.getPublic(params);
      setInsightReportData(response.items);
    } catch (error) {
      console.error(error);
    }
  };

  // const fillData = async (data) => {
  //   const filledData = [...data];
  //   while (filledData.length % slideShow !== 0) {
  //     filledData.push(null);
  //   }
  //   return filledData;
  // };

  const handleClickDashBoard = (id, name) => {
    const Name = encodeURIComponent(name);
    navigate(`/dashboard/${Name}`);
  };

  const handleClickInsight = (id, name) => {
    const Name = encodeURIComponent(name);
    navigate(`/insight-report/${Name}`);
  };

  const consentCookie = () => {
    localStorage.setItem("cookie", "true");
    setIsModalCookieOpen(false);
  }
  const declineCookie = () => {
    localStorage.setItem("cookie", "false");
    setIsModalCookieOpen(false);
    removeCookie("dashboard-condition");
    removeCookie("insight-report-condition");
    removeCookie("data-catalog-condition");
  }

  return (
    <>
      
      <div className="home-body">
        {isModalCookieOpen ? (
          <div className="cookie-layout">
            <Flex>
              <IconCookieMini style={{ marginLeft: 20, marginTop: 10 }} />{" "}
              <p className="cookie-title">เว็บไซต์นี้มีการใช้งานคุกกี้</p>
            </Flex>
            <p className="cookie-des">
              เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพ
              และประสบการณ์ที่ดีในการใช้งานเว็บไซต์
              เมื่อคุณกดยอมรับเราจะสามารถเลือกแสดงสิ่งที่น่าสนใจสำหรับคุณได้โดยเฉพาะ
            </p>
            <Space>
              <Button
                type="defualt"
                onClick={consentCookie}
                className={localStorage.getItem("cookie") === "false" ? "cookie-decline" : "cookie-consent"}
              >
                ยอมรับ
              </Button>
              <Button
                type="defualt"
                onClick={declineCookie}
                className={localStorage.getItem("cookie") === "false" ? "cookie-consent" : "cookie-decline"}
              >
                ปฎิเสธ
              </Button>
            </Space>
          </div>
        ) : null}

        <div className="home-container">
          <p className="content-head">
            ผู้นำอุตสาหกรรม MICE
            ร่วมกันสร้างความร่วมมือในการพัฒนาและสร้างสรรค์สิ่งใหม่ๆ
          </p>
          <p className="content-head-detail">
            ความร่วมมือเชิงกลยุทธ์ผู้นำอุตสาหกรรมและสถาบันวิจัยที่มุ่งเน้นผู้มีส่วนได้ส่วนเสียด้าน
            MICE ในประเทศไทยเพื่อรักษาความได้เปรียบทางการแข่งขันในระดับโลก
          </p>

          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
              <div className="content-card">
                <Flex>
                  <img
                    className="content-image"
                    alt="example"
                    src={ImageContent1}
                  />
                  <Flex vertical="false" justify="center">
                    <div style={{ padding: "8px" }}>
                      <p className="content-card-title">
                        เชื่อมโยงอุตสาหกรรม MICE
                      </p>
                      <p className="content-card-detail">
                        ความร่วมมือเชิงกลยุทธ์ผู้นำอุตสาหกรรมและสถาบันวิจัยที่มุ่งเน้นผู้มีส่วนได้ส่วนเสียด้าน
                        MICE
                        ในประเทศไทยเพื่อรักษาความได้เปรียบทางการแข่งขันในระดับโลก
                      </p>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </Col>
            <Col xs={24} md={24} xl={12} xxl={12}>
              <div className="content-card">
                <Flex>
                  <img
                    className="content-image"
                    alt="example"
                    src={ImageContent2}
                  />
                  <Flex vertical="false" justify="center">
                    <div style={{ padding: "8px" }}>
                      <p className="content-card-title">
                        วิเคราะห์ข้อมูลเชิงลึก
                      </p>
                      <p className="content-card-detail">
                        การวิเคราะห์ข้อมูลเชิงลึกเป็นกระบวนการที่ใช้เทคนิคการเรียนรู้ของเครื่องเพื่อการวิเคราะห์ข้อมูลที่มีความซับซ้อนหรือข้อมูลที่มีโครงสร้างที่ซับซ้อน
                      </p>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </Col>
            <Col xs={24} md={24} xl={12} xxl={12}>
              <div className="content-card">
                <Flex>
                  <img
                    className="content-image"
                    alt="example"
                    src={ImageContent3}
                    // src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                  />
                  <Flex vertical="false" justify="center">
                    <div style={{ padding: "8px" }}>
                      <p className="content-card-title">สร้างความร่วมมือ</p>
                      <p className="content-card-detail">
                        ร่วมกันสร้างความร่วมมือในการพัฒนาและสร้างสรรค์สิ่งใหม่ๆ
                        ที่สามารถเพิ่มประสิทธิภาพในการทำงานของอุตสาหกรรม MICE
                        ในประเทศไทย
                      </p>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </Col>
            <Col xs={24} md={24} xl={12} xxl={12}>
              <div className="content-card">
                <Flex>
                  <img
                    className="content-image"
                    alt="example"
                    src={ImageContent4}
                  />
                  <Flex vertical="false" justify="center">
                    <div style={{ padding: "8px" }}>
                      <p className="content-card-title">
                        กระตุ้นการจัดกิจกรรม MICE
                      </p>
                      <p className="content-card-detail">
                        สร้างความสนุกสนานและเพลิดเพลินในการจัดกิจกรรม MICE
                        ให้เป็นที่น่าจดจำและสร้างประสบการณ์ที่ดีให้กับผู้เข้าร่วม
                      </p>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="container-dashboard">
        <div className="content-dashboard">
          <p className="content-head-dashboard">แดชบอร์ด</p>
          <p className="content-head-detail-dashboard">
            เราได้นำเสนอแดชบอร์ดที่เกี่ยวข้องกับอุตสาหกรรม MICE
            ที่สามารถช่วยให้คุณเข้าใจข้อมูลและข่าวสารที่สำคัญได้ง่ายขึ้น
          </p>
          <Flex justify="space-between" align="center">
            <Space>
              {(dashboardData &&
                dashboardData?.length > 2 &&
                slideShowDashboard !== 1) ||
              (slideShowDashboard === 1 && dashboardData?.length > 1) ? (
                <>
                  <Button
                    className="arrow-left-home"
                    onClick={() => carouselRefDashboard.current.prev()}
                  >
                    <HiOutlineArrowLeft />
                  </Button>
                  <Button
                    className="arrow-right-home"
                    onClick={() => carouselRefDashboard.current.next()}
                  >
                    <HiOutlineArrowRight />
                  </Button>
                </>
              ) : (
                <div></div>
              )}
            </Space>

            <Flex
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              <p className="see-all">ดูทั้งหมด</p>
              <IconRightArrowAll />
            </Flex>
          </Flex>
        </div>

        <div className="container-card-dashboard">
          <Carousel
            ref={carouselRefDashboard}
            slidesToShow={slideShowDashboard}
            slidesToScroll={slideShowDashboard}
            infinite={false}
            dots={false}
            centerMode={slideShowDashboard === 1}
            centerPadding="40px"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {dashboardData?.sort((a, b) => a.sequence - b.sequence).map((item, index) => (
                <div key={item.id} >
                  {item && (
                    <div style={{ margin: "8px 8px 20px 8px" }}>
                      <div
                        className="content-card-dashboard"
                        style={{
                          height: screens.xs ? "" : heightShowDashboard,
                        }}
                      >
                        <div>
                          <div
                            style={{
                              height: heightShowImgDashboard,
                              overflow: "hidden",
                              marginBottom: "20px",
                            }}
                          >
                            <img
                              className="content-image-dashboard"
                              alt="example"
                              src={
                                item?.thumbnailSrc ?? `${NoImg}`
                              }
                              onError={(e) => {
                                if (e.target.alt === "error") {
                                  //console.log("No img");
                                  e.target.src = NoImg;
                                } else {
                                  e.target.src = NoImg;
                                }
                              }}
                            />
                          </div>
                          <div>
                            <Flex wrap gap="small">
                              {item?.dashboardTags.slice(0, 3).map((tag) => (
                                <Tag
                                  key={tag.id}
                                  bordered={false}
                                  color={tag.tagColor}
                                >
                                  {tag.tagName}
                                </Tag>
                              ))}
                            </Flex>
                          </div>
                          <div>
                            <p className="content-card-title-dashboard">
                              {item.name}
                            </p>
                            <p className="content-card-detail-dashboard">
                              {item.shortDescription.length > 100
                                ? item.shortDescription.substring(0, 100) +
                                  "..."
                                : item.shortDescription}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            paddingLeft: screens.xl ? "80px" : "",
                            paddingRight: screens.xl ? "80px" : "",
                          }}
                        >
                          <Flex
                            justify="center"
                            align="center"
                            style={{
                              padding: "10px 20px",
                              backgroundColor: "#f0f5ff",
                              borderRadius: "10px",
                              cursor: "pointer",
                              color: "#2B7BF6",
                              fontWeight: "bold",
                              fontSize: "14px",
                              lineHeight: "1.5",
                            }}
                            onClick={() =>
                              handleClickDashBoard(item.id, item.name)
                            }
                          >
                            <p
                              style={{
                                margin: "0",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span style={{ marginRight: "8px" }}>
                                ดูรายละเอียด
                              </span>
                              <IconRightArrowViewDashboard />
                            </p>
                          </Flex>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </Carousel>
        </div>
      </div>

      <div className="container-insight-report">
        <div className="content-insight-report">
          <p className="content-head-dashboard">
            Insight Report (รายงานที่วิเคราะห์ข้อมูลเชิงลึก)
          </p>
          <Flex justify="space-between" align="center">
            <Space>
              {(insightReportData &&
                insightReportData?.length > 4 &&
                slideShowInsight === 4) ||
              (slideShowInsight === 3 && insightReportData?.length > 3) ||
              (slideShowInsight === 2 && insightReportData?.length > 2) ||
              (slideShowInsight === 1 && insightReportData?.length > 1) ? (
                <>
                  <Button
                    className="arrow-left-home"
                    onClick={() => carouselRefInsight.current.prev()}
                  >
                    <HiOutlineArrowLeft />
                  </Button>
                  <Button
                    className="arrow-right-home"
                    onClick={() => carouselRefInsight.current.next()}
                  >
                    <HiOutlineArrowRight />
                  </Button>
                </>
              ) : (
                <div></div>
              )}
            </Space>
            <Flex
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/insight-report");
              }}
            >
              <p className="see-all">ดูทั้งหมด</p>
              <IconRightArrowAll />
            </Flex>
          </Flex>
        </div>

        <div className="container-card-insight-report">
          <Carousel
            ref={carouselRefInsight}
            slidesToShow={slideShowInsight}
            slidesToScroll={slideShowInsight}
            infinite={false}
            dots={false}
            centerMode={slideShowInsight === 1}
            centerPadding="40px"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {insightReportData
              ?.sort((a, b) => a.sequence - b.sequence)
              .map((item, index) => (
                <div key={item.id}>
                  {item && (
                    <div style={{ margin: "8px 8px 8px 8px" }}>
                      <div
                        key={index}
                        className="content-card-insight-report"
                        style={{ height: heightShowInsight }}
                      >
                        <div>
                          <div
                            style={{
                              height: "175px",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              className="content-image-insight-report"
                              alt="example"
                              src={
                                item?.thumbnailSrc ?? `${NoImg}`
                              }
                              onError={(e) => {
                                if (e.target.alt === "error") {
                                  //console.log("No img");
                                  e.target.src = NoImg;
                                } else {
                                  e.target.src = NoImg;
                                }
                              }}
                            />
                          </div>
                          <div style={{ padding: "24px 24px 0px 24px" }}>
                            <Flex wrap gap="small">
                              {item?.insightReportTags
                                .slice(0, 3)
                                .map((tag) => (
                                  <Tag
                                    key={tag.id}
                                    bordered={false}
                                    color={tag.tagColor}
                                  >
                                    {tag.tagName}
                                  </Tag>
                                ))}
                            </Flex>

                            <p className="content-card-title-dashboard">
                              {item?.name}
                            </p>
                            <p className="content-card-detail-insight-report">
                              {item?.shortDescription.length > 100
                                ? item?.shortDescription.substring(0, 100) +
                                  "..."
                                : item?.shortDescription}
                            </p>

                            <p className="insight-report-date">
                              {item?.createdDateFrom}
                            </p>
                          </div>
                        </div>
                        <div style={{ padding: "0px 24px 24px 24px" }}>
                          <Flex
                            justify="flex-start"
                            onClick={() =>
                              handleClickInsight(item.id, item.name)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <p className="content-tag-dashboard-button-text">
                              อ่านต่อ
                            </p>
                            <IconRightArrowViewDashboard />
                          </Flex>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </Carousel>
        </div>
      </div>

      <IconFeedbackButtton
        className="home-feedback"
        onClick={() => setIsModalFormOpen(true)}
      />
      <IconCookieButtton
        className="home-cookie"
        onClick={() => setIsModalCookieOpen(true)}
      />

      <Modal
        title={<p>Feedback</p>}
        open={isModalFormOpen}
        footer={null}
        closable={true}
        destroyOnClose={true}
        width={680}
        onCancel={() => setIsModalFormOpen(false)}
      >
        {/* <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSeGFFo2tOYe2AUrFdgRVlfW65M2RtpDvGyvMbHNYfNq3sNedg/viewform?embedded=true"
                width="640"
                height="959"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                onLoad={resizeIframe}
                id="mainframe" 
              >
                กำลังโหลด…
              </iframe> */}

        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSfVfGwbpX1NSezBwt8eqNyje3ZQOnV89XslQ246F1sZf063mw/viewform?embedded=true"
          width="100%"
          height="919"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          กำลังโหลด…
        </iframe>
      </Modal>
    </>
  );
};

export default Home;
