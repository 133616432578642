import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const DataSourceService = {
//   get: async function (id, cancel = false) {
//     const response = await api.request({
//       url: `/datasource/${id}`,
//       method: "GET",
//       signal: cancel
//         ? cancelApiObject[this.get.name].handleRequestCancellation().signal
//         : undefined,
//     });

//     return response.data;
//   },
  search: async function (params, cancel = false) {
    const response = await api.request({
      url: `/datasource`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  create: async function (data, cancel = false) {
    const response = await api.request({
      url: `/datasource`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  savebulk: async function (data, cancel = false) {
    const response = await api.request({
      url: `/datasource/bulk`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.savebulk.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  update: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/datasource/${id}`,
      method: "PUT",
      data: data,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  delete: async function (id, cancel = false) {
    const response =  await api.request({
      url: `/datasource/${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  searchPublic: async function (params, cancel = false) {
    const query = new URLSearchParams();
    params.TagIds.forEach((id) => {
      query.append("TagIds", id);
      delete params.TagIds;
    });
    const response = await api.request({
      url: `/datasource/public?${query.toString()}`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getByName: async function (name, cancel = false) {
    const response =  await api.request({
      url: `/datasource/by-name?name=${name}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getByName.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  getAssociationList: async function (cancel = false) {
    const response =  await api.request({
      url: `/datasource/association-list`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAssociationList.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
};

const cancelApiObject = defineCancelApiObject(DataSourceService);
