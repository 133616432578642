import React, { useEffect, useState } from "react";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Switch,
  Col,
  Row,
  Space,
  Flex,
  Modal,
  Select,
  Tooltip,
} from "antd";
import "./SwaggerDoc.css";
import CustomButton from "../../../components/Button/CustomButton";
import DeleteAction from "../../../components/Button/DeleteAction";

const { Option } = Select;

const SwaggerRouteForm = ({
  open,
  cancel,
  action,
  filedValue,
  onSave,
  items,
  editingItem,
  routeExternalList,
  tags,
  selectTag,
  setSelectTag,
  handleSetTagOption,
  tagOptions,
  schemas,
  schemaOption,
}) => {
  const [form] = Form.useForm();
  // const [inputSchemaName, setInputSchemaName] = useState("");

  useEffect(() => {
    // handleSetSchema();
    setTimeout(() => {
      form.resetFields();
      handleSetSchema();
    }, 100);
  }, [open]);

  const onFinish = (values) => {
    onSave(values);
  };

  const handleSelectPath = (value) => {
    const findRoute = routeExternalList.find((item) => item.path === value);
    form.setFieldsValue({ method: findRoute?.method });
  };

  const handleChangeTag = (value) => {
    const select = value.filter((i) => i !== selectTag[0]);

    if (select.length !== 0) {
      const filterTag = tags.filter((i) => i === select[0]);
      if (filterTag.length === 0) {
        tags.push(select[0]);
        handleSetTagOption();
      }
    }
    form.setFieldsValue({ tag: select[0] });
    setSelectTag(select);
  };

  // const handleInputChange = (e) => {
  //   setInputSchemaName(e.target.value);
  // };

  // const handleSetOptionSchema = () => {
  //   if (inputSchemaName && !schemaOption.includes(inputSchemaName)) {
  //     setSchemaOption([
  //       ...schemaOption,
  //       { value: inputSchemaName, label: inputSchemaName },
  //     ]);
  //   }
  // };

  const handleSetSchema = (e) => {
    const { requestBody, responses } = form.getFieldsValue();
    const responseSchema = responses?.map((i) => i?.responsesBody?.name);

    const items = schemas?.filter(
      (i) => i.name === requestBody.name || responseSchema.includes(i.name)
    );

    form.setFieldsValue({ schemas: items });
  };

  const customLabel = (name, TooltipTitle) => {
    return (
      <span>
        <span style={{ fontWeight: "bold", marginRight: "5px" }}>
          {name}&nbsp;
        </span>
        <span style={{ fontWeight: "bold", color: "red" }}>*</span>
        {TooltipTitle && (
          <Tooltip title={TooltipTitle}>
            <InfoCircleOutlined style={{ color: "#0063A7", marginLeft: 8 }} />
          </Tooltip>
        )}
      </span>
    );
  };

  return (
    <Modal
      className="swagger-modal"
      title={
        <span className="text-title">
          {action !== "update" ? "Add new swagger" : "Edit swagger"}
        </span>
      }
      open={open}
      onCancel={() => {
        form.resetFields();
        cancel();
      }}
      footer={null}
      width={1000}
    >
      <hr />
      <br />
      <Form
        hideRequiredMark
        form={form}
        initialValues={filedValue}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="path"
              label={customLabel("Path", "This field is required.")}
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
                // {
                //   pattern: /^\/[a-zA-Z0-9/_-]+$/,
                //   message: "Please enter a valid path!",
                // },
                {
                  validator: async (_, value) => {
                    if (value) {
                      const isPathDuplicate = items.some(
                        (item) =>
                          item.path === value && item.path !== editingItem?.path
                      );
                      if (isPathDuplicate) {
                        return Promise.reject("This route already exists.");
                      }
                    }
                  },
                },
              ]}
            >
              <Select
                placeholder="/xxxx/xxx/xxxxxx"
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleSelectPath}
                // value={selectedPath}
                options={routeExternalList?.map((i) => ({
                  value: i.path,
                  label: i.path,
                  // disabled:
                  //   items.some((item) => item.path === i.path) &&
                  //   selectedPath !== i.path,
                }))}
                allowClear
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="summary"
              label={customLabel("Summary")}
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Input type="text" placeholder="Summary" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="method"
              label={customLabel("Method", "This field is required.")}
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Select
                placeholder="Select"
                allowClear
                // value={selectMethod}
                // onChange={onChange}
                // defaultValue={}
              >
                <Option value="get">GET</Option>
                <Option value="post">POST</Option>
                <Option value="put">PUT</Option>
                <Option value="patch">PATCH</Option>
                <Option value="delete">DELETE</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tag"
              label={customLabel("Tag", "This field is required.")}
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                  // type: "array",
                },
              ]}
            >
              <Select
                mode="tags"
                placeholder="Select or Create"
                onChange={handleChangeTag}
                value={selectTag}
                options={tagOptions}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={["requestBody", "name"]}
              label={<span style={{ fontWeight: "bold" }}>Request Body</span>}
              // rules={[
              //   {
              //     required: requestBodyList.length !== 0,
              //     message: "This field is required.",
              //   },
              // ]}
            >
              <Select
                placeholder="Select"
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                // onChange={handleChangeTag}
                // value={selectTag}
                options={schemaOption}
                allowClear
                onChange={handleSetSchema}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="response-container">
          <Form.List name="responses">
            {(fields, { add, remove }) => (
              <>
                <Flex justify="space-between" style={{ marginBottom: "20px" }}>
                  <span className="text-title">Responses (JSON format)</span>
                  <CustomButton
                    text="Add"
                    type="primary"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    width={90}
                  />
                </Flex>
                <div className="response-box">
                  {/* <Flex justify="flex-start" style={{ marginBottom: "20px" }}>
                      <CustomButton
                        text="Add"
                        type="primary"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        width={90}
                      />
                    </Flex> */}
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <Row gutter={16}>
                        <Col span={11}>
                          <Form.Item
                            {...restField}
                            name={[name, "httpCode"]}
                            label={customLabel(
                              "HTTP Code",
                              "This field is required."
                            )}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                              {
                                pattern: /^(?!000)\d{3}$/,
                                message: "Invalid",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Ex.200"
                              maxLength={3}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            {...restField}
                            name={[name, "description"]}
                            label={customLabel(
                              "Description",
                              "This field is required."
                            )}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Input type="text" placeholder="Description" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          {fields.length > 1 && (
                            <Form.Item label={<></>}>
                              <DeleteAction
                                onClick={() => {
                                  remove(name);
                                  setTimeout(() => {
                                    handleSetSchema();
                                  }, 50);
                                }}
                              />
                            </Form.Item>
                          )}
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={11}>
                          <Form.Item
                            {...restField}
                            name={[name, "responsesBody", "name"]}
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Responses Body
                              </span>
                            }
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "This field is required.",
                            //   },
                            // ]}
                          >
                            <Select
                              placeholder="Select"
                              showSearch
                              optionFilterProp="label"
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              options={schemaOption}
                              allowClear
                              onChange={handleSetSchema}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ))}
                </div>
              </>
            )}
          </Form.List>
        </div>
        <hr />
        <div className="schemas-container">
          <Form.List name="schemas">
            {(fields, { add, remove }) => (
              <>
                {/* <Flex justify="space-between"> */}
                <span className="text-title">Schemas (JSON format)</span>
                {/* <CustomButton
                      text="Add"
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                      width={90}
                    /> */}
                {/* </Flex> */}
                {fields.map(({ key, name, ...restField }) => (
                  <div className="schemas-box-filed">
                    <Flex justify="space-between" style={{ marginBottom: 20 }}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Schemas</span>
                        <Tooltip title="Schemas">
                          <InfoCircleOutlined
                            style={{ color: "#0063A7", marginLeft: 8 }}
                          />
                        </Tooltip>
                      </div>
                      {/* {fields.length > 1 && ( */}
                      {/* <DeleteAction
                          onClick={() => {
                            const isCheck = handleCheckSchema(name);
                            if (isCheck) {
                              message.error("Currently using!");
                            } else {
                              handleRemoveSchema(name);
                              remove(name);
                            }
                          }}
                        /> */}
                      {/* )} */}
                    </Flex>

                    <Form.Item name={[name, "id"]} noStyle>
                      <Input type="hidden" />
                    </Form.Item>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          label={
                            <span style={{ fontWeight: "bold" }}>Name</span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "This field is required.",
                            },
                          ]}
                        >
                          <Input
                            disabled
                            type="text"
                            placeholder="Name"
                            // value={inputSchemaName}
                            // onChange={handleInputChange}
                            // onBlur={handleSetOptionSchema}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "$ref"]}
                            label={customLabel(
                              "Ref",
                              "This field is required."
                            )}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Input type="text" placeholder="Ref" />
                          </Form.Item>
                        </Col> */}
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          name={[name, "type"]}
                          label={
                            <span style={{ fontWeight: "bold" }}>Type</span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "This field is required.",
                            },
                          ]}
                        >
                          <Select
                            disabled
                            placeholder="Select"
                            allowClear
                            // value={value}
                            // onChange={onChange}
                            // defaultValue={}
                          >
                            <Option value="object">object</Option>
                            {/* <Option value="integer">Integer</Option> */}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "additionalProperties"]}
                          label={
                            <span style={{ fontWeight: "bold" }}>
                              Additional Properties
                            </span>
                          }
                        >
                          <Switch
                            disabled
                            checkedChildren="True"
                            unCheckedChildren="False"
                            // defaultChecked={disableRateLimitHeaders}
                            // onChange={(e) => {
                            //   setDisableRateLimitHeaders(e);
                            //   setIsChanged(true);
                            //   sessionStorage.setItem("isChanged", true);
                            // }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, "nullable"]}
                          label={
                            <span style={{ fontWeight: "bold" }}>Nullable</span>
                          }
                        >
                          <Switch
                            disabled
                            checkedChildren="True"
                            unCheckedChildren="False"
                            // defaultChecked={disableRateLimitHeaders}
                            // onChange={(e) => {
                            //   setDisableRateLimitHeaders(e);
                            //   setIsChanged(true);
                            //   sessionStorage.setItem("isChanged", true);
                            // }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="properties-box-filed">
                      <Form.List name={[name, "properties"]}>
                        {(fields, { add, remove }) => (
                          <>
                            <Flex
                              justify="space-between"
                              style={{ marginBottom: 30 }}
                            >
                              <div>
                                <span style={{ fontWeight: "bold" }}>
                                  Properties
                                </span>
                                <Tooltip title="Schemas">
                                  <InfoCircleOutlined
                                    style={{
                                      color: "#0063A7",
                                      marginLeft: 8,
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              {/* <CustomButton
                                text="Add Properties"
                                type="primary"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                                width={152}
                              /> */}
                            </Flex>
                            {fields.map(({ key, name, ...restField }) => (
                              <Row gutter={16}>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "name"]}
                                    label={
                                      <span style={{ fontWeight: "bold" }}>
                                        Name
                                      </span>
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required.",
                                      },
                                    ]}
                                  >
                                    <Input
                                      disabled
                                      type="text"
                                      placeholder="Name"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "type"]}
                                    label={
                                      <span style={{ fontWeight: "bold" }}>
                                        Type
                                      </span>
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required.",
                                      },
                                    ]}
                                  >
                                    <Select
                                      disabled
                                      placeholder="Select"
                                      allowClear
                                      // value={value}
                                      // onChange={onChange}
                                      // defaultValue={}
                                    >
                                      <Option value="string">String</Option>
                                      <Option value="integer">Integer</Option>
                                      <Option value="float">Float</Option>
                                      <Option value="double">Double</Option>
                                      <Option value="boolean">Boolean</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "format"]}
                                    label={
                                      <span style={{ fontWeight: "bold" }}>
                                        Format
                                      </span>
                                    }
                                    // rules={[
                                    //   {
                                    //     required: true,
                                    //     message: "This field is required.",
                                    //   },
                                    // ]}
                                  >
                                    <Select
                                      disabled
                                      placeholder="Select"
                                      allowClear
                                      // value={value}
                                      // onChange={onChange}
                                      // defaultValue={}
                                    >
                                      {/* <Option value="test">Null</Option> */}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "nullable"]}
                                    label={
                                      <span style={{ fontWeight: "bold" }}>
                                        Nullable
                                      </span>
                                    }
                                  >
                                    <Switch
                                      disabled
                                      checkedChildren="True"
                                      unCheckedChildren="False"
                                      // defaultChecked={disableRateLimitHeaders}
                                      // onChange={(e) => {
                                      //   setDisableRateLimitHeaders(e);
                                      //   setIsChanged(true);
                                      //   sessionStorage.setItem("isChanged", true);
                                      // }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={3}>
                                  {/* {fields.length > 1 && ( */}
                                  {/* <Form.Item label={<></>}>
                                    <DeleteAction
                                      onClick={() => {
                                        remove(name);
                                      }}
                                    />
                                  </Form.Item> */}
                                  {/* )} */}
                                </Col>
                              </Row>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </div>
                  </div>
                ))}
              </>
            )}
          </Form.List>

          <Form.Item name="status" noStyle>
            <Input type="hidden" />
          </Form.Item>
        </div>
        <Flex justify="flex-end" style={{ marginTop: 30 }}>
          <Space>
            <CustomButton
              text="Cancel"
              type="primary"
              ghost={true}
              onClick={cancel}
              width={90}
            />
            <CustomButton
              text="Submit"
              type="primary"
              onClick={() => form.submit()}
              width={90}
            />
          </Space>
        </Flex>
      </Form>
    </Modal>
  );
};

export default SwaggerRouteForm;
