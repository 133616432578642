import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const TagService = {
    get: async function (cancel = false) {
        const response = await api.request({
            url: `/mst-tag`,
            method: "GET",
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        });

        return response.data;
    },
    getSearch: async function (keyword, cancel = false) {
        const response = await api.request({
            url: `/mst-tag?Keywords=${keyword}`,
            method: "GET",
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        });

        return response.data;
    },
    delete: async function (id, cancel = false) {
        const response = await api.request({
            url: `/mst-tag?id=${id}`,
            method: "DELETE",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        });

        return response;
    },
    Createdata: async function (data, cancel = false) {
        const response = await api.request({
            url: `/mst-tag`, 
            method: "POST", 
            data: data, 
            headers: {
                "Content-Type": "application/json",
            },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation().signal
                : undefined,
        });
        return response;
    },




}

const cancelApiObject = defineCancelApiObject(TagService);