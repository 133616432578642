import React from "react";
import { Modal, Flex } from "antd";
import IconConWarning from "../../assets/icon/icon_warning_dialog";
import CustomButton from "../../components/Button/CustomButton";

const WarningDialog = ({
  isModalOpen,
  closable,
  title,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  description,
}) => {
  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        footer={null}
        closable={closable}
        centered
      >
        {/* className="flex flex-col justify-center items-center" */}
        <Flex justify="center" align="center" vertical>
          <IconConWarning />
          <span
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 10,
            }}
          >
            {title}
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 0,
              color: "#475467",
            }}
          >
            {description}
          </span>
          {/* <div className="flex flex-row items-center justify-center mt-[20px]"></div> */}
          <Flex
            gap="middle"
            justify="center"
            align="center"
            vertical={false}
            style={{ marginTop: 20 }}
          >
            <CustomButton
              //loading={loadingExcel}
              // icon={<FileExcelOutlined />}
              width={135}
              height={32}
              borderRadius="4px"
              // type="primary"
              // ghost={true}
              text={cancelText}
              // style={{
              //   marginRight: 15,
              //   borderRadius: 10.5,
              //   border: "1px solid #D9D9D9",
              //   //marginTop: 20,
              //   color: "#000",
              //   backgroundColor: "#FFF",
              // }}
              border="1px solid #D0D5DD"
              color="#000"
              onClick={() => {
                onCancel();
              }}
            />
            <CustomButton
              text={confirmText}
              type="primary"
              width={135}
              height={32}
              borderRadius="4px"
              background={"#FFC044"}
              onClick={() => {
                onCancel();
                onConfirm();
                // navigate("/survey-form-list");
              }}
              // type="primary"
            />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default WarningDialog;
