import React, { useState, useEffect } from "react";
import "./RoleManagement.css";
import {
  Space,
  Col,
  Row,
  Table,
  Button,
  Select,
  Flex,
  Pagination,
  Divider,
  Form,
  Input,
  Checkbox,
} from "antd";
import {
  ConfirmDialog,
  DeleteButton,
  SuccessDialog,
  DeleteDialog,
  FailedDialog,
  EditButton,
  DiscardDialog,
} from "../../../../../components";
import { RoleService } from "../../../../../services/RoleService";
import { MstPageService } from "../../../../../services/MstPageService";

const { Option } = Select;

const RoleManagement = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [selectDeleteId, setSelectDeleteId] = useState();
  const [action, setAction] = useState();
  const [editId, setEditId] = useState("");
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalSuccessBinOpen, setIsModalSuccessBinOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [selectListViewId, setSelectListViewId] = useState([]);
  const [selectListEditId, setSelectListEditId] = useState([]);

  useEffect(() => {
    fetchData();
    // JSON.stringify(tableParams)
  }, []);

  useEffect(() => {
    if (!roleData.length) {
      fetchRoleData();
    }
  }, [roleData]);

  useEffect(() => {
    const { current, pageSize } = tableParams.pagination;
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setTableList(data.slice(startIndex, endIndex));
  }, [tableParams]);

  const getParams = (params) => ({
    // Name: keywords,
    // "Pagination.PageSize": params.pagination?.pageSize,
    // "Pagination.Page": params.pagination?.current,
  });

  const fetchData = async () => {
    try {
      setLoading(true);

      let params = getParams(tableParams);
      let response = await RoleService.search(params);

      setData(response.items);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.pagination.totalCount,
        },
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchRoleData = async () => {
    try {
      setLoading(true);

      const response = await MstPageService.get();
      setRoleData(response.items);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchDataById = async (id) => {
    try {
      setLoading(true);

      let response = await RoleService.get(id);

      let listViewId = [];
      let listEditId = [];

      const updatedPagePermissions = roleData.map((role) => {
        const permission = response.pagePermissions.find(
          (item) => item.pageId === role.id
        );

        if (permission.isViewable) {
          listViewId.push(permission.pageId);
        }
        if (permission.isEditable) {
          listEditId.push(permission.pageId);
        }

        return {
          id: permission?.id,
          pageId: permission.pageId,
          roleId: permission.roleId,
          isViewable: permission?.isViewable,
          isEditable: permission?.isEditable,
        };
      });

      setSelectListViewId(listViewId);
      setSelectListEditId(listEditId);

      form.setFieldsValue({
        ...response,
        pagePermissions: updatedPagePermissions,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleCreate = async (value) => {
    try {
      const data = {
        nameEn: value.nameEn,
        nameTh: value.nameTh,
        sequence: 0,
        pagePermissions: value.pagePermissions.map((item) => {
          return {
            pageId: item.pageId,
            isViewable: item.isViewable,
            isEditable: item.isEditable,
          };
        }),
      };

      const response = await RoleService.create(data);
      setAction();
      setEditId("");
      form.resetFields();
      setSelectListViewId([]);
      setSelectListEditId([]);
      setIsModalSuccessOpen(true);
      fetchData();
      setTimeout(() => {
        setIsChanged(false);
        setIsModalSuccessOpen(false);
      }, 2000);
    } catch (error) {
      console.error(error);
      setIsModalFailedOpen(true);
    }
  };

  const handleUpdateById = async (value) => {
    try {
      const response = await RoleService.update(value.id, value);
      setAction();
      setEditId("");
      form.resetFields();
      setSelectListViewId([]);
      setSelectListEditId([]);
      setIsModalSuccessOpen(true);
      fetchData();
      setTimeout(() => {
        setIsChanged(false);
        setIsModalSuccessOpen(false);
      }, 2000);
    } catch (error) {
      console.error(error);
      setIsModalFailedOpen(true);
    }
  };

  const handleDeleteById = async () => {
    try {
      const response = await RoleService.delete(selectDeleteId);
      setSelectDeleteId();
      setIsModalDeleteOpen(false);
      setIsModalSuccessBinOpen(true);
      fetchData();
      setTimeout(() => {
        setIsModalSuccessBinOpen(false);
      }, 2000);
    } catch (error) {
      console.error(error);
      setIsModalFailedOpen(true);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setTableParams({
      pagination: {
        current: page,
        pageSize,
        total: tableParams.pagination?.total,
      },
    });

    if (pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handlePageSizeChange = (current, pageSize) => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize,
        total: tableParams.pagination?.total,
      },
    });
  };

  const onFinish = (value) => {
    switch (action) {
      case "create":
        handleCreate(value);
        break;
      case "edit":
        handleUpdateById(value);
        break;
      default:
        break;
    }
  };

  const handleSelectViewId = (id) => {
    setSelectListViewId((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleSelectEditId = (id, index) => {
    setSelectListEditId((prev) => {
      const isSelected = prev.includes(id);
      const newEditList = isSelected
        ? prev.filter((item) => item !== id)
        : [...prev, id];

      const fieldsValue = form.getFieldsValue();
      const updatedPagePermissions = fieldsValue.pagePermissions.map(
        (item, idx) => {
          if (idx === index) {
            return {
              ...item,
              isViewable: isSelected ? item.isViewable : true,
            };
          }
          return item;
        }
      );

      form.setFieldsValue({
        ...fieldsValue,
        pagePermissions: updatedPagePermissions,
      });

      return newEditList;
    });
  };

  const handleSelectAllView = (checked) => {
    setIsChanged(true);
    const listViewId = roleData.map((item) => item.id);
    setSelectListViewId(checked ? listViewId : []);
    const fieldsValue = form.getFieldsValue();
    const updatedPagePermissions = fieldsValue.pagePermissions.map((item) => {
      return {
        ...item,
        isViewable: checked,
      };
    });
    form.setFieldsValue({
      ...fieldsValue,
      pagePermissions: updatedPagePermissions,
    });
  };

  const handleSelectAllEdit = (checked) => {
    setIsChanged(true);
    const listEditId = roleData.map((item) => item.id);
    setSelectListEditId(checked ? listEditId : []);
    setSelectListViewId(listEditId);
    const fieldsValue = form.getFieldsValue();
    const updatedPagePermissions = fieldsValue.pagePermissions.map((item) => {
      return {
        ...item,
        isEditable: checked,
        isViewable: checked ? true : item.isViewable,
      };
    });
    form.setFieldsValue({
      ...fieldsValue,
      pagePermissions: updatedPagePermissions,
    });
  };

  const columns = [
    {
      title: <span className="role-columns-title">คำสั่ง</span>,
      key: "action",
      width: "10%",
      render: (_, record) => (
        <Space size="middle">
          <EditButton
            onClick={() => {
              setAction("edit");
              fetchDataById(record.id);
              setEditId(record.id);
            }}
          />
          <DeleteButton
            onClick={() => {
              setSelectDeleteId(record.id);
              setIsModalDeleteOpen(true);
            }}
          />
        </Space>
      ),
    },
    {
      title: <span className="role-columns-title">#</span>,
      dataIndex: "index",
      key: "index",
      width: "10%",
      render: (text, record, index) => {
        const current = tableParams.pagination?.current || 1;
        const pageSize = tableParams.pagination?.pageSize || 10;
        return (current - 1) * pageSize + index + 1;
      },
    },
    {
      title: (
        <span className="role-columns-title">บทบาทและสิทธิ์การเข้าถึง</span>
      ),
      dataIndex: "nameTh",
      key: "nameTh",
      width: "30%",
    },
    {
      title: <span className="role-columns-title">ภาษาอังกฤษ</span>,
      dataIndex: "nameEn",
      key: "nameEn",
      width: "20%",
    },
    {
      title: <span className="role-columns-title">วันที่สร้าง</span>,
      dataIndex: "createdDateFrom",
      key: "createdDateFrom",
      width: "30%",
    },
  ];

  const roleColumns = [
    {
      title: <span className="role-columns-title">เมนู / เมนูย่อย</span>,
      dataIndex: "name",
      key: "name",
      rowScope: "row",
      render: (text, record, index) => (
        <>
          <Form.Item name={["pagePermissions", index, "pageId"]} noStyle>
            {record.name}
          </Form.Item>
          <Form.Item name={["pagePermissions", index, "id"]} noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name={["pagePermissions", index, "roleId"]} noStyle>
            <Input type="hidden" />
          </Form.Item>
        </>
      ),
    },
    {
      title: (
        <Space>
          <Checkbox
            indeterminate={
              selectListViewId.length !== 0 &&
              selectListViewId.length !== roleData.length
            }
            checked={selectListViewId.length === roleData.length}
            onChange={(e) => handleSelectAllView(e.target.checked)}
            disabled={
              form
                .getFieldsValue()
                .pagePermissions?.some((item) => item.isEditable) &&
              selectListViewId.length === roleData.length
            }
          />
          <span className="role-columns-title">ดู</span>
        </Space>
      ),
      dataIndex: "view",
      key: "view",
      render: (text, record, index) => {
        const fieldsValue = form.getFieldsValue();
        const isEditable =
          Array.isArray(fieldsValue.pagePermissions) &&
          fieldsValue.pagePermissions[index]?.isEditable;

        return (
          <Form.Item
            name={["pagePermissions", index, "isViewable"]}
            valuePropName="checked"
            noStyle
          >
            <Checkbox
              disabled={isEditable}
              onChange={() => {
                handleSelectViewId(record.id);
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: (
        <Space>
          <Checkbox
            indeterminate={
              selectListEditId.length !== 0 &&
              selectListEditId.length !== roleData.length
            }
            checked={selectListEditId.length === roleData.length}
            onChange={(e) => handleSelectAllEdit(e.target.checked)}
          />
          <span className="role-columns-title">แก้ไข</span>
        </Space>
      ),
      dataIndex: "edit",
      key: "edit",
      render: (text, record, index) => (
        <Form.Item
          name={["pagePermissions", index, "isEditable"]}
          valuePropName="checked"
          noStyle
        >
          <Checkbox
            onChange={(e) => {
              handleSelectEditId(record.id, index);
              if (e.target.checked && !selectListViewId.includes(record.id)) {
                handleSelectViewId(record.id);
              }
            }}
          />
        </Form.Item>
      ),
    },
  ];

  return (
    <div className="role-management-body">
      {!action ? (
        <div className="role-management-container">
          <Flex justify="space-between" align="center">
            <p className="role-management-title">
              จัดการข้อมูลสิทธิ์การเข้าถึง
            </p>
            <Button
              type="primary"
              style={{
                borderRadius: "10px",
                width: "116px",
                height: "40px",
                color: "#2E323A",
                fontWeight: "500",
                fontSize: "16px",
              }}
              onClick={() => {
                form.setFieldsValue({
                  pagePermissions: roleData.map((item) => ({
                    pageId: item.id,
                    isViewable: true,
                    isEditable: false,
                  })),
                });
                setAction("create");

                const listId = roleData.map((item) => item.id);
                setSelectListViewId(listId);
              }}
            >
              + เพิ่มบทบาท
            </Button>
          </Flex>

          <Table
            style={{
              margin: "8px 0",
            }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={tableList}
            pagination={false}
            onChange={handlePageChange}
            loading={loading}
            scroll={{
              x: 1000,
            }}
          />

          {data.length ? (
            <Row
              justify="space-between"
              align="middle"
              style={{ marginTop: 16 }}
            >
              <Col>
                <span
                  style={{
                    color: "#2E323A",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  ทั้งหมด {tableParams.pagination?.total} รายการ
                </span>
              </Col>
              <Col>
                <Pagination
                  style={{ pointerEvents: loading ? "none" : "auto" }}
                  current={tableParams.pagination?.current}
                  pageSize={tableParams.pagination?.pageSize}
                  total={tableParams.pagination?.total}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                />
              </Col>
              <Col>
                <Select
                  value={tableParams.pagination?.pageSize}
                  onChange={(pageSize) =>
                    handlePageSizeChange(
                      tableParams.pagination?.current,
                      pageSize
                    )
                  }
                  style={{
                    width: "108px",
                    height: "40px",
                    borderRadius: "10px",
                  }}
                >
                  <Option value={10}>
                    <span
                      style={{
                        color: "#2E323A",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      10 / หน้า
                    </span>
                  </Option>
                  <Option value={25}>
                    <span
                      style={{
                        color: "#2E323A",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      25 / หน้า
                    </span>
                  </Option>
                  <Option value={50}>
                    <span
                      style={{
                        color: "#2E323A",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      50 / หน้า
                    </span>
                  </Option>
                  <Option value={100}>
                    <span
                      style={{
                        color: "#2E323A",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      100 / หน้า
                    </span>
                  </Option>
                </Select>
              </Col>
            </Row>
          ) : null}
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={(changedValues, allValues) => {
            setIsChanged(true);
          }}
        >
          <div className="role-management-create-edit-container">
            <Flex justify="space-between" align="center">
              <p
                style={{
                  color: "#2e323a",
                  fontSize: "24px",
                  fontWeight: "600",
                  margin: "0px",
                }}
              >
                จัดการข้อมูลสิทธิ์การเข้าถึง
              </p>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    if (isChanged) {
                      setIsModalDiscardOpen(true);
                    } else {
                      setAction();
                      setEditId("");
                      form.resetFields();
                      setSelectListViewId([]);
                      setSelectListEditId([]);
                    }
                  }}
                  style={{
                    background: "#EBEBEB",
                    height: "40px",
                    width: "140px",
                    borderRadius: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    ยกเลิก
                  </span>
                </Button>
                <Button
                  onClick={() => {
                    setIsModalConfirmOpen(true);
                  }}
                  type="primary"
                  style={{
                    height: "40px",
                    width: "140px",
                    borderRadius: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    บันทึก
                  </span>
                </Button>
              </Space>
            </Flex>
            <Divider className="role-divider" />

            <Form.Item name="id" noStyle>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item name="sequence" noStyle>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item name="isActivated" noStyle>
              <Input type="hidden" />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span
                      style={{
                        color: "#2E323A",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      ชื่อบทบาท (ภาษาไทย)
                    </span>
                  }
                  name="nameTh"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอกข้อมูล!",
                    },
                    {
                      pattern: /^[ก-๙0-9\s!@#$%^&*()\-_=+[\]{}|:;"'<>,.?/]+$/,
                      message: "กรุณากรอกภาษาไทย!",
                    },
                    {
                      max: 100,
                      message: "ความยาวต้องไม่เกิน 100 ตัวอักษร!",
                    },
                    {
                      validator: (_, value) => {
                        if (value) {
                          const isCheck = data.some(
                            (item) =>
                              item?.nameTh.toLowerCase() ===
                                value.toLowerCase() && item.id !== editId
                          );
                          if (isCheck) {
                            return Promise.reject(
                              new Error("ชื่อบทบาทซ้ำกับข้อมูลที่มีอยู่แล้ว!")
                            );
                          }
                          return Promise.resolve();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  required={false}
                >
                  <Input placeholder="ชื่อบทบาท (ภาษาไทย)" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span
                      style={{
                        color: "#2E323A",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      ชื่อบทบาท (ภาษาอังกฤษ)
                    </span>
                  }
                  name="nameEn"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอกข้อมูล!",
                    },
                    {
                      pattern:
                        /^[A-Za-z0-9\s!@#$%^&*()\-_=+[\]{}|:;"'<>,.?/]+$/,
                      message: "กรุณากรอกภาษาอังกฤษ!",
                    },
                    {
                      max: 100,
                      message: "ความยาวต้องไม่เกิน 100 ตัวอักษร!",
                    },
                    {
                      validator: (_, value) => {
                        if (value) {
                          const isCheck = data.some(
                            (item) =>
                              item?.nameEn.toLowerCase() ===
                                value.toLowerCase() && item.id !== editId
                          );
                          if (isCheck) {
                            return Promise.reject(
                              new Error("ชื่อบทบาทซ้ำกับข้อมูลที่มีอยู่แล้ว!")
                            );
                          }
                          return Promise.resolve();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  required={false}
                >
                  <Input placeholder="ชื่อบทบาท (ภาษาอังกฤษ)" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="role-Columns">
            <Table
              loading={loading}
              columns={roleColumns}
              rowKey={(record) => record.id}
              dataSource={roleData}
              pagination={false}
              style={{
                marginTop: "20px",
                border: "1px solid #EAECF0",
                borderRadius: "8px",
              }}
            />
          </div>
        </Form>
      )}

      <ConfirmDialog
        isModalOpen={isModalConfirmOpen}
        closable={false}
        title="ยืนยันการบันทึก"
        description="คุณต้องการบันทึกใช่หรือไม่?"
        cancelText="ยกเลิก"
        confirmText="บันทึก"
        onCancel={() => {
          setIsModalConfirmOpen(false);
          setSelectDeleteId();
        }}
        onConfirm={() => {
          setIsModalConfirmOpen(false);
          form.submit();
        }}
      />

      <DeleteDialog
        isModalOpen={isModalDeleteOpen}
        closable={false}
        title="ยืนยันการลบ"
        description="คุณต้องการลบใช่หรือไม่?"
        cancelText="ยกเลิก"
        confirmText="ลบ"
        onCancel={() => {
          setIsModalDeleteOpen(false);
        }}
        onConfirm={() => {
          handleDeleteById();
        }}
      />

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        title="สำเร็จ"
        detail="บันทึกข้อมูลสำเร็จแล้ว"
        onCancel={() => {
          setIsModalSuccessOpen(false);
        }}
        onAfterClose={() => {
          setIsModalSuccessOpen(false);
        }}
      />

      <SuccessDialog
        isModalOpen={isModalSuccessBinOpen}
        closable={false}
        type="bin"
        title="สำเร็จ"
        detail="ลบข้อมูลสำเร็จแล้ว"
        onCancel={() => {
          setIsModalSuccessBinOpen(false);
        }}
        onAfterClose={() => {
          setIsModalSuccessBinOpen(false);
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title="ล้มเหลว"
        detail="มีบางอย่างผิดพลาด โปรดลองอีกครั้ง."
        onCancel={() => {
          setIsModalFailedOpen(false);
        }}
        onAfterClose={() => {
          setIsModalFailedOpen(false);
        }}
        onConfirm={() => {
          setIsModalFailedOpen(false);
        }}
      />

      <DiscardDialog
        isModalOpen={isModalDiscardOpen}
        closable={false}
        title="ยกเลิกการเปลี่ยนแปลง"
        cancelText="ยกเลิก"
        confirmText="ยืนยัน"
        onCancel={() => {
          setIsModalDiscardOpen(false);
        }}
        onConfirm={() => {
          setAction();
          setEditId("");
          form.resetFields();
          setSelectListViewId([]);
          setSelectListEditId([]);
          setIsModalDiscardOpen(false);
        }}
      />
    </div>
  );
};

export default RoleManagement;
