import Icon from "@ant-design/icons";

const icon = () => (
    <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="78" height="78" rx="20" fill="#F8F8F9"/>
    <g clipPath="url(#clip0_6055_27526)">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.125 39.5C15.125 26.0381 26.0381 15.125 39.5 15.125C52.9619 15.125 63.875 26.0381 63.875 39.5C63.875 52.9619 52.9619 63.875 39.5 63.875C26.0381 63.875 15.125 52.9619 15.125 39.5ZM39.5 30.125C40.5355 30.125 41.375 30.9645 41.375 32V41.375C41.375 42.4105 40.5355 43.25 39.5 43.25C38.4645 43.25 37.625 42.4105 37.625 41.375V32C37.625 30.9645 38.4645 30.125 39.5 30.125ZM39.5 50.75C40.5355 50.75 41.375 49.9105 41.375 48.875C41.375 47.8395 40.5355 47 39.5 47C38.4645 47 37.625 47.8395 37.625 48.875C37.625 49.9105 38.4645 50.75 39.5 50.75Z" fill="#475467"/>
    </g>
    <defs>
    <clipPath id="clip0_6055_27526">
    <rect width="60" height="60" fill="white" transform="translate(9.5 9.5)"/>
    </clipPath>
    </defs>
    </svg>
);

const icon_warning_gray_dialog = (props) => <Icon component={icon} {...props} />;

export default icon_warning_gray_dialog;
