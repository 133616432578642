import React, { useState, useEffect } from "react";
import {  Col, Row ,Collapse,Button,Input, Modal, Flex, Form, Select} from "antd";
import "./AccountManagement.css";
import { FaUserCircle } from "react-icons/fa";
import { UserService } from "../../../../../services/UserService";
import CustomButton from "../../../../../components/Button/CustomButton";
import IconConWarningGray from "../../../../../assets/icon/icon_warning_gray_dialog";
import SuccessDialog from "../../../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../../../components/Dialog/FailedDialog";
import { handleLogout } from "../../../../../keycloak/KeycloakAuth";
import { LinkOutlined } from "@ant-design/icons";
import { ConfirmDialog } from "../../../../../components";


const { Panel } = Collapse;

const AccountManagement = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [modalSuccessStr, setModalSuccessStr] = useState({
      title:"",
      detail:"",
  });
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [modalFailedStr, setModalFailedStr] = useState({
      title:"",
      detail:"",
  });
  const [data, setData] = useState();
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const [isConfirmUnLinkedModalOpen, setIsConfirmUnLinkedModalOpen] = useState(false);
  const [unlinkedIdp, setUnlinkedIdp] = useState(null);
  const [editMode, setEditMode] = useState(false); // State to toggle edit mode
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    position: '',
    typeOfIndustries: ''
  });
  const [jobFunctions, setJobFunctions] = useState([]);
  const [confirmEmail,setConfirmEmail] = useState('');

  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, [data]);
  const fetchJobFunctions = async () => {
    try {
        const response = await UserService.getJob();
        setJobFunctions(response);  // Set job functions from API response
        //setFormData({ typeOfIndustries: response[0] || '' });  // Default to first job function if available
    } catch (error) {
        console.error('Error fetching job functions:', error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = sessionStorage.getItem("userMe") ? JSON.parse(sessionStorage.getItem("userMe")) : await UserService.getMe();
      console.log("response = ",response)
      setData(response);
      setFormData({
        id:response?.id || '',
        firstName: response?.firstName || '',
        lastName: response?.lastName || '',
        email: response?.email || '',
        tel: response?.tel || '',
        position: response?.position || '',
        typeOfIndustries: response?.jobFunction || '',
        userSources: response?.userSources || [],
        idp: response?.idp || '',
      });
      fetchJobFunctions();
      //console.log("my profile >> fetchData > response = ",response)
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "tel") {
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleDisconnect = async (source) => {


    setIsLoading(true);
    try {
      await UserService.disconnectUserSource(formData.id, source.provider);
      let userMe = await UserService.getMe();
      sessionStorage.setItem("userMe", JSON.stringify(userMe));
      setData(userMe);
      setIsModalSuccessOpen(true);
      setModalSuccessStr({
        title:"สำเร็จ",
        detail:'ยกเลิกการเชื่อมต่อบัญชีสำเร็จ',
      });
      setTimeout(() => {setIsModalSuccessOpen(false)}, 3000);
    } catch (error) {
      console.error("Error disconnecting user source", error);
      setIsModalFailedOpen(true);
      setModalFailedStr({
        title:"ไม่สำเร็จ",
        detail:error.response?.data?.message?.messageTh ?? error.message,
      });
      setTimeout(() => {setIsModalFailedOpen(false)}, 3000);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSave = async () => {
    ////console.log("handleSave")
    setIsLoading(true);
    try {
      let data ={
          "id": formData.id,
          "firstName": formData.firstName,
          "lastName": formData.lastName,
          "email": formData.email,
          "attributes": {
              "typeOfIndustries": [
                  formData.typeOfIndustries
              ],
              "company": [
                formData.company
              ],
              "position": [
                formData.position
              ],
              "tel": [
                formData.tel
              ]
          }
      }
      
      await UserService.updateUser(data.id,data);
      let userMe = await UserService.getMe();
      sessionStorage.setItem("userMe", JSON.stringify(userMe));
      setEditMode(false); 
      setIsModalSuccessOpen(true);
      setModalSuccessStr({
        title:"สำเร็จ",
        detail:'บันทึกข้อมูลสำเร็จ',
      });
      setTimeout(() => {setIsModalSuccessOpen(false)}, 3000);
      //console.log("Profile updated successfully", response);
    } catch (error) {
      console.error("Error updating profile", error);
      setIsModalFailedOpen(true);
      setModalFailedStr({
        title:"ไม่สำเร็จ",
        detail:error.response.data.message.messageTh,
      });
      setTimeout(() => {setIsModalFailedOpen(false)}, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    ////console.log("handleDelete")
    setIsLoading(true);
    try {
      const response = await UserService.deleteUser(data.id);
      setModalSuccessStr({
        title:"สำเร็จ",
        detail:'ลบบัญชีของคุณสำเร็จแล้ว',
      });
      setIsModalSuccessOpen(true)
      setIsLoading(false);
      handleLogout()
      setTimeout(() => {
        setIsModalSuccessOpen(false);
        handleLogout()
        window.location.reload()
        //handleLogin()
      }, 3000);

      //console.log("Delete Profile successfully", response);
    } catch (error) {
      console.error("Error Delete profile", error);
      setModalFailedStr({
        title:"ไม่สำเร็จ",
        detail:error.response.data.message.messageTh,
      });
      setIsLoading(false);
      setIsModalFailedOpen(true);
      
    }finally{
      setIsLoading(false);
    }
  };

  const clickDelete = () => {
    setIsConfirmDeleteModalOpen(true)
  };
  

  return (
    <>
      <div className="profile-body" >
        <h3>บัญชีของคุณ</h3>
        <div className="color-card">
          <Row>
            <Col sm={5} lg={12}>
                <div className="color-card-img ">
                  <FaUserCircle fontSize={116} className="img-icon"/>
                </div>
            </Col>
            <Col sm={19} lg={12}>
                <div className="color-card-action ">
                {editMode ? (
                  <div>
                    <Row gutter={8}>
                      <Col sm={12} lg={12}>
                        <CustomButton text={"ยกเลิก"} onClick={() => setEditMode(false)}/>
                      </Col>
                      <Col sm={12} lg={12}>
                        <CustomButton type={"primary"} text={"บันทึก"} onClick={()=> handleSave() } />
                      </Col>
                    </Row>
                      </div>
                  ):(
                    <div>
                      {!data?.type &&(<CustomButton text={"แก้ไข"} onClick={() => setEditMode(true)}/>)}
                      
                    </div>
                  
                )}
                  
                </div>
            </Col>
          </Row>
          
        </div>


        <Row >
          <Col sm={24} lg={24}>
            <div className="profile-content">
              
              {editMode ? (
                <div>
                  <Row gutter={24}>
                      <Col sm={12} md={12} lg={11}>
                      <h4>ชื่อ</h4>
                          <Input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                          />
                      </Col>
                      <Col sm={12} md={12} lg={11}>
                      <h4>นามสกุล</h4>
                          <Input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                          />
                      </Col>
                  </Row>
                  <p></p>
                </div>
              ) : (
                <div>
                    <h4>ชื่อ - นามสกุล</h4>
                    <p>{data?.fullName}</p>
                </div>
                
              )}
              
              
              <h4>อีเมล์</h4>
              {editMode ? (
                <div>
                  <Row gutter={24}>
                    <Col sm={24} lg={22}>
                      <Input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        disabled
                      />
                     
                    </Col>
                  </Row>
                  <p/>
                </div>
              ) : (
                <p>{data?.email}</p>
              )}
              <h4>แหล่งที่มาของบัญชี</h4>
              <ul style={{listStyleType: 'none', paddingLeft: 0, margin: 0 }}>
              {data?.userSources?.map((source, index) => ( 
                <li key={index}>{source.name}

                  {editMode && (source.name.trim() !== "MICE Data Platform") && source.provider !== data?.idp && (
                    <>
                        <button
                            style={{
                                color: '#1677FF',
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '14px',
                                textDecoration: 'underline',
                                padding: '0',
                                marginLeft: '0',
                                float: 'right',
                            }}
                            onClick={() => {
                                setUnlinkedIdp(source);
                                setIsConfirmUnLinkedModalOpen(true);
                            }}
                        >
                            <LinkOutlined style={{ marginRight: '5px' }} /> ยกเลิกการเชื่อมต่อบัญชี
                        </button>
                    </>
                  )}
                
                </li>
              ))}
              </ul>
              <h4>เบอร์โทรศัพท์</h4>
              {editMode ? (
                <div>
                  <Row gutter={24}>
                    <Col sm={24} lg={22}>
                        <Input
                        type="text"
                        name="tel"
                        value={formData.tel}
                        onChange={handleChange}
                        maxLength={10}
                      />
                    </Col>
                  </Row>
                  <p/>
                </div>
              ) : (
                <p>{formData.tel}</p>
              )}

              <h4>ตำแหน่ง</h4>
              {editMode ? (
                <div>
                 <Row gutter={24}>
                   <Col sm={24} lg={22}>
                    <Input
                      type="text"
                      name="position"
                      value={formData.position}
                      onChange={handleChange}
                    />
                   </Col>
                 </Row>
                 <p/>
               </div>

               
              ) : (
                <p>{formData.position}</p>
              )}

              <h4>ประเภทงาน</h4>
              {editMode ? (
                <div>
                  <Row gutter={24}>
                    <Col sm={24} lg={22}>
                      <Select
                          name="typeOfIndustries"
                          value={formData?.typeOfIndustries}
                          
                          onChange={(value) => handleChange({ target: { name: 'typeOfIndustries', value } })}
                          style={{ width: '100%' }}
                      >
                          {jobFunctions.map((jobFunction, index) => (
                              <Select.Option key={index} value={jobFunction}>
                                  {jobFunction}
                              </Select.Option>
                          ))}
                      </Select>
                        
                    </Col>
                  </Row>
                  <p/>
                </div>
              ) : (
                <p>{formData.typeOfIndustries}</p>
              )}
            </div>
          </Col>
        </Row>
        <div className="delete-card">
          <Row>
            <Col sm={24} lg={24}>
                <div className="delete-card-content">
                
                <Collapse ghost expandIconPosition="right">
                <Panel 
                  header={<div><b>ลบบัญชีของคุณ</b> 
                          <br/>การลบบัญชีจะทำให้คุณไม่สามารถเข้าถึงการตั้งค่าใดๆ ของคุณ หรือลงชื่อเข้าใช้ TCEB ได้อีก การกระทำนี้ไม่สามารถย้อนกลับได้ 
                          <br/>บัญชี TCEB ของคุณสร้างขึ้นแล้วเมื่อ {data?.formattedCreatedDate} </div>}
                  key="1"
                  >
                  <CustomButton danger={true} text={"ลบบัญชี"} onClick={()=>{clickDelete()}} />
                </Panel>
              </Collapse>
                </div>
            </Col>
          </Row>
          
        </div>


        <Modal
        centered
        title=""
        open={isConfirmDeleteModalOpen}
        footer={null}
        closable={false}
        destroyOnClose={true}
        onCancel={() => {
          //onCancel();
        }}
        afterClose={(e) => {
         // onAfterClose();
        }}
      >
        <Flex justify="left" align="left" vertical>
          <IconConWarningGray
            style={{
              marginTop: 12,
            }}
          />
          <span  
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 12,
            }}
          >
             {"คุณกำลังจะลบบัญชีของคุณ"}
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 8,
              textAlign:"left"
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: "การลบบัญชีจะทำให้คุณไม่สามารถเข้าถึงการตั้งค่าใดๆ ของคุณ <br/> หรือลงชื่อเข้าใช้ TCEB ได้อีก การกระทำนี้ไม่สามารถย้อนกลับได้"}}/>
            <hr style={{marginTop:20}}/>
          </span>
          
         
        </Flex>
            <Form
                  //form={form}
                  name="confirm-email-form"
                  className="confirm-email-form"
                  layout='vertical'
                  
                  // initialValues={{ remember: true }}
              >
                  <Form.Item
                      name="itemConfirmEmail"
                      label={<span className='label' style={{fontWeight:600,fontSize:16}}> กรอกอีเมลของคุณเพื่อยืนยันการลบ</span>}
                      rules={[{ required: true, message: 'กรอกอีเมลของคุณเพื่อยืนยัน' }]}
                      required={false}
                  >
                      <Input name='confirmEmail' placeholder="ต.ย. Name@email.com" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} />
                  </Form.Item>
              </Form>
        <Flex
            gap="middle"
            justify="center"
            align="center"
            vertical={false}
            style={{  }}
          >
             
             <Button
            type="primary"
            onClick={() => {
              setIsConfirmDeleteModalOpen(false)
            }}
            style={{
              width:150,
              height:40,
              marginTop: 20,
              // marginRight: 10,
               backgroundColor: "#EBEBEB",
              // color: "#ffffff",
              // border: "2px solid #FF7F07",
            }}
            // type="primary"
          >
            ยกเลิก
          </Button>
          <Button
            type="primary"
            onClick={() => {
              //console.log("confirmEmail ==>",confirmEmail)   
              //console.log("confirmEmail == data.email ==>",confirmEmail == data.email)       
              if(confirmEmail == data.email){
                handleDelete();
                setIsConfirmDeleteModalOpen(false);
              }else{
                setModalFailedStr({
                  title:"ผิดผลาด",
                  detail:"อีเมลของคุณไม่ถูกต้อง",
                });
                setIsModalFailedOpen(true);
              }
            }}
            style={{
              width:150,
              height:40,
              marginTop: 20,
              // marginRight: 10,
              backgroundColor: "#FF4D4F",
              color: "#ffffff",
              // border: "2px solid #FF7F07",
            }}
            // type="primary"
          >
            ยืนยัน
          </Button>
          </Flex>
         
      </Modal>

      {isLoading && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="spinner"></div>
                        <p>กรุณารอซักครู่...</p>
                    </div>
                </div>
            )}

      <FailedDialog 
            isModalOpen={isModalFailedOpen}
            closable={false}
            title={modalFailedStr.title}
            detail={modalFailedStr.detail}
            onCancel={() => {
                setIsModalFailedOpen(false);
            }}
            onAfterClose={() => {
                setIsModalFailedOpen(false);
            }}
            onConfirm={() => {
                setIsModalFailedOpen(false);
            }}
        />

        <SuccessDialog
            isModalOpen={isModalSuccessOpen}
            closable={false}
            title={modalSuccessStr.title}
            detail={modalSuccessStr.detail}
            onCancel={() => {

            }}
            onAfterClose={() => {
              //handleLogout()
            }}
        />
        <ConfirmDialog  
          title={"ยืนยันการยกเลิกเชื่อมต่อบัญชี"}
          description={`คุณต้องการยกเลิกการเชื่อมต่อ\nบัญชี ${unlinkedIdp ? unlinkedIdp.name : ''} หรือไม่`}
          confirmText={"ยืนยัน"}
          cancelText={"ยกเลิก"}
          isModalOpen={isConfirmUnLinkedModalOpen}
          onCancel={() => {
            setIsConfirmUnLinkedModalOpen(false);
            
          }}
          onConfirm={() => {
            handleDisconnect(unlinkedIdp);
            setIsConfirmUnLinkedModalOpen(false);
          }}
          onAfterClose={() => {
            setUnlinkedIdp(null);
          }}
        />

      </div>
    </>
  );
};

export default AccountManagement;
