import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  createContext,
} from "react";
import { Await, useNavigate, useLocation } from "react-router-dom";
import "./InsightReportManagement.css";
import AdminInsightReportManagement from "./AdminInsightReportManagement";
import {
  Layout,
  Flex,
  Button,
  Divider,
  Input,
  Menu,
  Switch,
  Space,
  message,
  Form,
  Row,
  Col,
  Spin,
} from "antd";
import { DeleteDialog } from "../../../../../components";
import {
  AiOutlineSearch,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlinePlusCircle,
  AiOutlineEdit,
  AiOutlineCaretUp,
  AiOutlineCaretDown,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import NoData from "../../../../../assets/img/no_data.js";
import { InsightReportService } from "../../../../../services/InsightReportService";
import { InsightReportCategoryService } from "../../../../../services/InsightReportCategoryService";
import DiscardDialog from "../../../../../components/Dialog/DiscardDialog";

export const AppContext = createContext(null);

const { Header, Content, Sider } = Layout;

const InsightReportManagementDetail = () => {
  const navigate = useNavigate();
  const [formEditMenu] = Form.useForm();
  const [select, setSelect] = useState();
  const [addCategory, setAddCategory] = useState(false);
  const [editingId, setEditingId] = useState();
  const [detailList, setDetailList] = useStateCallback([
    // { key: 1, id:'5bf8b946-2b27-46a0-bf86-aeae6350995f', },
    // { key: 2, id:'dsad'},
  ]);
  // useStateCallback([
  //   {key:1, name:"", }]);
  const [insightCategory, setInsightCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [selectEdit, setSelectEdit] = useState();
  const [selectDelete, setSelectDelete] = useState();
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [submitIndex, setSubmitIndex] = useStateCallback(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
  const [targetId, setTargetId] = useState();
  const passData = useLocation();
  let catagoryId = "-";
  if(passData.state !== null && passData.state.catagoryId !== null){
    catagoryId = passData.state.catagoryId;
  }
  const [prevId, setPrevId] = useState();
  const [isChanged, setIsChanged] = useState();
  const [currentMenu, setCurrentMenu] = useState();
  let isFirstSetData = true;

  useEffect(() => {
    if(isFirstSetData){
      setSelect(catagoryId);
      setPrevId(catagoryId);
      isFirstSetData = false;
    }
  }, []);

  useEffect(() => {
    if (select !== undefined) {
      fetchData();
    }
  }, [select]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [keyword]);

  function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; // store current, passed callback in ref
      setState(state);
    }, []); // keep object reference stable, exactly like `useState`

    useEffect(() => {
      // cb.current is `null` on initial render,
      // so we only invoke callback on state *updates*
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null; // reset callback after execution
      }
    }, [state]);

    return [state, setStateCallback];
  }

  function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; // store current, passed callback in ref
      setState(state);
    }, []); // keep object reference stable, exactly like `useState`

    useEffect(() => {
      // cb.current is `null` on initial render,
      // so we only invoke callback on state *updates*
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null; // reset callback after execution
      }
    }, [state]);

    return [state, setStateCallback];
  }

  useEffect(() => {
    if (!insightCategory) {
      fetchInsightCategory();
    }
  }, [insightCategory]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (select === undefined) {
        setLoading(false);
        return;
      }

      const params = {
        InsightReportCategoryIds: select,
        Keywords: keyword,
      };

      const response = await InsightReportService.search(params);
      setDetailList([], function(data){
        let updatedList = response.items.map((item) => {
          return { ...item, dataStatus:"edit" }; // else return unmodified item
        });
        updatedList?.sort((a, b) => a.sequence - b.sequence)
        setDetailList(updatedList);
      });
      setSubmitIndex(0);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchInsightCategory = async () => {
    try {
      // setLoading(true);

      const params = {
        // Keywords: ,
      };

      const response = await InsightReportCategoryService.search(params);
      setInsightCategory(response);

      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error(error);
    }
  };

  const handleSearchInsight = () => {
    fetchData();
  };

  const handleCreateCategory = async () => {
    const data = [
      {
        id: "",
        name: addCategory,
        sequence: insightCategory?.pagination?.totalCount + 1,
      },
    ];
    try {
      await InsightReportCategoryService.create(data);
      setAddCategory("");
      setIsAddCategory(false);
      fetchInsightCategory();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddDetail = () => {
    messageApi.open({
      type: 'success',
      content: 'เพิ่ม dashboard แล้ว',
    });
    setIsChanged(true);
    setDetailList(
      [...detailList, { id: uuidv4(), sequence: detailList.length + 1,
        insightReportCategoryId:select, dataStatus:"new", insightReportCategoryName:currentMenu, 
         insightReportPermissions:[], insightReportDashboards:[] }],
      function (updateData) {}
    );
  };

  const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  };

  const handleUpdateCategory = async () => {
    const data = {
      name: selectEdit.name,
      sequence: selectEdit.sequence,
      isActivated: selectEdit.isActivated,
    };

    try {
      await InsightReportCategoryService.update(selectEdit.id, data);
      setSelectEdit();
      fetchInsightCategory();
    } catch (error) {
      console.error();
    }
  };

  const handleUpdateCategorySequence = async (items) => {
    try {
      await InsightReportCategoryService.create(items);
      setAddCategory("");
      setIsAddCategory(false);
      setIsDuplicate(false);
      fetchInsightCategory();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      await InsightReportCategoryService.delete(selectDelete.id);
      setSelectDelete();
      setIsModalDeleteOpen(false);
      setIsDuplicate(false);
      // fetchInsightCategory();

      const updatedCategories = insightCategory.items
        .filter((item) => item.id !== selectDelete.id)
        .map((item, index) => ({
          ...item,
          sequence: index + 1,
        }));

      handleUpdateCategorySequence(updatedCategories);
    } catch (error) {
      console.error();
    }
  };

  const moveItem = (id, direction) => {
    setInsightCategory((prev) => {
      const items = [...prev.items];
      const index = items.findIndex((item) => item.id === id);

      if (index < 0) return prev;
      const swapIndex = index + direction;

      if (swapIndex < 0 || swapIndex >= items.length) return prev;

      [items[index].sequence, items[swapIndex].sequence] = [
        items[swapIndex].sequence,
        items[index].sequence,
      ];

      items.sort((a, b) => a.sequence - b.sequence);

      handleUpdateCategorySequence(items);

      return { ...prev, items };
    });
  };

  const handleOnSubmit = () => {
    let updatedList = detailList.map((item) => {
      return { ...item, onSubmit: true };
    });
    setDetailList(updatedList);
    // form.validateFields().then((values) => {
    //   // do something with values
    //   alert("test")
    // })
  };

  function handleClick(e, menu, menuName) {
    // This will set e.defaultPrevented to true
    // (for all synthetic events firing after this one)
    // let isChangeed = sessionStorage.getItem("isDiscardChanged");
    if (isChanged !== null && isChanged !== undefined) {
      setIsModalDiscardOpen(true);
      setTargetId(menu);
      e.domEvent.preventDefault();
      e.domEvent.stopPropagation();
      // e.dispatchEvent(e);
    }else{
      setCurrentMenu(menuName);
      setSelect(menu);
      setPrevId(menu);
    }
  }

  return (
    <AppContext.Provider
      value={{
        detailList,
        setDetailList,
        isChanged,
        setIsChanged,
        setSubmitIndex,
      }}
    >
       {contextHolder}
       <DiscardDialog
        isModalOpen={isModalDiscardOpen}
        closable={false}
        title="Discard changes"
        cancelText="Cancel"
        confirmText="Confirm"
        onCancel={() => {
          setIsModalDiscardOpen(false);
        }}
        onConfirm={() => {
          setIsChanged(null);
          let element = document.getElementById(targetId);
          element.click();
          setSelect(targetId);
          setPrevId(targetId);
          setIsModalDiscardOpen(false);
          // alert("OK");
        }}
        description="Changes will not be saved. Do you want to proceed ?"
      />

      <div className="insight-report-management-body">
        <Layout>
          <Header
            style={{
              height: "70px",
              background: "#FFF",
              borderBottom: "1px solid #D0D5DD",
            }}
          >
            <Flex
              gap="middle"
              justify="flex-start"
              align="center"
              style={{ height: "100%" }}
            >
              <AiOutlineArrowLeft
                style={{ fontSize: "24px", cursor: "pointer" }}
                onClick={() =>
                  navigate("/management/insight-report-management")
                }
              />
              <p style={{ margin: "0", fontSize: "18px", fontWeight: "600" }}>
                เพิ่ม/แก้ไขข้อมูล รายงานวิเคราะห์
              </p>
            </Flex>
          </Header>
          <Layout style={{ height: "100vh" }}>
            <Sider
              width={302}
              style={{
                margin: "14px",
                background: "#FFF",
                borderRadius: "8px",
              }}
            >
              <div style={{ padding: "14px" }}>
                <p style={{ margin: "0", fontSize: "16px", fontWeight: "600" }}>
                  เลือกประเภทรายงานวิเคราะห์
                </p>
                <Input
                  placeholder="ค้นหา ชื่อรายงานวิเคราะห์..."
                  allowClear
                  suffix={
                    <AiOutlineSearch
                      onClick={() => handleSearchInsight()}
                      style={{
                        color: "rgba(0,0,0,.45)",
                        cursor: "pointer",
                      }}
                    />
                  }
                  onChange={(e) => setKeyword(e.target.value)}
                  onPressEnter={() => handleSearchInsight()}
                  style={{ marginTop: "14px", borderRadius: "8px" }}
                />
              </div>
              <Divider style={{ margin: "0" }} />
              <div style={{ padding: "14px" }}>
                <Button
                  type="dashed"
                  icon={<AiOutlinePlusCircle style={{ fontSize: "16px" }} />}
                  onClick={() => {
                    setIsAddCategory(true);
                    setSelectEdit();
                  }}
                  style={{
                    color: "#1677FF",
                    borderColor: "#1677FF",
                    width: "100%",
                    height: "50px",
                  }}
                >
                  <span style={{ fontSize: "16px", fontWeight: "500" }}>
                    เพิ่มประเภท
                  </span>
                </Button>
                {isAddCategory && (
                  <div
                    style={{
                      background: "#EFEFEF",
                      width: "272px",
                      height: "44px",
                      padding: "6px ",
                      borderRadius: "8px",
                      marginTop: "14px",
                    }}
                  >
                    <Flex align="center">
                      <Form>
                        <Row>
                          <Col>
                            <Form.Item
                              name="input"
                              rules={[
                                {
                                  validator: (_, value) => {
                                    if (value) {
                                      const isCheck =
                                        insightCategory?.items.some(
                                          (item) =>
                                            item?.name.toLowerCase() ===
                                            value.toLowerCase()
                                        );
                                      setIsDuplicate(isCheck);
                                      if (isCheck) {
                                        return Promise.reject(
                                          new Error("มีชื่อประเภทแล้ว!")
                                        );
                                      }
                                    }
                                  },
                                },
                              ]}
                            >
                              <Input
                                showCount
                                maxLength={20}
                                placeholder="ชื่อประเภท"
                                onChange={(e) => setAddCategory(e.target.value)}
                                // onPressEnter={() => handleCreateCategory()}
                                style={{
                                  borderRadius: "6px",
                                  width: "200px",
                                  height: "32px",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Space
                              size="small"
                              style={{
                                marginLeft: "8px",
                                marginTop: "8px",
                              }}
                            >
                              <AiOutlineCheck
                                onClick={() => {
                                  if (!isDuplicate && addCategory)
                                    handleCreateCategory();
                                }}
                                style={{
                                  color:
                                    !isDuplicate && addCategory
                                      ? "#00B6A9"
                                      : "#D9D9D9",
                                  fontSize: "16px",
                                  cursor:
                                    !isDuplicate && addCategory
                                      ? "pointer"
                                      : "not-allowed",
                                }}
                              />
                              <AiOutlineClose
                                onClick={() => {
                                  setIsDuplicate(false);
                                  setIsAddCategory(false);
                                  setAddCategory("");
                                }}
                                style={{
                                  color: "#FF4D4F",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                              />
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </Flex>
                  </div>
                )}
              </div>

              {insightCategory && insightCategory?.items?.length !== 0 ? (
                <div style={{ padding: "14px" }}>
                  <Menu
                    // mode="inline"
                    defaultSelectedKeys={["1"]}
                    selectedKeys={prevId}
                    style={{
                      height: "100%",
                    }}
                  >
                    {insightCategory?.items
                      ?.sort((a, b) => a.sequence - b.sequence)
                      .map((item, index) => (
                        <Menu.Item
                          key={item.id}
                          id={item.id}
                          // onClick={() => setSelect(item.id)}
                          onClick={(e) => {
                            handleClick(e, item.id, item.name);
                          }}
                          style={{
                            padding: 0,
                            marginBottom:
                              isDuplicate && selectEdit?.id === item.id
                                ? "20px"
                                : "0px",
                          }}
                        >
                          {selectEdit?.id === item.id ? (
                            <div
                              style={{
                                background: "#EFEFEF",
                                width: "272px",
                                height: "44px",
                                padding: "6px ",
                                borderRadius: "8px",
                                marginBottom: "10px",
                              }}
                            >
                              <Flex align="center">
                                <Form form={formEditMenu}>
                                  <Row>
                                    <Col>
                                      <Form.Item
                                        name="editmenu"
                                        rules={[
                                          {
                                            validator: (_, value) => {
                                              if (value) {
                                                const isCheck =
                                                  insightCategory?.items.some(
                                                    (item) =>
                                                      item?.name.toLowerCase() ===
                                                        value.toLowerCase() &&
                                                      item.id !== selectEdit.id
                                                  );
                                                setIsDuplicate(isCheck);
                                                if (isCheck) {
                                                  return Promise.reject(
                                                    new Error(
                                                      "มีชื่อประเภทแล้ว"
                                                    )
                                                  );
                                                }
                                              }
                                            },
                                          },
                                        ]}
                                      >
                                        <Input
                                          showCount
                                          maxLength={20}
                                          placeholder="ชื่อประเภท"
                                          onChange={(e) =>
                                            setSelectEdit({
                                              ...selectEdit,
                                              name: e.target.value,
                                            })
                                          }
                                          style={{
                                            borderRadius: "6px",
                                            width: "200px",
                                            height: "32px",
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Space
                                        size="small"
                                        style={{
                                          marginLeft: "8px",
                                          marginTop: "8px",
                                        }}
                                      >
                                        <AiOutlineCheck
                                          onClick={() => {
                                            if (!isDuplicate && selectEdit.name)
                                              handleUpdateCategory();
                                          }}
                                          style={{
                                            color:
                                              !isDuplicate && selectEdit.name
                                                ? "#00B6A9"
                                                : "#D9D9D9",
                                            fontSize: "16px",
                                            cursor:
                                              !isDuplicate && selectEdit.name
                                                ? "pointer"
                                                : "not-allowed",
                                          }}
                                        />
                                        <AiOutlineClose
                                          onClick={() => {
                                            setSelectEdit();
                                            setIsDuplicate(false);
                                          }}
                                          style={{
                                            color: "#FF4D4F",
                                            fontSize: "16px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Space>
                                    </Col>
                                  </Row>
                                </Form>
                              </Flex>
                            </div>
                          ) : (
                            <div
                              style={{
                                width: "250px",
                                height: "100%",
                                position: "relative",
                                top: "-5px",
                                margin: "8px",
                              }}
                            >
                              <Flex
                                justify="space-between"
                                align="center"
                                style={{ height: "100%" }}
                              >
                                <Space>
                                  <Flex vertical>
                                    <AiOutlineCaretUp
                                      onClick={() => moveItem(item.id, -1)}
                                      style={{
                                        color: "#667085",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <AiOutlineCaretDown
                                      onClick={() => moveItem(item.id, 1)}
                                      style={{
                                        color: "#667085",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Flex>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {item.name} ({item?.count || 0})
                                  </span>
                                </Space>
                                <Flex gap="small">
                                  <AiOutlineEdit
                                    onClick={() => {
                                      setIsAddCategory(false);
                                      setAddCategory("");
                                      setSelectEdit(item);
                                      formEditMenu.setFieldsValue({
                                        editmenu: item?.name,
                                      });
                                    }}
                                    style={{
                                      fontSize: "16px",
                                      color: "#667085",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <AiOutlineDelete
                                    onClick={() => {
                                      setIsAddCategory(false);
                                      setAddCategory("");
                                      setSelectEdit();
                                      setSelectDelete(item);
                                      if (!item?.count) {
                                        setIsModalDeleteOpen(true);
                                      }
                                    }}
                                    style={{
                                      fontSize: "16px",
                                      color: !item?.count
                                        ? "#667085"
                                        : "#E0E4EA",
                                      cursor: !item?.count
                                        ? "pointer"
                                        : "not-allowed",
                                    }}
                                  />
                                </Flex>
                              </Flex>
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                  </Menu>
                </div>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ height: "80%" }}
                >
                  <NoData />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#667085",
                    }}
                  >
                    ไม่มีข้อมูล
                  </p>
                </Flex>
              )}
            </Sider>

            <Content
              style={{
                margin: "14px",
                marginLeft: "0",
                overflowY: "scroll",
              }}
            >
              {select ? (
                <>
                  <div
                    style={{
                      height: "70px",
                      width: "100%",
                      background: "#fff",
                      borderRadius: "8px",
                      padding: "14px",
                      position: "sticky",
                      top: "0px",
                      zIndex: "2",
                      marginBottom: "14px",
                    }}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      style={{ height: "100%" }}
                    >
                      <p
                        style={{
                          margin: "0",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        รายละเอียด
                      </p>
                      <Space>
                        <Button
                          onClick={handleAddDetail}
                          type="dashed"
                          icon={
                            <AiOutlinePlusCircle style={{ fontSize: "16px" }} />
                          }
                          style={{
                            color: "#1677FF",
                            borderColor: "#1677FF",
                            height: "40px",
                            width: "140px",
                            borderRadius: "10px",
                          }}
                        >
                          <span style={{ fontSize: "16px", fontWeight: "500" }}>
                            เพิ่มใหม่
                          </span>
                        </Button>
                        <Divider type="vertical" />
                        <Button
                          type="primary"
                          onClick={() => {
                            navigate(
                              {
                                pathname: `/management/insight-report-management`,
                        
                                // pagedata: pageValue,
                              },
                              { state: { catagoryId: select } }
                            );
                          }}
                          style={{
                            background: "#EBEBEB",
                            height: "40px",
                            width: "140px",
                            borderRadius: "10px",
                          }}
                        >
                          <span style={{ fontSize: "16px", fontWeight: "500" }}>
                            ยกเลิก
                          </span>
                        </Button>
                        <Button
                          onClick={() => {
                            setSubmitIndex(submitIndex + 1);
                          }}
                          type="primary"
                          style={{
                            height: "40px",
                            width: "140px",
                            borderRadius: "10px",
                          }}
                        >
                          <span style={{ fontSize: "16px", fontWeight: "500" }}>
                            บันทึก
                          </span>
                        </Button>
                      </Space>
                    </Flex>
                  </div>
                  {detailList?.length !== 0 ? (
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{
                        display: "flex",
                      }}
                    >
                      {detailList?.map((value, index) => (
                        <div id={index} key={index}>
                          <Spin tip="Loading" spinning={loading}>
                           <AdminInsightReportManagement data={value} submitIndex={submitIndex} categoryId={select}/>
                          </Spin>
                        </div>
                      ))} 
                    </Space>
                  ) : (
                    <Flex
                      vertical
                      justify="center"
                      align="center"
                      style={{ height: "100%" }}
                    >
                      <NoData />
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#667085",
                        }}
                      >
                        ไม่มีข้อมูล
                      </p>
                    </Flex>
                  )}
                </>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ height: "100%" }}
                >
                  <NoData />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#667085",
                    }}
                  >
                    กรุณาเลือกประเภทก่อนเพิ่มข้อมูล
                  </p>
                </Flex>
              )}
            </Content>
          </Layout>
        </Layout>

        <DeleteDialog
          isModalOpen={isModalDeleteOpen}
          closable={false}
          title="ยืนยันการลบ"
          description="คุณต้องการลบใช่หรือไม่?"
          cancelText="ยกเลิก"
          confirmText="ลบ"
          onCancel={() => {
            setSelectDelete();
            setIsModalDeleteOpen(false);
          }}
          onConfirm={() => {
            handleDeleteCategory();
          }}
        />
      </div>

    </AppContext.Provider>
  );
};

export default InsightReportManagementDetail;
