import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="126"
    height="126"
    viewBox="0 0 126 126"
    fill="none"
  >
    <rect x="8" y="8" width="110" height="110" rx="42" fill="#FFE2E2" />
    <rect
      x="8"
      y="8"
      width="110"
      height="110"
      rx="42"
      stroke="#FFF7F7"
      stroke-width="14.9697"
    />
    <g clipPath="url(#clip0_7977_53418)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.6742 46.6742C47.4064 45.9419 48.5936 45.9419 49.3258 46.6742L63 60.3483L76.6742 46.6742C77.4064 45.9419 78.5936 45.9419 79.3258 46.6742C80.0581 47.4064 80.0581 48.5936 79.3258 49.3258L65.6517 63L79.3258 76.6742C80.0581 77.4064 80.0581 78.5936 79.3258 79.3258C78.5936 80.0581 77.4064 80.0581 76.6742 79.3258L63 65.6517L49.3258 79.3258C48.5936 80.0581 47.4064 80.0581 46.6742 79.3258C45.9419 78.5936 45.9419 77.4064 46.6742 76.6742L60.3483 63L46.6742 49.3258C45.9419 48.5936 45.9419 47.4064 46.6742 46.6742Z"
        fill="#FF4D4F"
      />
    </g>
    <defs>
      <clipPath id="clip0_7977_53418">
        <rect
          width="60"
          height="60"
          fill="white"
          transform="translate(33 33)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Icon_Failed_Dialog = (props) => <Icon component={icon} {...props} />;

export default Icon_Failed_Dialog;
