import React, { useEffect, useState } from "react";
import "./ManagementLayout.css";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { FaRegUserCircle, FaAlignLeft, FaRegCheckCircle } from "react-icons/fa";
import {
  AiOutlineTeam,
  AiOutlineCopy,
  AiOutlineSolution,
  AiOutlineTags,
  AiOutlineAppstoreAdd,
  AiOutlineContainer,
  AiOutlineCode,
  AiOutlineControl,
} from "react-icons/ai";
import { UserService } from "../../../services/UserService";

const { Sider } = Layout;

const ManagementLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [permissions, setPermissions] = useState([]);

  const siderStyle = {
    textAlign: "center",
    lineHeight: "120px",
    color: "#fff",

    backgroundColor: "rgba(255, 255, 255, 1)",
  };

  const fetchUserPermission = async () => {
    if(window.permissions === undefined){ 
      let userPermission = await UserService.getPermissions(); 
      let permissions = userPermission.filter(p=>p.isViewable);
      window.permissions = permissions;      
    }
    var permissions = window.permissions.map((p) => p.routePath);
    permissions.push("/management/account-management");
    permissions.push("/management/change-password");
    permissions.push("/management/terms-and-conditions");
    setPermissions(permissions);
    if (!permissions.includes(currentPath) && !currentPath.includes(currentPath.replace('-detail',''))) {
      navigate("/");
    }
  };

  useEffect(() => {
    fetchUserPermission();
  }, [currentPath]);

  return (
    <Layout>
      <Sider style={siderStyle} className="admin-menu" width={320}>
        <Menu
          mode="inline"
          defaultSelectedKeys={currentPath}
          openKeys={["sub1"]}
          // defaultOpenKeys={["sub1"]}
          style={{
            height: "100%",
          }}
        >
          <Menu.Item key="labelUser" disabled>
            โปรไฟล์
          </Menu.Item>
          <Menu.Item
            key="/management/account-management"
            icon={<FaRegUserCircle />}
            onClick={() => navigate("/management/account-management")}
          >
            บัญชีของคุณ
          </Menu.Item>
          <Menu.Item key="2" icon={<AiOutlineCode />}>
            ขอใช้ API
          </Menu.Item>
          <Menu.Item key="3" icon={<AiOutlineControl />}>
            ตั้งค่าความยินยอมของคุณ
          </Menu.Item>
          <Menu.Item
            key="/management/change-password"
            icon={<LockOutlined />}
            onClick={() => navigate("/management/change-password")}
          >
            เปลี่ยนรหัสผ่าน
          </Menu.Item>
          {permissions.length > 0 && (
            <Menu.Item key="labelAdmin" disabled>
              ผู้ดูแลระบบ
            </Menu.Item>
          )}
          {permissions.includes("/management/manage-user") && (
            <Menu.Item
              key="/management/manage-user"
              icon={<AiOutlineTeam />}
              onClick={() => navigate("/management/manage-user")}
            >
              จัดการข้อมูลผู้ใช้งาน
            </Menu.Item>
          )}
          {permissions.includes("/management/feed-back") && (
            <Menu.Item key="/management/feed-back" icon={<FaAlignLeft />}
              onClick={() => navigate("/management/feed-back")}
            >
              จัดการข้อมูล Feedback
            </Menu.Item>
          )}
          {permissions.includes("/management/terms-and-conditions") && (
            <Menu.Item
              key="/management/terms-and-conditions"
              icon={<AiOutlineCopy />}
              style={{ wordWrap: "break-word" }}
            >
              ข้อกำหนดและเงื่อนไขการให้บริการ
            </Menu.Item>
          )}
          {permissions.includes("/management/manage-consent") || permissions.includes("/management/view-consent")  && (
            
          <Menu.SubMenu
            key="sub1"
            icon={<FaRegCheckCircle />}
            title="จัดการข้อมูลคำยินยอมการใช้งาน"
          >
            {permissions.includes("/management/manage-consent") && (
              <Menu.Item key="8" onClick={() => navigate("/management/manage-consent")}>
                <span style={{ margin: "12px" }}>สร้างข้อมูลคำยินยอมใช้งาน</span>
              </Menu.Item>
            )}
            {permissions.includes("/management/view-consent") && (
              <Menu.Item key="/management/view-consent">
                <span style={{ margin: "12px" }}>
                  การให้ความยินยอมการใช้งานข้อมูล
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
          )}
          {permissions.includes("/management/role-management") && (
            <Menu.Item
              key="/management/role-management"
              icon={<AiOutlineSolution />}
              onClick={() => navigate("/management/role-management")}
            >
              จัดการข้อมูลสิทธิ์การเข้าถึง
            </Menu.Item>
          )}
          {permissions.includes("/management/edit-tag") && (
            <Menu.Item
              key="/management/edit-tag"
              icon={<AiOutlineTags />}
              onClick={() => navigate("/management/edit-tag")}
            >
              จัดการประเภท Tag
            </Menu.Item>
          )}
          {permissions.includes("/management/dashboard-management") && (
            <Menu.Item
              key="/management/dashboard-management"
              icon={<AiOutlineAppstoreAdd />}
              onClick={() => navigate("/management/dashboard-management")}
            >
              จัดการข้อมูล Dashboard
            </Menu.Item>
          )}
          {permissions.includes("/management/insight-report-management") && (  
            <Menu.Item
              key="/management/insight-report-management"
              icon={<AiOutlineContainer />}
              onClick={() => navigate("/management/insight-report-management")}
            >
              จัดการข้อมูลรายงานวิเคราะห์
            </Menu.Item>
          )}
          {permissions.includes("/management/datasource-management") && (
            <Menu.Item
              key="/management/datasource-management"
              icon={<AiOutlineCopy />}
              onClick={() => navigate("/management/datasource-management")}
            >
              จัดการข้อมูลดาต้าแค็ตตาล็อก
            </Menu.Item>
          )}
          {permissions.includes("/management/datasource-request") && (
            <Menu.Item key="/management/datasource-request" icon={<AiOutlineCode />}>
              จัดการคำร้องขอใช้ API
            </Menu.Item>
          )}
        </Menu>
      </Sider>
      <Layout>
        <div>
          <Outlet />
        </div>
      </Layout>
    </Layout>
  );
};

export default ManagementLayout;
