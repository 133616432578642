import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Tooltip } from "antd";

const FormItem = ({
  children,
  name,
  label,
  required = false,
  requiredMessage = "This field is required.",
  requiredType,
  pattern,
  patternMessage = "",
  validator,
  tooltipMessage = "",
}) => {
  return (
    <>
      <Form.Item
        name={name}
        label={
          label && (
            <span>
              <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                {label}&nbsp;
              </span>
              {required && (
                <span style={{ fontWeight: "bold", color: "red" }}>*</span>
              )}
              {tooltipMessage && (
                <Tooltip title={tooltipMessage}>
                  <InfoCircleOutlined
                    style={{ color: "#0063A7", marginLeft: 8 }}
                  />
                </Tooltip>
              )}
            </span>
          )
        }
        rules={[
          { required: required, message: requiredMessage, type: requiredType },
          { pattern: pattern, message: patternMessage },
          { validator: validator },
        ]}
      >
        {children}
      </Form.Item>
    </>
  );
};

export default FormItem;
