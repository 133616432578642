import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { LockOutlined, EyeInvisibleOutlined, EyeOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import logo from '../Login/logo 1.png';
import Error from '../register/Error.png';
import Success from '../register/verify_email/success.png';
import { useNavigate, useLocation } from 'react-router-dom';
import './edit_password.css';
import { KeycloakService } from '../../../services/KeycloakService';

const EditPasswordPage = () => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false); // Add success state
    const [token, setToken] = useState('');
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const siteKey = process.env.REACT_APP_SITE_KEY_RECAPCHA;
    const secret = process.env.REACT_APP_SECRET_KEY_RECAPCHA;
    const [isValid, setIsValid] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        digit: false,
        specialChar: false,
    });

    const toggleVisibility = () => {
        setVisible(!visible);
    };
    useEffect(() => {
        //console.log(siteKey)
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        document.head.appendChild(script);
    }, [siteKey]);
    useEffect(() => {
        const tokenFromUrl = new URLSearchParams(window.location.search);
        const token = tokenFromUrl.get('token');
        if (token) {
            setToken(token);
        }
    }, [location]);

    useEffect(() => {
        const verifyEmailWithToken = async () => {
            if (token) {
                try {
                    const responserecap = await KeycloakService.forgotPassWord(token);
                } catch (error) {
                    console.error('Error verifying email:', error);
                }
            }
        };
        verifyEmailWithToken();
    }, [token]);

    const executeRecaptcha = async (action) => {
        return new Promise((resolve) => {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(siteKey, { action }).then((token) => {
              resolve(token);
            });
          });
        });
    };
    const verifyingRecaptcha = async () => {
        return new Promise(async (resolve, reject) => {
          const token = await executeRecaptcha('login');
          setIsLoading(true);
          if (token) {
              const recap = {
                  secret: secret,
                  response: token
              };
              try {
                  const responserecap = await KeycloakService.recap(recap);
                  if (responserecap.success == true) {
                      //console.log("reCAPTCHA success");
                      resolve(true);
                  }
              } catch (error) {
                  setIsLoading(false);
                  console.error("Error while verifying reCAPTCHA:", error);
                  resolve(false);
              }
          }else{
              setIsLoading(false);
              resolve(false);
          }
        });
      };

    const onFinish = async (values) => {
        verifyingRecaptcha().then(async (result) => {
            if (result) {
                setIsLoading(true);
                try {
                    const { password } = values;
                    const response = await KeycloakService.sendResetPassword(token, password);
                    //console.log("Response:", response);
                    setShowSuccess(true); // Set success state to true
                } catch (error) {
                    console.error('Error verifying email:', error);
                    setShowError(true);
                } finally {
                    setIsLoading(false);
                }
            }
        });
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setIsValid({
            length: newPassword.length >= 8 && newPassword.length <= 20,
            uppercase: /[A-Z]/.test(newPassword),
            lowercase: /[a-z]/.test(newPassword),
            digit: /\d/.test(newPassword),
            specialChar: /[*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|]/.test(newPassword),
        });
    };

    const handleOk = () => {
        setShowError(false);
    };

    const goBack = () => {
        navigate('/login');
    };

    return (
        <div className="login-containe1">
            <Row>
                <Col span={18} md={18} xs={24}>
                    <Row>
                        <Col span={24} md={24} xs={0}>
                        <div className="login-bg"></div>
                        <div className='login-left'>
                            <div className="logo">
                            <img
                                src={logo}
                                alt="TCEB Logo"
                                className="logo-image"
                                onClick={() => navigate('/')}
                                style={{ cursor: 'pointer' }}
                            />
                            </div>
                        </div>
                        <div className="copyright">
                            © 2567 สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)
                        </div>
                        </Col>
                        <Col span={24} xs={24} md={0}>
                        <div className="login-bg" style={{position:'fixed'}}></div>
                        <div className="logo" style={{textAlign:"center", marginTop:"15px"}}>
                            <img
                            src={logo}
                            alt="TCEB Logo"
                            className="logo-image"
                            onClick={() => navigate('/')}
                            style={{ cursor: 'pointer' }}
                            />
                        </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={6} md={6} xs={24}>
                    <div className="center-v">
                        <div style={{textAlign:"center"}}>
                        {showSuccess ? (
                            <div className="login-card">
                                <img src={Success} style={{ width: 'auto', height: '30%' }} alt="Success" />
                                <h2 style={{ marginTop: '16px' }}>เปลี่ยนรหัสผ่านเรียบร้อยแล้ว</h2>
                                <p style={{ margin: '16px 0', fontSize: '14px', color: '#475467', textAlign: "center" }}>
                                    คุณได้เปลี่ยนรหัสผ่านใหม่เรียบร้อยแล้ว<br />
                                    กรุณาเข้าสู่ระบบใหม่อีกครั้ง
                                </p>
                                <Button type="primary" onClick={goBack}>
                                    เข้าสู่ระบบ
                                </Button>
                            </div>
                        ) : showError ? (
                            <div className="login-card">
                                <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="78" height="78" rx="20" fill="#FFE2E2"/>
                                <g clipPath="url(#clip0_7808_28362)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M22.6742 22.6742C23.4064 21.9419 24.5936 21.9419 25.3258 22.6742L39 36.3483L52.6742 22.6742C53.4064 21.9419 54.5936 21.9419 55.3258 22.6742C56.0581 23.4064 56.0581 24.5936 55.3258 25.3258L41.6517 39L55.3258 52.6742C56.0581 53.4064 56.0581 54.5936 55.3258 55.3258C54.5936 56.0581 53.4064 56.0581 52.6742 55.3258L39 41.6517L25.3258 55.3258C24.5936 56.0581 23.4064 56.0581 22.6742 55.3258C21.9419 54.5936 21.9419 53.4064 22.6742 52.6742L36.3483 39L22.6742 25.3258C21.9419 24.5936 21.9419 23.4064 22.6742 22.6742Z" fill="#FF4D4F"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_7808_28362">
                                <rect width="60" height="60" fill="white" transform="translate(9 9)"/>
                                </clipPath>
                                </defs>
                                </svg>

                                <h2 style={{ marginTop: '16px' }}>ไม่สำเร็จ</h2>
                                <p style={{ margin: '16px 0', fontSize: '14px', color: '#475467', textAlign:"center" }}>
                                    เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง
                                </p>
                                <Button
                                    type="primary"
                                    onClick={handleOk}
                                    style={{ backgroundColor: '#E03A3A', borderColor: '#E03A3A', color: '#ffffff' }}
                                >
                                    ลองอีกครั้ง
                                </Button>
                            </div>
                        ) : (
                            <div className="login-card">
                                <div className="header">
                                    <p style={{ margin: '20px 0 0 0', textAlign: 'left', fontSize: '18px', fontWeight: 'bold' }}>
                                        เปลี่ยนรหัสผ่านใหม่
                                    </p>
                                </div>
                                <span style={{ display: 'block', margin: '10px 0', textAlign: 'left' }}>
                                    กรุณาสร้างรหัสผ่านใหม่เพื่อเข้าถึงบัญชีของคุณ
                                </span>

                                <Form name="login_form" initialValues={{ remember: true }} onFinish={onFinish}>
                                    <label style={{ fontWeight: 700, display: 'block', textAlign: 'left' }}>
                                        รหัสผ่านใหม่
                                    </label>
                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
                                        style={{ textAlign: 'left' }}
                                    >
                                        <Input
                                            prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.5)' }} />}
                                            type={visible ? 'text' : 'password'}
                                            placeholder="รหัสผ่าน"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            suffix={
                                                visible ? (
                                                    <EyeInvisibleOutlined onClick={toggleVisibility} style={{ cursor: 'pointer' }} />
                                                ) : (
                                                    <EyeOutlined onClick={toggleVisibility} style={{ cursor: 'pointer' }} />
                                                )
                                            }
                                        />
                                    </Form.Item>

                                    <label style={{ fontWeight: 700, display: 'block', textAlign: 'left' }}>
                                        ยืนยันรหัสผ่านใหม่
                                    </label>
                                    <Form.Item
                                        name="confirmPassword"
                                        rules={[
                                            { required: true, message: 'กรุณากรอกรหัสผ่าน' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน'));
                                                },
                                            }),
                                        ]}
                                        style={{ textAlign: 'left' }}
                                    >
                                        <Input
                                            prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.5)' }} />}
                                            type={visible ? 'text' : 'password'}
                                            placeholder="ยืนยันรหัสผ่าน"
                                            suffix={
                                                visible ? (
                                                    <EyeInvisibleOutlined onClick={toggleVisibility} style={{ cursor: 'pointer' }} />
                                                ) : (
                                                    <EyeOutlined onClick={toggleVisibility} style={{ cursor: 'pointer' }} />
                                                )
                                            }
                                        />
                                    </Form.Item>

                                    <div style={{ textAlign: 'left', marginBottom: '10px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckCircleIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    color: isValid.length ? '#1AC487' : 'gray',
                                                }}
                                            />
                                            <span style={{color: isValid.length ? '#1AC487' : 'gray', marginLeft: "10px"}}>ความยาวรหัสผ่านต้องมีอย่างน้อย 8 - 20 ตัวอักษร</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckCircleIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    color: isValid.uppercase ? '#1AC487' : 'gray',
                                                }}
                                            />
                                            <span style={{color: isValid.uppercase ? '#1AC487' : 'gray', marginLeft: "10px"}}>ต้องมีตัวอักษรพิมพ์ใหญ่</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckCircleIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    color: isValid.lowercase ? '#1AC487' : 'gray',
                                                }}
                                            />
                                            <span style={{color: isValid.lowercase ? '#1AC487' : 'gray', marginLeft: "10px"}}>ต้องมีตัวอักษรพิมพ์เล็ก</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckCircleIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    color: isValid.digit ? '#1AC487' : 'gray',
                                                }}
                                            />
                                            <span style={{color: isValid.digit ? '#1AC487' : 'gray', marginLeft: "10px"}}>ต้องมีหมายเลข (ตัวเลข)</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckCircleIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    color: isValid.specialChar ? '#1AC487' : 'gray',
                                                }}
                                            />
                                            <span style={{color: isValid.specialChar ? '#1AC487' : 'gray', marginLeft: "10px"}}>ต้องมีอักขระพิเศษ [*.!@#&amp;$%^&amp;(){ }[]:;&lt;&gt;,.?/~_+-=|]</span>
                                        </div>
                                    </div>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="register-form-button"
                                        disabled={!Object.values(isValid).every(Boolean)}
                                    >
                                        ยืนยันรหัสผ่าน
                                    </Button>
                                </Form>
                            </div>
                        )}
                        </div>
                    </div>
                </Col>
            </Row>
            {isLoading && (
                <div className="modal-overlay">
                <div className="modal-content">
                    <div className="spinner"></div>
                    <p>กรุณารอซักครู่...</p>
                </div>
                </div>
            )}  
        </div>
    );
};

export default EditPasswordPage;
