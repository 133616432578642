import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
  >
    <rect width="78" height="78" rx="20" fill="#F8F8F9" />
    <g clipPath="url(#clip0_7977_97060)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.625 39C14.625 25.5381 25.5381 14.625 39 14.625C52.4619 14.625 63.375 25.5381 63.375 39C63.375 52.4619 52.4619 63.375 39 63.375C25.5381 63.375 14.625 52.4619 14.625 39ZM39 29.625C40.0355 29.625 40.875 30.4645 40.875 31.5V40.875C40.875 41.9105 40.0355 42.75 39 42.75C37.9645 42.75 37.125 41.9105 37.125 40.875V31.5C37.125 30.4645 37.9645 29.625 39 29.625ZM39 50.25C40.0355 50.25 40.875 49.4105 40.875 48.375C40.875 47.3395 40.0355 46.5 39 46.5C37.9645 46.5 37.125 47.3395 37.125 48.375C37.125 49.4105 37.9645 50.25 39 50.25Z"
        fill="#475467"
      />
    </g>
    <defs>
      <clipPath id="clip0_7977_97060">
        <rect width="60" height="60" fill="white" transform="translate(9 9)" />
      </clipPath>
    </defs>
  </svg>
);

const Icon_warning_dialog = (props) => <Icon component={icon} {...props} />;

export default Icon_warning_dialog;
