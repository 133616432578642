import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Row, Space, Flex, Table, Tag, Tabs, Input } from "antd";
import "./SwaggerDoc.css";
import SwaggerRouteForm from "./SwaggerRouteForm.js";
import SwaggerSchemaForm from "./SwaggerSchemaForm.js";
import CustomButton from "../../../components/Button/CustomButton";
import EditAction from "../../../components/Button/EditAction";
import DeleteAction from "../../../components/Button/DeleteAction";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../components/Dialog/FailedDialog";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import WarningDialog from "../../../components/Dialog/WarningDialog.js";
import NavOcelot from "../../../components/Nav/NavOcelot";
import FooterOcelot from "../../../components/Footer/FooterOcelot";
import { ReRouteService } from "../../../services/ReRouteService";

// import { mock, mock2 } from "../../../mock.js";

const TabPane = Tabs.TabPane;

const SwaggerDoc = () => {
  const [initialData, setInitialData] = useState();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState();
  const [schemas, setSchemas] = useState();
  const [tags, setTags] = useState();
  const [keyTab, setKeyTab] = useState();
  const [filteredItem, setFilteredItem] = useState([]);
  const [searchItemText, setSearchItemText] = useState("");
  const [filteredSchemas, setFilteredSchemas] = useState([]);
  const [searchSchemaText, setSearchSchemaText] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [routeExternalList, setRouteExternalList] = useState();
  const [filedValue, setFiledValue] = useState();
  const [editingItem, setEditingItem] = useState(null);
  const [action, setAction] = useState();
  const [schemaOption, setSchemaOption] = useState([]);
  const [selectItem, setSelcetItem] = useState();
  const [tagOptions, setTagOptions] = useState([]);
  const [selectTag, setSelectTag] = useState([]);

  const [isShowFormSchema, setIsShowFormSchema] = useState(false);
  const [filedValueSchema, setFiledValueSchema] = useState();
  const [editSchema, setEditSchema] = useState(null);
  const [actionSchema, setActionSchema] = useState();

  const [isChanged, setIsChanged] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalWarningOpen, setIsModalWarningOpen] = useState(false);

  const routeColumns = [
    // {
    //   title: "Swagger name",
    //   dataIndex: "name",
    // },
    {
      title: "Path",
      dataIndex: "path",
    },
    {
      title: "Method",
      dataIndex: "method",
      render: (_, record) => (
        <Tag>
          <span style={{ fontWeight: 600 }}>{record.method.toUpperCase()}</span>
        </Tag>
      ),
    },
    {
      title: "Tag",
      dataIndex: "tag",
      render: (_, record) => (
        <Tag>
          <span style={{ fontWeight: 600 }}>{record.tag}</span>
        </Tag>
      ),
    },
    {
      title: "Summary",
      dataIndex: "summary",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => {
        let status = <span>-</span>;
        switch (record.status) {
          case "new":
            status = (
              <Tag color="#B7EB8F">
                <span style={{ fontWeight: 600 }}>{record.status}</span>
              </Tag>
            );
            break;
          case "change":
            status = (
              <Tag color="#1890FF">
                <span style={{ fontWeight: 600 }}>{record.status}</span>
              </Tag>
            );
            break;
        }
        return status;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      render: (record) => (
        <Space size="middle">
          <EditAction
            onClick={() => {
              handleSetSchemaOption();
              handleSetTagOption();
              setFiledValue(record);
              setEditingItem(record);
              fetchRouteExternal();
              setSelectTag([record.tag]);
              setAction("update");
              handleOpen();
            }}
          />
          <DeleteAction
            onClick={() => {
              setSelcetItem(record.id);
              setIsModalDeleteOpen(true);
            }}
          />
        </Space>
      ),
    },
  ];

  const schemaColumns = [
    {
      title: "Schema name",
      dataIndex: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Additional Properties",
      dataIndex: "additionalProperties",
      render: (_, record) => (
        <Tag>
          <span
            style={{ fontWeight: 600 }}
          >{`${record.additionalProperties}`}</span>
        </Tag>
      ),
    },
    {
      title: "Nullable",
      dataIndex: "nullable",
      render: (_, record) => (
        <Tag>
          <span style={{ fontWeight: 600 }}>{`${record.nullable}`}</span>
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => {
        let status = <span>-</span>;
        switch (record.status) {
          case "new":
            status = (
              <Tag color="#B7EB8F">
                <span style={{ fontWeight: 600 }}>{record.status}</span>
              </Tag>
            );
            break;
          case "change":
            status = (
              <Tag color="#1890FF">
                <span style={{ fontWeight: 600 }}>{record.status}</span>
              </Tag>
            );
            break;
        }
        return status;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      render: (record) => (
        <Space size="middle">
          <EditAction
            onClick={() => {
              setActionSchema("update");
              setFiledValueSchema(record);
              setEditSchema(record);
              openFormSchema();
            }}
          />
          <DeleteAction
            onClick={() => {
              setEditSchema(record);
              handleCheckSchema(record);
            }}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!initialData) {
      fetchData();
    }
  }, [initialData]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await ReRouteService.getSwaggerDoc();
      // const response = mock;
      setInitialData(response);

      const data = await transformApiResponse(response);
      setItems(data.items);
      setSchemas(data.schemas);
      setTags(data.tags);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchRouteExternal = async () => {
    try {
      const response = await ReRouteService.getRouteExternal();
      // const response = mock2;
      setRouteExternalList(
        response?.routes.map((i) => ({
          path: i.upstreamPathTemplate,
          method: i.upstreamHttpMethod[0],
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const mapSchemaResponses = (schemas, ref) => {
    if (ref) {
      let schema;
      let name = ref.endsWith("/") ? "" : ref.split("/").pop();
      const fliterSchema = schemas.filter((item) => item.name === name);
      if (fliterSchema.length > 0) {
        schema = { id: fliterSchema[0].id, name: fliterSchema[0].name };
      } else {
        schema = { id: "", name: "" };
      }
      return schema;
    } else {
      return { id: "", name: "" };
    }
  };

  const transformApiResponse = async (response) => {
    const data = {
      items: [],
      schemas: [],
      tags: [],
    };

    for (const name in response.components.schemas) {
      const schema = response.components.schemas[name];

      const itme = {
        id: uuidv4(),
        name,
        $ref: name,
        type: schema.type,
        properties: Object.keys(schema.properties || {}).map((name) => ({
          name,
          type: schema.properties[name].type || "",
          format: schema.properties[name].format || "",
          nullable: schema.properties[name].nullable || false,
          additionalProperties:
            schema.properties[name].additionalProperties || false,
        })),
        nullable: schema.nullable || false,
        additionalProperties: schema.additionalProperties || false,
        status: "default",
      };

      data.schemas.push(itme);
    }

    for (const path in response.paths) {
      const methods = response.paths[path];

      for (const method in methods) {
        const details = methods[method];

        const value = details.requestBody?.content["application/json"].schema;
        const extractedValue = value?.$ref.split("/").pop();
        const fliterSchema = extractedValue
          ? data.schemas.filter((item) => item.name === extractedValue)
          : [];
        const schemaRequest =
          fliterSchema.length > 0
            ? { id: fliterSchema[0].id, name: fliterSchema[0].name }
            : { id: "", name: "" };

        const schema = Object.keys(details.responses || {}).map((httpCode) =>
          details.responses[httpCode].content !== null
            ? details.responses[httpCode].content[
                "application/json"
              ]?.schema?.$ref?.endsWith("/")
              ? ""
              : details.responses[httpCode].content[
                  "application/json"
                ]?.schema?.$ref
                  ?.split("/")
                  .pop()
            : ""
        );

        if (extractedValue) {
          schema.push(extractedValue);
        }

        const item = {
          id: uuidv4(),
          path,
          method,
          summary: details.summary || "",
          tag: details.tags[0] || "",
          security: details.security || [],
          requestBody: schemaRequest,
          responses: Object.keys(details.responses || {}).map((httpCode) => ({
            httpCode,
            description: details.responses[httpCode].description || "",
            content: details.responses[httpCode].content || "",
            responsesBody:
              details.responses[httpCode].content !== null
                ? mapSchemaResponses(
                    data.schemas,
                    details.responses[httpCode].content["application/json"]
                      ?.schema?.$ref
                  )
                : { id: "", name: "" },
          })),
          // schemas: [],
          schemas: data.schemas.filter((item) => schema.includes(item.name)),
          parameters: details.parameters || [],
          status: "default",
        };

        data.items.push(item);

        const tag = data.tags.includes(details.tags[0]);
        if (!tag) {
          data.tags.push(details.tags[0]);
        }
      }
    }

    return data;
  };

  const findSchemaByName = (name) => {
    return schemas.find((schema) => schema.name === name) || null;
  };

  const transformDataSave = (data) => {
    const transformData = data.map((item) => {
      const transformItem = {
        Path: item.path,
        Method: item.method,
        Tag: item.tag ? [item.tag] : [],
        Summary: item.summary || "",
        RequestBody: item.requestBody ? item.requestBody.name : "",
        Responses: {},
      };

      item.responses.forEach((response) => {
        transformItem.Responses[response.httpCode] = {
          Description: response.description,
          content: {
            "application/json": {
              schema: {
                $ref: response.responsesBody.name || "",
              },
            },
            "text/json": {
              schema: {
                $ref: response.responsesBody.name || "",
              },
            },
            "application/*+json": {
              schema: {
                $ref: response.responsesBody.name || "",
              },
            },
          },
        };
      });

      transformItem.Schemas = [];

      if (item.requestBody && item.requestBody.name) {
        const requestBodySchema = findSchemaByName(item.requestBody.name);
        if (requestBodySchema) {
          transformItem.Schemas.push({
            Name: requestBodySchema.name,
            $ref: requestBodySchema.$ref,
            Type: requestBodySchema.type,
            Properties: requestBodySchema.properties
              ? requestBodySchema.properties.reduce((props, prop) => {
                  props[prop.name] = {
                    Type: prop.type,
                    Format: prop.format === "none" ? "" : prop.format,
                    Nullable: prop.nullable,
                  };
                  return props;
                }, {})
              : {},
            // AdditionalProperties: requestBodySchema.additionalProperties,
            Nullable: null, // ส่งเป็น null ไปก่อน
            AdditionalProperties: false, // ส่งเป็น false ไปก่อน
          });
        }
      }

      item.responses.forEach((response) => {
        if (response.responsesBody && response.responsesBody.name) {
          const responseBodySchema = findSchemaByName(
            response.responsesBody.name
          );
          if (responseBodySchema) {
            transformItem.Schemas.push({
              Name: responseBodySchema.name,
              $ref: responseBodySchema.$ref,
              Type: responseBodySchema.type,
              Properties: responseBodySchema.properties
                ? responseBodySchema.properties.reduce((props, prop) => {
                    props[prop.name] = {
                      Type: prop.type,
                      Format: prop.format === "none" ? "" : prop.format,
                      Nullable: prop.nullable,
                    };
                    return props;
                  }, {})
                : {},
              // AdditionalProperties: responseBodySchema.additionalProperties,
              Nullable: null, // ส่งเป็น null ไปก่อน
              AdditionalProperties: false, // ส่งเป็น false ไปก่อน
            });
          }
        }
      });

      return transformItem;
    });

    return transformData;
  };

  const onFinish = async (values) => {
    switch (action) {
      case "create":
        await handleAdd(values);
        break;
      case "update":
        await handleEdit(values);
        break;
    }
    handleCancel();
    sessionStorage.setItem("isChanged", true);
  };

  const handleSubmit = async () => {
    const data = transformDataSave(items);

    try {
      const response = await ReRouteService.setSwaggerDoc(data);
      setIsModalSuccessOpen(true);
      setIsChanged(false);
      localStorage.removeItem("isChanged");
      setTimeout(() => {
        fetchData();
        setIsModalSuccessOpen(false);
      }, 2000);
    } catch (error) {
      // console.error();
      setIsModalFailedOpen(true);
    }
  };

  const handleSetSchemaOption = async () => {
    const item = schemas.map((item) => ({
      value: item.name,
      label: item.name,
    }));
    setSchemaOption(item);
  };

  const handleSetTagOption = async () => {
    const item = tags.map((item) => ({ value: item, label: item }));
    setTagOptions(item);
  };

  const handleAdd = async (values) => {
    setItems([...items, { ...values, id: uuidv4(), status: "new" }]);
  };

  const handleEdit = async (values) => {
    setItems(
      items.map((item) =>
        item.id === values.id ? { ...values, status: "change" } : item
      )
    );
  };

  const handleDelete = async (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const handleOpen = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFiledValue();
    setEditingItem(null);
    setAction();
  };

  const openFormSchema = () => {
    setIsShowFormSchema(true);
  };

  const cancelFormSchema = () => {
    setIsShowFormSchema(false);
    setFiledValueSchema();
    setEditSchema(null);
    setActionSchema();
  };

  const onFinishSchema = async (values) => {
    switch (actionSchema) {
      case "create":
        await handleAddSchema(values);
        break;
      case "update":
        await handleEditSchema(values);
        break;
    }
    cancelFormSchema();
    sessionStorage.setItem("isChanged", true);
  };

  const handleAddSchema = async (values) => {
    setSchemas([
      ...schemas,
      {
        ...values,
        id: uuidv4(),
        $ref: values.name,
        additionalProperties: values.additionalProperties
          ? values.additionalProperties
          : false,
        nullable: values.nullable ? values.nullable : false,
        status: "new",
      },
    ]);
  };

  const handleEditSchema = async (values) => {
    setSchemas(
      schemas.map((item) =>
        item.id === values.id
          ? { ...values, $ref: values.name, status: "change" }
          : item
      )
    );

    updateItemSchema(values);
  };

  const handleDeleteSchema = async (id) => {
    setSchemas(schemas.filter((item) => item.id !== id));
    updateItemSchema(null);
  };

  const handleCheckSchema = (record) => {
    const schemaIds = [];

    for (const i in items) {
      if (items[i].requestBody.id) {
        schemaIds.push(items[i].requestBody.id);
      }
      for (const j in items[i].responses) {
        if (items[i].responses[j].responsesBody.id) {
          schemaIds.push(items[i].responses[j].responsesBody.id);
        }
      }
    }

    const isCheck = schemaIds.includes(record.id);
    setSelcetItem(record.id);

    if (isCheck) {
      setIsModalWarningOpen(true);
    } else {
      setIsModalDeleteOpen(true);
    }
  };

  const updateItemSchema = (value) => {
    const updatedItems = items.map((item) => {
      let updatedItem = { ...item };

      if (item?.requestBody?.name === editSchema.name) {
        updatedItem = {
          ...updatedItem,
          requestBody: {
            ...item.requestBody,
            name: value ? value.name : "",
          },
        };
      }

      const updatedResponses = item.responses?.map((response) => {
        if (response?.responsesBody?.name === editSchema.name) {
          return {
            ...response,
            responsesBody: {
              ...response.responsesBody,
              name: value ? value.name : "",
            },
          };
        }
        return response;
      });

      if (updatedResponses) {
        updatedItem = {
          ...updatedItem,
          responses: updatedResponses,
        };
      }

      return updatedItem;
    });

    setItems(updatedItems);

    if (!value) {
      setEditSchema(null);
    }
  };

  const onSearchItem = (value) => {
    setSearchItemText(value); // เก็บข้อความที่ค้นหา
    const filteredData = items?.filter((item) =>
      item.path.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItem(filteredData);
  };

  const onSearchSchema = (value) => {
    setSearchSchemaText(value); // เก็บข้อความที่ค้นหา
    const filteredData = schemas?.filter((schema) =>
      schema.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSchemas(filteredData);
  };

  return (
    <>
      <div className="swagger-doc-body">
        <Col>
          <Row>
            <NavOcelot width={1144} />
          </Row>
          <Row>
            <div className="swagger-doc-container">
              <p className="text-title">Swagger Docs Input</p>
              <div style={{ marginTop: "-40px" }}>
                <Flex justify="space-between" align="center">
                  <p className="text-suptitle">
                    Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                    sit <br />
                    phasellus mollis sit aliquam sit nullam neque ultrices.
                  </p>
                  {/* <CustomButton
                    text="Add new swagger"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      const initialForm = {
                        responses: [{}],
                      };
                      handleSetSchemaOption();
                      handleSetTagOption();
                      setFiledValue(initialForm);
                      setEditingItem(null);
                      setAction("create");
                      fetchRouteExternal();
                      handleOpen();
                    }}
                  /> */}
                </Flex>
              </div>
              <br />
              <Tabs
                type="card"
                onChange={(e) => setKeyTab(e)}
                style={{ marginBottom: "10px" }}
              >
                <TabPane tab="Swagger Route" key="route">
                  <Flex justify="space-between">
                    <Input
                      placeholder="Search"
                      onChange={(e) => onSearchItem(e.target.value)}
                      value={searchItemText}
                      suffix={<SearchOutlined />}
                      style={{ width: 280 }}
                    />
                    <CustomButton
                      text="Add new swagger"
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        const initialForm = {
                          responses: [{}],
                        };
                        handleSetSchemaOption();
                        handleSetTagOption();
                        setFiledValue(initialForm);
                        setEditingItem(null);
                        setAction("create");
                        fetchRouteExternal();
                        handleOpen();
                      }}
                    />
                  </Flex>
                  <Table
                    className="custom-table"
                    columns={routeColumns}
                    // dataSource={items}
                    dataSource={searchItemText ? filteredItem : items}
                    // pagination={false}
                    // onChange={handlePageChange}
                    loading={loading}
                    style={{ marginTop: "20px" }}
                  />
                </TabPane>
                <TabPane tab="Swagger Schema" key="schema">
                  <Flex justify="space-between">
                    <Input
                      placeholder="Search"
                      onChange={(e) => onSearchSchema(e.target.value)}
                      value={searchSchemaText}
                      suffix={<SearchOutlined />}
                      style={{ width: 280 }}
                    />
                    <CustomButton
                      text="Add new schema"
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        const initialForm = {};
                        setFiledValueSchema(initialForm);
                        setEditSchema(null);
                        setActionSchema("create");
                        openFormSchema();
                      }}
                    />
                  </Flex>
                  <Table
                    className="custom-table"
                    columns={schemaColumns}
                    // dataSource={schemas}
                    dataSource={searchSchemaText ? filteredSchemas : schemas}
                    // pagination={false}
                    // // onChange={handlePageChange}
                    loading={loading}
                    // expandable={{
                    //   expandedRowRender: (record) => (
                    //     <>
                    //       <Table className="custom-table-ecpand" />
                    //     </>
                    //   ),
                    // }}
                    style={{ marginTop: "20px" }}
                  />
                </TabPane>
              </Tabs>
            </div>
          </Row>
        </Col>
      </div>
      <FooterOcelot
        // disabled={!isChanged}
        onSave={() => {
          setIsModalOpen(true);
        }}
        onCancel={() => {
          // handleCancel();
        }}
      />

      <SwaggerRouteForm
        open={isModalVisible}
        cancel={handleCancel}
        action={action}
        onSave={onFinish}
        filedValue={filedValue}
        items={items}
        editingItem={editingItem}
        routeExternalList={routeExternalList}
        tags={tags}
        selectTag={selectTag}
        setSelectTag={setSelectTag}
        handleSetTagOption={handleSetTagOption}
        tagOptions={tagOptions}
        schemas={schemas}
        schemaOption={schemaOption}
      />

      <SwaggerSchemaForm
        open={isShowFormSchema}
        cancel={cancelFormSchema}
        filedValue={filedValueSchema}
        onSave={onFinishSchema}
        action={actionSchema}
        schemas={schemas}
        editSchema={editSchema}
      />

      <ConfirmDialog
        isModalOpen={isModalOpen}
        closable={false}
        title="Confirm Save"
        cancelText="Cancel"
        confirmText="Confirm"
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={() => {
          handleSubmit();
        }}
        description="Do you want to Save data ?"
      />

      <WarningDialog
        isModalOpen={isModalWarningOpen}
        closable={false}
        title="Warning currently using !"
        cancelText="Cancel"
        confirmText="Ok"
        onCancel={() => {
          setIsModalWarningOpen(false);
        }}
        onConfirm={() => {
          handleDeleteSchema(selectItem);
        }}
        description="Are you sure to delete ?"
      />

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        title="Success"
        detail="Success save data."
        onCancel={() => {
          setIsModalSuccessOpen(false);
        }}
        onAfterClose={() => {
          // alert();
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title="Failed"
        detail="Something went wrong. Please try again."
        onCancel={() => {
          setIsModalFailedOpen(false);
        }}
        onAfterClose={() => {
          // alert("Dialog dissmiss");
        }}
        onConfirm={() => {
          // handleSubmit();
          setIsModalFailedOpen(false);
        }}
      />

      <DeleteDialog
        isModalOpen={isModalDeleteOpen}
        closable={false}
        title="Confirm Delete"
        cancelText="Cancel"
        confirmText="Delete"
        onCancel={() => {
          setIsModalDeleteOpen(false);
        }}
        onConfirm={() => {
          switch (keyTab) {
            case "route":
              handleDelete(selectItem);
              break;
            case "schema":
              handleDeleteSchema(selectItem);
              break;
          }
          sessionStorage.setItem("isChanged", true);
        }}
        description="Are you sure to delete ?"
      />
    </>
  );
};

export default SwaggerDoc;
