import { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { handleLogin } from "../../keycloak/KeycloakAuth";
import { OAuthService } from "../../services/OAuthService";

const Callback = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const prevCodeRef = useRef();
  const [data, setData] = useState({});

  useEffect(() => {
    if (code) {
      if (prevCodeRef.current !== code) {
        fetchData();
        prevCodeRef.current = code;
      }
    } else {
      // handleLogin();
      navigate("/");
    }
  }, [code]);

  const fetchData = async () => {
    try {
      const isLocalHost = (process.env.REACT_APP_IS_LOCALHOST === "false");
      const response = await OAuthService.codeAuthorization(code, isLocalHost);
      setData(response);

      sessionStorage.setItem("accessToken", response.accessToken);
      sessionStorage.setItem("refreshToken", response.refreshToken);

      //redirect to home page
      navigate("/");
    } catch (error) {
      console.error(error);
      // handleLogin();

      //////
      navigate("/");
    }
  };

  return <></>;
};

export default Callback;
