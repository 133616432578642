import React from "react";
import { Button, Modal, Flex, Space } from "antd";
import IconConfirmSubmit from "../../assets/icon/icon_warning_dialog.js";

const DiscardDialog = ({
  isModalOpen,
  closable,
  title,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  description,
}) => {
  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        footer={null}
        closable={closable}
        centered
        width={400}
        style={{
          borderRadius: "16px",
          overflow: "auto",
        }}
      >
        {/* className="flex flex-col justify-center items-center" */}
        <Flex justify="center" align="center" vertical>
          <IconConfirmSubmit />
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: 20,
            }}
          >
            {title}
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 0,
              color: "#475467",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            {description || (
              <>
                การเปลี่ยนแปลงนี้จะไม่ถูกบันทึก <br />
                คุณต้องการดำเนินการต่อหรือไม่
              </>
            )}
          </span>
          <Space style={{ marginTop: 20 }}>
            <Button
              type="primary"
              // ghost={true}
              style={{
                width: 135,
                height: 40,
                backgroundColor: "#63E1E1",
                color: "#2E323A",
                fontWeight: 500,
                fontSize: 16,
              }}
              onClick={() => {
                onCancel();
              }}
            >
              {cancelText}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onCancel();
                onConfirm();
              }}
              style={{
                width: 135,
                height: 40,
                // border: "1px solid #D0D5DD",
                color: "#2E323A",
                backgroundColor: "#EBEBEB",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              {confirmText}
            </Button>
          </Space>
        </Flex>
      </Modal>
    </>
  );
};

export default DiscardDialog;
