import React from "react";
import { Button, Modal, Flex, notification } from "antd";
import IconSuccessDialog from "../../assets/icon/icon_success_dialog";

const SuccessNotification = (
  title,
  detail,
  api
) => {

  api.success({
    message: <div style={{ fontWeight:600 }}>{title}</div>,
    description: detail,
    placement: "top",
    // icon: (<IconSuccessDialog/>)
  });
};

export default SuccessNotification;
