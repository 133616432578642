import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
  >
    <rect width="78" height="78" rx="20" fill="#EFF6FD" />
    <path
      d="M16.5 28.5C16.5 24.2996 16.5 22.1994 17.3175 20.5951C18.0365 19.1839 19.1839 18.0365 20.5951 17.3175C22.1994 16.5 24.2996 16.5 28.5 16.5H45.6863C46.9092 16.5 47.5207 16.5 48.0962 16.6382C48.6063 16.7606 49.0941 16.9627 49.5414 17.2368C50.046 17.546 50.4784 17.9784 51.3431 18.8431L59.1569 26.6569C60.0216 27.5216 60.454 27.954 60.7632 28.4586C61.0373 28.9059 61.2394 29.3937 61.3619 29.9038C61.5 30.4793 61.5 31.0908 61.5 32.3137V49.5C61.5 53.7004 61.5 55.8006 60.6825 57.4049C59.9635 58.8161 58.8161 59.9635 57.4049 60.6825C55.8006 61.5 53.7004 61.5 49.5 61.5H28.5C24.2996 61.5 22.1994 61.5 20.5951 60.6825C19.1839 59.9635 18.0365 58.8161 17.3175 57.4049C16.5 55.8006 16.5 53.7004 16.5 49.5V28.5Z"
      fill="#1677FF"
    />
    <path
      d="M26.5 16.5V25C26.5 26.4001 26.5 27.1002 26.7725 27.635C27.0122 28.1054 27.3946 28.4878 27.865 28.7275C28.3998 29 29.0999 29 30.5 29H47.5C48.9001 29 49.6002 29 50.135 28.7275C50.6054 28.4878 50.9878 28.1054 51.2275 27.635C51.5 27.1002 51.5 26.4001 51.5 25V19M51.5 61.5V45.5C51.5 44.0999 51.5 43.3998 51.2275 42.865C50.9878 42.3946 50.6054 42.0122 50.135 41.7725C49.6002 41.5 48.9001 41.5 47.5 41.5H30.5C29.0999 41.5 28.3998 41.5 27.865 41.7725C27.3946 42.0122 27.0122 42.3946 26.7725 42.865C26.5 43.3998 26.5 44.0999 26.5 45.5V61.5M61.5 32.3137V49.5C61.5 53.7004 61.5 55.8006 60.6825 57.4049C59.9635 58.8161 58.8161 59.9635 57.4049 60.6825C55.8006 61.5 53.7004 61.5 49.5 61.5H28.5C24.2996 61.5 22.1994 61.5 20.5951 60.6825C19.1839 59.9635 18.0365 58.8161 17.3175 57.4049C16.5 55.8006 16.5 53.7004 16.5 49.5V28.5C16.5 24.2996 16.5 22.1994 17.3175 20.5951C18.0365 19.1839 19.1839 18.0365 20.5951 17.3175C22.1994 16.5 24.2996 16.5 28.5 16.5H45.6863C46.9092 16.5 47.5207 16.5 48.0962 16.6382C48.6063 16.7606 49.0941 16.9627 49.5414 17.2368C50.046 17.546 50.4784 17.9784 51.3431 18.8431L59.1569 26.6569C60.0216 27.5216 60.454 27.954 60.7632 28.4586C61.0373 28.9059 61.2394 29.3937 61.3619 29.9038C61.5 30.4793 61.5 31.0908 61.5 32.3137Z"
      stroke="#EFF6FD"
      stroke-width="3.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Icon_Save_Blue = (props) => <Icon component={icon} {...props} />;

export default Icon_Save_Blue;
