import { ocelotConfigApi } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const ReRouteService = {   
    // Global Config 
    getGlobalConfig: async function (params, cancel = false) {
        const response = await ocelotConfigApi.request({
          url: `/re-routes/globalConfig`,
          method: "GET",
          params: params,
          signal: cancel ? cancelApiObject[this.getGlobalConfig.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    setGlobalConfig: async function (data, cancel = false) {
        await ocelotConfigApi.request({
            url: `/re-routes/globalConfig`,
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.setGlobalConfig.name].handleRequestCancellation().signal : undefined,
        });
    },

    // Authen Provider
    getAuthenProvider: async function (params, cancel = false) {
        const response = await ocelotConfigApi.request({
          url: `/re-routes/authenProvider`,
          method: "GET",
          params: params,
          signal: cancel ? cancelApiObject[this.getAuthenProvider.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    setAuthenProvider: async function (data, cancel = false) {
        await ocelotConfigApi.request({
            url: `/re-routes/authenProvider`,
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.setAuthenProvider.name].handleRequestCancellation().signal : undefined,
        });
    },

    // Route
    getRoute: async function (params, cancel = false) {
        const response = await ocelotConfigApi.request({
          url: `/re-routes/route`,
          method: "GET",
          params: params,
          signal: cancel ? cancelApiObject[this.getRoute.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    setRoute: async function (data, cancel = false) {
        await ocelotConfigApi.request({
            url: `/re-routes/route`,
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.setRoute.name].handleRequestCancellation().signal : undefined,
        });
    },

    // role Reta Limit
    getroleRetaLimit: async function (params, cancel = false) {
        const response = await ocelotConfigApi.request({
          url: `/re-routes/roleRateLimit`,
          method: "GET",
          params: params,
          signal: cancel ? cancelApiObject[this.getroleRetaLimit.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    setroleRetaLimit: async function (data, cancel = false) {
        await ocelotConfigApi.request({
            url: `/re-routes/roleRateLimit`,
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.setroleRetaLimit.name].handleRequestCancellation().signal : undefined,
        });
    },

    // Generate Schema
    generateSchema: async function (data, cancel = false) {
        const response = await ocelotConfigApi.request({
            url: `/re-routes/generateSchema`,
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.generateSchema.name].handleRequestCancellation().signal : undefined,
        });

        return response.data;
    },

    // realm role
    getRealmRole: async function (params, cancel = false) {
        const response = await ocelotConfigApi.request({
          url: `/re-routes/realm-role`,
          method: "GET",
          params: params,
          signal: cancel ? cancelApiObject[this.getRealmRole.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    // Route External
    getRouteExternal: async function (params, cancel = false) {
        const response = await ocelotConfigApi.request({
          url: `/re-routes/route-external`,
          method: "GET",
          params: params,
          signal: cancel ? cancelApiObject[this.getRouteExternal.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    // swagger doc
    getSwaggerDoc: async function (cancel = false) {
        const response = await ocelotConfigApi.request({
          url: `/read`,
          method: "GET",
          signal: cancel ? cancelApiObject[this.getSwaggerDoc.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    setSwaggerDoc: async function (data, cancel = false) {
        await ocelotConfigApi.request({
            url: `/write`,
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.setSwaggerDoc.name].handleRequestCancellation().signal : undefined,
        });
    },
};

const cancelApiObject = defineCancelApiObject(ReRouteService);