import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./helpers/PrivateRoute";
import Callback from "./pages/OAuth/Callback";
import NotFound from "./pages/NotFound/NotFound";
import NetworkError from "./pages/NetworkError/NetWorkError";

// Main
import Layout from "./pages/Main/Layout/Layout";
import Home from "./pages/Main/Home/Home";
import About from "./pages/Main/About/About";
import Dashboard from "./pages/Main/Dashboard/Dashboard";
import InsightReport from "./pages/Main/InsightReport/InsightReport";
import Contact from "./pages/Main/Contact/Contact";
// import UserList from "./pages/Main/User/UserList";
// import UserDetail from "./pages/Main/User/UserDetail";
import TagPage from "./pages/Main/Tag/tag";

// Management
import ManagementLayout from "./pages/Main/Layout/ManagementLayout";
import AccountManagement from "./pages/Main/Management/User/AccountManagement/AccountManagement";
import RoleManagement from "./pages/Main/Management/Admin/RoleManagement/RoleManagement";
import DashboardManagement from "./pages/Main/Management/Admin/DashboardManagement/DashboardManagement";
import DashboardManagementDetail from "./pages/Main/Management/Admin/DashboardManagement/DashboardManagementDetail";
import InsightReportManagementNav from "./pages/Main/Management/Admin/InsightReportManagement/InsightReportManagementNav";
import InsightReportManagementDetail from "./pages/Main/Management/Admin/InsightReportManagement/InsightReportManagementDetail";
import DataSourceManagement from "./pages/Main/Management/Admin/DataSourceManagement/DataSourceManagement";
import DataSourceManagementDetail from "./pages/Main/Management/Admin/DataSourceManagement/DataSourceManagementDetail";

// Ocelot-Config
import GlobalConfigure from "./pages/OcelotConfigure/GlobalConfigure/GlobalConfigure";
import AuthenticationProvidersList from "./pages/OcelotConfigure/AuthenticationProviderOcelot/AuthenticationProvidersList";
import RoutesOcelot from "./pages/OcelotConfigure/RoutesOcelot/RoutesOcelot";
import SwaggerDoc from "./pages/OcelotConfigure/SwaggerDoc/SwaggerDoc";

import Template from "./pages/Template/Template";
import LoginPage from "./pages/Main/Login/login";
import RegisterPage from "./pages/Main/register/register";
import ForgotPage from "./pages/Main/forgot/forgot";
import VerifyEmailPage from "./pages/Main/register/verify_email/verify_email";
import VerifyEmailTimeoutPage from "./pages/Main/register/verify_timeout/verify_timeout";
import EditPasswordPage from "./pages/Main/EditPassword/edit_password";
import RegisterGooglePage from "./pages/Main/register/regisgoogle/regis_google";
import DetailDashboard from "./pages/Main/Dashboard/DetailDashBoard/DetailDashBoard";
import InsightReportPage from "./pages/Main/InsightReport/InsightReportDetail/InsightReportDetail";
import ChangePassword from "./pages/Main/Management/User/ChangePassword/ChangePassword";
import ManageUserPage from "./pages/Main/MenageUser/ManageUser";
import ManageUserDetail from "./pages/Main/ManageUserDetail/ManageUserDetail";
import DataCatalog from "./pages/Main/DataCatalog/DataCatalog";
import DataCatalogDetail from "./pages/Main/DataCatalog/DataCatalogDetail/DataCatalogDetail";
import TradeAssociation from "./pages/Main/TradeAssociation/TradeAssociation";
import MangeConsent from "./pages/Main/MangeConsent/mangeconsent";
import MangeConsentDetail from "./pages/Main/MangeConsent/manageconsent-detail/mangeconsent-detail";
import FeedbackPage from "./pages/Main/Feedback/feedback";

function App() {
  sessionStorage.removeItem("isChanged");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/oauth/callback" element={<Callback />} />
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/network-error" element={<NetworkError />}></Route>

        <Route path="/template" element={<Template />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot" element={<ForgotPage />} />
        <Route path="/verifyemail" element={<VerifyEmailPage />} />
        <Route
          path="/verifyemailtimeout"
          element={<VerifyEmailTimeoutPage />}
        />
        <Route path="/editpassword" element={<EditPasswordPage />} />
        <Route path="/registergoogle" element={<RegisterGooglePage />} />

        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="dashboard"
            element={
              // <PrivateRoute>
              <Dashboard />
              // </PrivateRoute>
            }
          />
          <Route
            path="dashboard/:title"
            element={
              // <PrivateRoute>
              <DetailDashboard />
              // </PrivateRoute>
            }
          />
          <Route
            path="insight-report"
            element={
              // <PrivateRoute>
              <InsightReport />
              // </PrivateRoute>
            }
          />
          <Route
            path="data-catalog"
            element={
              // <PrivateRoute>
              <DataCatalog />
              // </PrivateRoute>
            }
          />
          <Route
            path="data-catalog/:title"
            element={
              // <PrivateRoute>
              <DataCatalogDetail />
              // </PrivateRoute>
            }
          />
          <Route
            path="insight-report/:title"
            element={
              // <PrivateRoute>
              <InsightReportPage />
              // </PrivateRoute>
            }
          />
          <Route
            path="trade-association"
            element={
              // <PrivateRoute>
              <TradeAssociation />
              // </PrivateRoute>
            }
          />
          <Route
            path="api-management/global-configuration"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <GlobalConfigure />
              </PrivateRoute>
            }
          />
          <Route
            path="api-management/authentication-providers"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <AuthenticationProvidersList />
              </PrivateRoute>
            }
          />
          <Route
            path="api-management/routes"
            element={
              // <PrivateRoute allowedRoles={["portal_admin"]}>
              <RoutesOcelot />
              // </PrivateRoute>
            }
          />
          <Route
            path="api-management/swagger-docs"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <SwaggerDoc />
              </PrivateRoute>
            }
          />

          <Route path="management" element={<ManagementLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                <AccountManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="account-management"
              element={
                <PrivateRoute>
                <AccountManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="change-password"
              element={
                <PrivateRoute>
                <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="role-management"
              element={
                <PrivateRoute>
                <RoleManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="dashboard-management"
              element={
                <PrivateRoute>
                <DashboardManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="dashboard-management-detail"
              element={
                <PrivateRoute>
                <DashboardManagementDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="insight-report-management"
              element={
                <PrivateRoute>
                <InsightReportManagementNav />
                </PrivateRoute>
              }
            />
            <Route
              path="insight-report-management-detail"
              element={
                <PrivateRoute>
                <InsightReportManagementDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="datasource-management"
              element={
                <PrivateRoute>
                <DataSourceManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="datasource-management-detail"
              element={
                <PrivateRoute>
                <DataSourceManagementDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="edit-tag"
              element={
                <PrivateRoute>
                <TagPage />
                </PrivateRoute>
              }
            />
            <Route
              path="manage-user"
              element={
                <PrivateRoute>
                <ManageUserPage />
                </PrivateRoute>
              }
            />
            <Route
              path="manage-user-detail"
              element={
                <PrivateRoute>
                <ManageUserDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="manage-consent"
              element={
                <PrivateRoute>
                <MangeConsent />
                </PrivateRoute>
              }
            />
            <Route
              path="manage-consent-add"
              element={
                <PrivateRoute>
                <MangeConsentDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="feed-back"
              element={
                <PrivateRoute>
                <FeedbackPage />
                </PrivateRoute>
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
