import React, { useState, useEffect } from "react";
import { Layout, Flex, DatePicker } from "antd";
import { Column } from '@ant-design/plots';
import { InsightReportService } from "../../../../../services/InsightReportService.js";
import "./InsightReportDownloadAnalysis.css";
import dayjs from "dayjs";
import { max } from "lodash";


const InsightReportDownloadAnalysis = () => {
   
  const ChartColumn = () => {
    const config = {
      data: data,
      xField: 'insightReportName',
      yField: 'count',
      colorField: 'insightReportName', // กำหนดให้แต่ละแท่งมีสีที่แตกต่างกัน
      height: window.screen.height - 500, // ความสูงของกราฟ
      autoFit: true,
      padding: 'auto',
      style: {
        
        inset: 5,
        maxWidth: 80,
        // 矩形单个方向的内边距
        // insetLeft:5,
        // insetRight:20,
        // insetBottom:10
        // insetTop:10
      },
      label: {
        position: 'top', // ตำแหน่งของ Label
        style: {
          fill: '#000', // สีของตัวอักษร
          opacity: 0.7,
        },
        text: (d) => `${(d.count)} times`,
        textBaseline: 'bottom',
      },
      // tooltip: {
      //   showMarkers: false, // ซ่อน Marker ใน Tooltip
      //   shared: true, // ให้ Tooltip ใช้ร่วมกัน
      // },
      columnStyle: {
        radius: [5, 5, 0, 0], // ปรับขอบแท่งให้โค้งมน
      },
      xAxis: {
        label: {
          
          autoHide: true,
          autoRotate: true,
          style: {
            fontSize: 14,
          },
        },
      },
      
      yAxis: {
        style: {
          lineWidth: 2,
        },
        label: {
          formatter: (value) => `${value}`, // กำหนดรูปแบบตัวเลขในแกน Y
        },
      },
      legend: {
        position: 'top', // ตำแหน่งของ Legend
      },
    };
   
    return <Column 
    {...config} />;
  };
  

  const { Header } = Layout;
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  
  useEffect(() => {
      fetchData();
  }, [selectedDateRange]);

  const fetchData = async () => {
    try {
      if(selectedDateRange.length == 2){
        const data = await InsightReportService.downloadedStats(selectedDateRange[0], selectedDateRange[1]);
        setData(data);
      }else{
        const data = await InsightReportService.downloadedStats();
        setData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChangeDate = (dates) => {
    let formattedDates = []
    if(dates != null){
      formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));
    }
    setSelectedDateRange(formattedDates)
  };

  
  
  return (
    <>
    <Header
      style={{
        height: "70px",
        background: "#FFF",
        borderBottom: "1px solid #D0D5DD",
      }}
    >
      <Flex justify="space-between" align="center" style={{ height: "100%" }}>
        <p style={{ margin: "0", fontSize: "18px", fontWeight: "600" }}>
          ข้อมูลดาวน์โหลดรายงานวิเคราะห์ 
        </p>
        <div>
        <RangePicker 
          placeholder="DD/MM/YYYY"
          inputReadOnly={true}
          
          format={"DD/MM/YYYY"}
          onChange={handleChangeDate}
          className="date-range"
          value={selectedDateRange.length > 0 ? [dayjs(selectedDateRange[0]), dayjs(selectedDateRange[1])] : null}
        />
        </div>
      </Flex>

    </Header>
    <Layout style={{ height: "calc(100vh - 260px)" }}>
      <div className="content-card-insight-report-download">
      <ChartColumn 
      />
      </div>
   
    </Layout>
    </>
  );
};

export default InsightReportDownloadAnalysis;
