import React, { useState, useEffect, useContext } from "react";
import "./DataSourceManagement.css";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Modal,
  Tooltip,
  Button,
  Divider,
  Flex,
  Space,
} from "antd";
import {
  PlusOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ReRouteService } from "../../../../../services/ReRouteService";
import { AppContext } from "./DataSourceManagementDetail";

const { Option } = Select;

const DataSourceFormRouteConfig = ({
  isModalVisible,
  handleCancelModal,
  item,
}) => {
  const { setDetailList, setIsChanged } = useContext(AppContext);

  const [form] = Form.useForm();
  const [authenOption, setAuthenOption] = useState([]);

  useEffect(() => {
    if (isModalVisible) {
      handleSetFieldsValue(item);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (!authenOption.length) {
      fetchAuthenOption();
    }
  }, [authenOption]);

  const fetchAuthenOption = async (selectedKey) => {
    try {
      const response = await ReRouteService.getRealmRole({
        realm: selectedKey,
      });
      setAuthenOption(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSetFieldsValue = (item) => {
    if (item.routeConfigJson) {
      const routeValue = JSON.parse(item.routeConfigJson);
      form.setFieldsValue({
        ...routeValue,
        upstreamHttpMethod: routeValue.upstreamHttpMethod[0],
      });
    }
  };

  const handleCancel = () => {
    handleCancelModal();
    setTimeout(() => {
      form.resetFields();
    }, 200);
  };

  const onFinish = (values) => {
    const routeConfigData = {
      routeName: values.routeName,
      routeType: "External",
      upstreamPathTemplate: values.upstreamPathTemplate,
      upstreamHttpMethod: [values.upstreamHttpMethod],
      downstreamScheme: values.downstreamScheme,
      downstreamHostAndPorts: values.downstreamHostAndPorts,
      downstreamPathTemplate: values.downstreamPathTemplate,
    };

    const routeConfigJsonData = JSON.stringify(routeConfigData);

    setDetailList((prev) =>
      prev.map((detail) => {
        if (detail.id === item.id) {
          return {
            ...detail,
            upstreamPath: values.upstreamPathTemplate,
            upstreamHttpMethod: values.upstreamHttpMethod,
            routeConfigJson: routeConfigJsonData,
          };
        }
        return detail;
      })
    );

    handleCancel();
  };

  const customLabel = (name, TooltipTitle) => {
    return (
      <div style={{ padding: "" }}>
        <span
          style={{ fontWeight: "bold", color: "#1D273C", marginRight: "5px" }}
        >
          {name}&nbsp;
        </span>
        <span style={{ fontWeight: "bold", color: "red" }}>*</span>
        {TooltipTitle && (
          <Tooltip title={TooltipTitle}>
            <InfoCircleOutlined style={{ color: "#0063A7", marginLeft: 8 }} />
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <Modal
      title={
        <span style={{ color: "#2E323A", fontSize: "18px", fontWeight: "600" }}>
          ตั้งค่า Route
        </span>
      }
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      width={1000}
      zIndex={2000}
    >
      <Divider
        style={{
          borderColor: "#d9d9d9",
        }}
      />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          downstreamHostAndPorts: [{}],
        }}
        onValuesChange={(changedValues, allValues) => {
          setIsChanged(true);
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={customLabel("Route Name")}
              name="routeName"
              rules={[
                {
                  required: true,
                  message: "Please input route name!",
                },
              ]}
              required={false}
            >
              <Input placeholder="Route name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={customLabel("Upstream Path", "This is a required field")}
              name="upstreamPathTemplate"
              rules={[
                { required: true, message: "Please input upstream path!" },
                {
                  pattern: /^\/[a-zA-Z0-9/_-]+$/,
                  message: "Please enter a valid path!",
                },
              ]}
              required={false}
            >
              <Input placeholder="/xxxx/xxx/xxxxxx" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={customLabel(
                "Upstream Http Method",
                "This is a required field"
              )}
              name="upstreamHttpMethod"
              rules={[
                { required: true, message: "Please select HTTP method!" },
              ]}
              required={false}
            >
              <Select
                // mode="multiple"
                placeholder="Select HTTP Method"
                allowClear
              >
                <Option value="GET">GET</Option>
                <Option value="POST">POST</Option>
                <Option value="PUT">PUT</Option>
                <Option value="DELETE">DELETE</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={customLabel(
                "Downstream Scheme",
                "This is a required field"
              )}
              name="downstreamScheme"
              rules={[{ required: true, message: "Please select scheme!" }]}
              required={false}
            >
              <Select placeholder="Select Scheme" allowClear>
                <Option value="http">HTTP</Option>
                <Option value="https">HTTPS</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div
          style={{
            padding: "8px",
            background: "#F8F8F9",
            borderRadius: "8px",
          }}
        >
          <Form.List name="downstreamHostAndPorts">
            {(fields, { add, remove }) => (
              <>
                <Flex justify="space-between">
                  <span
                    style={{
                      color: "#2E323A",
                      fontSize: "18px",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                  >
                    Downstream Host and Post
                  </span>
                  <Button
                    type="primary"
                    icon={
                      <PlusOutlined
                        style={{ color: "#2E323A", fontSize: "14px" }}
                      />
                    }
                    onClick={() => add()}
                    style={{
                      height: "40px",
                      color: "#2E323A",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    เพิ่ม
                  </Button>
                </Flex>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={16}>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        name={[name, "host"]}
                        label={customLabel("Host", "This field is required.")}
                        rules={[
                          {
                            required: true,
                            message: "Please select host!",
                          },
                        ]}
                        required={false}
                      >
                        <Input placeholder="Host" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        name={[name, "port"]}
                        label={customLabel("Post", "This field is required.")}
                        rules={[
                          {
                            required: true,
                            message: "Please select port!",
                          },
                          {
                            pattern: /^\d+$/,
                            message: "Please enter a valid port number!",
                          },
                        ]}
                        required={false}
                      >
                        <Input placeholder="Port" />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      {fields.length > 1 && (
                        <Form.Item label={<></>}>
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              remove(name);
                            }}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </div>

        <br />
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={customLabel("Downstream Path", "This is a required field")}
              name="downstreamPathTemplate"
              rules={[
                { required: true, message: "Please input Downstream Path" },
                {
                  pattern: /^\/[a-zA-Z0-9/_-]+$/,
                  message: "Please enter a valid path!",
                },
              ]}
              required={false}
            >
              <Input placeholder="Downstream Path" />
            </Form.Item>
          </Col>
        </Row>
        <Divider
          style={{
            borderColor: "#d9d9d9",
          }}
        />



        <br />
        <div style={{ textAlign: "right" }}>
          <Space>
            <Button
              onClick={() => {
                handleCancel();
              }}
              style={{ width: "140px", height: "40px", borderRadius: "10px" }}
            >
              ยกเลิก
            </Button>
            <Button
              type="primary"
              onClick={() => form.submit()}
              style={{ width: "140px", height: "40px", borderRadius: "10px" }}
            >
              บันทึก
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default DataSourceFormRouteConfig;
