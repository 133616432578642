import React from "react";
import { Button, Tooltip } from "antd";
import { AiOutlineDelete } from "react-icons/ai";

const DeleteButton = ({ onClick }) => {
  return (
    <Tooltip title="ลบบัญชี">
      <Button
        className="custom-button-action"
        icon={
          <AiOutlineDelete
            style={{
              fontSize: "20px",
            }}
          />
        }
        onClick={onClick}
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "8px",
        }}
      />
    </Tooltip>
  );
};

export default DeleteButton;
