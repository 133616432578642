import React from "react";
import { Button, Modal, Flex } from "antd";
import IconFailedDialog from "../../assets/icon/icon_failed_dialog";

const FailedDialog = ({
  isModalOpen,
  closable,
  title,
  detail,
  onCancel,
  onAfterClose,
  onConfirm,
}) => {
  return (
    <>
      <Modal
        centered
        title=""
        open={isModalOpen}
        footer={null}
        closable={closable}
        destroyOnClose={true}
        onCancel={() => {
          onCancel();
        }}
        afterClose={(e) => {
          onAfterClose();
        }}
        width={400}
        style={{
          borderRadius: "16px",
          overflow: "auto",
        }}
      >
        <Flex justify="center" align="center" vertical>
          <IconFailedDialog
            style={
              {
                marginTop: 10,
              }
            }
          />
          <span
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 20,
            }}
          >
            {title}
          </span>
          <span
            style={{
              fontWeight: 400,
              fontSize: 16,
              marginTop: 8,
              color: "#344054B2",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            {detail}
          </span>
          <Button
            type="primary"
            onClick={() => {
              onCancel();
              onConfirm();
            }}
            style={{
              width: 115,
              height: 40,
              marginTop: 20,
              backgroundColor: "#FF4D4F",
              color: "#ffffff",
            }}
          >
            ลองอีกครั้ง
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default FailedDialog;
