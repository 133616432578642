import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
import "./AuthenticationProvidersList.css";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import {
  Card,
  Button,
  Table,
  Space,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Tooltip,
  message,
} from "antd";
import { ReRouteService } from "../../../services/ReRouteService";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../components/Dialog/FailedDialog";
import NavOcelot from "../../../components/Nav/NavOcelot";
import FooterOcelot from "../../../components/Footer/FooterOcelot";

const AuthenticationProvidersList = () => {
  const testData = [
    {
      key: "PORTAL_REALM",
      metadataAddress: "http://keycloak-ccs-service.com",
      authority: "http://keycloak-ccs-service.com",
      audience: "Portal - client",
      status: "-",
    },
    {
      key: "API_REALM",
      metadataAddress: "http://keycloak-ccs-service.com",
      authority: "http://keycloak-ccs-service.com",
      audience: "api - client",
      status: "-",
    },
  ];

  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Metadata Address",
      dataIndex: "metadataAddress",
      key: "metadataAddress",
    },
    {
      title: "Authority",
      dataIndex: "authority",
      key: "authority",
    },
    {
      title: "Audience",
      dataIndex: "audience",
      key: "audience",
    },
    // {
    //   title: 'Status',
    //   key: 'status',
    //   render: (text) => (
    //     <Button
    //       type="primary"
    //       style={{
    //         backgroundColor: '#F6FFED',
    //         borderColor: '#B7EB8F',
    //         color: '#52C41A',
    //       }}
    //     >
    //       New
    //     </Button>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            style={{
              border: "1px solid #0063A7",
              color: "#0063A7",
            }}
            onClick={() => {
              setCurrentItem(record);
              //console.log(record);
              form.setFieldsValue({
                id: record.id,
                key: record.key,
                metadataAddress: record.metadataAddress,
                authority: record.authority,
                audience: record.audience,
              });
              setDisabledSave(false);
              handleAdd();
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            style={{
              border: "1px solid #0063A7",
              color: "#0063A7",
            }}
            onClick={() => {
              setCurrentItem(record);
              setIsModalDeleteOpen(true);
            }}
          />
        </Space>
      ),
    },
  ];

  const onFinish = (values) => {
    // if(){}
    sessionStorage.setItem("isChanged", true);
    if (currentItem === null) {
      values.id = uuidv4();
      setData((oldArray) => [...oldArray, values]);
    } else {
      let updatedList = data.map((item) => {
        if (item.id == values.id) {
          return {
            ...item,
            key: values.key,
            metadataAddress: values.metadataAddress,
            authority: values.authority,
            audience: values.audience,
          }; //gets everything that was already in item, and updates "done"
        }
        return item; // else return unmodified item
      });
      setData(updatedList);
    }

    handleCancel();
    //console.log("Form values:", values); // ค่าของฟอร์มจะถูกพิมพ์ออกมาที่นี่
  };

  const handleAdd = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };

  const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await ReRouteService.getAuthenProvider();
      let updatedList = response.authenticationProviders.map((item) => {
        if (item.id === undefined) {
          return {
            ...item,
            id: uuidv4(),
          }; //gets everything that was already in item, and updates "done"
        }
        return item; // else return unmodified item
      });
      setData(updatedList);
      //console.log("Data list : ", updatedList);
      //console.log(response);
    } catch (error) {
      // console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    let request = { authenticationProviders: data };
    //console.log("Request:", request); // ค่าของฟอร์มจะถูกพิมพ์ออกมาที่นี่

    try {
      const response = await ReRouteService.setAuthenProvider(request);
      sessionStorage.removeItem("isChanged")
      setIsModalSuccessOpen(true);
      // setIsChanged(false);
      setTimeout(() => {
        fetchData();
        setIsModalSuccessOpen(false);
      }, 2000);
    } catch (erro) {
      // console.error();
      setIsModalFailedOpen(true);
    }
  };

  return (
    <>
      <ConfirmDialog
        isModalOpen={isModalOpen}
        closable={false}
        title="Confirm Save"
        cancelText="Cancel"
        confirmText="Confirm"
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={() => {
          handleSubmit();
        }}
        description="Do you want to Save data ?"
      />

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        title="Success"
        detail="Success save data."
        onCancel={() => {
          setIsModalSuccessOpen(false);
        }}
        onAfterClose={() => {
          // alert();
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title="Failed"
        detail="Something went wrong. Please try again."
        onCancel={() => {
          setIsModalFailedOpen(false);
        }}
        onAfterClose={() => {
          // alert("Dialog dissmiss");
        }}
        onConfirm={() => {
          // handleSubmit();
          setIsModalFailedOpen(false);
        }}
      />

      <div className="body-template">
        <Col>
          <Row>
            <NavOcelot width={1144} />
          </Row>
          <Row>
            <Card style={{ width: "1144px" }}>
              <h1>Authentication Providers</h1>
              <div className="button-group">
                <div className="text-container">
                  <h4>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit.
                    Mattis sit <br /> Phasellus mollis sit aliquam sit nullam
                    neque ultrices.
                  </h4>
                </div>

                <Button
                  className="add-button"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setCurrentItem(null);
                    setDisabledSave(true);
                    form.setFieldsValue({
                      key: "",
                      metadataAddress: "",
                      authority: "",
                      audience: "",
                    });
                    handleAdd();
                  }}
                >
                  Add Authentication Providers
                </Button>
              </div>
              <br />
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={loading}
                className="custom-table"
              />
            </Card>
          </Row>
        </Col>
      </div>
      <FooterOcelot
        // disabled={!isChanged}
        onSave={() => {
          setIsModalOpen(true);
        }}
        onCancel={() => {
          // handleCancel();
        }}
      />

      <Modal
        title={
          <span
            style={{ color: "#0063A7", fontSize: "18px", fontWeight: "bold" }}
          >
            {currentItem ? "Edit" : "Add"} Authentication Providers
          </span>
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 20 }} // ปรับตำแหน่ง Modal
        bodyStyle={{ padding: "3px" }} // ปรับพื้นที่ภายในของ Modal
        width={1000} // ปรับขนาดความกว้างของ Modal
      >
        <hr />
        <br />
        <Form
          form={form} // เชื่อมต่อกับฟอร์ม
          onFinish={onFinish} // จัดการการส่งฟอร์ม
          onFinishFailed={onFinishFailed}
          layout="vertical" // การจัดเลย์เอาต์ของฟอร์ม
          onFieldsChange={handleFormChange}
        >
          <Form.Item
            style={{ display: "none" }}
            className="custom-form-item"
            label={
              <span>
                Id&nbsp; <span style={{ color: "red" }}>*</span>
              </span>
            }
            name="id"
            required={false}
          >
            <Input placeholder="Id" />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                label={
                  <span>
                    Key&nbsp; <span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="key"
                rules={[{ required: true, message: "Please input key !" }]}
                required={false}
              >
                <Input placeholder="Key" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    Metadata Address&nbsp;{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Tooltip title="This is a required field">
                      <InfoCircleOutlined
                        style={{ color: "#0063A7", marginLeft: 8 }}
                      />
                    </Tooltip>
                  </span>
                }
                name="metadataAddress"
                rules={[
                  {
                    required: true,
                    message: "Please input Metadata Address!",
                  },
                  {
                    type: "url",
                    warningOnly: false,
                  },
                ]}
                required={false}
              >
                <Input placeholder="http://xxx:0000.com" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                label={
                  <span>
                    Authority&nbsp; <span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="authority"
                rules={[
                  { required: true, message: "Please input Authority !" },
                  {
                    type: "url",
                    warningOnly: false,
                  },
                ]}
                required={false}
              >
                <Input placeholder="http://xxx:0000.com" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    Audience&nbsp; <span style={{ color: "red" }}>*</span>
                    <Tooltip title="This is a required field">
                      <InfoCircleOutlined
                        style={{ color: "#0063A7", marginLeft: 8 }}
                      />
                    </Tooltip>
                  </span>
                }
                name="audience"
                rules={[{ required: true, message: "Please input Audience!" }]}
                required={false}
              >
                <Input placeholder="Audience" />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ textAlign: "right" }}>
            <Button onClick={handleCancel} style={{ marginRight: "8px" }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" disabled={disabledSave}>
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      <DeleteDialog
        isModalOpen={isModalDeleteOpen}
        closable={false}
        title="Confirm Delete"
        cancelText="Cancel"
        confirmText="Delete"
        onCancel={() => {
          setIsModalDeleteOpen(false);
        }}
        onConfirm={() => {
          setData((l) => l.filter((item) => item.key !== currentItem.key));
          sessionStorage.setItem("isChanged", true);
        }}
        description="Are you sure to delete ?"
      />
    </>
  );
};

export default AuthenticationProvidersList;
