import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import EN from "../locales/en.json";
import TH from "../locales/th.json";

const resources = {
  en: {
    translation: EN,
  },
  th: {
    translation: TH,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    // fallbackLng: "en",
    // lng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
