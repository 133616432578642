import React from "react";
import { Layout, Flex, Space } from "antd";
import "./Footer.css";
import CustomButton from "../Button/CustomButton";

const { Footer } = Layout;

const CustomFooterOcelot = ({ onSave, onCancel, disabled }) => {
  return (
    <>
      <Footer className="footer-ocelot-container">
        <Flex justify="flex-end">
          <Space>
            <CustomButton
              text="Cancel"
              type="primary"
              ghost={true}
              onClick={onCancel}
              disabled={disabled}
              width={90}
            />
            <CustomButton
              text="Save"
              type="primary"
              onClick={onSave}
              disabled={disabled}
              width={90}
            />
          </Space>
        </Flex>
      </Footer>
    </>
  );
};

export default CustomFooterOcelot;
