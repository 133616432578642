import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Flex, notification, Modal, Col } from "antd";
import "./Template.css";
import { useTranslation } from "react-i18next";


// import components
import {
  CustomButton,
  DeleteButton,
  EditButton,
  MailButton,
  PinButton,
  ConfirmButton,
  FilterButton,
  HistoryButton,
  EyeButton,
  ConfirmDialog,
  DeleteDialog,
  DiscardDialog,
  FailedDialog,
  SuccessDialog,
  SuccessNotification,
  CustomSearch,
  CustomCard,
  CustomIFrame,
} from "../../components";
// import FormItem from "../../components/FormItem/FormItem";

const Template = () => {
  const { i18n, t } = useTranslation();
  // Dialog
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalMailOpen, setIsModalMailOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalSuccessBinOpen, setIsModalSuccessBinOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
  const [isModalWarningOpen, setIsModalWarningOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);

  // Search
  const [keywords, setKeywords] = useState("");
  const handleSearch = (value) => {
    setKeywords(value);
  };

  // Form
  // const [form] = Form.useForm();
  // const [formData, setFormData] = useState({
  //   name: "kiw",
  //   lastname: "phak",
  //   message: "",
  //   phone: "",
  //   select: "",
  //   checked: false,
  //   textarea: "",
  // });

  // const onFinish = async (values) => {
  //   //console.log("Form values:", values);
  // };

  // const validator = async (_, value) => {
  //   if (value && value.length < 5) {
  //     return Promise.reject("The value should be at least 5 characters long.");
  //   }
  //   return Promise.resolve();
  // };

  function resizeIframe(obj) {
    // var test = obj.currentTarget.contentWindow.location;
    // alert(test);
  }

  function iframeURLChange(iframe, callback) {
    var unloadHandler = function () {
      // Timeout needed because the URL changes immediately after
      // the `unload` event is dispatched.
      setTimeout(function () {
        callback(iframe.contentWindow.location.href);
      }, 0);
    };

    function attachUnload() {
      // Remove the unloadHandler in case it was already attached.
      // Otherwise, the change will be dispatched twice.
      iframe.contentWindow.removeEventListener("unload", unloadHandler);
      iframe.contentWindow.addEventListener("unload", unloadHandler);
    }

    iframe.addEventListener("load", attachUnload);
    attachUnload();
  }

  return (
    <>
      <div className="template-container">
        <h2>Template</h2>
        <Flex gap="small" align="flex-start" vertical>
          <h3>Button</h3>
          <Flex wrap gap="small">
            <CustomButton text="Primary" type="primary" />
            <CustomButton text="Default" />
            <CustomButton text="Ghost" type="primary" ghost={true} />
            <CustomButton
              type="primary"
              icon={<SearchOutlined />}
              shape="circle"
            />
            <CustomButton text="Danger" danger={true} />
            <CustomButton
              text="Danger type=primary"
              type="primary"
              danger={true}
            />
            <CustomButton text="text" type="text" />
            <CustomButton text="dashed" type="dashed" />
            <CustomButton text="link" type="link" />
            <CustomButton
              text="Click Me"
              type="primary"
              onClick={() => alert("Button clicked!")}
              width={130}
              height={40}
              borderRadius={30}
            />
            <CustomButton
              text="Search"
              type="primary"
              icon={<SearchOutlined />}
              color="#000"
              padding="0px 30px"
            />
            <CustomButton
              text="Search"
              type="primary"
              icon={<SearchOutlined />}
              iconPosition="end"
              padding="0px 30px"
            />
            <CustomButton text="background" color="#fff" background="#999900" />
            <CustomButton text="Loading" type="primary" loading={true} />
            <CustomButton text="fontSize" fontSize="18px" />
            <CustomButton text="fontWeight" fontSize="18px" fontWeight="bold" />
            <CustomButton
              text="custom"
              color="#fff"
              background="linear-gradient(135deg, #6253e1, #04befe)"
            />
          </Flex>

          <h3>Button Action</h3>
          <Flex wrap gap="small">
            <EditButton onClick={() => alert("edit")} />
            <EditButton onClick={() => {}} text={true} />
            <DeleteButton onClick={() => setIsModalDeleteOpen(true)} />
            <MailButton onClick={() => {}} />
            <HistoryButton onClick={() => {}} />
            <EyeButton onClick={() => {}} />
            <PinButton
              onClick={() => alert("isPinned: false")}
              isPinned={false}
            />
            <PinButton
              onClick={() => alert("isPinned: ture")}
              isPinned={true}
            />
            <ConfirmButton onClick={() => alert("Confirm Button")} />
            <FilterButton onClick={() => alert("Filter Button")} />
            <FilterButton onClick={() => {}} count={2} />
          </Flex>

          <Flex wrap gap="small">
            <Col>
              <h3>Confirm Dialog (default)</h3>
              <CustomButton
                text="Show Dialog"
                type="primary"
                onClick={() => setIsModalOpen(true)}
                width={130}
                height={40}
              />
              <ConfirmDialog
                isModalOpen={isModalOpen}
                closable={false}
                title="ยืนยันการบันทึก"
                description="คุณต้องการบันทึกใช่หรือไม่?"
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                  setIsModalOpen(false);
                }}
                onConfirm={() => {
                  alert("OK");
                }}
              />
            </Col>
            <Col>
              <h3>Confirm Dialog (icon mail)</h3>
              <CustomButton
                text="Show Dialog"
                type="primary"
                onClick={() => setIsModalMailOpen(true)}
                width={130}
                height={40}
              />
              <ConfirmDialog
                isModalOpen={isModalMailOpen}
                closable={false}
                type="mail"
                title="ยืนยันอีเมล"
                description="คุณต้องการ..."
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                  setIsModalMailOpen(false);
                }}
                onConfirm={() => {
                  alert("OK");
                }}
              />
            </Col>
            <Col>
              <h3>Confirm Dialog (icon warning)</h3>
              <CustomButton
                text="Show Dialog"
                type="primary"
                onClick={() => setIsModalWarningOpen(true)}
                width={130}
                height={40}
              />
              <p style={{ margin: 0, color: "red" }}>
                * แสดงรายละเอียดแบบขึ้นบรรทัดใหม่
              </p>
              <ConfirmDialog
                isModalOpen={isModalWarningOpen}
                closable={false}
                type="warning"
                title="คุณต้องการส่ง OTP ในอีเมล"
                description={`แสดงรายละเอียด\nแบบขึ้นบรรทัดใหม่`} // \n
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                  setIsModalWarningOpen(false);
                }}
                onConfirm={() => {
                  alert("OK");
                }}
              />
            </Col>
            {/* <Col>
              <h3>Warning Dialog</h3>
              <CustomButton
                text="Show Dialog"
                type="primary"
                onClick={() => setIsModalWarningOpen(true)}
                width={130}
                height={40}
              />
              <WarningDialog
                isModalOpen={isModalWarningOpen}
                closable={false}
                title="Are you sure?"
                cancelText="Cancel"
                confirmText="Ok"
                onCancel={() => {
                  setIsModalWarningOpen(false);
                }}
                onConfirm={() => {
                  alert("OK");
                }}
                description="Warning"
              />
            </Col> */}
            <Col>
              <h3>Success Dialog (icon default)</h3>
              <CustomButton
                text="Show Dialog"
                type="primary"
                onClick={() => {
                  setIsModalSuccessOpen(true);
                  setTimeout(() => {
                    setIsModalSuccessOpen(false);
                  }, 2000);
                }}
                // width={130}
                height={40}
              />
              <SuccessDialog
                isModalOpen={isModalSuccessOpen}
                closable={false}
                title="สำเร็จ"
                detail="คำอธิบาย..."
                onCancel={() => {
                  setIsModalSuccessOpen(false);
                }}
                onAfterClose={() => {
                  alert("Dialog dissmiss");
                }}
              />
            </Col>
            <Col>
              <h3>Success Dialog (icon bin)</h3>
              <CustomButton
                text="Show Dialog"
                type="primary"
                onClick={() => {
                  setIsModalSuccessBinOpen(true);
                  setTimeout(() => {
                    setIsModalSuccessBinOpen(false);
                  }, 2000);
                }}
                // width={130}
                height={40}
              />
              <SuccessDialog
                isModalOpen={isModalSuccessBinOpen}
                closable={false}
                type="bin"
                title="สำเร็จ"
                detail="คำอธิบาย..."
                onCancel={() => {
                  setIsModalSuccessBinOpen(false);
                }}
                onAfterClose={() => {
                  alert("Dialog dissmiss");
                }}
              />
            </Col>
            <Col>
              <h3>Failed Dialog</h3>
              <CustomButton
                text="Show Dialog"
                type="primary"
                onClick={() => setIsModalFailedOpen(true)}
                width={130}
                height={40}
              />
              <FailedDialog
                isModalOpen={isModalFailedOpen}
                closable={false}
                title="ล้มเหลว"
                detail="มีบางอย่างผิดพลาด โปรดลองอีกครั้ง."
                onCancel={() => {
                  setIsModalFailedOpen(false);
                }}
                onAfterClose={() => {
                  alert("Dialog dissmiss");
                }}
                onConfirm={() => {
                  alert("Try again");
                }}
              />
            </Col>
            <Col>
              <h3>Delete Dialog</h3>
              <CustomButton
                text="Show Dialog"
                type="primary"
                onClick={() => setIsModalDeleteOpen(true)}
                width={130}
                height={40}
              />
              <DeleteDialog
                isModalOpen={isModalDeleteOpen}
                closable={false}
                title="ยืนยันการลบ"
                description="คุณต้องการลบใช่หรือไม่?"
                cancelText="ยกเลิก"
                confirmText="ลบ"
                onCancel={() => {
                  setIsModalDeleteOpen(false);
                }}
                onConfirm={() => {
                  alert("OK");
                }}
              />
            </Col>
            <Col>
              <h3>Discard Dialog</h3>
              <CustomButton
                text="Show Dialog"
                type="primary"
                onClick={() => setIsModalDiscardOpen(true)}
                width={130}
                height={40}
              />
              <DiscardDialog
                isModalOpen={isModalDiscardOpen}
                closable={false}
                title="ยกเลิกการเปลี่ยนแปลง"
                cancelText="ยกเลิก"
                confirmText="ยืนยัน"
                onCancel={() => {
                  setIsModalDiscardOpen(false);
                }}
                onConfirm={() => {
                  alert("OK");
                }}
              />
            </Col>
            <Col>
              <h3>Success notification</h3>
              <CustomButton
                text="Show Notification"
                type="primary"
                onClick={() =>
                  SuccessNotification("Success", "Login Successfully", api)
                }
                width={130}
                height={40}
              />
              {contextHolder}
            </Col>
            <Col>
              <h3>Embed Google Form</h3>
              <CustomButton
                text="Show Google Form"
                type="primary"
                onClick={() => {
                  setIsModalFormOpen(true);
                  // setTimeout(() => {

                  // iframeURLChange(document.getElementById("mainframe"), function (newURL) {
                  //   //console.log("URL changed:", newURL);
                  // });
                  // }, 1000);
                }}
                width={130}
                height={40}
              />
              <Modal
                title={<p>Feedback</p>}
                open={isModalFormOpen}
                footer={null}
                closable={true}
                destroyOnClose={true}
                width={680}
                onCancel={() => setIsModalFormOpen(false)}
                // onCancel={onCancel}
                // afterClose={(e) => {
                //   onAfterClose();
                // }}
                // style={{ top: 20 }}
              >
                {/* <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSeGFFo2tOYe2AUrFdgRVlfW65M2RtpDvGyvMbHNYfNq3sNedg/viewform?embedded=true"
                width="640"
                height="959"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                onLoad={resizeIframe}
                id="mainframe" 
              >
                กำลังโหลด…
              </iframe> */}

                <iframe
                  id="survey-form"
                  src="https://docs.google.com/forms/d/e/1FAIpQLSfVfGwbpX1NSezBwt8eqNyje3ZQOnV89XslQ246F1sZf063mw/viewform?embedded=true"
                  width="640"
                  height="919"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                >
                  กำลังโหลด…
                </iframe>
              </Modal>
            </Col>
          </Flex>

          {/* <h3>Input (Form Item)</h3>
          <Form
            hideRequiredMark
            layout="vertical"
            form={form}
            initialValues={formData}
            onFinish={onFinish}
          >
            <FormItem
              name="name"
              label="Name"
              required={true}
              requiredMessage="please Input Name."
              tooltipMessage="This is a required field"
            >
              <Input
                type="text"
                placeholder="Input name."
                style={{ width: "200px" }}
              />
            </FormItem>
            <FormItem name="lastname" label="Last Name">
              <Input
                type="text"
                placeholder="Input last name."
                style={{ width: "300px", borderRadius: "20px" }}
              />
            </FormItem>
            <FormItem
              name="message"
              label="Message"
              tooltipMessage="Message > 5 characters"
              validator={validator}
            >
              <Input placeholder="Input Message." />
            </FormItem>
            <FormItem
              name="phone"
              label="Phone Number"
              required={true}
              requiredMessage="please Input Phone Number."
              pattern={/^\d{10}$/}
              patternMessage="Phone number must be exactly 10 digits long."
            >
              <Input placeholder="Input Phone Number." allowClear />
            </FormItem>
            <FormItem
              name="select"
              label="Option"
              required={true}
              requiredMessage="please select option."
              tooltipMessage="select option."
            >
              <CustomSelect
                placeholder="select option"
                options={options}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </FormItem>
            <FormItem
              name="textarea"
              label="Text Area"
              tooltipMessage="Text Area"
            >
              <TextArea rows={4} placeholder="Text" maxLength={6} />
            </FormItem>
            <CustomButton
              text="Submit"
              type="primary"
              htmlType="submit"
              // onClick={() => form.submit()}
            />
          </Form> */}

          <h3>Search</h3>
          <Flex gap="small">
            <CustomSearch
              placeholder="Search for..."
              onSearch={handleSearch}
              keywords={keywords}
              setKeywords={setKeywords}
              width={500}
              height={40}
            />
          </Flex>

          <h3>Card</h3>
          <Flex gap="small">
            <CustomCard
              title="Dashboard 1"
              description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
              onClickButton={() => alert("show dashboard detail!")}
            />
            <CustomCard
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              title="Dashboard 2"
              description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
              onClickButton={() => alert("show dashboard detail!")}
            />
          </Flex>

          <h3>IFrame</h3>
          <Flex gap="small">
            <CustomIFrame
              src=""
              title="Dashboard 1"
              description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Template;
