import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const DashboardService = {

  getdatalist: async function (idInsight, cancel = false) {
    const response = await api.request({
      url: `/dashboard/by-insight-report?InsightReportIds=${idInsight}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getdatalist.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  // g

  getdatalistTag: async function (tagIds, cancel = false) {
    const queryString = tagIds.map((id) => `TagIds=${id}`).join("&");
    const response = await api.request({
      url: `/dashboard/by-tag?${queryString}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getdatalist.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  // getdatalist: async function (params, cancel = false) {
  //   const response = await api.request({
  //     url: `/dashboard/public`,
  //     method: "GET",
  //     params: params,
  //     signal: cancel
  //       ? cancelApiObject[this.getdatalist.name].handleRequestCancellation().signal
  //       : undefined,
  //   });

  //   return response.data;
  // },
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/dashboard/by-name?name=${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getPublic: async function (params, cancel = false) {
    const response = await api.request({
      url: `/dashboard/public`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  search: async function (params, cancel = false) {
    const response = await api.request({
      url: `/dashboard`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  searchPublic: async function (params, cancel = false) {
    const query = new URLSearchParams();
    const queryTag = new URLSearchParams();
    params.DashboardCategoryIds.forEach((id) => {
      if (id !== "all") query.append("dashboardCategoryIds", id);
    });
    params.TagIds.forEach((id) => {
      query.append("TagIds", id);
    });
    delete params.DashboardCategoryIds;
    delete params.TagIds;

    const response = await api.request({
      url: `/dashboard/public?${query.toString() + queryTag.toString()}`,
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.searchPublic.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },
  create: async function (data, cancel = false) {
    const response = await api.request({
      url: `/dashboard`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  savebulk: async function (data, cancel = false) {
    const response = await api.request({
      url: `/dashboard/bulk`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.savebulk.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },
  update: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/dashboard/${id}`,
      method: "PUT",
      data: data,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  updatePinned: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/dashboard/${id}/is-pinned`,
      method: "PATCH",
      data: data,
      signal: cancel
        ? cancelApiObject[this.updatePinned.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },
  updateActivated: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/dashboard/${id}/is-activated`,
      method: "PATCH",
      data: data,
      signal: cancel
        ? cancelApiObject[this.updateActivated.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/dashboard/${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getDashboard: async function (cancel = false) {
    const response = await api.request({
      url: `/dashboard/id-name`,
      method: "GET",
      // params: params,
      signal: cancel
        ? cancelApiObject[this.search.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(DashboardService);
