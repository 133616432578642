import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="85"
    viewBox="0 0 85 85"
    fill="none"
  >
    <path
      d="M0 20C0 8.9543 8.95431 0 20 0H85V85H20C8.9543 85 0 76.0457 0 65V20Z"
      fill="#E3FFF0"
    />
    <g clipPath="url(#clip0_7977_56373)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.625 43C18.625 29.5381 29.5381 18.625 43 18.625C56.4619 18.625 67.375 29.5381 67.375 43C67.375 56.4619 56.4619 67.375 43 67.375C29.5381 67.375 18.625 56.4619 18.625 43ZM52.0257 38.4648C52.6276 37.6222 52.4325 36.4511 51.5898 35.8493C50.7472 35.2474 49.5761 35.4425 48.9742 36.2852L40.8857 47.6091L36.8258 43.5492C36.0936 42.8169 34.9064 42.8169 34.1742 43.5492C33.4419 44.2814 33.4419 45.4686 34.1742 46.2008L39.7992 51.8258C40.1889 52.2156 40.7304 52.4141 41.2797 52.3686C41.8289 52.3232 42.3304 52.0383 42.6508 51.5898L52.0257 38.4648Z"
        fill="#1AC487"
      />
    </g>
    <defs>
      <clipPath id="clip0_7977_56373">
        <rect
          width="60"
          height="60"
          fill="white"
          transform="translate(13 13)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Icon_success_default = (props) => <Icon component={icon} {...props} />;

export default Icon_success_default;
