import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const UserService = {
  getJob: async function (params, cancel = false) {
    const response = await api.request({
      url: `/users/job-functions`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getMe.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },
  getMe: async function (params, cancel = false) {
    const response = await api.request({
      url: `/users/me`,
      method: "GET",
      params: params,
      signal: cancel ? cancelApiObject[this.getMe.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/Keycloak-users/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },
  getJobFunction: async function (cancel = false) {
    const response = await api.request({
      url: `/users/job-functions`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getJobFunction.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  search: async function (requestObject, cancel = false) {
    let params = "?Keywords=" +
      requestObject.keywords +
      "&Pagination.Page=" +
      requestObject.page +
      "&Pagination.PageSize=" +
      requestObject.pageSize +
      "&Pagination.SortBy=" +
      requestObject.sortBy +
      "&Pagination.SortDestination=" +
      requestObject.sortDirection
    if (requestObject.enabled !== undefined) {
      params += "&enabled=" +
        requestObject.enabled
    }
    // requestObject.enabled !== null ? 
    // "&enabled=" +
    // requestObject.enabled  : ""+
    const response = await api.request({
      url: `/Keycloak-users` +
        params,
      method: "GET",
      // params: params,
      signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
  },

  create: async function (data, cancel = false) {
    const response = await api.request({
      url: `/Keycloak-users`,
      method: "POST",
      data: data,
      signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },

  update: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/Keycloak-users/${id}`,
      method: "PUT",
      data: data,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },

  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/Keycloak-users/${id}`,
      method: "DELETE",
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },

  updateUser: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/users?id=${id}`,
      method: "PUT",
      data: data,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response;
  },

  changePasswordUser: async function (data, cancel = false) {
    const response = await api.request({
      url: `/users/change-password`,
      method: "PUT",
      data: data,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },

  deleteUser: async function (id, cancel = false) {
    const response = await api.request({
      url: `/users/${id}`,
      method: "DELETE",
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    });
    return response;
  },

  deleteUserMuti: async function (ids, cancel = false) {
    const response = await api.request({
      url: "/users/bulk",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json", // ระบุประเภทของข้อมูลที่ส่ง
      },
      data: ids,  // เปลี่ยนจาก body เป็น data
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    });
    return response;
  },


  sendEmailMuti: async function (ids, cancel = false) {
    const response = await api.request({
      url: "/users/register-user-keycloak/bulk",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json", // ระบุประเภทของข้อมูลที่ส่ง
      },
      data: ids,  // เปลี่ยนจาก body เป็น data
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    });
    return response;
  },


  getalluser: async function (page, pageSize, filters, cancel = false) {
    try {
      const params = {
        'Pagination.Page': page,  // ส่งหมายเลขหน้า
        'Pagination.PageSize': pageSize,  // ส่งจำนวนข้อมูลที่จะแสดงต่อหน้า
      };

      // เพิ่ม Keywords ในกรณีที่มีการค้นหา
      if (filters && filters.Keywords) {
        params['Keywords'] = filters.Keywords;
      }

      // เพิ่ม IsVerifiedEmail เฉพาะเมื่อมีค่า
      if (filters && typeof filters.IsVerifiedEmail !== 'undefined') {
        params['IsVerifiedEmail'] = filters.IsVerifiedEmail;
      }

      // เพิ่ม UserSources (type) เฉพาะเมื่อมีค่า
      if (filters && filters.type) {
        params['UserSources'] = filters.type;  // เพิ่มค่า type ใน UserSources
      }

      const response = await api.request({
        url: '/users/all-users',
        method: 'GET',
        params: params,
        signal: cancel ? cancelApiObject[this.getMe.name].handleRequestCancellation().signal : undefined,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;  // ถ้ามีข้อผิดพลาด ให้ throw error ขึ้นไป
    }
  },


  getUserById: async function (id, cancel = false) {

    const response = await api.request({
      url: `/users/${id}`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getMe.name].handleRequestCancellation().signal : undefined,
    });
    //console.log("aaaaaaaaaaaaaasdasdadd", response);
    return response.data;
  },

  updateUser: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/users?id=${id}`,
      method: "PUT",
      data: data,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },
  disconnectUserSource: async function (id, data,  cancel = false) {
    const response = await api.request({
      url: `/users/disconnect-user-source/${id}`,
      method: "DELETE",
      params: {
        idpName: data
      },
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response;
  },
  getPermissions: async function (cancel = false) {
    const response = await api.request({
      url: `/users/permissions`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response.data;
  },

}

const cancelApiObject = defineCancelApiObject(UserService);