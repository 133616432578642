import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import "./Layout.css";
import { Layout, theme } from "antd";
import Nav from "../../../components/Nav/Nav";
import Footer from "../../../components/Footer/Footer";

const { Content } = Layout;

const App = () => {
  
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const CheckPath = (currentPath) => {
    const pathsToShowFooter = ["/", "/dashboard", "/insight-report"];

    return pathsToShowFooter.includes(currentPath);
  };

  const contentStyle = {
    // textAlign: 'center',
    // minHeight: 120,
    // lineHeight: '120px',
    // color: '#fff',
    backgroundColor: "rgba(248, 248, 249, 1)",
  };

  return (
    <>
      <Layout className="layout">
        <Nav />
        <Content className="content">
          <Layout>
            <Content style={contentStyle}>
              <div>
                <Outlet />
              </div>
            </Content>
          </Layout>
        </Content>

        {CheckPath(currentPath) && <Footer />}
      </Layout>
    </>
  );
};
export default App;
