import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./InsightReportManagement.css";
import {
  Layout,
  Flex,
  Button,
  Divider,
  Input,
  Menu,
  Switch,
  Space,
  Row,
  Col,
  Tag,
  Tooltip,
} from "antd";
import {
  DeleteButton,
  PinButton,
  SuccessDialog,
  DeleteDialog,
  FailedDialog,
} from "../../../../../components";
import { AiOutlineSearch } from "react-icons/ai";
import NoData from "../../../../../assets/img/no_data.js";
import NoImg from "../../../../../assets/img/no_img.png";
import { InsightReportService } from "../../../../../services/InsightReportService.js";
import { InsightReportCategoryService } from "../../../../../services/InsightReportCategoryService.js";
import { FileService } from "../../../../../services/FileService.js";

const { Header, Content, Sider } = Layout;

const InsightReportManagement = () => {
  const navigate = useNavigate();
  const [select, setSelect] = useState();
  const [insightList, setInsightList] = useState([]);
  const [insightCategory, setInsightCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectDelete, setSelectDelete] = useState();
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const passData = useLocation();
  let catagoryId = "";
  if (passData.state !== null && passData.state.catagoryId !== null) {
    catagoryId = passData.state.catagoryId;
  }
  const [prevId, setPrevId] = useState(catagoryId);

  useEffect(() => {
    setSelect(catagoryId);
    setPrevId(catagoryId);
  }, []);

  useEffect(() => {
    if (select !== undefined) {
      fetchData();
    }
  }, [select]);

  useEffect(() => {
    if (!insightCategory) {
      fetchInsightCategory();
    }
  }, [insightCategory]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [keyword]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (select === undefined) {
        return;
      }

      const params = {
        InsightReportCategoryIds: select,
        Keywords: keyword,
      };

      const response = await InsightReportService.search(params);
      setInsightList(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchInsightCategory = async () => {
    try {
      setLoading(true);

      const params = {
        // Keywords: ,
      };

      const response = await InsightReportCategoryService.search(params);
      setInsightCategory(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleUpdateActivated = async (checked, item) => {
    try {
      const data = {
        id: item.id,
        isActivated: checked,
      };

      await InsightReportService.updateActivated(item.id, data);
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdatePinend = async (checked, item) => {
    setPinLoading(item.id);
    try {
      const data = {
        id: item.id,
        isPinned: checked,
      };

      await InsightReportService.updatePinned(item.id, data);
      setPinLoading(false);
      fetchData();
    } catch (error) {
      setPinLoading(false);
      console.error(error);
    }
  };

  const handleDeleteInsightReportById = async () => {
    try {
      await InsightReportService.delete(selectDelete);
      setSelectDelete();
      setIsModalDeleteOpen(false);
      setIsModalSuccessOpen(true);
      setTimeout(() => {
        setIsModalSuccessOpen(false);
      }, 2000);
      fetchInsightCategory();
      fetchData();
    } catch (error) {
      setIsModalFailedOpen(true);
      console.error(error);
    }
  };

  const handleSearchInsightReport = () => {
    fetchData();
  };

  const downloadFile = async (item) => {
    let file = item?.insightReportFiles.find(
      (item) => item?.fileType === "insight_report_file"
    );

    try {
      const response = await FileService.get(file?.fileId);

      const url = window.URL.createObjectURL(response);

      const link = document.createElement("a");
      link.href = url;
      // link.href = file?.fileSrc;
      link.download = file?.fileRawName;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
      setIsModalFailedOpen(true);
    }
  };

  return (
    <>
      <Header
        style={{
          height: "70px",
          background: "#FFF",
          borderBottom: "1px solid #D0D5DD",
        }}
      >
        <Flex justify="space-between" align="center" style={{ height: "100%" }}>
          <p style={{ margin: "0", fontSize: "18px", fontWeight: "600" }}>
            จัดการข้อมูลรายงานวิเคราะห์
          </p>
          <Button
            type="primary"
            onClick={() =>
              // navigate("/management/insight-report-management-detail")
              navigate(
                {
                  pathname: `/management/insight-report-management-detail`,

                  // pagedata: pageValue,
                },
                { state: { catagoryId: select } }
              )
            }
            style={{
              width: "140px",
              height: "40px",
              fontSize: "16px",
              fontWeight: "500",
              background: "#63E1E1",
              borderRadius: "10px",
            }}
          >
            + เพิ่ม/แก้ไข
          </Button>
        </Flex>
      </Header>
      <Layout style={{ height: "100vh" }}>
        <Sider
          width={302}
          style={{
            margin: "14px",
            background: "#FFF",
            borderRadius: "8px",
          }}
        >
          <div style={{ padding: "14px" }}>
            <p
              style={{
                margin: "0",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              ประเภทรายงานวิเคราะห์ (
              {insightCategory?.pagination?.totalCount || 0})
            </p>
            <Input
              placeholder="ค้นหา ชื่อรายงานวิเคราะห์..."
              allowClear
              suffix={
                <AiOutlineSearch
                  onClick={() => handleSearchInsightReport()}
                  style={{
                    color: "rgba(0,0,0,.45)",
                    cursor: "pointer",
                  }}
                />
              }
              onChange={(e) => setKeyword(e.target.value)}
              onPressEnter={() => handleSearchInsightReport()}
              style={{ marginTop: "14px", borderRadius: "8px" }}
            />
          </div>
          <Divider style={{ margin: "0" }} />
          {insightCategory && insightCategory?.items?.length !== 0 ? (
            <div style={{ padding: "14px" }}>
              <Menu
                // mode="inline"
                defaultSelectedKeys={["1"]}
                selectedKeys={prevId}
                style={{
                  height: "100%",
                }}
              >
                {insightCategory?.items
                  ?.sort((a, b) => a.sequence - b.sequence)
                  .map((item, index) => (
                    <Menu.Item
                      key={item.id}
                      onClick={() => {
                        setSelect(item.id);
                        setPrevId(item.id);
                      }}
                      style={{
                        paddingLeft: "14px",
                      }}
                    >
                      <span style={{ fontSize: "16px", fontWeight: "500" }}>
                        {item.name} ({item?.count || 0})
                      </span>
                    </Menu.Item>
                  ))}
              </Menu>
            </div>
          ) : (
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ height: "80%" }}
            >
              <NoData />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#667085",
                }}
              >
                ไม่มีข้อมูล
              </p>
            </Flex>
          )}
        </Sider>

        <Content
          style={{
            margin: "14px",
            marginLeft: "0",
            overflowY: "scroll",
          }}
        >
          {select ? (
            <>
              {insightList?.items?.length !== 0 ? (
                <Space
                  direction="vertical"
                  size="middle"
                  style={{
                    display: "flex",
                  }}
                >
                  {insightList?.items
                    ?.sort((a, b) => a.sequence - b.sequence)
                    .map((item) => (
                      <div
                        style={{
                          padding: "14px",
                          background: "#FFF",
                          borderRadius: "8px",
                        }}
                      >
                        <Flex justify="space-between" align="center">
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            ใช้งาน/ไม่ใช้งาน :
                            <span style={{ margin: "10px" }}>
                              <Switch
                                // defaultChecked={item?.isPinned}
                                checked={item?.isActivated}
                                onChange={(e) => handleUpdateActivated(e, item)}
                              />
                            </span>
                          </span>
                          <Space>
                            <PinButton
                              loading={pinLoading === item.id}
                              onClick={() =>
                                handleUpdatePinend(!item.isPinned, item)
                              }
                              isPinned={item?.isPinned}
                            />
                            <DeleteButton
                              onClick={() => {
                                setSelectDelete(item.id);
                                setIsModalDeleteOpen(true);
                              }}
                            />
                          </Space>
                        </Flex>
                        <Divider />
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          ชื่อรายงานวิเคราะห์
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#667085",
                          }}
                        >
                          {item?.name || "-"}
                        </p>

                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          รายละเอียด หน้า List
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#667085",
                          }}
                        >
                          {item?.shortDescription || "-"}
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          รายละเอียด
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#667085",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: item?.description || "-",
                          }}
                        ></p>
                        <Row gutter={16}>
                          <Col span={12}>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              อัปโหลดภาพขนาดย่อ
                            </p>
                            {item?.thumbnailSrc ? (
                              <img
                                alt="example"
                                src={item?.thumbnailSrc}
                                onError={(e) => {
                                  if (e.target.alt === "error") {
                                    //console.log("No img");
                                    e.target.src = NoImg;
                                  } else {
                                    e.target.src = NoImg;
                                  }
                                }}
                                width={204}
                                style={{
                                  padding: "6px",
                                  border: "1px solid #D9D9D9",
                                  borderRadius: "2px",
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </Col>
                          <Col span={12}>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              อัปโหลดภาพในหน้า รายละเอียด
                            </p>
                            {item?.contentImageSrc ? (
                              <img
                                alt="example"
                                src={item?.contentImageSrc}
                                onError={(e) => {
                                  if (e.target.alt === "error") {
                                    //console.log("No img");
                                    e.target.src = NoImg;
                                  } else {
                                    e.target.src = NoImg;
                                  }
                                }}
                                width={204}
                                style={{
                                  padding: "6px",
                                  border: "1px solid #D9D9D9",
                                  borderRadius: "2px",
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </Col>
                        </Row>

                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Tag
                        </p>
                        <Flex wrap gap="small">
                          {item?.insightReportTags &&
                          item?.insightReportTags?.length !== 0
                            ? item?.insightReportTags.map((item) => (
                                <Tag
                                  bordered={false}
                                  color={item?.tagColor}
                                >
                                  {item?.tagName}
                                </Tag>
                              ))
                            : "-"}
                        </Flex>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          เลือกแดซบอร์ดที่ต้องการเชื่อมกับรายงานวิเคราะห์
                        </p>
                        <Flex wrap gap="small">
                          {item?.insightReportDashboards &&
                          item?.insightReportDashboards?.length !== 0
                            ? item?.insightReportDashboards?.map((item) => (
                                <Tag
                                  style={{
                                    borderRadius: "8px",
                                    padding: "6px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item.dashboardName}
                                </Tag>
                              ))
                            : "-"}
                        </Flex>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          เลือก Role ที่ต้องการให้ดาวน์โหลดรายงานวิเคราะห์
                        </p>
                        <Flex wrap gap="small">
                          {item?.insightReportPermissions &&
                          item?.insightReportPermissions?.length !== 0
                            ? item?.insightReportPermissions?.map((item) => (
                                <Tag
                                  style={{
                                    borderRadius: "8px",
                                    padding: "6px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item.roleName}
                                </Tag>
                              ))
                            : "-"}
                        </Flex>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          อัปโหลดไฟล์ รายงานวิเคราะห์
                        </p>
                        {item?.insightReportFileRawName ? (
                          <a
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                            onClick={() => downloadFile(item)}
                          >
                            {item?.insightReportFileRawName}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                    ))}
                </Space>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ height: "100%" }}
                >
                  <NoData />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#667085",
                    }}
                  >
                    ไม่มีข้อมูล
                  </p>
                </Flex>
              )}
            </>
          ) : (
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ height: "100%" }}
            >
              <NoData />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#667085",
                }}
              >
                กรุณาเลือกประเภทรายงานวิเคราะห์
              </p>
            </Flex>
          )}
        </Content>
      </Layout>

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        title="สำเร็จ"
        detail="ลบข้อมูลสำเร็จแล้ว"
        onCancel={() => {
          setIsModalSuccessOpen(false);
        }}
        onAfterClose={() => {
          setIsModalSuccessOpen(false);
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title="Failed"
        detail="Something went wrong. Please try again."
        onCancel={() => {
          setIsModalFailedOpen(false);
        }}
        onAfterClose={() => {
          setIsModalFailedOpen(false);
        }}
        onConfirm={() => {
          setIsModalFailedOpen(false);
        }}
      />

      <DeleteDialog
        isModalOpen={isModalDeleteOpen}
        closable={false}
        title="ยืนยันการลบ"
        description="คุณต้องการลบใช่หรือไม่?"
        cancelText="ยกเลิก"
        confirmText="ลบ"
        onCancel={() => {
          setSelectDelete();
          setIsModalDeleteOpen(false);
        }}
        onConfirm={() => {
          handleDeleteInsightReportById();
        }}
      />
    </>
  );
};

export default InsightReportManagement;
