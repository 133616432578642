import React from "react";
import { Button } from "antd";
import { AiOutlineCheck } from "react-icons/ai";

const ConfirmButton = ({ onClick }) => {
  return (
    <Button
      icon={
        <AiOutlineCheck
          style={{
            fontSize: "16px",
          }}
        />
      }
      onClick={onClick}
      style={{
        width: "134px",
        height: "32px",
        borderRadius: "10px",
        color: "#1677FF",
        border: "1px solid #1677FF",
        fontSize: "14px",
        fontWeight: "400",
      }}
    >
      ยืนยันผู้ใช้งาน
    </Button>
  );
};

export default ConfirmButton;
