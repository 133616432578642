import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
  >
    <path
      d="M10.75 8.25V5.125C10.75 3.05393 9.07107 1.375 7 1.375C4.92893 1.375 3.25 3.05393 3.25 5.125V8.25M2.625 17.625H11.375C12.4105 17.625 13.25 16.7855 13.25 15.75V10.125C13.25 9.08947 12.4105 8.25 11.375 8.25H2.625C1.58947 8.25 0.75 9.08947 0.75 10.125V15.75C0.75 16.7855 1.58947 17.625 2.625 17.625Z"
      stroke="#475467"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const icon_Lock_outlined = (props) => <Icon component={icon} {...props} />;

export default icon_Lock_outlined;
