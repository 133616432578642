import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Col, Row, Collapse, Button, Input, Modal, Flex, Form, Switch, Select } from "antd";
import "../Management/User/AccountManagement/AccountManagement.css";
import { ArrowLeftOutlined, MailOutlined, CheckOutlined, LockOutlined } from "@ant-design/icons";
import { FaUserCircle } from "react-icons/fa";
import { UserService } from "../../../services/UserService";
import CustomButton from "../../../components/Button/CustomButton";
import IconConWarningGray from "../../../assets/icon/icon_warning_gray_dialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../components/Dialog/FailedDialog";
import {  handleLogin } from "../../../keycloak/KeycloakAuth";
import { useNavigate } from 'react-router-dom';
import { RoleService } from "../../../services/RoleService";
import {
    ConfirmDialog,
} from "../../../components";

const { Panel } = Collapse;

const ManageUserDetail = ({ }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = location.state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [isModalSuccessOpen2, setIsModalSuccessOpen2] = useState(false);
    const [isModalSuccessOpen3, setIsModalSuccessOpen3] = useState(false);
    const [modalSuccessStr, setModalSuccessStr] = useState({
        title: "",
        detail: "",
    });
    const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
    const [modalFailedStr, setModalFailedStr] = useState({
        title: "",
        detail: "",
    });
    const [data, setData] = useState();
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false); // State to toggle edit mode
    const [formData, setFormData] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        position: '',
        jobFunction: '',
        role: '',
        userSources: '',
        createdDate: '',
        lastAccessDate: '',
    });
    const [confirmEmail, setConfirmEmail] = useState('');
    const [isModalMailOpen, setIsModalMailOpen] = useState(false);
    const [isModalMailOpen2, setIsModalMailOpen2] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalSuccessOpenSave, setIsModalSuccessOpenSave] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState("");
    const [jobFunctions, setJobFunctions] = useState([]);
    const [roles, setRoles] = useState([]);  // ค่าเริ่มต้นเป็น array เปล่า

    const fetchJobFunctions = async () => {
        try {
            const response = await UserService.getJob();
            setJobFunctions(response);  // Set job functions from API response
            setFormData({ jobFunction: response[0] || '' });  // Default to first job function if available
        } catch (error) {
            console.error('Error fetching job functions:', error);
        }
    };
    const fetchRoleFunctions = async () => {
        try {
            const response = await RoleService.search();
            console.log('response', response);
            if (response && response.items) {
                setRoles(response.items);
                setFormData({ role: response.items[0]?.nameTh || '' });
            }
        } catch (error) {
            console.error('Error fetching job functions:', error);
        }
    };
    // ฟังก์ชันสุ่มรหัสผ่าน
    const generatePassword = () => {
        const length = 8; // กำหนดความยาวรหัสผ่าน
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
        let password = '';
        for (let i = 0; i < length; i++) {
            password += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return password; // คืนค่ารหัสผ่านที่สุ่มได้
    };

    // เปิด Modal และสุ่มรหัสผ่าน
    const showModal = () => {
        const newPassword = generatePassword(); // สุ่มรหัสผ่านใหม่
        setGeneratedPassword(newPassword); // ตั้งค่ารหัสผ่านที่สุ่ม
        setIsModalVisible(true); // เปิด Modal
    };
    const handleOk = () => {
        console.log("Generated Password:", generatedPassword);
        // sendPasswordToAPI(generatedPassword); // เรียกฟังก์ชันเพื่อส่งรหัสผ่าน
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // อัปเดตรหัสผ่านใน Input
    const handlePasswordChange = (e) => {
        setGeneratedPassword(e.target.value);
    };
    useEffect(() => {
        console.log("load data");
        fetchJobFunctions().then(() => {
            fetchRoleFunctions().then(() => {
                fetchData();
            });
        });
    }, []);
    useEffect(() => {
        if (!data) {
            fetchData();
        }
    }, [data]);

    const fetchData = async () => {
        try {
            // เรียกข้อมูลจาก UserService
            setIsLoading(true);
            const userData = await UserService.getUserById(id);
            // ตรวจสอบว่าข้อมูลมีอยู่จริง
            if (!userData) {
                console.error("No user data found");
                return;
            }

            console.log("Fetched User Data:", userData);


            // ตั้งค่าให้กับ state
            setData(userData);

            // ตั้งค่าให้กับฟอร์ม
            setFormData({
                id: userData.id || '',
                firstName: userData.firstName || '',
                lastName: userData.lastName || '',
                email: userData.email || '',
                tel: userData.tel || '', // กรณีที่ null ให้ใส่ค่าว่าง
                position: userData.position || '',
                jobFunction: userData.jobFunction || '',
                role: userData.roleId || '',
                userSources: Array.isArray(userData.userSources)
                    ? userData.userSources.join(', ') // แปลง Array เป็น String
                    : userData.userSources || '', // รองรับทั้ง Array และ String
                createdDate: userData.createdDateText || '', // ใช้ `createdDateText`
                lastAccessDate: userData.lastAccessDateText || '', // ใช้ `lastAccessDateText`
            });
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "tel") {
            const numericValue = value.replace(/[^0-9]/g, '');
            setFormData({
                ...formData,
                [name]: numericValue
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSave = async () => {
        setIsModalOpen(true);
    };


    const handleSaveData = async () => {
        try {
            let data = {
                id: formData.id || '',
                firstName: formData.firstName || '',
                lastName: formData.lastName || '',
                email: formData.email || '',
                RoleId: formData.role || '',
                attributes: {
                    typeOfIndustries: formData.jobFunction ? [formData.jobFunction] : [],
                    company: formData.company ? [formData.company] : [],
                    position: formData.position ? [formData.position] : [],
                    tel: formData.tel ? [formData.tel] : []
                }
            };

            console.log("Data to save:", data);

            // // ใช้ `updateUser` ในการบันทึกข้อมูล
            setIsLoading(true);
            const response = await UserService.updateUser(data.id, data, false); // `false` คือค่า default ของ `cancel`
            console.log("Profile updated successfully", response);
            setEditMode(false);
            fetchData();
            setIsModalSuccessOpenSave(true);
        } catch (error) {
            console.error("Error updating profile", error);
        } finally {
            setIsLoading(false);
        }

        setTimeout(() => {
            setIsModalSuccessOpenSave(false);
        }, 2000);
    };



    const handleDelete = async () => {
        ////console.log("handleDelete")
        setIsLoading(true);
        try {
            const response = await UserService.deleteUser(data.id);
            setModalSuccessStr({
                title: "สำเร็จ",
                detail: 'ลบบัญชีของคุณสำเร็จแล้ว',
            });
            setIsModalSuccessOpen(true)
            setIsLoading(false);

            //console.log("Delete Profile successfully", response);
        } catch (error) {
            console.error("Error Delete profile", error);
            setModalFailedStr({
                title: "ไม่สำเร็จ",
                detail: error.response.data.message.messageTh,
            });
            setIsLoading(false);
            setIsModalFailedOpen(true);
        }
    };

    const clickDelete = () => {
        setIsConfirmDeleteModalOpen(true)
    };



    const handleGoBack = () => {
        navigate('/management/manage-user');
    };


    const handleConfirm = async (userId) => {
        console.log('User ID:', userId); // ตรวจสอบว่า id ถูกส่งมาถูกต้อง
        let data = {
            id: formData.id || '',
            emailVerified: true,
        };
        const response = await UserService.updateUser(data.id, data, false);
        console.log("Profile updated successfully", response);
        setIsModalSuccessOpen2(true); // เปิด SuccessDialog  
        fetchData();
    };

    const handleConfirmSendEmail = async (userId) => {
        console.log('User ID:', userId);
        const userIdArray = [userId.toString()];
        console.log(userIdArray);

        // Send the array in the request
        const response = await UserService.sendEmailMuti(userIdArray, false);

        console.log("Send email successfully", response);
        setIsModalSuccessOpen3(true);
        fetchData();
    };




    return (
        <>
            <div className="profile-body" >
                <h2 onClick={handleGoBack} style={{ cursor: 'pointer' }}>
                    <ArrowLeftOutlined /> แก้ไขข้อมูลส่วนตัว
                </h2>
                <div className="color-card">
                    <Row>
                        <Col sm={5} lg={12}>
                            <div className="color-card-img ">
                                <FaUserCircle fontSize={116} className="img-icon" />
                            </div>
                        </Col>
                        <Col sm={19} lg={12}>
                            <div className="color-card-action ">
                                {editMode ? (
                                    <div>
                                        <Row gutter={8}>
                                            <Col sm={12} lg={12}>
                                                <CustomButton text={"ยกเลิก"} onClick={() => setEditMode(false)} />
                                            </Col>
                                            <Col sm={12} lg={12}>
                                                <CustomButton type={"primary"} text={"บันทึก"} onClick={() => handleSave()} />
                                            </Col>
                                        </Row>
                                    </div>
                                ) : (
                                    <div>
                                        {/* {!data?.type && (<CustomButton text={"แก้ไข"} onClick={() => setEditMode(true)} />)} */}
                                        <CustomButton text={"แก้ไข"} onClick={() => setEditMode(true)} />
                                    </div>

                                )}

                            </div>
                        </Col>
                    </Row>

                </div>


                <Row >
                    <Col sm={24} lg={24}>
                        <div className="profile-content">
                            {editMode ? (
                                <div>
                                    <Row gutter={24}>
                                        <Col sm={12} md={12} lg={11}>
                                            <h4>ชื่อ</h4>
                                            <Input
                                                type="text"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col sm={12} md={12} lg={11}>
                                            <h4>นามสกุล</h4>
                                            <Input
                                                type="text"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <p></p>
                                </div>
                            ) : (
                                <div>
                                    <h4>ชื่อ - นามสกุล</h4>
                                    <p>{data?.firstName} {data?.lastName}</p>
                                </div>
                            )}

                            <h4>อีเมล์</h4>
                            {editMode ? (
                                <Row gutter={24}>
                                    <Col sm={24} lg={22}>
                                        <Input
                                            type="text"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <p>{data?.email}</p>
                            )}

                            <h4>แหล่งที่มาของบัญชี</h4>
                            {editMode ? (
                                <ul style={{ listStyleType: 'none', paddingLeft: 0, margin: 0 }}>
                                    {Array.isArray(formData.userSources)
                                        ? formData.userSources.map((source, index) => (
                                            <li key={index} style={{ marginBottom: '8px', color: '#667085' }}>
                                                • {source.name.trim()}
                                            </li>
                                        ))
                                        : formData.userSources
                                            ? formData.userSources.split(',').map((source, index) => (
                                                <li key={index} style={{ marginBottom: '8px', color: '#667085' }}>
                                                    • {source.name.trim()}
                                                </li>
                                            ))
                                            : null}
                                </ul>
                            ) : (
                                <div>
                                    <ul style={{ listStyleType: 'none', paddingLeft: 0, margin: 0 }}>
                                        {Array.isArray(data?.userSources)
                                            ? data.userSources.map((source, index) => (
                                                <li key={index} style={{ marginBottom: '8px', color: '#667085', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Row>
                                                        <Col>
                                                            {/* ข้อความแหล่งที่มา */}
                                                            <span style={{ marginRight: 10 }}>• {source.name.trim()}</span>

                                                            {/* แสดงข้อความป้ายสีแดงในกรณีที่ยังไม่ได้ยืนยันผู้ใช้งาน */}
                                                            {data?.isVerifiedEmail === false && data?.userSources?.map(o=>o.name).includes("MICE Data Platform") && (
                                                                <span
                                                                    style={{
                                                                        backgroundColor: '#FFEBEB',
                                                                        color: '#F5222D',
                                                                        padding: '4px 8px',
                                                                        borderRadius: '15px',
                                                                        fontSize: '12px',
                                                                        //marginLeft: '-500px',
                                                                        borderColor: '#FFA39E',
                                                                        borderStyle: 'solid',
                                                                        borderWidth: '1px',
                                                                    }}
                                                                >
                                                                    ยังไม่ได้ยืนยันผู้ใช้งาน
                                                                </span>

                                                            )}
                                                            {data?.isVerifiedEmail === true && source.name.name  === "MICE Data Platform" && (
                                                                <span
                                                                    style={{
                                                                        backgroundColor: '#F6FFED',
                                                                        color: '#52C41A',
                                                                        padding: '4px 8px',
                                                                        borderRadius: '15px',
                                                                        fontSize: '12px',
                                                                        //marginLeft: '-650px',
                                                                        borderColor: '#B7EB8F',
                                                                        borderStyle: 'solid',
                                                                        borderWidth: '1px',
                                                                    }}
                                                                >
                                                                    ยืนยันตัวตนแล้ว
                                                                </span>
                                                            )}
                                                        </Col>
                                                    </Row>


                                                    {/* ปุ่มส่งอีเมล */}
                                                    {data?.isVerifiedEmail === false && source.name  === "MICE Data Platform" && (
                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                            <button
                                                                style={{
                                                                    color: '#1677FF',
                                                                    backgroundColor: 'transparent',
                                                                    border: 'none',
                                                                    cursor: 'pointer',
                                                                    fontSize: '14px',
                                                                    textDecoration: 'underline',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    padding: '0'
                                                                }}
                                                                onClick={() => {
                                                                    setIsModalMailOpen2(true);
                                                                    setSelectedUserId(data.id); // เก็บ id ที่เลือกใน state
                                                                }}
                                                            >
                                                                <span style={{ marginRight: '5px' }}><MailOutlined /></span> ส่งอีเมลยืนยันตัวตน
                                                            </button>
                                                            <span style={{ color: '#1677FF' }}> | </span>
                                                            <button
                                                                style={{
                                                                    color: '#1677FF',
                                                                    backgroundColor: 'transparent',
                                                                    border: 'none',
                                                                    cursor: 'pointer',
                                                                    fontSize: '14px',
                                                                    textDecoration: 'underline',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    padding: '0'
                                                                }}
                                                                onClick={() => {
                                                                    setIsModalMailOpen(true);
                                                                    setSelectedUserId(data.id); // เก็บ id ที่เลือกใน state
                                                                }}
                                                            >
                                                                <span style={{ marginRight: '5px' }}><CheckOutlined /></span> ยืนยันผู้ใช้งาน
                                                            </button>

                                                        </div>
                                                    )}
                                                    {data?.isVerifiedEmail === true && (
                                                        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                            {(source.name.trim() === "MICE Data Platform") && (
                                                                <button
                                                                    style={{
                                                                        color: "#1677FF",
                                                                        backgroundColor: "transparent",
                                                                        border: "none",
                                                                        cursor: "pointer",
                                                                        fontSize: "14px",
                                                                        textDecoration: "underline",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "0",
                                                                    }}
                                                                    onClick={showModal}
                                                                >
                                                                    <span style={{ marginRight: "5px" }}>
                                                                        <LockOutlined />
                                                                    </span>{" "}
                                                                    กดรีเซ็ตรหัสผ่านใหม่
                                                                </button>
                                                            )}
                                                            {/* ไม่ต้องแสดงปุ่มยกเลิกการเชื่อมต่อบัญชี */}
                                                            {
                                                            /*(source.trim() !== "MICE Data Platform") && (
                                                                <>
                                                                    <button
                                                                        style={{
                                                                            color: '#1677FF',
                                                                            backgroundColor: 'transparent',
                                                                            border: 'none',
                                                                            cursor: 'pointer',
                                                                            fontSize: '14px',
                                                                            textDecoration: 'underline',
                                                                            padding: '0',
                                                                            marginLeft: '0',
                                                                        }}
                                                                        onClick={() => {
                                                                            // handleDisconnect(source);
                                                                        }}
                                                                    >
                                                                        <LinkOutlined style={{ marginRight: '5px' }} /> ยกเลิกการเชื่อมต่อบัญชี
                                                                    </button>
                                                                </>
                                                            )*/}
                                                        </div>
                                                    )}

                                                </li>
                                            ))
                                            : typeof data?.userSources === 'string'
                                                ? data.userSources.split(',').map((source, index) => (
                                                    <li key={index} style={{ marginBottom: '8px', color: '#667085', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        {/* ข้อความแหล่งที่มา */}
                                                        <span>• {source.name.trim()}</span>

                                                        {/* แสดงข้อความป้ายสีแดงในกรณีที่ยังไม่ได้ยืนยันผู้ใช้งาน */}
                                                        {data?.isVerifiedEmail === false && data?.userSources?.includes("MICE Data Platform") && (
                                                            <span
                                                                style={{
                                                                    backgroundColor: '#FFEBEB',
                                                                    color: '#FF0000',
                                                                    padding: '4px 8px',
                                                                    borderRadius: '4px',
                                                                    fontSize: '14px',
                                                                    marginLeft: '-500px',
                                                                }}
                                                            >
                                                                ยังไม่ได้ยืนยันผู้ใช้งาน
                                                            </span>
                                                        )}

                                                        {/* ปุ่มส่งอีเมล */}
                                                        {data?.isVerifiedEmail === false && data?.userSources?.includes("MICE Data Platform") && (
                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                <button
                                                                    style={{
                                                                        color: '#007BFF',
                                                                        backgroundColor: 'transparent',
                                                                        border: 'none',
                                                                        cursor: 'pointer',
                                                                        fontSize: '14px',
                                                                        textDecoration: 'underline',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <span style={{ marginRight: '5px' }}><MailOutlined /></span> ส่งอีเมลยืนยันตัวตน
                                                                </button>

                                                                <button
                                                                    style={{
                                                                        color: '#28a745',
                                                                        backgroundColor: 'transparent',
                                                                        border: 'none',
                                                                        cursor: 'pointer',
                                                                        fontSize: '14px',
                                                                        textDecoration: 'underline',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <span style={{ marginRight: '5px' }}><CheckOutlined /></span> ยืนยันผู้ใช้งาน
                                                                </button>
                                                            </div>
                                                        )}
                                                    </li>
                                                ))
                                                : null}
                                    </ul>
                                </div>
                            )}




                            <h4>เบอร์โทรศัพท์</h4>
                            {editMode ? (
                                <Row gutter={24}>
                                    <Col sm={24} lg={22}>
                                        <Input
                                            type="text"
                                            name="tel"
                                            value={formData.tel}
                                            onChange={handleChange}
                                            maxLength={10}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <p>{data?.tel || 'ไม่มีข้อมูล'}</p>
                            )}

                            <h4>ตำแหน่ง</h4>
                            {editMode ? (
                                <Row gutter={24}>
                                    <Col sm={24} lg={22}>
                                        <Input
                                            type="text"
                                            name="position"
                                            value={formData.position}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <p>{data?.position || 'ไม่มีข้อมูล'}</p>
                            )}


                            <h4>ประเภทงาน</h4>
                            {editMode ? (
                                <Row gutter={24}>
                                    <Col sm={24} lg={22}>
                                        <Select
                                            name="jobFunction"
                                            value={formData.jobFunction}
                                            onChange={(value) => handleChange({ target: { name: 'jobFunction', value } })}
                                            style={{ width: '100%' }}
                                        >
                                            {jobFunctions.map((jobFunction, index) => (
                                                <Select.Option key={index} value={jobFunction}>
                                                    {jobFunction}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            ) : (
                                <p>{data?.jobFunction || 'ไม่มีข้อมูล'}</p>
                            )}


                            <h4>บทบาทหน้าที่</h4>
                            {editMode ? (
                                <Row gutter={24}>
                                    <Col sm={24} lg={22}>
                                        <Select
                                            name="role"
                                            value={formData.role}
                                            onChange={(value) => setFormData((prevState) => ({ ...prevState, role: value }))}
                                            style={{ width: '100%' }}
                                        >
                                            {/* แสดง roles ที่ได้รับจาก API */}
                                            {roles.map((role) => (
                                                <Select.Option key={role.id} value={role.id}>
                                                    {role.nameTh}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            ) : (
                                <p>{data?.roleNameTh || 'ไม่มีข้อมูล'}</p>
                            )}

                            <h4>สถานะการใช้งาน</h4>
                            {editMode ? (
                                <Row gutter={24}>
                                    <Col sm={24} lg={22}>
                                        <Switch
                                            checked={formData.isEnabled !== undefined ? formData.isEnabled : data?.isEnabled}
                                            onChange={(checked) =>
                                                handleChange({ target: { name: 'isEnabled', value: checked } })
                                            }
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Switch checked={data?.isEnabled} disabled />
                            )}

                        </div>
                    </Col>
                </Row>
                <br />


                <Modal
                    centered
                    title=""
                    open={isConfirmDeleteModalOpen}
                    footer={null}
                    closable={false}
                    destroyOnClose={true}
                    onCancel={() => {
                        //onCancel();
                    }}
                    afterClose={(e) => {
                        // onAfterClose();
                    }}
                >
                    <Flex justify="left" align="left" vertical>
                        <IconConWarningGray
                            style={{
                                marginTop: 12,
                            }}
                        />
                        <span
                            style={{
                                fontWeight: 600,
                                fontSize: 22,
                                marginTop: 12,
                            }}
                        >
                            {"คุณกำลังจะลบบัญชีของคุณ"}
                        </span>
                        <span
                            style={{
                                fontWeight: 500,
                                fontSize: 16,
                                marginTop: 8,
                                textAlign: "left"
                            }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: "การลบบัญชีจะทำให้คุณไม่สามารถเข้าถึงการตั้งค่าใดๆ ของคุณ <br/> หรือลงชื่อเข้าใช้ TCEB ได้อีก การกระทำนี้ไม่สามารถย้อนกลับได้" }} />
                            <hr style={{ marginTop: 20 }} />
                        </span>


                    </Flex>
                    <Form
                        //form={form}
                        name="confirm-email-form"
                        className="confirm-email-form"
                        layout='vertical'

                    // initialValues={{ remember: true }}
                    >
                        <Form.Item
                            name="itemConfirmEmail"
                            label={<span className='label' style={{ fontWeight: 600, fontSize: 16 }}> กรอกอีเมลของคุณเพื่อยืนยันการลบ</span>}
                            rules={[{ required: true, message: 'กรอกอีเมลของคุณเพื่อยืนยัน' }]}
                            required={false}
                        >
                            <Input name='confirmEmail' placeholder="ต.ย. Name@email.com" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} />
                        </Form.Item>
                    </Form>
                    <Flex
                        gap="middle"
                        justify="center"
                        align="center"
                        vertical={false}
                        style={{}}
                    >

                        <Button
                            type="primary"
                            onClick={() => {
                                setIsConfirmDeleteModalOpen(false)
                            }}
                            style={{
                                width: 150,
                                height: 40,
                                marginTop: 20,
                                // marginRight: 10,
                                backgroundColor: "#EBEBEB",
                                // color: "#ffffff",
                                // border: "2px solid #FF7F07",
                            }}
                        // type="primary"
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                //console.log("confirmEmail ==>", confirmEmail)
                                //console.log("confirmEmail == data.email ==>", confirmEmail == data.email)
                                if (confirmEmail == data.email) {
                                    handleDelete();
                                    setIsConfirmDeleteModalOpen(false);
                                } else {
                                    setModalFailedStr({
                                        title: "ผิดผลาด",
                                        detail: "อีเมลของคุณไม่ถูกต้อง",
                                    });
                                    setIsModalFailedOpen(true);
                                }
                            }}
                            style={{
                                width: 150,
                                height: 40,
                                marginTop: 20,
                                // marginRight: 10,
                                backgroundColor: "#FF4D4F",
                                color: "#ffffff",
                                // border: "2px solid #FF7F07",
                            }}
                        // type="primary"
                        >
                            ยืนยัน
                        </Button>
                    </Flex>

                </Modal>

                {isLoading && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <div className="spinner"></div>
                            <p>กรุณารอซักครู่...</p>
                        </div>
                    </div>
                )}

                <FailedDialog
                    isModalOpen={isModalFailedOpen}
                    closable={false}
                    title={modalFailedStr.title}
                    detail={modalFailedStr.detail}
                    onCancel={() => {
                        setIsModalFailedOpen(false);
                    }}
                    onAfterClose={() => {
                        setIsModalFailedOpen(false);
                    }}
                    onConfirm={() => {
                        setIsModalFailedOpen(false);
                    }}
                />

                <SuccessDialog
                    isModalOpen={isModalSuccessOpen}
                    closable={false}
                    title={modalSuccessStr.title}
                    detail={modalSuccessStr.detail}
                    onCancel={() => {
                        handleLogin()
                        setIsModalSuccessOpen(false);
                    }}
                    onAfterClose={() => {
                        handleLogin()
                    }}
                />


                <ConfirmDialog
                    isModalOpen={isModalMailOpen}
                    closable={false}
                    type="mail"
                    title="ยืนยันผู้ใช้งาน"
                    description="คุณต้องการยืนยันผู้ใช้งานนี้ ใช่หรือไม่ ?"
                    cancelText="ยกเลิก"
                    confirmText="บันทึก"
                    onCancel={() => {
                        setIsModalMailOpen(false);
                    }}
                    onConfirm={() => handleConfirm(selectedUserId)} // ส่ง selectedUserId เข้าไปใน handleConfirm
                />

                <ConfirmDialog
                    isModalOpen={isModalMailOpen2}
                    closable={false}
                    type="mail"
                    title="ส่งอีเมล"
                    description="คุณต้องการส่งอีเมลยืนยันผู้ใช้งานนี้ ใช่หรือไม่ ?"
                    cancelText="ยกเลิก"
                    confirmText="บันทึก"
                    onCancel={() => {
                        setIsModalMailOpen2(false);
                    }}
                    onConfirm={() => handleConfirmSendEmail(selectedUserId)} // ส่ง selectedUserId เข้าไปใน handleConfirm
                />


                {/* SuccessDialog */}
                <SuccessDialog
                    isModalOpen={isModalSuccessOpen}
                    closable={false}
                    title="สำเร็จ"
                    detail="ยืนยันข้อมูลสำเร็จแล้ว"
                    onCancel={() => {
                        setIsModalSuccessOpen(false);
                    }}
                    onAfterClose={() => {

                    }}
                />

                {/* SuccessDialog */}
                <SuccessDialog
                    isModalOpen={isModalSuccessOpen2}
                    closable={false}
                    title="สำเร็จ"
                    detail="ยืนยันข้อมูลสำเร็จแล้ว"
                    onCancel={() => {
                        setIsModalSuccessOpen2(false);
                    }}
                    onAfterClose={() => {
                        setIsModalSuccessOpen2(false);
                    }}
                />

                <SuccessDialog
                    isModalOpen={isModalSuccessOpen3}
                    closable={false}
                    title="สำเร็จ"
                    detail="ส่งอีเมลยืนยันตัวตนสำเร็จแล้ว"
                    onCancel={() => {
                        setIsModalSuccessOpen3(false);
                    }}
                    onAfterClose={() => {
                        setIsModalSuccessOpen3(false);
                    }}
                />

                <ConfirmDialog
                    isModalOpen={isModalOpen}
                    closable={false}
                    title="ยืนยันการบันทึกข้อมูล"
                    description="คุณต้องการบันทึกข้อมูลใช่หรือไม่?"
                    cancelText="ยกเลิก"
                    confirmText="บันทึก"
                    onCancel={() => {
                        setIsModalOpen(false);
                    }}
                    onConfirm={handleSaveData}
                />

                <SuccessDialog
                    isModalOpen={isModalSuccessOpenSave}
                    closable={false}
                    title="สำเร็จ"
                    detail="บันทึกข้อมูลสำเร็จแล้ว"
                    onCancel={() => {
                        setIsModalSuccessOpenSave(false);
                    }}
                    onAfterClose={() => {

                    }}
                />
                <Modal
                    title="ตั้งรหัสผ่านใหม่"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <Input
                        value={generatedPassword}
                        onChange={handlePasswordChange} // อัปเดตรหัสผ่านเมื่อแก้ไข
                        addonAfter={
                            <Button
                                type="link"
                                onClick={showModal}
                                style={{
                                    padding: 0, // ลบ padding ของปุ่มให้เท่ากับช่อง input
                                    height: "50%", // ความสูงของปุ่มให้เท่ากับช่อง input
                                    lineHeight: "20px", // ให้ข้อความในปุ่มมีขนาดเหมาะสม
                                    fontSize: "14px", // ขนาดตัวอักษรของปุ่มให้พอดีกับช่อง input
                                }}
                            >
                                สร้างรหัสผ่านใหม่
                            </Button>
                        }
                        style={{
                            width: "100%", // ความกว้างเต็มที่ของช่อง input
                            height: "32px", // ความสูงของช่อง input
                            fontSize: "14px", // ขนาดตัวอักษรในช่อง input
                        }}
                    />
                </Modal>


            </div>
        </>
    );
};

export default ManageUserDetail;
