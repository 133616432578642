import React from "react";
import "./CustomButton.css"
import { Button, Tooltip } from "antd";
import { AiOutlineMail } from "react-icons/ai";

const MailButton = ({ onClick }) => {
  return (
    <Tooltip title="ส่งอีเมลยืนยันตัวตน">
      <Button
        className="custom-button-action"
        icon={
          <AiOutlineMail
            style={{
              fontSize: "20px",
              // color: "#000",
            }}
          />
        }
        onClick={onClick}
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "8px",
        }}
      />
    </Tooltip>
  );
};

export default MailButton;
