import React from "react";
import { Button, Badge } from "antd";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";

const FilterButton = ({ onClick, count }) => {
  return (
    <Button
      icon={
        <HiOutlineAdjustmentsHorizontal
          style={{
            fontSize: "16px",
          }}
        />
      }
      onClick={onClick}
      style={{
        height: "40px",
        borderRadius: "10px",
        fontSize: "16px",
        fontWeight: "300",
      }}
    >
      <span>ตัวกรอง</span>
      <Badge count={count} />
    </Button>
  );
};

export default FilterButton;
