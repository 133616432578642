import React from "react";
import {} from "antd";
import "./CustomIFrame.css";

const CustomIFrame = ({ title, description, src }) => {
  return (
    <>
      <div className="iframe-wrapper">
        <p className="iframe-title">{title}</p>
        <p className="iframe-description">{description}</p>
        <div className="iframe-container">
          <iframe
            className="iframe"
            src={src}
            allowFullScreen
          />
        </div>
      </div>
    </>
  );
};

export default CustomIFrame;
