import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="124"
    viewBox="0 0 100 124"
    fill="none"
  >
    <path
      d="M50 115.003C77.6142 115.003 100 92.6172 100 65.0029C100 37.3887 77.6142 15.0029 50 15.0029C22.3858 15.0029 0 37.3887 0 65.0029C0 92.6172 22.3858 115.003 50 115.003Z"
      fill="url(#paint0_linear_7078_80210)"
    />
    <g filter="url(#filter0_d_7078_80210)">
      <path
        d="M78.6628 43.668H21.3294C19.4885 43.668 17.9961 45.1604 17.9961 47.0013V117.001C17.9961 118.842 19.4885 120.335 21.3294 120.335H78.6628C80.5037 120.335 81.9961 118.842 81.9961 117.001V47.0013C81.9961 45.1604 80.5037 43.668 78.6628 43.668Z"
        fill="white"
      />
    </g>
    <path
      d="M43.3333 53.6699H26C24.8954 53.6699 24 54.5654 24 55.6699C24 56.7745 24.8954 57.6699 26 57.6699H43.3333C44.4379 57.6699 45.3333 56.7745 45.3333 55.6699C45.3333 54.5654 44.4379 53.6699 43.3333 53.6699Z"
      fill="#E6E6E6"
    />
    <path
      d="M55.3333 62.3359H26C24.8954 62.3359 24 63.2314 24 64.3359C24 65.4405 24.8954 66.3359 26 66.3359H55.3333C56.4379 66.3359 57.3333 65.4405 57.3333 64.3359C57.3333 63.2314 56.4379 62.3359 55.3333 62.3359Z"
      fill="#C0C0C0"
    />
    <path
      d="M43.3333 71.6709H26C24.8954 71.6709 24 72.5663 24 73.6709C24 74.7755 24.8954 75.6709 26 75.6709H43.3333C44.4379 75.6709 45.3333 74.7755 45.3333 73.6709C45.3333 72.5663 44.4379 71.6709 43.3333 71.6709Z"
      fill="#E6E6E6"
    />
    <path
      d="M55.3333 80.3369H26C24.8954 80.3369 24 81.2323 24 82.3369C24 83.4415 24.8954 84.3369 26 84.3369H55.3333C56.4379 84.3369 57.3333 83.4415 57.3333 82.3369C57.3333 81.2323 56.4379 80.3369 55.3333 80.3369Z"
      fill="#C0C0C0"
    />
    <path
      d="M43.3333 89.666H26C24.8954 89.666 24 90.5614 24 91.666C24 92.7706 24.8954 93.666 26 93.666H43.3333C44.4379 93.666 45.3333 92.7706 45.3333 91.666C45.3333 90.5614 44.4379 89.666 43.3333 89.666Z"
      fill="#E6E6E6"
    />
    <path
      d="M55.3333 98.332H26C24.8954 98.332 24 99.2275 24 100.332C24 101.437 24.8954 102.332 26 102.332H55.3333C56.4379 102.332 57.3333 101.437 57.3333 100.332C57.3333 99.2275 56.4379 98.332 55.3333 98.332Z"
      fill="#C0C0C0"
    />
    <g filter="url(#filter1_d_7078_80210)">
      <path
        d="M78.6628 9.66504H21.3294C19.4885 9.66504 17.9961 11.1574 17.9961 12.9984V32.9984C17.9961 34.8393 19.4885 36.3317 21.3294 36.3317H78.6628C80.5037 36.3317 81.9961 34.8393 81.9961 32.9984V12.9984C81.9961 11.1574 80.5037 9.66504 78.6628 9.66504Z"
        fill="#A5A5A5"
      />
    </g>
    <path
      d="M43.3333 16.9971H26C24.8954 16.9971 24 17.8925 24 18.9971C24 20.1016 24.8954 20.9971 26 20.9971H43.3333C44.4379 20.9971 45.3333 20.1016 45.3333 18.9971C45.3333 17.8925 44.4379 16.9971 43.3333 16.9971Z"
      fill="#E6E6E6"
    />
    <path
      d="M55.3333 25.6631H26C24.8954 25.6631 24 26.5585 24 27.6631C24 28.7677 24.8954 29.6631 26 29.6631H55.3333C56.4379 29.6631 57.3333 28.7677 57.3333 27.6631C57.3333 26.5585 56.4379 25.6631 55.3333 25.6631Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_7078_80210"
        x="11.9961"
        y="34.668"
        width="76"
        height="88.667"
        filterUnits="userSpaceOnUse"
        colorcolorinterpolationfilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7078_80210"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7078_80210"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_7078_80210"
        x="11.9961"
        y="0.665039"
        width="76"
        height="38.667"
        filterUnits="userSpaceOnUse"
        colorcolorinterpolationfilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7078_80210"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7078_80210"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_7078_80210"
        x1="50"
        y1="15.0029"
        x2="50"
        y2="115.003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EAEAEA" />
        <stop offset="1" stopColor="#CBCBCB" />
      </linearGradient>
    </defs>
  </svg>
);

const no_data = (props) => <Icon component={icon} {...props} />;

export default no_data;
