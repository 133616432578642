
import React, { useEffect, useState } from "react";
import { Space, Col, Row, Table, Input, Button, Modal, message, Flex, Switch, Badge, Pagination, Spin } from "antd";
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'; // Correct path for Heroicons v2
import { DeleteOutlined, EditOutlined, MailOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from "../../../components/index.js";
import SuccessDialog from "../../../components/Dialog/SuccessDialog.js";
import FailedDialog from "../../../components/Dialog/FailedDialog.js";
import "./ManageUser.css";
import { UserService } from "../../../services/UserService.js";
import DeleteDialog from "../../../components/Dialog/DeleteDialog.js";
import NoData from "../../../assets/img/no_data.js";
const ManageUserPage = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);
    const [isModalSuccessOpenSave, setIsModalSuccessOpenSave] = useState(false);
    const [usersData, setUsersData] = useState([]);  // ข้อมูลผู้ใช้
    const [totalCount, setTotalCount] = useState(0);  // จำนวนข้อมูลทั้งหมด
    const [totalNotVerifyEmail, setTotalNotVerifyEmail] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);  // หน้าเริ่มต้น
    const [pageSize, setPageSize] = useState(10);  // จำนวนรายการต่อหน้า
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filters, setFilters] = useState({
        IsVerifiedEmail: null, // สถานะอีเมล
        roles: [], // บทบาทหน้าที่
        type: null, // ประเภท
    });
    const [selectedCount1, setSelectedCount1] = useState(0); // นับสำหรับ filter อันแรก
    const [selectedCount2, setSelectedCount2] = useState(0);
    const [selectedCount3, setSelectedCount3] = useState(0);
    const [keywords, setKeywords] = useState("");
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalDeleteMutiOpen, setIsModalDeleteMutiOpen] = useState(false);
    const [isModalEmailMutiOpen, setIsModalEmailMutiOpen] = useState(false);

    const [selectedId, setSelectedId] = useState(null);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [isModalSuccessOpen3, setIsModalSuccessOpen3] = useState(false);
    const [tab, setTab] = useState('all'); // ค่าเริ่มต้นคือ 'all'
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
    const [isModalFailedOpen2, setIsModalFailedOpen2] = useState(false);

    const [errorDetail, setErrorDetail] = useState("");
    const [isModalSuccessOpen2, setIsModalSuccessOpen2] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    let searchTimeout;

    const handleSelectChange = (event) => {
        // อัปเดตค่า type ใน filters โดยไม่ลบค่าของคีย์อื่นๆ
        setFilters(prevFilters => ({
            ...prevFilters,
            type: event.target.value, // อัปเดตเฉพาะ type
        }));

        // เพิ่ม count เมื่อมีการเลือกใหม่
        setSelectedCount3(1); // เพิ่มจำนวนขึ้น 1
    };


    const handleSearchChange = (e) => {
        const value = e.target.value;
        setKeywords(value);

        // ล้าง timeout เดิมก่อนจะตั้งใหม่
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }


        searchTimeout = setTimeout(() => {
            fetchData(undefined, undefined, { Keywords: value });
        }, 1500);
    };


    const fetchData = async (currentPageNext = currentPage, pageSizeNext = pageSize, filters = {}) => {
        setLoading(true);
        setIsModalVisible(true);
        try {
            const response = await UserService.getalluser(currentPageNext, pageSizeNext, filters);

            // ตรวจสอบว่ามี response.items ก่อนทำการกรอง
            if (response && response.items) {
                const countUnverifiedEmails = response.items.filter(item => item.isVerifiedEmail === false).length;
                setUsersData(response.items);
                setTotalCount(response.pagination.totalCount);
                setTotalNotVerifyEmail(countUnverifiedEmails);
            } else {
                console.error("No items found in response");
            }
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false); // หยุดโหลดข้อมูล
            setIsModalVisible(false); // ปิด Modal เมื่อโหลดเสร็จ
        }
    };



    useEffect(() => {
        fetchData();
    }, []);


    const handleDelete = (id) => {
        console.log(id);
        setSelectedId(id); // เก็บ id ที่เลือกไว้
        setIsModalDeleteOpen(true); // เปิด Modal
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await UserService.deleteUser(selectedId);
            if (response.status === 204) {
                setIsModalSuccessOpen(true);
                fetchData();
            } else {
                setIsModalDeleteOpen(false);
            }
        } catch (error) {
            console.error('เกิดข้อผิดพลาดในการลบข้อมูล:', error);
        }
    };

    const handleConfirmDeleteMuti = async () => {
        console.log("Selected records to delete:", selectedRecords);

        try {
            // ตรวจสอบว่ามีการเลือก record หรือไม่
            if (selectedRecords.length === 0) {
                console.log("ไม่มีรายการที่เลือกสำหรับลบ");
                return; // หากไม่มีรายการให้ลบให้หยุดการทำงาน
            }
            const response = await UserService.deleteUserMuti(selectedRecords);

            if (response.status === 204) {
                setIsModalSuccessOpen(true); // แสดง modal success
                fetchData(); // ดึงข้อมูลใหม่
                setSelectedRecords([]); // ล้าง selected records หลังจากลบสำเร็จ
            } else {
                setIsModalDeleteMutiOpen(false); // ปิด modal ถ้าลบไม่สำเร็จ
            }
        } catch (error) {
            console.error("เกิดข้อผิดพลาดในการลบข้อมูล:", error);

            // ตั้งค่าข้อความ error ที่จะส่งไปแสดงใน modal
            setErrorDetail(error.message || "เกิดข้อผิดพลาดไม่ทราบสาเหตุ"); // ตั้งค่าข้อความ error
            setIsModalFailedOpen(true); // เปิด modal ที่แสดงข้อผิดพลาด
        }
    };




    const handleEdit = (id) => {
        //console.log(id);
        // /management/account-management
        navigate('/management/manage-user-detail', { state: { id } });
        window.scrollTo(0, 0);
    };

    const handleSwitchChange = async (checked, record) => {
        console.log(checked);
        setUsersData(prevData =>
            prevData.map(item =>
                item.id === record.id ? { ...item, isEnabled: checked } : item
            )
        );
        const payload = {
            id: record.id,
            enabled: checked, // ตั้งค่าสถานะ enabled เป็น true/false ตามที่กด
        };
        try {
            const response = await UserService.updateUser(record.id, payload);
            setIsModalSuccessOpen3(true);
        } catch (error) {
            console.error("Error updating user", error);
            message.error('อัปเดตสถานะไม่สำเร็จ');
        } finally {
            // ปิด modal ทุกครั้ง
            setIsModalOpen(false);
            setIsModalOpen2(false);
        }
    };

    const TabnotverifyEmail = (e, filterKey) => {
        const newFilters = { ...filters, [filterKey]: false }; // ตั้งค่า IsVerifiedEmail เป็น false
        setFilters(newFilters);
        fetchData("", "", newFilters); // เรียก fetchData พร้อม filters ใหม่
    };


    const TabshowAll = (e, filterKey) => {
        fetchData();
    };


    const handleSelectRecord = (record, isChecked) => {
        setSelectedRecords((prevSelected) => {
            if (isChecked) {
                // เพิ่ม record.id หากเช็คบ็อกซ์ถูกติ๊ก
                return [...prevSelected, record.id];
            } else {
                // ลบ record.id หากเช็คบ็อกซ์ถูกเอาติ๊กออก
                return prevSelected.filter((id) => id !== record.id);
            }
        });
    };


    const handleDeleteMuti = () => {
        console.log("Delete selected records:", selectedRecords);
        setIsModalDeleteMutiOpen(true);
    };


    const handleSendEmailMuti = () => {
        setIsModalEmailMutiOpen(true);
    };

    const handleConfirmSendEmail = async () => {
        try {
            // ดึงข้อมูลทั้งหมดจาก UserService
            const userResponse = await UserService.getalluser();
            console.log("userResponse", userResponse);

            if (userResponse && userResponse.items) {
                const allRecords = userResponse.items;

                // กรองรายการที่ยังไม่ได้ยืนยันอีเมล
                const unverifiedRecords = allRecords.filter(record =>
                    selectedRecords.includes(record.id) && !record.isVerifiedEmail
                );

                console.log("Unverified records:", unverifiedRecords);

                // กรองรายการที่ยืนยันอีเมลแล้ว
                const verifiedRecords = allRecords.filter(record =>
                    selectedRecords.includes(record.id) && record.isVerifiedEmail
                );

                if (verifiedRecords.length > 0) {
                    setIsModalFailedOpen2(true);
                    return;  // หยุดการทำงานต่อ
                }


                const unverifiedIds = unverifiedRecords.map(record => record.id);
                console.log("Unverified IDs:", unverifiedIds);
                if (unverifiedIds.length > 0) {
                    const sendEmailResponse = await UserService.sendEmailMuti(unverifiedIds, false);
                    console.log("Send email successfully", sendEmailResponse);
                    setIsModalSuccessOpen2(true);  // เปิด modal success
                    fetchData();
                } else {
                    console.log("ไม่มีผู้ใช้ที่ยังไม่ได้ยืนยันอีเมล");
                }
            } else {
                console.log("ไม่พบข้อมูลผู้ใช้");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };






    const columns = [
        {
            title: <input type="checkbox" />,
            dataIndex: "select",
            key: "select",
            render: (_, record) => (
                <input
                    type="checkbox"
                    checked={selectedRecords.includes(record.id)} // ตรวจสอบว่า id นี้ถูกเลือกแล้วหรือไม่
                    onChange={(e) => handleSelectRecord(record, e.target.checked)}
                />
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>คำสั่ง</span>,
            key: 'action',
            width: '10%',
            sorter: (a, b) => a.index - b.index,
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="default"
                        icon={<EditOutlined />}
                        style={{ padding: '5px 10px' }}
                        onClick={() => handleEdit(record.id)} // ส่ง id ไป
                    />
                    <Button
                        type="default"
                        icon={<DeleteOutlined />}
                        style={{ padding: '5px 10px' }}
                        onClick={() => handleDelete(record.id)} // ส่ง id ไป
                    />
                    {/* แสดงปุ่มไอคอนจดหมายเฉพาะเมื่อ id ถูกเลือก */}
                    {selectedRecords.includes(record.id) && (
                        <Button
                            type="default"
                            icon={<MailOutlined />} // ใช้ไอคอนจดหมาย
                            style={{ padding: '5px 10px' }}
                            onClick={() => handleSendEmailMuti()} // ส่ง id ไป
                        />
                    )}
                </Space>
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>#</span>,
            key: 'index',
            render: (_, __, index) => (
                <span>{(currentPage - 1) * pageSize + index + 1}</span> // คำนวณลำดับใหม่ตามหน้า
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>ชื่อ - นามสกุล</span>,
            dataIndex: "fullName",
            key: "fullName",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>อีเมล</span>,
            dataIndex: "email",
            key: "email",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>สถานะ</span>,
            dataIndex: "isVerifiedEmail",
            key: "isVerifiedEmail",
            render: (isActivated, record) => (
                <button
                    style={{
                        backgroundColor: isActivated ? "#f0fdf4" : "#fff5f5",
                        color: isActivated ? "#22c55e" : "#f44336",
                        border: isActivated ? "1px solid #bbf7d0" : "1px solid #fbc2c4",
                        borderRadius: "12px",
                        padding: "5px 12px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        cursor: "default",
                    }}
                >
                    {isActivated ? "ตรวจสอบแล้ว" : "ยังไม่ได้ยืนยันผู้ใช้งาน"}
                </button>
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>เบอร์โทรศัพท์</span>,
            dataIndex: "tel",
            key: "tel",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>ตำแหน่ง</span>,
            dataIndex: "position",
            key: "position",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>ประเภทงาน</span>,
            dataIndex: "jobFunction",
            key: "jobFunction",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>แหล่งที่มาของ ผู้ใช้งาน</span>,
            dataIndex: "userSources",
            key: "userSources",
            render: (text) => Array.isArray(text) ? text.map(o=>o.name).join(', ') : (text || '-'),
        },
        {
            title: <span style={{ color: '#475467' }}>บทบาทหน้าที่</span>,
            dataIndex: "roleNameTh",
            key: "roleNameTh",
            render: (role) => (
                <button
                    style={{
                        backgroundColor: "#f9fafb", // สีพื้นหลัง
                        color: "#374151", // สีตัวอักษร
                        border: "1px solid #d1d5db", // สีขอบ
                        borderRadius: "12px", // มุมมน
                        padding: "5px 12px", // ระยะห่างภายใน
                        fontSize: "14px", // ขนาดตัวอักษร
                        fontWeight: "bold", // ตัวหนา
                        cursor: "default", // ไม่สามารถกดได้
                    }}
                >
                    {role || 'ไม่มีบทบาท'}
                </button>
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>การให้คำยินยอม</span>,
            dataIndex: "access",
            key: "access",
            render: (access) => (
                <Button
                    type="default"
                    icon={
                        access === "ยอมรับการใช้งาน" ? (
                            <Badge status="success" />
                        ) : (
                            <Badge status="error" />
                        )
                    }
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderColor: "#d9d9d9",
                        borderRadius: "30px"
                    }}
                    onClick={() => {
                        if (access === "ยอมรับการใช้งาน") {
                            setIsModalOpen(true);
                        } else {
                            setIsModalOpen2(true);
                        }
                    }}
                >
                    {access || '-'}
                </Button>
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>Inactive/Active</span>,
            dataIndex: "isEnabled",
            key: "isEnabled",
            render: (isActive, record) => (
                <Switch
                    checked={isActive}
                    onChange={(checked) => {
                        setCurrentRecord(record); // เก็บ record ปัจจุบันใน state
                        if (checked) {
                            setIsModalOpen2(true);
                        } else {
                            setIsModalOpen(true);
                        }
                    }}
                />
            ),
        },
        {
            title: <span style={{ color: '#475467' }}>สร้างล่าสุด</span>,
            dataIndex: "createdDateText",
            key: "createdDateText",
            render: (text) => text || '-',
        },
        {
            title: <span style={{ color: '#475467' }}>เข้าใช้งานล่าสุด</span>,
            dataIndex: "lastAccessDateText",
            key: "lastAccessDateText",
            render: (text) => text || '-',
        }
    ];


    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const handleCheckboxChange = (event, filterKey) => {
        const value = event.target.value === 'true';
        const isChecked = event.target.checked;
        setFilters((prev) => {
            let updatedFilters = { ...prev };
            if (isChecked) {
                updatedFilters[filterKey] = value;
            } else {
                updatedFilters[filterKey] = null;
            }
            setSelectedCount1(isChecked ? 1 : 0);

            return updatedFilters;
        });
    };


    const handleCheckboxChange2 = (event, filterKey) => {
        const isChecked = event.target.checked;
        const value = event.target.value;
        setFilters((prev) => {
            let updatedFilters = { ...prev };
            if (!updatedFilters[filterKey]) {
                updatedFilters[filterKey] = [];
            }
            if (isChecked) {
                if (!updatedFilters[filterKey].includes(value)) {
                    updatedFilters[filterKey].push(value);
                }
            } else {
                updatedFilters[filterKey] = updatedFilters[filterKey].filter((v) => v !== value);
            }
            const selectedCount = Object.values(updatedFilters).reduce((count, currentValue) => {
                if (Array.isArray(currentValue)) {
                    return count + currentValue.length;
                }
                return count;
            }, 0);
            setSelectedCount2(selectedCount);

            return updatedFilters;
        });
    };

    const totalSelectedCount = selectedCount1 + selectedCount2 + selectedCount3;



    const handleChangeStatus = async () => {
        setIsModalSuccessOpenSave(true);
        setTimeout(() => {
            setIsModalSuccessOpenSave(false);
        }, 2000);
    }

    const handleSearchFilter = () => {
        setCurrentPage(1);
        fetchData("", "", filters)
    }

    return (

        <div className="page-container">
            <div className="tag-content">
                <h2>จัดการข้อมูลผู้ใช้งาน</h2>
                <div>
                    {/* แท็บ */}
                    <div style={{ display: 'flex', borderBottom: '2px solid #E5E5E5', marginBottom: '16px' }}>
                        <div
                            onClick={(e) => {
                                setTab('all');
                                TabshowAll(); // ตั้งค่า filters และ fetchData
                            }}
                            style={{
                                padding: '8px 16px',
                                cursor: 'pointer',
                                fontWeight: tab === 'all' ? 'bold' : 'normal',
                                color: '#683091',
                                borderBottom: tab === 'all' ? '4px solid #683091' : '4px solid transparent',
                                transition: 'border-color 0.3s',
                            }}
                        >
                            ผู้ใช้งานทั้งหมด ({totalCount})
                        </div>
                        <div
                            onClick={(e) => {
                                setTab('pending');
                                TabnotverifyEmail(e, 'IsVerifiedEmail'); // ตั้งค่า filters และ fetchData
                            }}
                            style={{
                                padding: '8px 16px',
                                cursor: 'pointer',
                                fontWeight: tab === 'pending' ? 'bold' : 'normal',
                                color: '#683091',
                                borderBottom: tab === 'pending' ? '4px solid #683091' : '4px solid transparent',
                                transition: 'border-color 0.3s',
                            }}
                        >
                            ยืนยันอีเมลผู้ใช้งาน ({totalNotVerifyEmail})
                        </div>
                    </div>
                </div>

                <Row gutter={16} align="middle" style={{ justifyContent: 'space-between' }}>
                    <Col span={12}>
                        <Input
                            placeholder="ค้นหา ชื่อ-นามสกุล, อีเมล, ตำแหน่ง..."
                            value={keywords}
                            onChange={handleSearchChange}
                            style={{
                                width: '100%',
                                height: '40px',
                                borderRadius: '10px',
                            }}
                        />
                    </Col>
                    <Col
                        span={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: '16px', // ระยะห่างระหว่าง Button และ Filter
                        }}
                    >
                        {selectedRecords.length > 0 && (
                            <>
                                <Button
                                    type="default"
                                    icon={<DeleteOutlined />}
                                    style={{ padding: '5px 10px' }}
                                    onClick={() => handleDeleteMuti()} // ส่ง id ไป
                                />
                                <Button
                                    type="default"
                                    icon={<MailOutlined />} // ใช้ไอคอนจดหมาย
                                    style={{ padding: '5px 10px' }}
                                    onClick={() => handleSendEmailMuti()} // ส่ง id ไป
                                />
                            </>
                        )}
                        <div style={{ position: 'relative' }}>
                            <button
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    color: '#000000',
                                    border: '1px solid #D1D5DB',
                                    borderRadius: '12px',
                                    padding: '8px 16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer',
                                }}
                                onClick={toggleFilter}
                            >
                                <AdjustmentsHorizontalIcon style={{ width: '20px', height: '20px' }} />
                                ตัวกรอง
                                {totalSelectedCount > 0 && (
                                    <span
                                        style={{
                                            backgroundColor: '#FF0000',
                                            color: '#FFFFFF',
                                            borderRadius: '50%',
                                            padding: '4px 8px',
                                            marginLeft: '8px',
                                            fontSize: '9px',
                                            fontWeight: 'bold',
                                            minWidth: '20px',
                                            textAlign: 'center',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {totalSelectedCount}
                                    </span>
                                )}
                            </button>

                            {/* เมนูตัวกรอง */}
                            {showFilter && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50px',
                                        left: '-200px',
                                        backgroundColor: 'white',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '12px',
                                        padding: '16px',
                                        zIndex: 1050,
                                        width: '300px',
                                        overflow: 'auto',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        <h4 style={{ margin: 0, color: '#000000' }}>
                                            ตัวกรอง
                                            {totalSelectedCount > 0 && (
                                                <span
                                                    style={{
                                                        backgroundColor: '#FF0000',
                                                        color: '#FFFFFF',
                                                        borderRadius: '50%',
                                                        padding: '4px 8px',
                                                        marginLeft: '8px',
                                                        fontSize: '9px',
                                                        fontWeight: 'bold',
                                                        minWidth: '20px',
                                                        textAlign: 'center',
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    {totalSelectedCount}
                                                </span>
                                            )}
                                        </h4>
                                        <button
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: '#3B82F6',
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                            }}
                                            onClick={() => {
                                                setFilters({});
                                                setSelectedCount(0);
                                                setSelectedCount1(0);
                                                setSelectedCount2(0);
                                                setSelectedCount3(0);
                                                document
                                                    .querySelectorAll('input[type="checkbox"]')
                                                    .forEach((checkbox) => {
                                                        checkbox.checked = false;
                                                    });
                                            }}
                                        >
                                            ล้างข้อมูล
                                        </button>
                                    </div>

                                    <div>
                                        <label>ประเภท</label>
                                        <select
                                            value={filters.type || ''}
                                            onChange={handleSelectChange}
                                            style={{
                                                width: '100%',
                                                padding: '8px',
                                                border: '1px solid #D1D5DB',
                                                borderRadius: '8px',
                                                marginBottom: '16px',
                                            }}
                                        >
                                            <option value="">ทุกประเภท</option>
                                            <option value="MICE Data Platform">MICE Data Platform</option>
                                            <option value="TCEB SSO">TCEB SSO</option>
                                            <option value="Google">Google</option>
                                        </select>

                                        <label>บทบาทหน้าที่</label>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="ไม่ระบุตัวตน"
                                                    onChange={(e) => handleCheckboxChange2(e, 'roles')}
                                                />{' '}
                                                ไม่ระบุตัวตน
                                            </label>
                                            <br />
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="ผู้ใช้ทั่วไป"
                                                    onChange={(e) => handleCheckboxChange2(e, 'roles')}
                                                />{' '}
                                                ผู้ใช้ทั่วไป
                                            </label>
                                            <br />
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="ผู้ใช้งานภายใน TCEB"
                                                    onChange={(e) => handleCheckboxChange2(e, 'roles')}
                                                />{' '}
                                                ผู้ใช้งานภายใน TCEB
                                            </label>
                                            <br />
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="แอดมิน"
                                                    onChange={(e) => handleCheckboxChange2(e, 'roles')}
                                                />{' '}
                                                แอดมิน
                                            </label>
                                        </div>

                                        <label style={{ marginTop: '16px', display: 'block' }}>สถานะอีเมล</label>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="IsVerifiedEmail"
                                                    value="false"
                                                    checked={filters.IsVerifiedEmail === false}
                                                    onChange={(e) => handleCheckboxChange(e, 'IsVerifiedEmail')}
                                                />{' '}
                                                ยังไม่ได้ยืนยันอีเมล
                                            </label>
                                            <br />
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="IsVerifiedEmail"
                                                    value="true"
                                                    checked={filters.IsVerifiedEmail === true}
                                                    onChange={(e) => handleCheckboxChange(e, 'IsVerifiedEmail')}
                                                />{' '}
                                                ตรวจสอบเรียบร้อย
                                            </label>
                                        </div>

                                        <button
                                            style={{
                                                backgroundColor: '#3B82F6',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '8px',
                                                padding: '10px',
                                                marginTop: '16px',
                                                width: '100%',
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                            }}
                                            onClick={handleSearchFilter}
                                        >
                                            ค้นหา
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>

                </Row>
                <div className="table-scroll-container">
                    {usersData && usersData.length > 0 ? (
                        <Table
                            columns={columns}  // คอลัมน์ของคุณ
                            dataSource={usersData}
                            rowKey="id"
                            pagination={false}  // ปิด pagination ที่ Table
                        />
                    ) : (
                        <Flex vertical justify="center" align="center" style={{ height: '50vh', width: '70vw' }}>
                            <NoData />
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#667085",
                                }}
                            >
                                ไม่มีข้อมูล
                            </p>
                        </Flex>
                    )}
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                    <span>
                        ทั้งหมด {totalCount} รายการ
                    </span>
                    <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                        <Pagination
                            className="custom-pagination"
                            current={currentPage}
                            pageSize={pageSize}
                            total={totalCount}
                            onChange={(page, pageSize) => {
                                //console.log("Current Page:", page);
                                setCurrentPage(page);
                                fetchData(page, pageSize);
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: '150px' }}>
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                const newPageSize = Number(e.target.value);
                                setPageSize(newPageSize);
                                setCurrentPage(1);
                                fetchData(1, newPageSize);
                            }}
                            style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '4px' }}
                        >
                            <option value="5" style={{ fontWeight: 'bold' }}>5 / หน้า</option>
                            <option value="10" style={{ fontWeight: 'bold' }}>10 / หน้า</option>
                            <option value="20" style={{ fontWeight: 'bold' }}>20 / หน้า</option>
                            <option value="50" style={{ fontWeight: 'bold' }}>50 / หน้า</option>
                        </select>


                    </div>
                </div>
            </div>

            <Modal
                open={isModalVisible}
                footer={null}  // ไม่ต้องการปุ่มใน Modal
                closable={false}  // ไม่ให้ปิด Modal
                centered
                width={80}
            >
                <Spin size="large" /> {/* แสดง Spin */}
            </Modal>
            <ConfirmDialog
                isModalOpen={isModalOpen}
                closable={false}
                title="ยืนยันการเปลี่ยนแปลงสถานะ"
                description="เปลี่ยนสถานะเป็น 'ไม่ใช้งาน' หรือไม่?"
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                onConfirm={() => handleSwitchChange(false, currentRecord)} // ใช้ currentRecord เพื่อเก็บข้อมูล
            />

            <ConfirmDialog
                isModalOpen={isModalOpen2}
                closable={false}
                title="ยืนยันการเปลี่ยนแปลงสถานะ"
                description="เปลี่ยนสถานะเป็น 'ใช้งาน' หรือไม่?"
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                    setIsModalOpen2(false);
                }}
                onConfirm={() => handleSwitchChange(true, currentRecord)} // ใช้ currentRecord เพื่อเก็บข้อมูล
            />


            <SuccessDialog
                isModalOpen={isModalSuccessOpenSave}
                closable={false}
                title="สำเร็จ"
                detail="บันทึกข้อมูลสำเร็จแล้ว"
                onCancel={() => {
                    setIsModalSuccessOpenSave(false); // Corrected line here
                }}
                onAfterClose={() => {
                    // Handle after close if needed
                }}
            />

            <DeleteDialog
                isModalOpen={isModalDeleteOpen}
                closable={false}
                title="ยืนยันการลบ"
                cancelText="ยกเลิก"
                confirmText="ลบ"
                onCancel={() => {
                    setIsModalDeleteOpen(false);
                }}
                onConfirm={() => {
                    handleConfirmDelete();
                }}
                description="คุณต้องการลบใช่หรือไม่?"
            />
            <DeleteDialog
                isModalOpen={isModalDeleteMutiOpen}
                closable={false}
                title="ยืนยันการลบ"
                cancelText="ยกเลิก"
                confirmText="ลบ"
                onCancel={() => {
                    setIsModalDeleteMutiOpen(false);
                }}
                onConfirm={() => {
                    handleConfirmDeleteMuti();
                }}
                description="คุณต้องการลบใช่หรือไม่?"
            />

            <ConfirmDialog
                isModalOpen={isModalEmailMutiOpen}
                closable={false}
                type="mail"
                title="ส่งอีเมล"
                description="คุณต้องการส่งอีเมลยืนยันผู้ใช้งานนี้ ใช่หรือไม่ ?"
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                    setIsModalEmailMutiOpen(false);
                }}
                onConfirm={() => handleConfirmSendEmail()} // ส่ง selectedUserId เข้าไปใน handleConfirm
            />


            <SuccessDialog
                isModalOpen={isModalSuccessOpen3}
                closable={false}
                title="สำเร็จ"
                detail="อัพเดทสถานะสำเร็จ"
                onCancel={() => {
                    setIsModalSuccessOpen3(false);
                }}
                onAfterClose={() => {
                    setTimeout(() => {
                        setIsModalSuccessOpen3(false);
                    }, 3000);
                }}
            />


            <SuccessDialog
                isModalOpen={isModalSuccessOpen}
                closable={false}
                type="bin"
                title="สำเร็จ"
                detail="ลบข้อมูลสำเร็จ"
                onCancel={() => {
                    setIsModalSuccessOpen(false);
                }}
                onAfterClose={() => {
                    setTimeout(() => {
                        setIsModalSuccessOpen(false);
                    }, 3000);
                }}
            />

            <FailedDialog
                isModalOpen={isModalFailedOpen}
                closable={false}
                title="ล้มเหลว"
                detail={errorDetail}  // ส่ง error message ไปแสดงที่นี่
                onCancel={() => {
                    setIsModalFailedOpen(false);
                }}
                onAfterClose={() => {
                    setIsModalFailedOpen(false);
                }}
                onConfirm={() => {
                    setIsModalFailedOpen(false);
                }}
            />

            <FailedDialog
                isModalOpen={isModalFailedOpen2}
                closable={false}
                title="ล้มเหลว"
                detail="มีผู้ใช้ที่ยืนยันอีเมลแล้ว"
                onCancel={() => {
                    setIsModalFailedOpen2(false);
                }}
                onAfterClose={() => {
                    setIsModalFailedOpen2(false);
                }}
                onConfirm={() => {
                    setIsModalFailedOpen2(false);
                }}
            />


            <SuccessDialog
                isModalOpen={isModalSuccessOpen2}
                closable={false}
                title="สำเร็จ"
                detail="ส่งอีเมลยืนยันตัวตนสำเร็จแล้ว"
                onCancel={() => {
                    setIsModalSuccessOpen2(false);
                }}
                onAfterClose={() => {
                    setIsModalSuccessOpen2(false);
                }}
            />




        </div>
    );
};

export default ManageUserPage;
