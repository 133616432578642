import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9461 8.40956L0.38047 0.157214C0.352862 0.135479 0.319682 0.121971 0.284743 0.118242C0.249804 0.114514 0.214522 0.120715 0.182948 0.136135C0.151375 0.151554 0.124791 0.175567 0.106249 0.205414C0.0877078 0.235261 0.0779604 0.269733 0.0781271 0.30487V2.11659C0.0781271 2.23143 0.132033 2.34159 0.221096 2.4119L8.65857 9.00018L0.221096 15.5885C0.12969 15.6588 0.0781271 15.7689 0.0781271 15.8838V17.6955C0.0781271 17.8525 0.258595 17.9392 0.38047 17.8432L10.9461 9.59081C11.0359 9.52076 11.1085 9.43116 11.1585 9.32881C11.2084 9.22647 11.2344 9.11407 11.2344 9.00018C11.2344 8.88629 11.2084 8.7739 11.1585 8.67156C11.1085 8.56921 11.0359 8.47961 10.9461 8.40956Z" fill="#1677FF"/>
</svg>








);

const Icon_Right_Arrow_All = (props) => <Icon component={icon} {...props} />;

export default Icon_Right_Arrow_All;

