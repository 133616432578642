import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="85"
    viewBox="0 0 85 85"
    fill="none"
  >
    <path
      d="M0 20C0 8.9543 8.95431 0 20 0H85V85H20C8.9543 85 0 76.0457 0 65V20Z"
      fill="#E3FFF0"
    />
    <g clipPath="url(#clip0_7977_53725)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.2503 24.1955V24.7624C56.6371 24.9809 59.0042 25.2683 61.3494 25.6224C62.2178 25.7536 63.0831 25.8939 63.9453 26.0432C64.9657 26.22 65.6495 27.1904 65.4728 28.2108C65.296 29.2311 64.3256 29.915 63.3053 29.7382C63.1313 29.7081 62.9571 29.6783 62.7829 29.6489L60.2693 62.3252C59.9687 66.2327 56.7104 69.25 52.7914 69.25H33.2092C29.2902 69.25 26.0319 66.2327 25.7313 62.3252L23.2178 29.6489C23.0435 29.6783 22.8693 29.7081 22.6953 29.7382C21.675 29.915 20.7046 29.2311 20.5278 28.2108C20.3511 27.1904 21.0349 26.22 22.0553 26.0432C22.9175 25.8939 23.7829 25.7536 24.6512 25.6224C26.9964 25.2683 29.3635 24.9809 31.7503 24.7624V24.1955C31.7503 20.2843 34.7816 16.9455 38.7889 16.8173C40.1873 16.7725 41.5912 16.75 43.0003 16.75C44.4094 16.75 45.8133 16.7725 47.2117 16.8173C51.219 16.9455 54.2503 20.2843 54.2503 24.1955ZM38.9088 20.5654C40.2672 20.5219 41.6312 20.5 43.0003 20.5C44.3694 20.5 45.7334 20.5219 47.0918 20.5654C48.9784 20.6257 50.5003 22.2098 50.5003 24.1955V24.4771C48.0194 24.3264 45.5187 24.25 43.0003 24.25C40.482 24.25 37.9812 24.3264 35.5003 24.4771V24.1955C35.5003 22.2098 37.0223 20.6257 38.9088 20.5654ZM38.0218 35.4279C37.982 34.3932 37.1109 33.5866 36.0761 33.6264C35.0414 33.6662 34.2348 34.5373 34.2746 35.5721L35.14 58.0721C35.1798 59.1068 36.0509 59.9134 37.0856 59.8736C38.1204 59.8338 38.927 58.9627 38.8872 57.9279L38.0218 35.4279ZM51.7237 35.5721C51.7635 34.5373 50.9569 33.6662 49.9222 33.6264C48.8874 33.5866 48.0163 34.3932 47.9765 35.4279L47.1111 57.9279C47.0713 58.9627 47.8779 59.8338 48.9127 59.8736C49.9474 59.9134 50.8185 59.1068 50.8583 58.0721L51.7237 35.5721Z"
        fill="#1AC487"
      />
    </g>
    <defs>
      <clipPath id="clip0_7977_53725">
        <rect
          width="60"
          height="60"
          fill="white"
          transform="translate(13 13)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Icon_success_bin = (props) => <Icon component={icon} {...props} />;

export default Icon_success_bin;
