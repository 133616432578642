import React, { useEffect, useState } from "react";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Switch,
  Col,
  Row,
  Space,
  Flex,
  Modal,
  Select,
  Tooltip,
} from "antd";
import "./SwaggerDoc.css";
import CustomButton from "../../../components/Button/CustomButton";
import DeleteAction from "../../../components/Button/DeleteAction";

const { Option } = Select;

const SwaggerSchemaForm = ({
  open,
  cancel,
  action,
  filedValue,
  onSave,
  schemas,
  editSchema,
}) => {
  const [form] = Form.useForm();
  const [typeValues, setTypeValues] = useState({});

  const integerFormat = ["Int32", "Int64"];
  const numberFormat = ["Float", "Double"];
  const stringFormat = [
    "none",
    "byte",
    "binary",
    "date",
    "date-time",
    "password",
  ];

  useEffect(() => {
    if (open) {
      // ตรวจสอบว่าเป็น edit หรือ create
      if (action === "update") {
        // กรณี edit ให้ตั้งค่าเริ่มต้นตามข้อมูลที่มี
        // form.setFieldsValue(filedValue);
        // กำหนดค่า type ที่ถูกเลือกไว้ใน state
        const typeFields = {};
        filedValue.properties?.forEach((property, index) => {
          typeFields[index] = property.type;
        });
        setTypeValues(typeFields);
      } else {
        // กรณี create ให้รีเซ็ตฟอร์ม
        form.resetFields();
      }
    }
    setTimeout(() => {
      form.resetFields();
    }, 100);
  }, [open, action]);

  const onFinish = (values) => {
    onSave(values);
  };

  const handleTypeChange = (value, fieldName) => {
    setTypeValues((prev) => ({ ...prev, [fieldName]: value }));
    form.setFieldsValue({
      properties: {
        [fieldName]: {
          format: "",
        },
      },
    });
  };

  const handleSetFormatOption = (fieldName) => {
    const typeValue = typeValues[fieldName];
    if (typeValue === "integer") {
      return integerFormat.map((format) => ({ value: format, label: format }));
    } else if (typeValue === "number") {
      return numberFormat.map((format) => ({ value: format, label: format }));
    } else if (typeValue === "string") {
      return stringFormat.map((format) => ({ value: format, label: format }));
    } else {
      return [];
    }
  };

  const customLabel = (name, TooltipTitle) => {
    return (
      <span>
        <span style={{ fontWeight: "bold", marginRight: "5px" }}>
          {name}&nbsp;
        </span>
        <span style={{ fontWeight: "bold", color: "red" }}>*</span>
        {TooltipTitle && (
          <Tooltip title={TooltipTitle}>
            <InfoCircleOutlined style={{ color: "#0063A7", marginLeft: 8 }} />
          </Tooltip>
        )}
      </span>
    );
  };

  return (
    <Modal
      className="swagger-modal"
      title={
        <span className="text-title">
          {action !== "update" ? "Add new schema" : "Edit schema"}
        </span>
      }
      open={open}
      onCancel={() => {
        cancel();
      }}
      footer={null}
      width={1000}
    >
      <hr />
      <br />
      <Form
        hideRequiredMark
        form={form}
        initialValues={filedValue}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>

        <div className="schemas-container">
          <span className="text-title">Schemas (JSON format)</span>
          <Form.Item name="id" noStyle>
            <Input type="hidden" />
          </Form.Item>

          <div className="schemas-box-filed">
            <Flex justify="space-between" style={{ marginBottom: 20 }}>
              <div>
                <span style={{ fontWeight: "bold" }}>Schemas</span>
                <Tooltip title="Schemas">
                  <InfoCircleOutlined
                    style={{ color: "#0063A7", marginLeft: 8 }}
                  />
                </Tooltip>
              </div>
            </Flex>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label={customLabel("Name")}
                  rules={[
                    {
                      required: true,
                      message: "This field is required.",
                    },
                    {
                      validator: async (_, value) => {
                        if (value) {
                          const trimmedValue = value.trim();
                          if (trimmedValue) {
                            const isSchemaDuplicate = schemas?.some(
                              (item) =>
                                item.name.trim() === trimmedValue &&
                                item.name !== editSchema?.name
                            );
                            if (isSchemaDuplicate) {
                              return Promise.reject(
                                "This name has been created."
                              );
                            }
                          }
                        }
                      },
                    },
                  ]}
                >
                  <Input type="text" placeholder="Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label={customLabel("Type", "This field is required.")}
                  rules={[
                    {
                      required: true,
                      message: "This field is required.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    allowClear
                    // value={value}
                    // onChange={onChange}
                    // defaultValue={}
                  >
                    <Option value="object">object</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="additionalProperties"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Additional Properties
                    </span>
                  }
                >
                  <Switch
                    checkedChildren="True"
                    unCheckedChildren="False"
                    // defaultChecked={disableRateLimitHeaders}
                    // onChange={(e) => {
                    //   setDisableRateLimitHeaders(e);
                    //   setIsChanged(true);
                    //   sessionStorage.setItem("isChanged", true);
                    // }}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="nullable"
                  label={<span style={{ fontWeight: "bold" }}>Nullable</span>}
                >
                  <Switch
                    checkedChildren="True"
                    unCheckedChildren="False"
                    // defaultChecked={disableRateLimitHeaders}
                    // onChange={(e) => {
                    //   setDisableRateLimitHeaders(e);
                    //   setIsChanged(true);
                    //   sessionStorage.setItem("isChanged", true);
                    // }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="properties-box-filed">
              <Form.List name="properties">
                {(fields, { add, remove }) => (
                  <>
                    <Flex justify="space-between" style={{ marginBottom: 30 }}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Properties</span>
                        <Tooltip title="Schemas">
                          <InfoCircleOutlined
                            style={{
                              color: "#0063A7",
                              marginLeft: 8,
                            }}
                          />
                        </Tooltip>
                      </div>
                      <CustomButton
                        text="Add Properties"
                        type="primary"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        width={152}
                      />
                    </Flex>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={16}>
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            label={customLabel("Name")}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Input type="text" placeholder="Name" />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "type"]}
                            label={customLabel(
                              "Type",
                              "This field is required."
                            )}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              onChange={(value) =>
                                handleTypeChange(value, name)
                              }
                              // value={value}
                              // defaultValue={}
                            >
                              <Option value="string">String</Option>
                              <Option value="integer">Integer</Option>
                              <Option value="number">Number</Option>
                              <Option value="boolean">Boolean</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "format"]}
                            label={
                              <span style={{ fontWeight: "bold" }}>Format</span>
                            }
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "This field is required.",
                            //   },
                            // ]}
                          >
                            <Select
                              //   disabled={true}
                              placeholder="Select"
                              options={handleSetFormatOption(name)}
                              allowClear
                              disabled={typeValues[name] === "boolean"}
                              // value={value}
                              // onChange={onChange}
                              // defaultValue={}
                            >
                              <Option value={null}>Null</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item
                            {...restField}
                            name={[name, "nullable"]}
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Nullable
                              </span>
                            }
                          >
                            <Switch
                              checkedChildren="True"
                              unCheckedChildren="False"
                              // defaultChecked={disableRateLimitHeaders}
                              // onChange={(e) => {
                              //   setDisableRateLimitHeaders(e);
                              //   setIsChanged(true);
                              //   sessionStorage.setItem("isChanged", true);
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          {/* {fields.length > 1 && ( */}
                          <Form.Item label={<></>}>
                            <DeleteAction
                              onClick={() => {
                                remove(name);
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Col>
                        <Form.Item name="additionalProperties" noStyle>
                          <Input type="hidden" />
                        </Form.Item>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          </div>
          <Form.Item name="status" noStyle>
            <Input type="hidden" />
          </Form.Item>
        </div>
        <Flex justify="flex-end" style={{ marginTop: 30 }}>
          <Space>
            <CustomButton
              text="Cancel"
              type="primary"
              ghost={true}
              onClick={cancel}
              width={90}
            />
            <CustomButton
              text="Submit"
              type="primary"
              onClick={() => form.submit()}
              width={90}
            />
          </Space>
        </Flex>
      </Form>
    </Modal>
  );
};

export default SwaggerSchemaForm;
