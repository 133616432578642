import React, { useState } from 'react';
import { Form, Input, Button , Modal, Row, Col } from 'antd';
import { ArrowLeftOutlined , CloseCircleOutlined } from '@ant-design/icons';
import logo from '../Login/logo 1.png';
import key from '../register/key.png';
import { useNavigate } from 'react-router-dom';
import '../Login/login.css';
import '../forgot/forgot.css';
import { KeycloakService } from '../../../services/KeycloakService';

const ForgotPage = () => {
    const navigate = useNavigate();
    const [requestSent, setRequestSent] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง");
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = async (values) => {
        //console.log('Received values of form: ', values);
        setIsLoading(true);
        try {
            const responseData = await KeycloakService.sendEmailForgot(values.email);
            //console.log(responseData);
            setRequestSent(true);
        } catch (error) {
            console.error('Full error object:', error);
            console.error('Error details:', error.response ? error.response.data : error.message);
        
            setErrorMessage(
                error.response?.data?.messageTh || "เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้ง"
            );
            setIsModalVisible(true);
            form.setFields([
                {
                    name: 'email',
                    errors: ['อีเมลไม่ถูกต้อง โปรดตรวจสอบอีเมลอีกครั้ง'],
                },
            ]);
        }finally{
            setIsLoading(false);
        }
    };

    const handleOk = () => {
        setIsModalVisible(false); // ปิด modal เมื่อกดปุ่ม
    };

    const goBack = () => {
        navigate('/login');
    };

    const onValuesChange = (changedValues) => {
        const emailValue = form.getFieldValue('email');
        setIsButtonDisabled(!emailValue); // Enable button only if email has value
    };

    return (
        <div className="login-container1">
            <Row>
                <Col span={18} md={18} xs={24}>
                
                <Row>
                    <Col span={24} md={24} xs={0}>
                    <div className="login-bg"></div>
                    <div className='login-left'>
                        <div className="logo">
                        <img
                            src={logo}
                            alt="TCEB Logo"
                            className="logo-image"
                            onClick={() => navigate('/')}
                            style={{ cursor: 'pointer' }}
                        />
                        </div>
                    </div>
                    <div className="copyright">
                        © 2567 สำนักงานส่งเสริมการจัดประชุมและนิทรรศการ (องค์การมหาชน)
                    </div>
                    </Col>
                    <Col span={24} xs={24} md={0}>
                    <div className="login-bg" style={{position:'fixed'}}></div>
                    <div className="logo" style={{textAlign:"center", marginTop:"15px"}}>
                        <img
                        src={logo}
                        alt="TCEB Logo"
                        className="logo-image"
                        onClick={() => navigate('/')}
                        style={{ cursor: 'pointer' }}
                        />
                    </div>
                    </Col>
                </Row>
                </Col>
                <Col span={6} md={6} xs={24}>
                    <div className="center-v">
                    {!requestSent ? (
                        <div className="login-card">
                            <div className="header">
                                <ArrowLeftOutlined
                                    style={{ marginRight: '8px', cursor: 'pointer' }}
                                    onClick={goBack}
                                />
                            </div>
                            <p
                                style={{
                                    margin: '20px 0 0 0',
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                }}
                            >
                                คุณลืมรหัสผ่าน ?
                            </p>
                            <span style={{ display: 'block', margin: '10px 0' }}>
                                กรุณากรอกอีเมลที่คุณใช้ในการสมัครสมาชิกเพื่อกู้คืนรหัสผ่าน <br />
                                คุณจะได้รับลิงก์สำหรับรีเซ็ตรหัสผ่าน
                            </span>

                            <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
                                <Form.Item
                                    name="email"
                                    label={
                                        <span style={{ fontWeight: 'bold' }}>
                                            อีเมล
                                        </span>
                                    }
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    required={false}
                                    style={{ textAlign: 'left' }} // Aligns the error message to the left
                                >
                                    <Input placeholder="name@gmail.com" />
                                </Form.Item>
                                <Button type="primary" htmlType="submit" className="register-form-button" disabled={isButtonDisabled}>
                                    ยืนยัน
                                </Button>
                            </Form>
                        </div>
                    ) : (
                        <div className="register-card2">
                            <img
                                src={key}
                                alt="key"
                                className="logo-image"
                                style={{ width: '100px', height: 'auto' }}
                            />
                            <p
                                style={{
                                    margin: '20px 0 0 0',
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                }}
                            >
                                ส่งคำขอในการตั้งค่ารหัสผ่านใหม่
                            </p>
                            <span style={{ display: 'block', margin: '10px 0' }}>
                                ส่งคำขอเปลี่ยนรหัสผ่านในอีเมลของคุณเรียบร้อยแล้ว <br />
                                กรุณาตรวจสอบอีเมลเพื่อดำเนินการต่อ
                            </span>
                            <Button type="primary" className="register-form-button" onClick={goBack} >
                                รับทราบ
                            </Button>
                        </div>
                    )}
                    </div>
                </Col>
            </Row>          
            {isLoading && (
                <div className="modal-overlay">
                <div className="modal-content">
                    <div className="spinner"></div>
                    <p>กรุณารอซักครู่...</p>
                </div>
                </div>
            )}  
            <Modal
                open={isModalVisible}
                onOk={handleOk}
                footer={null}
                closable={false}
                centered
                width={500}
            >
                <div style={{ textAlign: 'center' }}>
                    <CloseCircleOutlined style={{ fontSize: '70px', color: '#E03A3A' }} /> {/* ลดขนาดไอคอน */}
                    <h2 style={{ marginTop: '16px' }}>ไม่สำเร็จ</h2>
                    <p style={{ margin: '16px 0', fontSize: '16px' }}>{errorMessage}</p> {/* แสดงข้อความ error */}
                    <Button type="primary" onClick={handleOk} style={{ backgroundColor: '#E03A3A', borderColor: '#E03A3A' }}>
                        ลองอีกครั้ง
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default ForgotPage;
