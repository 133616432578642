import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const DashboardTagService = {

  get: async function (cancel = false) {
    const response = await api.request({
      url: `/dashboard-tags`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

};

const cancelApiObject = defineCancelApiObject(DashboardTagService);
