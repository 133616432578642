import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
  >
    <path
      d="M13.9846 14.104C12.8434 12.5979 11.0353 11.625 9 11.625C6.96467 11.625 5.15658 12.5979 4.01539 14.104M13.9846 14.104C15.5279 12.7303 16.5 10.7287 16.5 8.5C16.5 4.35786 13.1421 1 9 1C4.85786 1 1.5 4.35786 1.5 8.5C1.5 10.7287 2.47208 12.7303 4.01539 14.104M13.9846 14.104C12.6596 15.2834 10.9135 16 9 16C7.08653 16 5.34042 15.2834 4.01539 14.104M11.5 6.625C11.5 8.00571 10.3807 9.125 9 9.125C7.61929 9.125 6.5 8.00571 6.5 6.625C6.5 5.24429 7.61929 4.125 9 4.125C10.3807 4.125 11.5 5.24429 11.5 6.625Z"
      stroke="#475467"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Icon_warning_dialog = (props) => <Icon component={icon} {...props} />;

export default Icon_warning_dialog;
