import React, { useEffect, useState } from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <>
      <h1>Contact</h1>
    </>
  );
};

export default Contact;
