import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  Layout,
  Menu,
  Flex,
  Col,
  Row,
  Avatar,
  Switch,
  Form,
  Button,
  Spin,
} from "antd";
import "./AdminInsightReportManagement.css";
import {
  LinkOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  TruckOutlined,
  PushpinOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";

import { UserService } from "../../../../../services/UserService";
import { TagService } from "../../../../../services/TagService";
import { RoleService } from "../../../../../services/RoleService";
import { InsightReportService } from "../../../../../services/InsightReportService";
import { DashboardService } from "../../../../../services/DashboardService";
import IconUpAdmin from "../../../../../assets/icon/icon_up_admin";
import IconUpAdminDisable from "../../../../../assets/icon/icon_up_admin_disable";
import IconDownAdmin from "../../../../../assets/icon/icon_down_admin";
import IconDownAdminDisable from "../../../../../assets/icon/icon_down_admin_disable";
import IconBinAdmin from "../../../../../assets/icon/icon_bin_admin";
import ConfirmDialog from "../../../../../components/Dialog/ConfirmDialog";
import SuccessDialog from "../../../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../../../components/Dialog/FailedDialog";
import DeleteDialog from "../../../../../components/Dialog/DeleteDialog";
import { PinButton } from "../../../../../components";
import {
  Input,
  Select,
  Tag,
  ConfigProvider,
  message,
  Upload,
  Image,
} from "antd";
import { FaS } from "react-icons/fa6";
import { AppContext } from "./InsightReportManagementDetail";
import { arrayMoveImmutable } from "array-move";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import htmlEditButton from "quill-html-edit-button";
import axios from "axios";

Quill.register({
  "modules/htmlEditButton": htmlEditButton,
});

const modules = {
  toolbar: [
    { header: [1, 2, 3, 4, 5, 6, false] },
    "bold",
    "italic",
    "underline",
    "link",
    // { list: "bullet" },
    // { list: "ordered" },
  ],
  htmlEditButton: {
    debug: true,
    msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
    okText: "Ok", // Text to display in the OK button, default: Ok,
    cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
    buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
    buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
    prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
    editorModules: {}, // The default mod
  },
};

const { Content, Sider } = Layout;

const AdminInsightReportManagement = ({ data, submitIndex, categoryId }) => {
  let context = AppContext;
  const { detailList, setDetailList, isChanged, setIsChanged, setSubmitIndex } =
    useContext(context);
  const navigate = useNavigate();
  const { TextArea } = Input;
  // const [data, setData] = useState();
  const [form] = Form.useForm();
  const [linkEmbedHaseError, setLinkEmbedHaseError] = useState(false);
  const [roleHasError, setRoleHasError] = useState(false);
  const [tagHasError, setTagHasError] = useState(false);
  const [dashboardNameHasError, setDashboardNameHasError] = useState(false);
  const [listDetailHasError, setListDetailHasError] = useState(false);
  const [mbLine2, setMbLine2] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [fileList, setFileList] = useStateCallback([]);
  const [tagData, setTagData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [enable, setEnable] = useState(data.isActivated);
  const [selectedTag, setSelectedTag] = useState([]);
  const [linkEmbed, setLinkEmbed] = useState([]);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useStateCallback(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [currentMethod, setCurrentMethod] = useStateCallback();
  const [dialogMessage, setDialogMessage] = useState();
  const [isPinned, setIsPinned] = useState(data.isPinned);
  const [isFirstLoadTag, setIsFirstLoadTag] = useStateCallback(true);
  const [isFirstLoadRole, setIsFirstLoadRole] = useStateCallback(true);
  const [dataLinkHasError, setDataLinkHasError] = useState(false);
  const [detailError, setDetailError] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [prevEditorValue, setPrevEditorValue] = useState();
  const [detailLength, setDetailLength] = useState(0);
  const quillRef = useRef();
  const [progress, setProgress] = useState();
  const [fileContentList, setFileContentList] = useStateCallback([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpenContent, setPreviewOpenContent] = useState(false);
  const [previewImageContent, setPreviewImageContent] = useState("");
  const [isHasUploadError, setIsHasUploadError] = useState();
  const [detailHasError, setDetailHasError] = useState(false);
  const [tagDataLink, setTagDataLink] = useState([]);
  let mockImage =
    "https://archive.org/download/placeholder-image/placeholder-image.jpg";
  const [uploadHasError, setUploadHasError] = useState(false);
  const [uploadContentHasError, setUploadContentHasError] = useState(false);
  const { Dragger } = Upload;
  const [fileInsightReportList, setFileInsightReportList] = useStateCallback(
    []
  );
  const [uploadInsightReportHasError, setUploadInsightReportHasError] =
    useState(false);
  const [insightReportFileUrl, setInsightReportFileUrl] = useStateCallback();
  const [isFirstLoadDataLink, setIsFirstLoadDataLink] = useStateCallback(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  let isFirstSetData = true;
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const options = [
    {
      value: "ทั้งหมด",
    },
  ];
  const createTagOptions = [
    {
      value: "New Tag",
    },
  ];

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      // alert("Test")
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <ConfigProvider
        theme={{
          components: {
            Tag: {
              // defaultColor:'#000000D9',
              // colorFillSecondary: '#000000D9',
            },
          },
        }}
      >
        <Tag
          // color={value}
          closeIcon={<CloseOutlined style={{ color: "#00000073" }} />}
          color={"#F8F8F9"}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{
            marginInlineEnd: 4,
            color: "#000000D9",
            fontSize: 12,
            fontWeight: 600,
            borderColor: "#BCC2CB",
          }}
        >
          {label}
        </Tag>
      </ConfigProvider>
    );
  };

  const createTagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      // alert("Test")
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <ConfigProvider
        theme={{
          components: {
            Tag: {
              // defaultColor:'#000000D9',
              // colorFillSecondary: '#000000D9',
            },
          },
        }}
      >
        <Tag
          // color={value}
          icon={<PlusOutlined style={{ color: "#00000073" }} />}
          color={"#F8F8F9"}
          onMouseDown={onPreventMouseDown}
          closable={false}
          onClick={() => {
            // alert("Go to create tag page");
            navigate("/management/edit-tag");
          }}
          // onClose={onClose}
          style={{
            marginInlineEnd: 4,
            color: "#000000D9",
            fontSize: 12,
            fontWeight: 600,
            borderColor: "#BCC2CB",
            borderStyle: "dashed",
          }}
        >
          {label}
        </Tag>
      </ConfigProvider>
    );
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUploadInsightReportFile = async (file) => {
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      message.error("You can only upload PDF file!");
      setFileInsightReportList((state) => [...state]);
      return false;
    }
    if (fileInsightReportList.length >= 1) {
      message.error("Upload only one file");
      setFileInsightReportList((state) => [...state]);
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error("Image must smaller than 20MB!");
      setFileInsightReportList((state) => [...state]);
      return false;
    }

    setFileInsightReportList((state) => [...state, file]);

    return isPDF && isLt2M;
  };

  const beforeUpload = async (file, mode) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      if (mode === "content") {
        setFileContentList((state) => [...state]);
      } else {
        setFileList((state) => [...state]);
      }
      setIsHasUploadError(true);
      return false;
    }

    // const reader = new FileReader();
    // let picWidth = 0;
    // let picHeight = 0;
    // reader.readAsDataURL(file);
    // reader.addEventListener('load', event => {
    //   const _loadedImageUrl = event.target.result;
    //   const image = document.createElement('img');
    //   image.src = _loadedImageUrl;
    //   image.addEventListener('load', () => {
    //     const { width, height } = image;
    //     // set image width and height to your state here
    //     //console.log(width, height);
    //     picWidth = width;
    //     picHeight = height;
    //     if(picWidth > 350 || picHeight > 175){
    //       message.error('Image size choose be 350x175');
    //       setFileList((state) => [...state]);
    //       return false;
    //     }
    //   });
    // });
    if (mode === "content") {
      if (fileContentList.length >= 1) {
        message.error("Upload only one file");
        setFileContentList((state) => [...state]);
        setIsHasUploadError(true);
        return false;
      }
    } else {
      if (fileList.length >= 1) {
        message.error("Upload only one file");
        setFileList((state) => [...state]);
        setIsHasUploadError(true);
        return false;
      }
    }

    let isSizeNormal = await checkImageDimension(file, mode);
    if (!isSizeNormal) {
      if (mode === "content") {
        message.error("Cannot upload image file size more than 5MB");
        setFileContentList((state) => [...state]);
        setIsHasUploadError(true);
        return false;
      } else {
        message.error("Cannot upload image file size more than 5MB");
        setFileList((state) => [...state]);
        setIsHasUploadError(true);
        return false;
      }
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
      if (mode === "content") {
        setFileContentList((state) => [...state]);
      } else {
        setFileList((state) => [...state]);
      }
      setIsHasUploadError(true);
      return false;
    }
    if (mode === "content") {
      setFileContentList((state) => [...state, file]);
    } else {
      setFileList((state) => [...state, file]);
    }
    setIsHasUploadError(false);

    return isJpgOrPng && isLt2M && isSizeNormal;
  };

  const checkImageDimension = (file, mode) => {
    let maxWidth = 375;
    let maxHeight = 175;
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    if (mode === "content") {
      maxWidth = 600;
      maxHeight = 600;
    }
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl;

        image.addEventListener("load", () => {
          const { width, height } = image;

          /*if (width > maxWidth || height > maxHeight) {
            return resolve(false);
          }*/
          if(file.size > MAX_FILE_SIZE){
            return resolve(false);
          }

          return resolve(true);
        });
      });
    });
  };

  const handlePreview = async (file, mode) => {
    // if (!file.url && !file.preview) {
    // file.preview = getBase64(file.originFileObj);
    if (file.url !== undefined) {
      if (mode === "content") {
        setPreviewImageContent(file.url || file.preview);
        setPreviewOpenContent(true);
      } else {
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
      }
    } else {
      getBase64(file.originFileObj, (url) => {
        if (mode === "content") {
          setPreviewImageContent(url || file.preview);
          setPreviewOpenContent(true);
        } else {
          setPreviewImage(url || file.preview);
          setPreviewOpen(true);
        }
      });
    }

    // }
  };

  const removeUpload = (file, mode) => {
    let fileType = "";
    if (mode === "content") {
      fileType = "content_image";
    } else if (mode === "insightReportFile") {
      fileType = "insight_report_file";
    } else {
      fileType = "thumbnail";
    }
    if (mode === "content") {
      if (fileContentList.some((item) => item.uid === file.uid)) {
        setFileContentList((fileList) =>
          fileList.filter((item) => item.uid !== file.uid)
        );
        removeFileData(fileType, mode);
        return true;
      }
    } else if (mode === "insightReportFile") {
      if (fileInsightReportList.some((item) => item.uid === file.uid)) {
        setFileInsightReportList((fileList) =>
          fileList.filter((item) => item.uid !== file.uid)
        );
        removeFileData(fileType, mode);
        return true;
      }
    } else {
      if (fileList.some((item) => item.uid === file.uid)) {
        setFileList((fileList) =>
          fileList.filter((item) => item.uid !== file.uid)
        );
        removeFileData(fileType, mode);
        return true;
      }
    }
    return false;
  };

  const removeFileData = (fileType, mode) => {
    let updatedList = detailList.map((item) => {
      if (item.id === data.id) {
        let fileList = item.insightReportFiles;
        if (fileList === undefined) {
          fileList = [];
        } else {
          fileList = fileList.filter((item) => item.fileType !== fileType);
          if (mode === "content") {
            item.contentImageSrc = undefined;
          } else if (mode === "insightReportFile") {
            item.insightReportFileRawName = undefined;
          } else {
            item.thumbnailSrc = undefined;
          }
          // if(findData !== undefined){
          //   let index = fileList.findIndex(x => x.fileType === fileType);
          // if (mode === "content") {
          //   findData.contentImageSrc = undefined;
          // } else if (mode === "insightReportFile") {
          //   findData.insightReportFileRawName = undefined;
          // } else {
          //   findData.thumbnailSrc = undefined;
          // }
          //   fileList[index] = findData;
          // }
        }
        return {
          ...item,
          insightReportFiles: fileList,
        };
      }
      return item;
    });
    setDetailList(updatedList, function (data) {
      triggerValidate("upload");
    });
  };

  const handleUploadChange = (info, mode) => {
    setIsChanged(true);
    if (info.file.status === "uploading") {
      // getBase64(info.file.originFileObj, (url) => {
      //   setImageUrl(url);
      //   // info.file.thumbUrl = url;
      //   // info.file.status = "uploading";
      //   // info.file.url = url;
      //   // info.file.preview = url;
      //   // info.file.percent = progress;
      //   // let updatedList = fileList.map((item) => {
      //   //   if (item.uid === info.file.uid) {
      //   //     return info.file; //gets everything that was already in item, and updates "done"
      //   //     //'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
      //   //   }
      //   //   return item; // else return unmodified item
      //   // });
      //   // if (updatedList.length > 0) {
      //   //   setFileList(updatedList);
      //   // }
      // });
      return;
    }
    let tempFileList = fileList;
    if (mode === "content") {
      tempFileList = fileContentList;
    } else if (mode === "insightReportFile") {
      tempFileList = fileInsightReportList;
    }
    if (info.file.status === "done") {
      let updatedList = tempFileList.map((item) => {
        if (item.uid === info.file.uid) {
          return info.file; //gets everything that was already in item, and updates "done"
          //'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
        }
        return item; // else return unmodified item
      });
      if (updatedList.length > 0) {
        if (mode === "content") {
          setFileContentList(updatedList);
        } else if (mode === "insightReportFile") {
          setFileInsightReportList(updatedList);
        } else {
          setFileList(updatedList);
        }
      }
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
      });
    }
    if (info.file.status === "error") {
      let updatedList = tempFileList.map((item) => {
        if (item.uid === info.file.uid) {
          // info.file.error = {"message":"Test"}
          return info.file; //gets everything that was already in item, and updates "done"
          //'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
        }
        return item; // else return unmodified item
      });
      if (updatedList.length > 0) {
        if (mode === "content") {
          setFileContentList(updatedList);
        } else if (mode === "insightReportFile") {
          setFileInsightReportList(updatedList);
        } else {
          setFileList(updatedList);
        }
      }
    }
  };

  // useEffect(() => {
  //   if (!data) {
  //     fetchData();
  //   }
  // }, [data]);

  // const fetchData = async () => {
  //   try {
  //     const response = await UserService.getMe();
  //     setData(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const onChange = (checked) => {
    setIsChanged(true);
    //console.log(`switch to ${checked}`);
    updateDetailData("isActivated", checked);
    setEnable(checked);
  };

  const handleChangeText = (value, field, mode) => {
    // var test = form.getFieldsError()
    setIsChanged(true);
    if (value.trim() === "") {
      if (field === "linkEmbed") {
        setLinkEmbedHaseError(true);
        setLinkEmbed(value);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("embedSrc", value);
      } else if (field === "dashboardName") {
        setDashboardNameHasError(true);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("name", value);
      } else if (field === "listDetail") {
        setListDetailHasError(true);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("shortDescription", value);
      } else if (field === "detail") {
        setDetailHasError(true);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("description", value);
      }
    } else {
      if (field === "linkEmbed") {
        setLinkEmbedHaseError(false);
        setLinkEmbed(value);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("embedSrc", value);
      } else if (field === "dashboardName") {
        setDashboardNameHasError(false);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("name", value);
      } else if (field === "listDetail") {
        setListDetailHasError(false);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("shortDescription", value);
      } else if (field === "detail") {
        setDetailHasError(false);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("description", value);
      }
    }
    // if (field === "detail") {
    //   updateDetailData("description", value);
    // }
    //console.log("handle change called");
  };

  const updateDetailData = (field, value) => {
    let updatedList = detailList.map((item) => {
      if (item.id == data.id) {
        let dataTag;
        let dataRole;
        if (field === "insightReportTags") {
          setIsFirstLoadTag(false);
          dataTag = value.map((itemString) => {
            let findData = tagData.find(
              (element) => element.name === itemString
            );

            return {
              id: findData.key,
              insightReportId: data.id,
              tagId: findData.key,
              tagName: findData.name,
              tagColor: findData.color,
            };
          });
          return { ...item, [field]: dataTag };
        } else if (field === "insightReportPermissions") {
          setIsFirstLoadRole(false);
          dataRole = value.map((id) => {
            let findData = roleData.find((element) => element.id === id);

            const findId = item.insightReportPermissions?.find((i) => i.roleId === id);

            return {
              id: findId ? findId?.id : null,
              insightReportId: data.id,
              roleId: findData.id,
              roleName: findData.nameTh,
            };
          });
          return { ...item, [field]: dataRole };
        } 
        else if (field === "dataLink") {
          setIsFirstLoadDataLink(false);
          dataTag = value.map((itemString) => {
            let findData = tagDataLink.find(
              (element) => element.name === itemString
            );

            return {
              id: findData.id,
              insightReportId: data.id,
              name: findData.name,
              dashboardId: findData.id,
            };
          });
          return { ...item, insightReportDashboards: dataTag };
        } else {
          return { ...item, [field]: value }; //gets everything that was already in item, and updates "done"
        }
      }
      return item; // else return unmodified item
    });
    setDetailList(updatedList);
  };

  const handleChangeSelect = (value, field, mode) => {
    // var test = form.getFieldsError()

    setIsChanged(true);
    let role = roleHasError;
    let tag = tagHasError;
    if (value.length === 0) {
      if (field === "role") {
        role = true;
        setRoleHasError(true);
        updateDetailData("insightReportPermissions", value);
      } else if (field === "tag") {
        tag = true;
        setTagHasError(true);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("insightReportTags", value);
      } else if (field === "dataLink") {
        // setDataLinkHasError(true);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("dataLink", value);
      }
      checkMbLine2(role, tag);
    } else {
      if (field === "role") {
        role = false;
        setRoleHasError(false);
        updateDetailData("insightReportPermissions", value);
      } else if (field === "tag") {
        tag = false;
        setTagHasError(false);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("insightReportTags", value);
      } else if (field === "dataLink") {
        // setDataLinkHasError(false);
        if (mode !== undefined) {
          return;
        }
        updateDetailData("dataLink", value);
      }
      checkMbLine2(role, tag);
    }
    //console.log("handle change called");
  };

  const checkMbLine2 = (roleHasError, tagHasError) => {
    if (tagHasError) {
      setMbLine2(50);
    } else {
      setMbLine2(0);
    }
  };

  function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; // store current, passed callback in ref
      setState(state);
    }, []); // keep object reference stable, exactly like `useState`

    useEffect(() => {
      // cb.current is `null` on initial render,
      // so we only invoke callback on state *updates*
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null; // reset callback after execution
      }
    }, [state]);

    return [state, setStateCallback];
  }

  const handleDeleteDetail = (id) => {
    // onSubmit(form)
    // setIsModalDeleteOpen(true)
    setIsChanged(true);
    setDetailList((l) => l.filter((item) => item.id !== id));
    // sessionStorage.setItem("isDiscardChanged", true);
    // let currentData = findCurrentData(id);
    // if(currentData.dataStatus !== undefined){
    //   setDetailList((l) => l.filter((item) => item.id !== id));
    // }else{
    //   setCurrentMethod("delete", function(data){
    //     setMessage(data);
    //   });
    //   deleteData();
    // }
  };

  const findCurrentData = (id) => {
    let currentElement = detailList.find((element) => element.id === id);
    return currentElement;
  };

  function findOrderIndex(data) {
    let findIndex = -1;
    detailList.map((item, index) => {
      if (item.id == data.id) {
        findIndex = index; //gets everything that was already in item, and updates "done"
      } // else return unmodified item
    });
    return findIndex;
  }

  function isCanTop(index) {
    let result = false;
    if (index > 0) {
      result = true;
    }
    return result;
  }

  function isCanBottom(index) {
    let result = false;
    if (index < detailList.length - 1) {
      result = true;
    }
    return result;
  }

  function getTopIndex(data) {
    let index = findOrderIndex(data);
    if (isCanTop(index)) {
      index--;
    }
    return index;
  }

  function getBottomIndex(data) {
    let index = findOrderIndex(data);
    if (isCanBottom(index)) {
      index++;
    }
    return index;
  }

  function moveOrder(direction, data) {
    setIsChanged(true);
    setIsFirstLoadTag(true);
    setIsFirstLoadRole(true);
    setIsFirstLoadDataLink(true);
    triggerValidate("upload");
    let oldIndex = findOrderIndex(data);
    let updatedList = [...detailList];
    let orderIndex = -1;
    if ("top" === direction) {
      orderIndex = getTopIndex(data);
    } else {
      orderIndex = getBottomIndex(data);
    }
    let newArray = arrayMoveImmutable(updatedList, oldIndex, orderIndex);

    setDetailList(newArray, function (updateData) {
      //console.log("Sort data ", updateData);
    });
  }

  const fetchTags = async () => {
    try {
      const response = await TagService.get();
      //console.log("API Response:", response);

      // ตรวจสอบว่า response ถูกต้องหรือไม่
      if (!response || !Array.isArray(response.items)) {
        throw new Error("Response format is invalid or items are missing.");
      }

      // จัดรูปแบบข้อมูล
      const formattedData = response.items.map((item, index) => ({
        key: item.id || index.toString(),
        index: item.sequence ?? index + 1, // ใช้ sequence หรือ index + 1
        name: item.name || "Default",
        color: item.color || "default",
        isActivated: item.isActivated ?? false,
        tagExample: item.name || "Default",
      }));

      // เรียงข้อมูลตาม index (ลำดับ #)
      const sortedData = formattedData.sort((a, b) => a.index - b.index);

      // ตั้งค่าข้อมูลที่เรียงแล้ว
      let updatedList = sortedData.map((item) => {
        return { ...item, value: item.name };
      });
      setTagData(updatedList);
      updateSelectedTag();
      setIsFirstLoadTag(false);
      // setSelectedTag(null)
    } catch (error) {
      console.error("Error fetching tags:", error);
      message.error("เกิดข้อผิดพลาดในการโหลดข้อมูล");
    } finally {
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await RoleService.search();

      // ตั้งค่าข้อมูลที่เรียงแล้ว
      setRoleData(response?.items);
      updateSelectedRole();
      setIsFirstLoadRole(false);
      // setSelectedTag(null)
    } catch (error) {
      message.error("เกิดข้อผิดพลาดในการโหลดข้อมูล");
    } finally {
    }
  };

  const updateSelectedTag = () => {
    if (
      data.insightReportTags != undefined &&
      data.insightReportTags.length > 0
    ) {
      // let testList = [{tagName:"test"}, {tagName:"ss"}];
      // let tagList = testList.map((item) => {
      let tagList = data.insightReportTags.map((item) => {
        return item.tagName;
      });

      setSelectedTag(tagList);
      // let testData = ['การเงิน','การท่องเที่ยว']
      form.setFieldsValue({
        tag: tagList,
        // "tag": testData
      });
    } else {
      if (isFirstLoadTag) {
        form.setFieldsValue({
          tag: null,
        });
        setIsFirstLoadTag(false);
      }
    }
  };

  const updateSelectedRole = () => {
    if (
      data.insightReportPermissions != undefined &&
      data.insightReportPermissions.length > 0
    ) {
      // let testList = [{tagName:"test"}, {tagName:"ss"}];
      // let tagList = testList.map((item) => {
      let RoleList = data.insightReportPermissions.map((item) => {
        return item.roleId;
      });

      form.setFieldsValue({
        role: RoleList,
      });
    } else {
      if (isFirstLoadRole) {
        form.setFieldsValue({
          role: null,
        });
        setIsFirstLoadRole(false);
      }
    }
  };

  const prepareThumbnail = () => {
    if (data.insightReportFiles === undefined) {
      setFileList([]);
      setFileContentList([]);
      setFileInsightReportList([]);
      return;
    }
    let fileData = data.insightReportFiles.find(
      (element) => element.fileType === "thumbnail"
    );
    if (fileData != undefined) {
      if (fileList.length > 0 && fileData.fileSrc === fileList[0].url) {
        return;
      }
      setFileList(
        [{ id: uuidv4(), url: fileData.fileSrc, thumbUrl: fileData.fileSrc }],
        function (updateData) {}
      );
    }
    let fileContentData = data.insightReportFiles.find(
      (element) => element.fileType === "content_image"
    );
    if (fileContentData != undefined) {
      if (
        fileContentList.length > 0 &&
        fileContentData.fileSrc === fileContentList[0].url
      ) {
        return;
      }
      setFileContentList(
        [
          {
            id: uuidv4(),
            url: fileContentData.fileSrc,
            thumbUrl: fileContentData.fileSrc,
          },
        ],
        function (updateData) {}
      );
    }
    let fileInsightReportData = data.insightReportFiles.find(
      (element) => element.fileType === "insight_report_file"
    );
    if (fileInsightReportData != undefined) {
      setInsightReportFileUrl(fileInsightReportData.fileSrc);
      if (
        fileInsightReportList.length > 0 &&
        fileInsightReportData.fileSrc === fileInsightReportList[0].url
      ) {
        return;
      }
      setFileInsightReportList(
        [
          {
            id: uuidv4(),
            name: fileInsightReportData.fileRawName,
            url: fileInsightReportData.fileSrc,
            thumbUrl: fileInsightReportData.fileSrc,
          },
        ],
        function (updateData) {}
      );
    }
  };

  const onFinish = (values) => {
    //console.log(values);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let test = detailList;
        setUploadHasError(false);
        setUploadContentHasError(false);
        setUploadInsightReportHasError(false);
        if (detailError === undefined) {
          saveData();
        } else {
          messageApi.open({
            type: "error",
            content: "กรุณากรอกข้อมูลให้สมบูรณ์",
          });
        }

        //console.log("");
      })
      .catch((errorInfo) => {
        messageApi.open({
          type: "error",
          content: "กรุณากรอกข้อมูลให้สมบูรณ์",
        });
        //console.log("errorInfo ...", errorInfo);
        let values = errorInfo.values;
        errorInfo.errorFields.map((item) => {
          let fieldName = item.name[0];
          const formValues = form.getFieldsValue();
          handleValidate(errorInfo);
          // handleChangeText()
        });
        // handleOnFinishEdit(values)
      });
  };

  const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  };

  const saveData = async () => {
    try {
      setLoading(true);
      setIsFullScreen(true);
      //Test only
      // let updatedList = detailList.map((item) => {
      //   return {
      //     ...item,
      //     dashboardFiles: [
      //       {
      //         fileId: "1234567",
      //         sequence: 0,
      //       },
      //     ],
      //   };
      // });
      // setDetailList(updatedList);
      let request = {
        insightReportCategoryId: data.insightReportCategoryId,
        items: detailList,
      };
      const response = await InsightReportService.saveBulk(request);
      if (response.status === 200) {
        setIsModalSuccessOpen(true);
        setTimeout(function () {
          handleNavigate();
          // navigate("/management/insight-report-management");
          // setIsModalSuccessOpen(false);
        }, 2000);
      }
      setLoading(false);
      setIsFullScreen(false);
    } catch (error) {
      setLoading(false);
      setIsFullScreen(false);
      setIsModalFailedOpen(true);
      console.error(error);
    }finally{
      // setLoading(false);
      // setIsFullScreen(false);
    }
  };

  const deleteData = async () => {
    try {
      setLoading(true);
      const response = await InsightReportService.delete(data.id);
      setIsModalSuccessOpen(true);
      setDetailList((l) => l.filter((item) => item.id !== data.id));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsModalFailedOpen(true);
      console.error(error);
    }
  };

  const setMessage = async (currentMethod) => {
    if (currentMethod === "save") {
      setDialogMessage("Successfully saved data.");
    } else {
      setDialogMessage("Successfully delete data.");
    }
  };

  const handleUpdatePinend = (checked, item) => {
    updateDetailData("isPinned", checked);
    setIsPinned(setIsPinned);
  };

  const handleOnEditTextChange = (content, delta, source, editor) => {
    const text = editor.getText(content);
    setDetailLength(text.trim().length);
    if (text.trim().length === 0) {
      setDetailError(
        <div style={{ marginTop: 40 }} class="ant-form-item-explain-error">
          This information is required.
        </div>
      );
    } else if (text.trim().length > 1000) {
      setDetailError(
        <div style={{ marginTop: 40 }} class="ant-form-item-explain-error">
          ข้อมูลนี้ต้องไม่เกิน 1000
        </div>
      );
    } else {
      setDetailError();
    }
    if (prevEditorValue !== content) {
      setPrevEditorValue(content);
      updateDetailData("description", content);
    }
    // alert(text);
    // setValue(value);
  };

  const getDetailLength = () => {
    if (quillRef.current !== undefined) {
      const editor = quillRef.current.getEditor();
      // alert(editor.getText(data.description));
      return editor.getText(data.description).trim().length;
    }
    return 0;
  };

  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
 

  const uploadImage = async (options, mode) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        // let updatedList = fileList.map((item) => {
        //   // item.file.thumbUrl = url;
        //     item.file.status = "uploading";
        //     // item.file.url = url;
        //     // item.file.preview = url;
        //     item.file.percent = percent
        //     return item.file; // else return unmodified item
        // });
        // if (updatedList.length > 0) {
        //   setFileList(updatedList);
        // }
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("File", file);
    let fileType = "";
    if (mode === "content") {
      fileType = "content_image";
    } else if (mode === "insightReportFile") {
      fileType = "insight_report_file";
    } else {
      fileType = "thumbnail";
    }
    fmData.append("FileType", fileType);

    try {
      const res = await axios.post(
        // "https://tceb.ccs-service.online/core-api/files",
        process.env.REACT_APP_SERVICE_BASE_URI + "/files",
        // "https://660d2bd96ddfa2943b3373  1c.mockapi.io/api/upload",
        fmData,
        config
      );
      let updatedList = detailList.map((item) => {
        if (item.id === data.id) {
          let fileList = item.insightReportFiles;
          if (fileList === undefined) {
            fileList = [];
          } else {
            let findData = fileList.find(
              (element) => element.fileType === fileType
            );
            if (findData !== undefined) {
              let index = fileList.findIndex((x) => x.fileType === fileType);
              findData.id = res.data.id;
              findData.insightReportId = data.id;
              findData.fileId = res.data.id;
              findData.fileSrc = res.data.srcUrl;
              if (mode === "content") {
                findData.contentImageSrc = res.data.srcUrl;
                item.contentImageSrc = res.data.srcUrl;
              } else if (mode === "insightReportFile") {
                findData.insightReportFileRawName = res.data.rawName;
                item.insightReportFileRawName = res.data.rawName;
              } else {
                findData.thumbnailSrc = res.data.srcUrl;
                item.thumbnailSrc = res.data.srcUrl;
              }
              fileList[index] = findData;
            } else {
              let dataFile = {
                id: res.data.id,
                insightReportId: data.id,
                fileId: res.data.id,
                sequence: fileList.length + 1,
                fileType: fileType,
                fileSrc: res.data.srcUrl,
              };
              if (mode === "content") {
                dataFile.contentImageSrc = res.data.srcUrl;
                item.contentImageSrc = res.data.srcUrl;
              } else if (mode === "insightReportFile") {
                dataFile.insightReportFileRawName = res.data.rawName;
                item.insightReportFileRawName = res.data.rawName;
              } else {
                dataFile.thumbnailSrc = res.data.srcUrl;
                item.thumbnailSrc = res.data.srcUrl;
              }
              fileList.push(dataFile);
            }
          }
          return {
            ...item,
            insightReportFiles: fileList,
          };
        }
        return item;
      });
      setDetailList(updatedList, function (data) {
        triggerValidate("upload");
      });
      onSuccess("Ok");

      //console.log("server res: ", res);
    } catch (err) {
      //console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const updateSelectedTagDataLink = () => {
    if (
      data.insightReportDashboards != undefined &&
      data.insightReportDashboards.length > 0
    ) {
      // let testList = [{tagName:"test"}, {tagName:"ss"}];
      // let tagList = testList.map((item) => {
      let tagList = data.insightReportDashboards.map((item) => {
        return item.dashboardName;
      });

      // setTagDataLink(tagList);
      // let testData = ['การเงิน','การท่องเที่ยว']
      form.setFieldsValue({
        dataLink: tagList,
        // "tag": testData
      });
    } else {
      if (isFirstLoadDataLink) {
        form.setFieldsValue({
          dataLink: null,
        });
        setIsFirstLoadDataLink(false);
      }
    }
  };

  const handleonImageError = (mode) => {
    // alert("Ok")
    if (mode === "thumb") {
      setFileList(
        // [{ id: uuidv4(), url: mo, thumbUrl: data.thumbnailSrc }],
        [{ id: uuidv4(), url: mockImage, thumbUrl: mockImage }],
        function (updateData) {}
      );
    } else {
      setFileContentList(
        // [{ id: uuidv4(), url: mo, thumbUrl: data.thumbnailSrc }],
        [{ id: uuidv4(), url: mockImage, thumbUrl: mockImage }],
        function (updateData) {}
      );
    }
  };

  const handleOnImageSuccess = () => {
    prepareThumbnail();
  };

  const triggerValidate = (mode) => {
    form
      .validateFields()
      .then((values) => {
        //console.log("");
        setUploadHasError(false);
        setUploadContentHasError(false);
        setUploadInsightReportHasError(false);
      })
      .catch((errorInfo) => {
        //console.log("errorInfo ...", errorInfo);
        let values = errorInfo.values;
        handleValidate(errorInfo, mode);
        // handleOnFinishEdit(values)
      });
  };

  const handleValidate = (errorInfo, mode) => {
    let uploadError = errorInfo.errorFields.find(
      (item) => item.name[0] === "upload"
    );
    if (uploadError === undefined) {
      setUploadHasError(false);
    }

    let uploadContentError = errorInfo.errorFields.find(
      (item) => item.name[0] === "uploadContent"
    );
    if (uploadContentError === undefined) {
      setUploadContentHasError(false);
    }
    errorInfo.errorFields.map((item) => {
      let fieldName = item.name[0];
      // setUploadHasError(false);
      const formValues = form.getFieldsValue();
      if (fieldName == "linkEmbed") {
        let linkEmbed =
          formValues.linkEmbed == undefined ? "" : formValues.linkEmbed;
        handleChangeText(linkEmbed, fieldName, mode);
      } else if (fieldName == "tag") {
        let tag = formValues.tag == undefined ? [] : formValues.tag;
        handleChangeSelect(tag, fieldName, mode);
      } else if (fieldName == "dashboardName") {
        let value =
          formValues.dashboardName == undefined ? "" : formValues.dashboardName;
        handleChangeText(value, fieldName, mode);
      } else if (fieldName == "listDetail") {
        let value =
          formValues.listDetail == undefined ? "" : formValues.listDetail;
        handleChangeText(value, fieldName, mode);
      } else if (fieldName == "detail") {
        let value = formValues.detail == undefined ? "" : formValues.detail;
        handleChangeText(value, fieldName, mode);
      } else if (fieldName == "upload") {
        setUploadHasError(true);
        // setFileList([], function (data) {
        //   setFileList(
        //     [{ id: uuidv4(), status: "error" }],
        //     function (updateData) {}
        //   );
        // });
      } else if (fieldName == "uploadContent") {
        setUploadContentHasError(true);
      } else if (fieldName == "insightReportFile") {
        setUploadInsightReportHasError(true);
      }

      // handleChangeText()
    });
  };

  const handleNavigate = () => {
    navigate(
      {
        pathname: `/management/insight-report-management`,

        // pagedata: pageValue,
      },
      { state: { catagoryId: categoryId } }
    );
    setIsModalSuccessOpen(false);
  };

  const fetchDashboard = async () => {
    try {
      const response = await DashboardService.getDashboard();
      //console.log("API Response:", response);

      // ตรวจสอบว่า response ถูกต้องหรือไม่
      if (!response || !Array.isArray(response.items)) {
        throw new Error("Response format is invalid or items are missing.");
      }

      // ตั้งค่าข้อมูลที่เรียงแล้ว
      let updatedList = response.items.map((item) => {
        return { ...item, value: item.name, id: item.id };
      });
      setTagDataLink(updatedList);
      updateSelectedTagDataLink();
      setIsFirstLoadDataLink(false);
    } catch (error) {
      console.error("Error fetching tags:", error);
      message.error("เกิดข้อผิดพลาดในการโหลดข้อมูล");
    } finally {
    }
  };

  useEffect(() => {
    if(isFirstSetData){
      fetchTags();
      fetchRoles();
      fetchDashboard();
      isFirstSetData = false;
    }
  }, []);

  useEffect(() => {
    setEnable(data.isActivated);
    setIsPinned(data.isPinned);
    if (data.embedSrc !== undefined) {
      setLinkEmbed(data.embedSrc);
    }
    updateSelectedTag();
    prepareThumbnail();
    // updateSelectedTagDataLink();
  }, [detailList]);

  useEffect(() => {
    if (submitIndex != 0) {
      // form.submit();
      setSubmitIndex(0, function (data) {
        setIsModalOpen(true);
      });
    }
  }, [submitIndex]);

  let index = findOrderIndex(data);
  let boolIsCanTop = isCanTop(index);
  let boolIsCanBottom = isCanBottom(index);

  return (
    <>
      {contextHolder}
      <Flex gap="small">
        <SuccessDialog
          isModalOpen={isModalSuccessOpen}
          closable={false}
          title="Success"
          detail={dialogMessage}
          // detail={"test"}
          onCancel={() => {
            // navigate("/management/insight-report-management");
            // setIsModalSuccessOpen(false);
            handleNavigate();
          }}
          onAfterClose={() => {
            // if(currentMethod === "save"){
            //   navigate("/management/dashboard-management");
            //   setIsModalSuccessOpen(false);
            // }
          }}
        />

        <ConfirmDialog
          isModalOpen={isModalOpen}
          closable={false}
          title="Confirm Save"
          cancelText="Cancel"
          confirmText="Confirm"
          onCancel={() => {
            setIsModalOpen(false);
          }}
          onConfirm={() => {
            setCurrentMethod("save", function (data) {
              setMessage(data);
            });
            handleSubmit();
            setIsModalOpen(false);
          }}
          description="Do you want to Save data ?"
        />

        <DeleteDialog
          isModalOpen={isModalDeleteOpen}
          closable={false}
          title="Confirm Delete"
          cancelText="Cancel"
          confirmText="Delete"
          onCancel={() => {
            setIsModalDeleteOpen(false);
          }}
          onConfirm={() => {
            setIsChanged(true);
            let currentData = findCurrentData(data.id);
            if (currentData.dataStatus !== undefined) {
              setDetailList((l) => l.filter((item) => item.id !== data.id));
            } else {
              setCurrentMethod("delete", function (data) {
                setMessage(data);
              });
              deleteData();
            }

            // if (currentRecord) {
            //   handleDelete(currentRecord, formDataRolelimit, formDataRole);
            // }
            setIsModalDeleteOpen(false); // Close the modal after confirming
          }}
          description="Are you sure to delete?"
        />

        <FailedDialog
          isModalOpen={isModalFailedOpen}
          closable={false}
          title="Failed"
          detail="Something went wrong. Please try again."
          onCancel={() => {
            setIsModalFailedOpen(false);
          }}
          onAfterClose={() => {
            // if (currentMethod === "save") {
            //   handleSubmit();
            // } else {
            //   deleteData();
            // }
            // alert("Dialog dissmiss");
          }}
          onConfirm={() => {
            if (currentMethod === "save") {
              handleSubmit();
            }
            setIsModalFailedOpen(false);
          }}
        />
      </Flex>
      <Spin tip="Loading" spinning={loading} fullscreen>
      </Spin>
      <div className="content-card-main-insight">
        <Row
          style={{
            width: "100%",
            marginTop: "0px",
            marginRight: "0px",
            // height: "150%",
          }}
        >
          <div style={{ width: "60px" }} span={2}>
            <div style={{ width: "100%", height: "100%" }}>
              <Row>
                <Col>
                  <Row style={{ marginBottom: 15 }}>
                    {!boolIsCanTop ? (
                      <IconUpAdminDisable />
                    ) : (
                      <IconUpAdmin
                        onClick={() => {
                          moveOrder("top", data);
                        }}
                      />
                    )}
                  </Row>
                  <Row>
                    {!boolIsCanBottom ? (
                      <IconDownAdminDisable />
                    ) : (
                      <IconDownAdmin
                        onClick={() => {
                          moveOrder("bottom", data);
                        }}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          <div style={{ width: "90%" }} span={22}>
            <div style={{ width: "100%", height: "100%", marginLeft: 0 }}>
              <Row>
                <Col span={24}>
                  <Row style={{}}>
                    <Col span={1}>
                      <div className="border-left-insight"></div>
                    </Col>
                    <Col span={23}>
                      <div style={{}}>
                        <Flex justify="end">
                          <PinButton
                            // loading={pinLoading === item.id}
                            onClick={() => handleUpdatePinend(!isPinned)}
                            isPinned={isPinned}
                          />
                          <IconBinAdmin
                            onClick={() => {
                              handleDeleteDetail(data.id);
                            }}
                            style={{
                              marginRight: 10,
                            }}
                          />
                        </Flex>
                      </div>
                      <Row>
                        <div className="border-bottom"></div>

                        {/* <div
                          style={{
                            backgroundColor: "red",
                            width: "200px",
                            height: "300px",
                          }}
                        ></div> */}
                      </Row>
                      <Row style={{ marginLeft: 20 }}>
                        <Col span={12}>
                          <div
                            style={
                              {
                                // backgroundColor: "red",
                                // width: "100%",
                                // height: "300px",
                              }
                            }
                          >
                            <p className="label-admin">
                              ประเภทแดซบอร์ด
                              {/* taeasdsadasdasdasdasdasdadasddaladsjlkjckdlasjdajdakldjkdjskaljdakdja;ksdja;ksdj;aksdja;dja;ksdjaksdja;skja */}
                            </p>

                            <p className="text-admin">
                              {data.insightReportCategoryName}
                            </p>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div
                            style={{
                              // backgroundColor: "blue",
                              width: "100%",
                              // height: "300px",
                            }}
                          >
                            <p className="label-admin">ใช้งาน/ไม่ใช้งาน</p>
                            <Switch
                              checked={enable}
                              style={{
                                marginTop: 10,
                                // backgroundColor: "#1890FF",
                              }}
                              defaultChecked
                              onChange={onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Form
                        form={form}
                        // className="search_inline w-full py-6"
                        onFinish={onFinish}
                        fields={[
                          {
                            name: ["dashboardName"],
                            value: findCurrentData(data.id).name,
                          },
                          {
                            name: ["listDetail"],
                            value: findCurrentData(data.id).shortDescription,
                          },
                          {
                            name: ["detail"],
                            value: findCurrentData(data.id).description,
                          },
                        ]}
                      >
                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 20,
                            marginBottom: mbLine2,
                          }}
                        >
                          <Col span={24}>
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  Tag <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="tag"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              {/* <Select
                                  onChange={(e) => handleChangeSelect(e, "tag")}
                                  mode="multiple"
                                  tagRender={tagRender}
                                  style={{
                                    width: "100%",
                                  }}
                                  options={tagData}
                                /> */}
                              {tagData.length == 0 ? (
                                <Select
                                  onChange={(e) => handleChangeSelect(e, "tag")}
                                  mode="multiple"
                                  tagRender={createTagRender}
                                  defaultValue={["New Tag"]}
                                  style={{
                                    width: "100%",
                                  }}
                                  options={createTagOptions}
                                />
                              ) : (
                                <Select
                                  onChange={(e) => handleChangeSelect(e, "tag")}
                                  mode="multiple"
                                  tagRender={tagRender}
                                  style={{
                                    width: "100%",
                                  }}
                                  options={tagData}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: 20, marginTop: 15 }}>
                          <Col span={24}>
                            <Form.Item
                              layout="vertical"
                              label={
                                <>
                                  <p className="label-admin">
                                    อัปโหลดภาพขนาดย่อ{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </p>
                                </>
                              }
                              name="upload"
                              colon={false}
                              required={false}
                              rules={[
                                // {
                                //   required: false,
                                //   message: "This information is required.",
                                // },
                                {
                                  validator: async () => {
                                    if (fileList <= 0)
                                      return Promise.reject(
                                        new Error("Please upload an image")
                                      );
                                  },
                                },
                              ]}
                              style={{
                                marginTop: 10,
                                marginRight: 10,
                                padding: 0,
                              }}
                            >
                              <span className="text-upload">
                                *สามารถอัปโหลดไฟล์ได้ 1 ไฟล์/ครั้ง
                                โดยแต่ละไฟล์ต้องมีขนาดไม่เกิน 5 MB (350x175 px)
                                (เฉพาะไฟล์ .png .jpg และ .jpeg เท่านั้น)
                              </span>
                              <p></p>
                              <img
                                id="tempImageThumb"
                                width="180"
                                height="50"
                                src={data.thumbnailSrc}
                                onError={() => handleonImageError("thumb")}
                                onSuccess={() => handleOnImageSuccess("thumb")}
                                style={{ display: "none" }}
                              ></img>
                              <Upload
                                // action={avatarUpload}
                                // action="https://tceb.ccs-service.online/core-api/files"
                                // multiple
                                headers={() => {
                                  let accessToken =
                                    sessionStorage.getItem("accessToken");
                                  return {
                                    Authorization: `Bearer ${accessToken}`,
                                  };
                                }}
                                maxCount={1}
                                fileList={fileList}
                                // fileList={testFileList}
                                listType="picture-card"
                                // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                beforeUpload={(file) => {
                                  return beforeUpload(file, "thumb");
                                }}
                                onRemove={(file) => {
                                  return removeUpload(file, "thumb");
                                }}
                                onChange={(info) => {
                                  handleUploadChange(info, "thumb");
                                }}
                                onPreview={(file) => {
                                  return handlePreview(file, "thumb");
                                }}
                                customRequest={(option) => {
                                  return uploadImage(option, "thumb");
                                  // if(isHasUploadError !== undefined &&!isHasUploadError){
                                  //   uploadImage(option, "thumb");
                                  // }
                                }}
                              >
                                {fileList.length <= 0 ? (
                                  <button
                                    className="upload-button"
                                    style={{
                                      border: 0,
                                      background: "none",
                                    }}
                                    type="button"
                                  >
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </button>
                                ) : (
                                  ""
                                )}
                              </Upload>
                              <Image
                                // width={200}
                                // height={200}
                                wrapperStyle={{
                                  display: "none",
                                }}
                                preview={{
                                  visible: previewOpen,
                                  onVisibleChange: (visible) =>
                                    setPreviewOpen(visible),
                                  afterOpenChange: (visible) =>
                                    !visible && setPreviewImage(""),
                                }}
                                src={previewImage}
                                // src={imageUrl}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: uploadHasError ? 180 : 145,
                          }}
                        >
                          <Col span={24}>
                            <Form.Item
                              layout="vertical"
                              label={
                                <>
                                  <p className="label-admin">
                                    อัปโหลดภาพในหน้า รายละเอียด{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </p>
                                </>
                              }
                              name="uploadContent"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  validator: async () => {
                                    if (fileContentList <= 0)
                                      return Promise.reject(
                                        new Error("Please upload an image")
                                      );
                                  },
                                },
                              ]}
                              style={{
                                marginTop: 10,
                                marginRight: 10,
                                padding: 0,
                              }}
                            >
                              <span className="text-upload">
                                *สามารถอัปโหลดไฟล์ได้ 1 ไฟล์/ครั้ง
                                โดยแต่ละไฟล์ต้องมีขนาดไม่เกิน 5 MB (600x600 px)
                                (เฉพาะไฟล์ .png .jpg และ .jpeg เท่านั้น)
                              </span>
                              <p></p>
                              <img
                                id="tempImageContent"
                                width="180"
                                height="50"
                                src={data.contentImageSrc}
                                onError={() => handleonImageError("content")}
                                onSuccess={() =>
                                  handleOnImageSuccess("content")
                                }
                                style={{ display: "none" }}
                              ></img>
                              <Upload
                                // action={avatarUpload}
                                // action="https://tceb.ccs-service.online/core-api/files"
                                // multiple
                                headers={() => {
                                  let accessToken =
                                    sessionStorage.getItem("accessToken");
                                  return {
                                    Authorization: `Bearer ${accessToken}`,
                                  };
                                }}
                                maxCount={1}
                                fileList={fileContentList}
                                // fileList={testFileList}
                                listType="picture-card"
                                // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                beforeUpload={(file) => {
                                  return beforeUpload(file, "content");
                                }}
                                onRemove={(file) => {
                                  return removeUpload(file, "content");
                                }}
                                onChange={(info) => {
                                  handleUploadChange(info, "content");
                                }}
                                onPreview={(file) => {
                                  return handlePreview(file, "content");
                                }}
                                customRequest={(option) => {
                                  return uploadImage(option, "content");
                                }}
                              >
                                {fileContentList.length <= 0 ? (
                                  <button
                                    className="upload-button"
                                    style={{
                                      border: 0,
                                      background: "none",
                                    }}
                                    type="button"
                                  >
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </button>
                                ) : (
                                  ""
                                )}
                              </Upload>
                              <Image
                                // width={200}
                                // height={200}
                                wrapperStyle={{
                                  display: "none",
                                }}
                                preview={{
                                  visible: previewOpenContent,
                                  onVisibleChange: (visible) =>
                                    setPreviewOpenContent(visible),
                                  afterOpenChange: (visible) =>
                                    !visible && setPreviewImageContent(""),
                                }}
                                src={previewImageContent}
                                // src={imageUrl}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            // marginTop: 135,
                            marginTop: uploadContentHasError ? 180 : 145,
                            marginBottom: dashboardNameHasError ? 50 : 0,
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "99%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  ชื่อรายงานวิเคราะห์{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="dashboardName"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              <Input
                                showCount
                                maxLength={100}
                                onChange={(e) =>
                                  handleChangeText(
                                    e.target.value,
                                    "dashboardName"
                                  )
                                }
                                placeholder="ชื่อรายงานวิเคราะห์"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 15,
                            marginBottom: listDetailHasError ? 110 : 0,
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "99%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  รายละเอียด หน้า List{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="listDetail"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              <TextArea
                                showCount
                                maxLength={300}
                                onChange={(e) =>
                                  handleChangeText(e.target.value, "listDetail")
                                }
                                placeholder="รายละเอียด หน้า List"
                                style={{ height: 85, resize: "none" }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 65,
                            // marginBottom: detailError ? 140 : 0,
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "99%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  รายละเอียด{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="detail"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              {/* <TextArea
                                showCount
                                maxLength={1000}
                                onChange={(e) =>
                                  handleChangeText(e.target.value, "detail")
                                }
                                placeholder="รายละเอียด"
                                style={{
                                  height: 145,
                                  resize: "none",
                                  marginBottom: 70,
                                }}
                              /> */}

                              <Flex
                                vertical={true}
                                gap="small"
                                justify="flex-end"
                                align="flex-end"
                              >
                                <ReactQuill
                                  className="react-quill"
                                  ref={quillRef}
                                  theme="snow"
                                  value={data.description}
                                  modules={modules}
                                  style={{ height: 300, width: "100%" }}
                                  onChange={handleOnEditTextChange}
                                />
                                {detailError}
                                <span
                                  style={{
                                    color: "#9ca0a6",
                                    marginTop: detailError ? 0 : 40,
                                  }}
                                >
                                  {getDetailLength()}/1000
                                </span>
                              </Flex>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: (detailError ? 210 : 180) + 155,
                            // marginBottom: dataLinkHasError ? 40 : 0,
                            marginBottom: 0,
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "99%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  เลือกแดซบอร์ดที่ต้องการเชื่อมกับรายงานวิเคราะห์
                                  {/* <span style={{ color: "red" }}>*</span> */}
                                </p>
                              }
                              name="dataLink"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: false,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              <Select
                                placeholder="เลือก (ได้มากกว่า 1)"
                                onChange={(e) =>
                                  handleChangeSelect(e, "dataLink")
                                }
                                mode="multiple"
                                tagRender={tagRender}
                                style={{
                                  width: "100%",
                                }}
                                options={tagDataLink}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 10,
                            // marginBottom: dataLinkHasError? 40: 0
                            marginBottom:
                              fileInsightReportList.length > 0 ? 25 : 0,
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "99%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  เลือก Role
                                  ที่ต้องการให้ดาวน์โหลดรายงานวิเคราะห์{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="role"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This information is required.",
                                },
                              ]}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              <Select
                                onChange={(e) => {
                                  handleChangeSelect(e, "role");
                                }}
                                mode="multiple"
                                tagRender={tagRender}
                                style={{
                                  width: "100%",
                                }}
                                options={roleData?.map((role) => ({
                                  label: role.nameTh,
                                  value: role.id,
                                }))}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 10,
                            // marginBottom: dataLinkHasError? 40: 0
                          }}
                        >
                          <Col span={24}>
                            {/* <div
                              style={{
                                // backgroundColor: "blue",
                                width: "99%",
                                // height: "300px",
                              }}
                            >
                              
                            </div> */}
                            {/* {fileInsightReportList.length <= 0 ? 

:

} */}
                            <Form.Item
                              layout="vertical"
                              label={
                                <p className="label-admin">
                                  อัปโหลดไฟล์ รายงานวิเคราะห์{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              }
                              name="insightReportFile"
                              colon={false}
                              required={false}
                              rules={[
                                {
                                  validator: async () => {
                                    if (fileInsightReportList <= 0)
                                      return Promise.reject(
                                        new Error("Please upload an file")
                                      );
                                  },
                                },
                              ]}
                              style={{
                                marginTop: 20,
                                marginRight: 10,
                                display:
                                  fileInsightReportList.length <= 0
                                    ? ""
                                    : "none",
                              }}
                            >
                              <Dragger
                                // {...props}
                                headers={() => {
                                  let accessToken =
                                    sessionStorage.getItem("accessToken");
                                  return {
                                    Authorization: `Bearer ${accessToken}`,
                                  };
                                }}
                                maxCount={1}
                                fileList={fileInsightReportList}
                                // fileList={testFileList}
                                // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                beforeUpload={(file) => {
                                  return beforeUploadInsightReportFile(file);
                                }}
                                onRemove={(file) => {
                                  return removeUpload(
                                    file,
                                    "insightReportFile"
                                  );
                                }}
                                onChange={(info) => {
                                  handleUploadChange(info, "insightReportFile");
                                }}
                                // onPreview={(file) => {
                                //   return handlePreview(file, "insightReportFile");
                                // }}
                                customRequest={(option) => {
                                  return uploadImage(
                                    option,
                                    "insightReportFile"
                                  );
                                }}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  marginBottom: 20,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                  }}
                                >
                                  <Row
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Col
                                      style={{
                                        textAlign: "center",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <UploadOutlined
                                        style={{
                                          fontSize: "30px",
                                          // color: "#FF7F07"
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      style={{
                                        margin: "0px 10px",
                                      }}
                                    >
                                      <Row
                                        style={{
                                          margin: "0px 0px",
                                        }}
                                      >
                                        <p className="ant-upload-text">
                                          คลิก หรือ
                                          ลากไฟล์มาที่พื้นที่นี้เพื่ออัปโหลด
                                        </p>
                                      </Row>
                                      <Row
                                        style={{
                                          margin: "-15px 0px",
                                        }}
                                      >
                                        <p className="ant-upload-hint">
                                          หรือ <a>เรียกดู</a>{" "}
                                          เพื่อเลือกไฟล์ในคอมพิวเตอร์
                                        </p>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </Dragger>
                              <span className="text-upload-insight-report">
                                *คุณสามารถอัปโหลดไฟล์ได้สูงสุด 1 ไฟล์
                                ขนาดไม่เกิน 20 MB รองรับเฉพาะไฟล์ประเภท .PDF
                                เท่านั้น
                              </span>
                            </Form.Item>
                            {fileInsightReportList.length > 0 ? (
                              <Flex>
                                <a
                                  href={insightReportFileUrl}
                                  style={{
                                    // marginTop: 120,
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.insightReportFileRawName}
                                </a>
                                <DeleteOutlined
                                  style={{ marginLeft: 10 }}
                                  onClick={() => {
                                    setFileInsightReportList([]);
                                    removeFileData(
                                      "insight_report_file",
                                      "insightReportFile"
                                    );
                                  }}
                                />
                              </Flex>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default AdminInsightReportManagement;
