import React, { useState, useEffect } from "react";
import { ArrowLeftOutlined, ShareAltOutlined, DownCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Space, Tag, Menu, Row, Col, Grid, Modal,Input } from 'antd';
import { InsightReportService } from "../../../../services/InsightReportService.js";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import IconRightArrowViewDashboard from "../../../../assets/icon/icon_right_arrow_view_dashboard.js";
import { DashboardService } from "../../../../services/DashboardService.js";
import { useNavigate, useParams } from 'react-router-dom';
import NoImg from "../../../../assets/img/no_img.png";
import "./InsightReportDetail.css";
import { FacebookShareButton, LineShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import icon_fb from "../../../../assets/icon/svg/icon_fb.svg";
import icon_linkedin from "../../../../assets/icon/svg/icon_linkedin.svg";
import icon_x from "../../../../assets/icon/svg/icon_x.svg";
import icon_line from "../../../../assets/icon/svg/icon_line.svg";

const InsightReportPage = ({ id }) => {
    const { id: urlId } = useParams();
    const navigate = useNavigate();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const [InsightData, setInsightData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isCopied, setIsCopied] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [insightDataList, setInsightDataList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0); // index ปัจจุบั
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const [dashboardDataList, setDashboardDataList] = useState([]);
    const itemsPerPage = 4;
    const itemsPerInsightPage = 4;

    const handleNext = () => {
        if (currentIndex + itemsPerPage < dashboardDataList.length) {
            setCurrentIndex(currentIndex + itemsPerPage);
        }
    };

    const handlePrev = () => {
        if (currentIndex - itemsPerPage >= 0) {
            setCurrentIndex(currentIndex - itemsPerPage);
        }
    };

    const handleNext2 = () => {
        if (currentIndex2 + itemsPerInsightPage < insightDataList.length) {
            setCurrentIndex2(currentIndex2 + itemsPerInsightPage);
        }
    };

    const handlePrev2 = () => {
        if (currentIndex2 - itemsPerInsightPage >= 0) {
            setCurrentIndex2(currentIndex2 - itemsPerInsightPage);
        }
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const displayedData = dashboardDataList.slice(currentIndex, currentIndex + itemsPerPage);
    const displayedData2 = insightDataList.slice(currentIndex2, currentIndex2 + itemsPerInsightPage);

    const fetchInsightReportData = async () => {
        try {
            // const dashboardId = id || "38dfccb3-9266-4fe9-9d03-3d9e02c3ce92";
            var title = decodeURI(window.location.pathname.split("/").pop());
            const response = await InsightReportService.get(title);
            if (response) {
                const ogImage = document.querySelector(`meta[property="og:image"]`);
                if (ogImage) {
                    ogImage.setAttribute("content", response.thumbnailSrc || NoImg);
                }
                const metaImage = document.querySelector(`meta[name="twitter:image"]`);
                if (metaImage) {
                    metaImage.setAttribute("content", response.thumbnailSrc || NoImg);
                }
                setInsightData(response); // ตั้งค่าข้อมูลที่ได้จาก API
                //console.log("Fetched Data:", response); // ตรวจสอบข้อมูล
                const tagIdObjects = response.insightReportTags.map((tag) => ({ tagId: tag.tagId }));
                //console.log(tagIdObjects);
                const tagIds = tagIdObjects.map((obj) => obj.tagId);
                const responseList = await InsightReportService.getdatalistTag(tagIds);
                if (Array.isArray(responseList.items)) {
                    setInsightDataList(responseList.items); // เข้าถึง items และตั้งค่า
                } else {
                    console.error("responseList.items is not an array");
                    setInsightDataList([]); // ถ้าไม่ใช่ array ให้ตั้งค่าเป็น array ว่างๆ
                }
                const idInsight = response.id;
                const responseList2 = await DashboardService.getdatalist(idInsight);
                if (Array.isArray(responseList2.items)) {
                    setDashboardDataList(responseList2.items);
                } else {
                    console.error("responseList.items is not an array");
                    setDashboardDataList([]);
                }
            } else {
                throw new Error("No data received from API");
            }
        } catch (err) {
            console.error("Error fetching insight report data:", err);
            setError("Error fetching data. Please try again.");
            if(err.response?.status === 404) {
                navigate("/not-found");
            }
        } finally {
            setIsLoading(false); // ยกเลิกสถานะการโหลด
        }
    };

    // ดึงข้อมูลจาก API
    useEffect(() => {
        //console.log('useEffect InsightReportPage');


        fetchInsightReportData();
    }, [urlId]);



    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: 'ดาวน์โหลด Excel',
                },
                {
                    key: '2',
                    label: 'ดาวน์โหลด CSV',
                },
            ]}
        />
    );
    const showModal = () => {
        setIsModalVisible(true);
        console.log("showModal");
    };

    // แสดงผลข้อมูล
    const { name, shortDescription, thumbnailSrc, insightReportTags, createdBy, createdDate, description, formattedCreatedDate, contentImageSrc, srcUrl } = InsightData;
    // const srcUrlId = srcUrl.split('/').pop();


    const handleBackClick = () => {
        navigate('/insight-report');
    };

    const handleClickInsight = (id, name) => {
        const Name = encodeURIComponent(name);
        navigate(`/insight-report/${Name}`);
        window.scrollTo(0, 0);
        fetchInsightReportData();
    };

    const handleClickDashbord = (id, name) => {
        const Name = encodeURIComponent(name);
        sessionStorage.setItem("itemId", id);
        navigate(`/dashboard/${Name}`);
        window.scrollTo(0, 0);
    };


    const handleDownload = async () => {
        try {
            // ส่งคำขอเพื่อดึงไฟล์
            const response = await fetch(srcUrl);
            const blob = await response.blob(); // แปลงเนื้อหาของไฟล์เป็น Blob

            const link = document.createElement('a'); // สร้างลิงก์ชั่วคราว
            link.href = URL.createObjectURL(blob); // สร้าง URL จาก Blob
            link.download = srcUrl.split('/').pop(); // ตั้งชื่อไฟล์จาก URL หรือกำหนดชื่อที่คุณต้องการ
            link.click(); // คลิกลิงก์เพื่อดาวน์โหลด
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    };

    return (
        <div className="content-insight-report-detail">
            <div className="heading">
                <span className="content-card-title">
                    <ArrowLeftOutlined className="arrow-back" onClick={handleBackClick} /> {name || "ไม่มีข้อมูล"}
                </span>
            </div>
            <div>
                <div className="card-container">
                    <Row>
                        <Col span={12} md={12} sm={24} xs={24} className={screens.md ? "cover-image-container" : ""}>
                            <img
                                className={screens.md ? "cover-image" : "cover-image-mobile"}
                                src={contentImageSrc || NoImg}
                                alt="Background"
                                onError={(e) => {
                                    //console.log("No img");
                                    e.target.src = NoImg;
                                }}
                            />
                        </Col>
                        <Col span={12} md={12} sm={24} xs={24}>
                            <div className="center-v">
                                {/* การ์ดเนื้อหา */}
                                <div className={screens.md ? 'content' : 'content-mobile'}>
                                    {screens.md && (
                                        <div className="share-button-container">
                                            <Button className="share-button" onClick={showModal}>
                                                <ShareAltOutlined />แชร์
                                            </Button>
                                            
                                    
                                        </div>
                                    )}
                                    <Modal
                                        title="แชร์ข้อมูลของคุณ"
                                        className="share-modal"
                                        open={isModalVisible}
                                        onCancel={handleCancel}
                                        footer={null} // ถ้าไม่ต้องการปุ่มใน footer ของ Modal
                                        width={500} // ความกว้างของ Modal
                                        style={{zIndex: 999999}}
                                    >
                                        <FacebookShareButton url={window.location.href}>
                                            <img src={icon_fb} alt="FB" width={30} height={30} title="แชร์ไปยัง Facebook"/>
                                        </FacebookShareButton>
                                        <TwitterShareButton url={window.location.href}>
                                            <img src={icon_x} alt="X" width={30} height={30} title="แชร์ไปยัง X"/>
                                        </TwitterShareButton>
                                        <LinkedinShareButton url={window.location.href}>
                                            <img src={icon_linkedin} alt="Linkedin" width={30} height={30} title="แชร์ไปยัง Linkedin"/>
                                        </LinkedinShareButton>
                                        <LineShareButton url={window.location.href}>
                                            <img src={icon_line} alt="Line" width={30} height={30} title="แชร์ไปยัง Line"/>
                                        </LineShareButton>
                                        <h4 >คัดลอก URL <small className="copied-text">{isCopied}</small></h4>
                                        <Space.Compact className="copy-url-container">
                                            <Input defaultValue={decodeURI(window.location.href)} readOnly={true}/>
                                            <CopyToClipboard text={decodeURI(window.location.href)}
                                                onCopy={() => {
                                                    setIsCopied("คัดลอกแล้ว");
                                                    setTimeout(() => {
                                                        setIsCopied("");
                                                    }, 3000);
                                                }}>
                                                <Button type="default" className="copy-url-button">คัดลอก</Button>
                                            </CopyToClipboard>
                                        </Space.Compact>
                                        
                                    </Modal>
                                    {/* ชื่อและคำอธิบาย */}
                                    <div>
                                        <h3 className="title">{name || "Untitled Report"}</h3>
                                    </div>
                                    {/* ปุ่มแชร์ */}

                                    <div> {/* เพิ่ม gap ระหว่าง tags */}
                                        {insightReportTags && insightReportTags.length > 0 ? (
                                            insightReportTags.map((tag) => (
                                                <Tag
                                                    key={tag.id}
                                                    className="tag"
                                                    color={tag.tagColor}
                                                >
                                                    {tag.tagName}
                                                </Tag>
                                            ))
                                        ) : (
                                            <p>No tags available.</p>
                                        )}
                                    </div>
                                    {!screens.md && (
                                        <div className="share-button-container-mobile">
                                            <Button className="share-button" onClick={showModal}>
                                                <ShareAltOutlined /> แชร์
                                            </Button>
                                        </div>
                                    )}
                                    <p
                                        className="description"
                                        dangerouslySetInnerHTML={{ __html: description || "No description available." }}
                                    ></p>

                                    <div>
                                        {/* วันที่ */}
                                        <span className="date">
                                            {formattedCreatedDate || "01-08-2567"}
                                        </span>
                                        <Button
                                            type="primary"
                                            className={screens.xs ? "download-button-mobile" : "download-button"}
                                            onClick={handleDownload}
                                        >
                                            <DownCircleOutlined />
                                            ดาวน์โหลดเอกสาร
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </div >

            <div>
                {displayedData?.length > 0 ? (
                    <div className={screens.md ? 'container-insight-report-detail' : 'container-insight-report-detail-mobile'}>
                        <div className="content-insight-report-detail">
                            <Flex justify="space-between" align="center">
                                <span className="content-card-title-detail">แดชบอร์ดที่เกี่ยวข้อง</span>
                                <Space>
                                    <Button className="arrow-left-home" onClick={handlePrev}>
                                        <HiOutlineArrowLeft />
                                    </Button>
                                    <Button className="arrow-right-home" onClick={handleNext}>
                                        <HiOutlineArrowRight />
                                    </Button>
                                </Space>
                            </Flex>
                        </div>

                        <div className="container-card-insight-report-detail">
                            <Space size="middle">
                                <Row style={{ width: screens.md ? "calc(100vw - 220px)" : "100%" }}>
                                    {displayedData.map((item) => (
                                        <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
                                            <div className="card-container">
                                                <div className="content-card-item">

                                                    <img
                                                        className="content-image-insight-report-detail-2"
                                                        alt="error"
                                                        src={item.thumbnailSrc ? item.thumbnailSrc : NoImg}
                                                        onError={(e) => {
                                                            if (e.target.alt === 'error') {
                                                                //console.log("No img")
                                                                e.target.src = NoImg;
                                                            } else {
                                                                e.target.src = NoImg;
                                                            }
                                                        }}
                                                    />
                                                    <div className="card-detail-container">
                                                        <div>
                                                            {item.dashboardTags &&
                                                                item.dashboardTags.map((tagItem, tagIndex) => (
                                                                    <Tag
                                                                        key={tagIndex}
                                                                        bordered={false}
                                                                        color={tagItem.tagColor}
                                                                    >
                                                                        {tagItem.tagName}
                                                                    </Tag>
                                                                ))}
                                                        </div>

                                                        <p className="content-card-title-insight-report-detail">{item.name}</p>
                                                        <p className="content-card-detail-insight-report-detail">
                                                            {item.shortDescription}
                                                        </p>
                                                        <p className="insight-report-date">
                                                        </p>

                                                        <Flex
                                                            className="detail-button-footer"
                                                            justify="center"
                                                            align="center"
                                                            style={{

                                                            }}
                                                            onClick={() => handleClickDashbord(item.id, item.name)}
                                                        >
                                                            <p>
                                                                <span>ดูรายละเอียด</span>
                                                                <IconRightArrowViewDashboard />
                                                            </p>
                                                        </Flex>

                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Space>
                        </div>

                    </div>
                ) : null}

            </div>


            <div>
                {displayedData2?.length > 0 ? (
                    <div className={screens.md ? 'container-insight-report-detail' : 'container-insight-report-detail-mobile'}>
                        <div className="content-insight-report-detail">
                            <Flex justify="space-between" align="center">
                                <span className="content-card-title-detail">รายงานที่วิเคราะห์ข้อมูลเชิงลึกที่เกี่ยวข้อง (Insight Report)</span>
                                <Space>
                                    <Button className="arrow-left-home" onClick={handlePrev2}>
                                        <HiOutlineArrowLeft />
                                    </Button>
                                    <Button className="arrow-right-home" onClick={handleNext2}>
                                        <HiOutlineArrowRight />
                                    </Button>
                                </Space>
                            </Flex>
                        </div>

                        <div className="container-card-insight-report-detail">
                            <Space size="middle">
                                <Row style={{ width: screens.md ? "calc(100vw - 220px)" : "100%" }}>
                                    {displayedData2?.map((item) => (
                                        <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} key={item.id}>
                                            <div className="card-container">

                                                <div className="content-card-item" key={item.id}>
                                                    <div className="content-image-container">
                                                        <img
                                                            className="content-image-insight-report-detail-2"
                                                            alt="error"
                                                            src={item.thumbnailSrc ? item.thumbnailSrc : NoImg}
                                                            onError={(e) => {
                                                                if (e.target.alt === 'error') {
                                                                    //console.log("No img")
                                                                    e.target.src = NoImg;
                                                                } else {
                                                                    e.target.src = NoImg;
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="card-detail-container">
                                                        <Space>
                                                            {item.insightReportTags?.map((tag) => (
                                                                <Tag
                                                                    key={tag.id}
                                                                    bordered={false}
                                                                    color={tag.tagColor}
                                                                >
                                                                    {tag.tagName}
                                                                </Tag>
                                                            ))}
                                                        </Space>
                                                        <br />
                                                        <p className="content-card-title-insight-report-detail">{item.name}</p>

                                                        <p className="content-card-detail-insight-report-detail">
                                                            {item.shortDescription}
                                                        </p>


                                                        <p className="content-card-detail-dashboard-detail">{item.createdDateFrom}</p>
                                                        <Flex
                                                            className="detail-button-footer"
                                                            justify="center"
                                                            align="center"
                                                            onClick={() => handleClickInsight(item.id, item.name)}
                                                        >

                                                            <p>
                                                                <span>อ่านต่อ</span>
                                                                <IconRightArrowViewDashboard />
                                                            </p>
                                                        </Flex>
                                                    </div>
                                                </div>

                                            </div>

                                        </Col>
                                    ))}
                                </Row>
                            </Space>
                        </div>


                    </div>
                ) : null}

            </div>
            {isLoading && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="spinner"></div>
                        <p>Loading...</p>
                    </div>
                </div>
            )}

        </div>
    );
};

export default InsightReportPage;
