import "./DataCatalogDetail.css";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Tabs,
  Space,
  DatePicker,
  Flex,
  Button,
  Col,
  Row,
  Tag,
  Select,
  Radio,
  ConfigProvider,
  Grid,
  List,
  Layout,
  Divider,
  Menu,
  Switch,
  Input,
  Dropdown,
} from "antd";
import { CustomButton, DeleteButton, PinButton } from "../../../../components";
import { LiaReadme } from "react-icons/lia";
import { TagService } from "../../../../services/TagService";
import TabPane from "antd/es/tabs/TabPane";
import NoData from "../../../../assets/img/no_data.js";
import { ArrowLeftOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { DataSourceService } from "../../../../services/DataSourceService.js";
import { DataSourceCategoryService } from "../../../../services/DataSourceCategoryService.js";


const {Content, Sider } = Layout;
const { RangePicker } = DatePicker;
const { useBreakpoint } = Grid;

const DataCatalogDetail = () => {

    const navigate = useNavigate();
    const [dataCatalogData, setDataCatalogData] = useState([]);
    
    useEffect(() => {
        fetchDataCatalogDetailData()
        
    }, []);

    const fetchDataCatalogDetailData = async () => {
        try {
            const title = decodeURI(window.location.pathname.split("/").pop());
            if (title) {
                const response = await DataSourceService.getByName(title); 
                setDataCatalogData(response.data);
                //console.log("response.data >> ",response.data);
            } else {
                console.error("No ID found in sessionStorage or URL");
            }
        } catch (err) {
            console.error("Error fetching dashboard data:", err);
            navigate(`*`);
        } 
    };

    const {dataSourceTags,dataSourceMetaDatas} = dataCatalogData;
    return (
       
    <>
    
       <div className="datacatalogdetail-body" >
            <div className="heading">
                <div className="content-card-title">
                    <ArrowLeftOutlined className="arrow-back" onClick={()=>navigate(`/data-catalog`)}  /> {dataCatalogData?.name}
                </div>
            </div>
            <div className="datacatalogdetail-container">
                <div className="detail">

                    <Row gutter={[16,8]}>
                            <>
                            <Col xs={24} >
                                    <div className="datacatalog-item-data">
                                        <Row justify={"space-between"} >
                                            <Col xs={24} sm={12} md={14} style={{alignContent:"center"}}>
                                                <Row style={{paddingBottom:10}}>
                                                    <Link className="name">{dataCatalogData?.name}</Link>
                                                </Row>
                                                
                                                <Row style={{paddingBottom:5}}>
                                                <Flex wrap gap="small">

                                                    {dataSourceTags && dataSourceTags.length > 0 ? (
                                                        dataSourceTags.map((tag) => (
                                                            <Tag
                                                                key={tag.id}
                                                                
                                                                color={tag.tagColor}
                                                            >
                                                                {tag.tagName}
                                                            </Tag>
                                                        ))
                                                    ) : (
                                                        <p>No tags available.</p>
                                                    )}
                                                
                                                </Flex>
                                                </Row>
                                                <Row>
                                                <Flex wrap gap="small">
                                                    <span style={{color:"#667085" ,marginBottom:5}}>อัพเดทล่าสุด : {dataCatalogData?.formattedUpdatedDate}</span>
                                                </Flex>
                                                </Row>
                                                
                                            
                                            </Col>
                                            <Col xs={24} sm={8} md={8
                                                
                                            } lg={6} xl={5} xxl={5} style={{alignContent:"start", textAlign:"end"}}>
                                            {/* <Dropdown overlay={menuDownload} trigger={['click']}> */}
                                                    <Button
                                                        type="default"
                                                        className=""
                                                        style={{
                                                            border: "1px solid #1677FF",
                                                            color:"#1677FF",
                                                            borderRadius:"10px",
                                                            width:"100%"
                                                        }}
                                                    >
                                                        <UploadOutlined/>
                                                        <span className="">ดาวน์โหลดข้อมูล</span>
                                                    </Button>

                                                {/* </Dropdown> */}
                                            </Col>
                                        </Row>
                                        <Row justify={"space-between"} >
                                            <Col xs={24}  style={{alignContent:"start", textAlign:"left"}}>
                                                    {/* <p>{dataCatalogData?.description}</p> */}
                                                    <div dangerouslySetInnerHTML={{ __html: dataCatalogData?.description }} />;
                                            </Col>
                                        </Row>

                                    </div>
                                    
                                
                            </Col>
                            </>
                    </Row>
                </div>
                <div className="meta-data">
                    <h3>ข้อมูลเพิ่มเติม</h3>
                    <div className="meta-data-item">



                    {dataSourceMetaDatas && dataSourceMetaDatas.length > 0 ? (
                        dataSourceMetaDatas.map((data,index) => (
                            <div className="meta-data-item-row">
                                <Row gutter={[16]}>
                                    <Col xs={24} sm={12} md={9}>
                                        <div className="txt-thai">
                                            {data?.fieldNameTH}
                                        </div>
                                        <div className="txt-eng">
                                            {data.fieldNameEN}
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} md={9}>
                                        <div className="lb-description">
                                            รายละเอียด
                                        </div>
                                        <div className="txt-description">
                                            {data.description}
                                        </div>
                                    </Col>
                                </Row>
                                {!((index+1) ==  dataSourceMetaDatas.length) && (
                                    <hr/>
                                )}
                                
                            </div> 
                        ))
                    ) : (
                        <p>No Data</p>
                    )}

                       

                        

                    </div>
                    
                    
                </div>
            </div>

        </div>
    </>
    )
   
    
}

export default DataCatalogDetail;