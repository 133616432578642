import React, { useState, useEffect } from "react";
import { Layout, Space, Tag, Col, Row, Table, Input, Button, Select, message, Flex } from "antd";
import "./tag.css";
import { ArrowLeftOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { TagService } from "../../../services/TagService";
import CustomButton from "../../../components/Button/CustomButton";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../components/Dialog/FailedDialog";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";

const { Content, Sider } = Layout;
const { Option } = Select;

const TagPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [isModalSuccessOpen2, setIsModalSuccessOpen2] = useState(false);
    const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
    const [showFirstTable, setShowFirstTable] = useState(false);
    const [selectedKey, setSelectedKey] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const fetchTags = async () => {
        try {
            setLoading(true);
            const response = await TagService.get();
            //console.log("API Response:", response);

            // ตรวจสอบว่า response ถูกต้องหรือไม่
            if (!response || !Array.isArray(response.items)) {
                throw new Error("Response format is invalid or items are missing.");
            }

            // จัดรูปแบบข้อมูล
            const formattedData = response.items.map((item, index) => ({
                key: item.id || index.toString(),
                index: item.sequence ?? index + 1, // ใช้ sequence หรือ index + 1
                name: item.name || "Default",
                color: item.color || "default",
                isActivated: item.isActivated ?? false,
                tagExample: item.name || "Default",
            }));

            // เรียงข้อมูลตาม index (ลำดับ #)
            const sortedData = formattedData.sort((a, b) => a.index - b.index);

            // ตั้งค่าข้อมูลที่เรียงแล้ว
            setData(sortedData);
        } catch (error) {
            console.error("Error fetching tags:", error);
            message.error("เกิดข้อผิดพลาดในการโหลดข้อมูล");
        } finally {
            setLoading(false);
        }
    };


    const fetchTagsSearch = async (keyword = "") => {
        try {
            //console.log("Searching with keyword:", keyword); // Debugging: Log the keyword
            setLoading(true);

            // Make the API call with the keyword
            const response = await TagService.getSearch(keyword);
            //console.log("API Response:", response); // Debugging: Log the entire response

            // Check if the response format is correct
            if (!response || !Array.isArray(response.items)) {
                throw new Error("Response format is invalid or items are missing.");
            }

            // Format the response data
            const formattedData = response.items.map((item, index) => ({
                key: item.id || index.toString(),
                index: item.sequence ?? index + 1,
                name: item.name || "Default",
                color: item.color || "default",
                isActivated: item.isActivated ?? false,
                tagExample: item.name || "Default",
            }));

            // Set the formatted data into the state
            setData(formattedData);
        } catch (error) {
            console.error("Error fetching tags:", error); // Debugging: Log the error
            message.error("เกิดข้อผิดพลาดในการโหลดข้อมูล");
        } finally {
            setLoading(false); // Set loading state to false when done
        }
    };


    const handleSearchChange = (e) => {
        const keyword = e.target.value;
        setSearchKeyword(keyword);
        fetchTagsSearch(keyword);
    };

    useEffect(() => {
        fetchTags();
    }, []);

    useEffect(() => {
        if (searchKeyword) {
            fetchTagsSearch(searchKeyword);
        } else {
            fetchTags();
        }
    }, [searchKeyword]);


    const handleColorChange = (color, key) => {
        const newData = data.map((item) => {
            if (item.key === key) {
                return { ...item, color };
            }
            return item;
        });
        setData(newData);
    };

    const handleNameChange = (value, key) => {
        const updatedData = data.map(item =>
            item.key === key
                ? { ...item, name: value, tagExample: value }
                : item
        );
        setData(updatedData);
    };

    const handleAddTag = () => {
        const newIndex = data.length + 1;
        const newTag = {
            key: newIndex.toString(),
            index: newIndex,
            name: 'New Tag',
            color: 'default',
            tagExample: 'New Tag',
        };
        setData([...data, newTag]);
    };



    const handleDeleteTag = (key) => {
        setSelectedKey(key); // เก็บ key ที่เลือกไว้
        setIsModalDeleteOpen(true); // เปิด Modal
    };

    const handleSaveButton = () => {
        setIsModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const tagToDelete = data.find((item) => item.key === selectedKey);

            if (!tagToDelete) {
                message.error("ไม่พบ Tag ที่ต้องการลบ");
                return;
            }
            const response = await TagService.delete(tagToDelete.key);
            if (response.status === 200) {
                setIsModalSuccessOpen(true);
                const filteredData = data.filter((item) => item.key !== selectedKey);
                setData(filteredData);
                setTimeout(() => {
                    setIsModalSuccessOpen(false);
                }, 3000);
                setShowFirstTable(false);
            } else {
                setIsModalFailedOpen(true);
            }
        } catch (error) {
            setIsModalFailedOpen(true);
            const errorMessage = error?.response?.data?.messageTh || "Something went wrong. Please try again.";
            setErrorMessage(errorMessage);  // Set the dynamic error message
            setShowFirstTable(false);
        } finally {
            setIsModalDeleteOpen(false); // ปิด Modal
        }
    };

    const handleSave = async () => {
        const jsonData = data.map(item => ({
            id: item.key && item.key.length < 2 ? "" : item.key || "",
            name: item.name || "Unknown",
            color: item.color || "DefaultColor",
            sequence: item.index,
        }));
        //console.log(data);
    
        try {
            const response = await TagService.Createdata(jsonData);
            setIsModalSuccessOpen2(true);
            fetchTags(); 
            setShowFirstTable(false);
            setTimeout(() => {
                setIsModalSuccessOpen2(false);
            }, 3000);
        } catch (error) {
            const errorMessage = error?.response?.data?.messageTh || "Something went wrong. Please try again.";
            setErrorMessage(errorMessage);  // Set the dynamic error message
            setIsModalFailedOpen(true);
        }
    };
    



    const handleswitch = () => {
        setShowFirstTable(true);
    };


    const handleCancel = () => {
        setShowFirstTable(false);
    }

    const handleClick = () => {
        setShowFirstTable(false);
    };

    const columns = [
        {
            title: 'คำสั่ง',
            key: 'action',
            width: '10%',
            sorter: (a, b) => a.index - b.index,
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="default"
                        icon={<DeleteOutlined />}
                        style={{ padding: '5px 10px' }}
                        onClick={() => handleDeleteTag(record.key)} // ส่ง key ไป
                    />
                </Space>
            ),
        },
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: '10%',
            sorter: (a, b) => a.index - b.index,  // เพิ่มฟังก์ชันเรียงลำดับ
        },
        {
            title: 'ชื่อ Tag',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            sorter: (a, b) => a.index - b.index,  // ไม่ให้สามารถเรียงลำดับได้
            render: (text, record) => (
                <Input
                    value={text}
                    maxLength={20}  // จำกัดไม่ให้เกิน 20 ตัวอักษร
                    onChange={(e) => handleNameChange(e.target.value, record.key)}
                    suffix={
                        <span style={{ fontSize: '12px', color: '#888' }}>
                            {text.length} / 20
                        </span>
                    }
                />
            ),
        },            
        {
            title: 'สี Tag',
            dataIndex: 'color',
            key: 'color',
            width: '20%',
            sorter: (a, b) => a.color.localeCompare(b.color),  // เรียงตามสี
            render: (color, record) => {
                // กรองสีที่ใช้ไปแล้วจากข้อมูลทั้งหมด
                const usedColors = data.filter(item => item.key !== record.key).map(item => item.color);
                
                return (
                    <Select
                        defaultValue={color}
                        style={{ width: 100 }}
                        onChange={(newColor) => handleColorChange(newColor, record.key)}
                    >
                        <Option value="default" disabled={usedColors.includes("default")}>Default</Option>
                        <Option value="magenta" disabled={usedColors.includes("magenta")}>Magenta</Option>
                        <Option value="green" disabled={usedColors.includes("green")}>Green</Option>
                        <Option value="red" disabled={usedColors.includes("red")}>Red</Option>
                        <Option value="volcano" disabled={usedColors.includes("volcano")}>Volcano</Option>
                        <Option value="gold" disabled={usedColors.includes("gold")}>Gold</Option>
                        <Option value="cyan" disabled={usedColors.includes("cyan")}>Cyan</Option>
                        <Option value="blue" disabled={usedColors.includes("blue")}>Blue</Option>
                        <Option value="purple" disabled={usedColors.includes("purple")}>Purple</Option>
                    </Select>
                );
            },
        },        
        {
            title: 'ตัวอย่าง Tag',
            dataIndex: 'tagExample',
            key: 'tagExample',
            width: '30%',
            sorter: false,  // ไม่ให้สามารถเรียงลำดับได้
            sorter: (a, b) => a.index - b.index,
            render: (tagExample, record) => (
                <Tag color={record.color}>
                    {tagExample}
                </Tag>
            ),
        },
    ];

    const columns2 = [
        {
            title: 'คำสั่ง',
            key: 'action',
            width: '10%',
            sorter: (a, b) => a.index - b.index,
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="default"
                        icon={<DeleteOutlined />}
                        style={{ padding: '5px 10px' }}
                        onClick={() => handleDeleteTag(record.key)} // ส่ง key ไป
                    />
                </Space>
            ),
        },
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: '10%',
            sorter: (a, b) => a.index - b.index,  // เพิ่มฟังก์ชันเรียงลำดับ
        },
        {
            title: 'ชื่อ Tag',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.index - b.index,
            render: (text) => (
                <span style={{ color: '#000', cursor: 'not-allowed' }}>{text}</span>
            ),
        },
        {
            title: 'ตัวอย่าง Tag',
            dataIndex: 'tagExample',
            key: 'tagExample',
            width: '30%',
            sorter: (a, b) => a.index - b.index,
            render: (tagExample, record) => (
                <Tag color={record.color}>
                    {tagExample}
                </Tag>
            ),
        },
    ];




    return (
        <div className="tag-body">

            {!showFirstTable && (
                <Row style={{ width: "100%", marginTop: "20px", justifyContent: "center" }}>
                    <Col lg={24}>
                        <div className="tag-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2>จัดการประเภท Tag</h2>
                                </div>
                                <div>
                                    <Button type="primary" style={{ marginRight: '10px' }} onClick={handleswitch}>+ เพิ่ม/แก้ไขแท๊ค</Button>
                                </div>
                            </div>
                            <hr
                                style={{
                                    border: '1px solid #ccc',
                                    margin: '10px 0',
                                    display: 'block',
                                }}
                            />

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                <Input
                                    placeholder="ค้นหาชื่อ Tag"
                                    value={searchKeyword}
                                    onChange={handleSearchChange}
                                    style={{
                                        width: '300px',
                                        height: '40px',
                                        borderRadius: '10px',
                                    }}
                                />

                            </div>


                            <div style={{ overflowX: 'auto' }}>
                                <Table
                                    columns={columns2}
                                    dataSource={data}
                                    pagination={false}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
            {showFirstTable && (

                <Row style={{ width: "100%", marginTop: "20px", justifyContent: "center" }}>
                    <Col lg={24}>
                        <div className="tag-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ArrowLeftOutlined
                                        style={{ marginRight: '8px', cursor: 'pointer' }} // เพิ่ม cursor pointer
                                        onClick={handleClick} // เมื่อคลิกจะเรียก handleClick
                                    />
                                    <h2>เพิ่ม/แก้ไขประเภทแดซบอร์ด (Tag)</h2>
                                </div>
                                <div>
                                    <Button
                                        type="primary"
                                        style={{
                                            backgroundColor: '#EBEBEB',
                                            color: '#000',
                                            marginRight: '10px'
                                        }}
                                        onClick={handleCancel}
                                    >
                                        ยกเลิก
                                    </Button>

                                    <Button type="primary" style={{ marginRight: '10px' }} onClick={handleSaveButton}>บันทึก</Button>
                                </div>
                            </div>
                            <hr
                                style={{
                                    border: '1px solid #ccc',
                                    margin: '10px 0',
                                    display: 'block',
                                }}
                            />

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                <h3>รายการ Tag</h3>
                                <Button
                                    icon={<PlusOutlined />}
                                    style={{
                                        width: '96px',
                                        height: '40px',
                                        padding: '0px 22px',
                                        borderRadius: '10px',
                                        borderColor: '#00B6A9',
                                        color: '#00B6A9',
                                    }}
                                    ghost
                                    onClick={handleAddTag}
                                >
                                    เพิ่ม
                                </Button>
                            </div>

                            <div style={{ overflowX: 'auto' }}>
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            )}


            <DeleteDialog
                isModalOpen={isModalDeleteOpen}
                closable={false}
                title="ยืนยันการลบ"
                cancelText="ยกเลิก"
                confirmText="ลบ"
                onCancel={() => {
                    setIsModalDeleteOpen(false);
                }}
                onConfirm={() => {
                    handleConfirmDelete();
                }}
                description="คุณต้องการลบใช่หรือไม่?"
            />

            {/* <SuccessDialog
                isModalOpen={isModalSuccessOpen}
                closable={false}
                title="สำเร็จ"
                detail="ลบข้อมูลสำเร็จ"
                onCancel={() => {
                    setIsModalSuccessOpen(false);
                }}
                onAfterClose={() => {
                    setTimeout(() => {
                        setIsModalSuccessOpen(false);
                    }, 3000);
                }}
            /> */}

            <SuccessDialog
                isModalOpen={isModalSuccessOpen}
                closable={false}
                type="bin"
                title="สำเร็จ"
                detail="ลบข้อมูลสำเร็จ"
                onCancel={() => {
                    setIsModalSuccessOpen(false);
                }}
                onAfterClose={() => {
                    setTimeout(() => {
                        setIsModalSuccessOpen(false);
                    }, 3000);
                }}
            />


            <SuccessDialog
                isModalOpen={isModalSuccessOpen2}
                closable={false}
                title="สำเร็จ"
                detail="บันทึกข้อมูลสำเร็จแล้ว"
                onCancel={() => {
                    setIsModalSuccessOpen2(false);
                }}
                onAfterClose={() => {
                    setTimeout(() => {
                        setIsModalSuccessOpen2(false);
                    }, 3000);
                }}
            />

            <FailedDialog
                isModalOpen={isModalFailedOpen}
                closable={false}
                title="ไม่สำเร็จ"
                detail={errorMessage}  // Dynamic error message here
                onCancel={() => {
                    setIsModalFailedOpen(false);
                }}
                onAfterClose={() => {
                    setIsModalFailedOpen(false);
                }}
                onConfirm={() => {
                    setIsModalFailedOpen(false);
                }}
            />

            <ConfirmDialog
                isModalOpen={isModalOpen}
                closable={false}
                title="ยืนยันการบันทึก"
                cancelText="ยกเลิก"
                confirmText="บันทึก"
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                onConfirm={() => {
                    handleSave();
                }}
                description="คุณต้องการบันทึกใช่หรือไม่?"
            />
        </div>
    );
};

export default TagPage;
