import React, { useEffect, useState } from "react";
import "./About.css";

const About = () => {
  return (
    <>
      <h1>About</h1>
    </>
  );
};

export default About;
