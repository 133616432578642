import React, { useEffect, useState } from "react";
import { Button, Modal, Flex, Space } from "antd";
import IconConfirmSubmit from "../../assets/icon/icon_save_blue.js";
import IconConfirmMail from "../../assets/icon/icon_confirm_mail.js";
import IconConfirmWarning from "../../assets/icon/icon_warning_dialog.js";

const ConfirmDialog = ({
  isModalOpen,
  closable,
  title,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  description,
  type,
}) => {
  const [icon, setIcon] = useState();

  useEffect(() => {
    if (isModalOpen) {
      switch (type) {
        case "mail":
          setIcon(<IconConfirmMail />);
          break;
        case "warning":
          setIcon(<IconConfirmWarning />);
          break;
        default:
          setIcon(<IconConfirmSubmit />);
          break;
      }
    }
  }, [isModalOpen]);
  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        footer={null}
        closable={closable}
        centered
        width={400}
        style={{
          borderRadius: "16px",
          overflow: "auto",
        }}
      >
        <Flex justify="center" align="center" vertical>
          {icon}
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: 10,
            }}
          >
            {title}
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 0,
              color: "#475467",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            {description}
          </span>
          <Space style={{ marginTop: 20 }}>
            <Button
              type="primary"
              // ghost={true}
              style={{
                width: 135,
                height: 40,
                // border: "1px solid #D0D5DD",
                color: "#2E323A",
                backgroundColor: "#EBEBEB",
                fontWeight: 500,
                fontSize: 16,
              }}
              onClick={() => {
                onCancel();
              }}
            >
              {cancelText}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onCancel();
                onConfirm();
              }}
              style={{
                width: 135,
                height: 40,
                backgroundColor: "#63E1E1",
                color: "#2E323A",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              {confirmText}
            </Button>
          </Space>
        </Flex>
      </Modal>
    </>
  );
};

export default ConfirmDialog;
