import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./DataSourceManagement.css";
import {
  Layout,
  Flex,
  Button,
  Divider,
  Input,
  Menu,
  Switch,
  Space,
  Tag,
} from "antd";
import {
  DeleteButton,
  SuccessDialog,
  DeleteDialog,
  FailedDialog,
} from "../../../../../components";
import { AiOutlineSearch } from "react-icons/ai";
import NoData from "../../../../../assets/img/no_data.js";
import { DataSourceService } from "../../../../../services/DataSourceService";
import { DataSourceCategoryService } from "../../../../../services/DataSourceCategoryService";

const { Header, Content, Sider } = Layout;

const DataSourceManagement = () => {
  const navigate = useNavigate();
  const [select, setSelect] = useState();
  const [dataSourceList, setDataSourceList] = useState([]);
  const [dataSourceCategory, setDataSourceCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectDelete, setSelectDelete] = useState();
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const passData = useLocation();
  let catagoryId = "";
  if (passData.state !== null && passData.state.catagoryId !== null) {
    catagoryId = passData.state.catagoryId;
  }
  const [prevId, setPrevId] = useState(catagoryId);

  useEffect(() => {
    setSelect(catagoryId);
    setPrevId(catagoryId);
  }, []);

  useEffect(() => {
    if (select) {
      fetchData();
    }
  }, [select]);

  useEffect(() => {
    if (!dataSourceCategory) {
      fetchDataSourceCategory();
    }
  }, [dataSourceCategory]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (select) {
        fetchData();
      }
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [keyword]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const params = {
        DataSourceCategoryIds: select,
        Keywords: keyword,
      };

      const response = await DataSourceService.search(params);
      setDataSourceList(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchDataSourceCategory = async () => {
    try {
      setLoading(true);

      const params = {
        // Keywords: ,
      };

      const response = await DataSourceCategoryService.search(params);
      setDataSourceCategory(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleUpdateActivate = async (checked, item) => {
    try {
      const data = {
        id: item.id,
        isActivated: checked,
      };

      await DataSourceService.updateActivated(item.id, data);
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteDashboardById = async () => {
    try {
      await DataSourceService.delete(selectDelete);
      setSelectDelete();
      setIsModalDeleteOpen(false);
      setIsModalSuccessOpen(true);
      setTimeout(() => {
        setIsModalSuccessOpen(false);
      }, 2000);
      fetchDataSourceCategory();
      fetchData();
    } catch (error) {
      setIsModalFailedOpen(true);
      console.error(error);
    }
  };

  const handleSearchDashboard = () => {
    fetchData();
  };

  return (
    <div className="datasource-management-body">
      <Layout>
        <Header
          style={{
            height: "70px",
            background: "#FFF",
            borderBottom: "1px solid #D0D5DD",
          }}
        >
          <Flex
            justify="space-between"
            align="center"
            style={{ height: "100%" }}
          >
            <p style={{ margin: "0", fontSize: "18px", fontWeight: "600" }}>
              จัดการข้อมูลดาต้าแค็ตตาล็อก
            </p>
            <Button
              type="primary"
              onClick={() =>
                navigate(
                  {
                    pathname: `/management/datasource-management-detail`,
                  },
                  { state: { catagoryId: select } }
                )
              }
              style={{
                width: "140px",
                height: "40px",
                fontSize: "16px",
                fontWeight: "500",
                background: "#63E1E1",
                borderRadius: "10px",
              }}
            >
              + เพิ่ม/แก้ไข
            </Button>
          </Flex>
        </Header>
        <Layout style={{ height: "100vh" }}>
          <Sider
            width={302}
            style={{
              margin: "14px",
              background: "#FFF",
              borderRadius: "8px",
            }}
          >
            <div style={{ padding: "14px" }}>
              <p style={{ margin: "0", fontSize: "16px", fontWeight: "600" }}>
                ประเภทดาต้าแค็ตตาล็อก (
                {dataSourceCategory?.pagination?.totalCount || 0})
              </p>
              <Input
                placeholder="ค้นหา ชื่อดาต้าแค็ตตาล็อก..."
                allowClear
                suffix={
                  <AiOutlineSearch
                    onClick={() => handleSearchDashboard()}
                    style={{
                      color: "rgba(0,0,0,.45)",
                      cursor: "pointer",
                    }}
                  />
                }
                onChange={(e) => setKeyword(e.target.value)}
                onPressEnter={() => handleSearchDashboard()}
                style={{ marginTop: "14px", borderRadius: "8px" }}
              />
            </div>
            <Divider style={{ margin: "0" }} />
            {dataSourceCategory && dataSourceCategory?.items?.length !== 0 ? (
              <div style={{ padding: "14px" }}>
                <Menu
                  // mode="inline"
                  defaultSelectedKeys={["1"]}
                  selectedKeys={prevId}
                  style={{
                    height: "100%",
                  }}
                >
                  {dataSourceCategory?.items
                    ?.sort((a, b) => a.sequence - b.sequence)
                    .map((item, index) => (
                      <Menu.Item
                        key={item.id}
                        onClick={() => {
                          setSelect(item.id);
                          setPrevId(item.id);
                        }}
                        style={{
                          paddingLeft: "14px",
                        }}
                      >
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          {item.name} ({item?.count || 0})
                        </span>
                      </Menu.Item>
                    ))}
                </Menu>
              </div>
            ) : (
              <Flex
                vertical
                justify="center"
                align="center"
                style={{ height: "80%" }}
              >
                <NoData />
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#667085",
                  }}
                >
                  ไม่มีข้อมูล
                </p>
              </Flex>
            )}
          </Sider>

          <Content
            style={{
              margin: "14px",
              marginLeft: "0",
              overflowY: "scroll",
            }}
          >
            {select ? (
              <>
                {dataSourceList?.items?.length !== 0 ? (
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{
                      display: "flex",
                    }}
                  >
                    {dataSourceList?.items
                      ?.sort((a, b) => a.sequence - b.sequence)
                      .map((item, index) => (
                        <div
                          key={index + 1}
                          style={{
                            padding: "14px",
                            background: "#FFF",
                            borderRadius: "8px",
                          }}
                        >
                          <Flex justify="space-between" align="center">
                            <span
                              style={{ fontSize: "14px", fontWeight: "600" }}
                            >
                              ใช้งาน/ไม่ใช้งาน :
                              <Switch
                                style={{ marginLeft: "10px" }}
                                checked={item?.isActivated}
                                onChange={(e) => handleUpdateActivate(e, item)}
                              />
                            </span>
                            <Space>
                              <DeleteButton
                                onClick={() => {
                                  setSelectDelete(item.id);
                                  setIsModalDeleteOpen(true);
                                }}
                              />
                            </Space>
                          </Flex>
                          <Divider />
                          <p style={{ fontSize: "16px", fontWeight: "600" }}>
                            ชื่อแหล่งที่มาของข้อมูล
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#667085",
                            }}
                          >
                            {item?.name || "-"}
                          </p>
                          <p style={{ fontSize: "16px", fontWeight: "600" }}>
                            ชื่อระบบฐานข้อมูล (API)
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#667085",
                            }}
                          >
                            {item?.apiName || "-"}
                          </p>
                          <p style={{ fontSize: "16px", fontWeight: "600" }}>
                            รายละเอียด
                          </p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item?.description || "-",
                            }}
                          ></p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            Tag
                          </p>
                          <Flex wrap gap="small">
                            {item?.dataSourceTags &&
                            item?.dataSourceTags?.length !== 0
                              ? item?.dataSourceTags.map((item, index) => (
                                  <Tag
                                    key={index + 1}
                                    bordered={false}
                                    color={item?.tagColor}
                                    style={{
                                      borderRadius: "8px",
                                      padding: "6px",
                                    }}
                                  >
                                    {item?.tagName}
                                  </Tag>
                                ))
                              : "-"}
                          </Flex>
                        </div>
                      ))}
                  </Space>
                ) : (
                  <Flex
                    vertical
                    justify="center"
                    align="center"
                    style={{ height: "100%" }}
                  >
                    <NoData />
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#667085",
                      }}
                    >
                      ไม่มีข้อมูล
                    </p>
                  </Flex>
                )}
              </>
            ) : (
              <Flex
                vertical
                justify="center"
                align="center"
                style={{ height: "100%" }}
              >
                <NoData />
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#667085",
                  }}
                >
                  กรุณาเลือกประเภทดาต้าแค็ตตาล็อก
                </p>
              </Flex>
            )}
          </Content>
        </Layout>
      </Layout>

      <SuccessDialog
        isModalOpen={isModalSuccessOpen}
        closable={false}
        type="bin"
        title="สำเร็จ"
        detail="ลบข้อมูลสำเร็จแล้ว"
        onCancel={() => {
          setIsModalSuccessOpen(false);
        }}
        onAfterClose={() => {
          setIsModalSuccessOpen(false);
        }}
      />

      <FailedDialog
        isModalOpen={isModalFailedOpen}
        closable={false}
        title="Failed"
        detail="Something went wrong. Please try again."
        onCancel={() => {
          setIsModalFailedOpen(false);
        }}
        onAfterClose={() => {
          setIsModalFailedOpen(false);
        }}
        onConfirm={() => {
          setIsModalFailedOpen(false);
        }}
      />

      <DeleteDialog
        isModalOpen={isModalDeleteOpen}
        closable={false}
        title="ยืนยันการลบ"
        description="คุณต้องการลบใช่หรือไม่?"
        cancelText="ยกเลิก"
        confirmText="ลบ"
        onCancel={() => {
          setSelectDelete();
          setIsModalDeleteOpen(false);
        }}
        onConfirm={() => {
          handleDeleteDashboardById();
        }}
      />
    </div>
  );
};

export default DataSourceManagement;
